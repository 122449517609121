<cybexer-widget-header [title]="'ui.nav.situationReport' | translate">
  <mat-slide-toggle
    class="switch-container"
    labelPosition="after"
    [ngModel]="(filter$ | async).pendingConfirmationOnly"
    (ngModelChange)="filterStateService.setFilter('pendingConfirmationOnly', $event)"
    *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
  >
    {{ 'ui.situationReport.pendingConfirmationOnly' | translate }}
  </mat-slide-toggle>
  <isa-filtered-select
    [options]="teams"
    [preselectedOption]="(filter$ | async).team"
    [optionKey]="'customName'"
    [optionValue]="'id'"
    [defaultOptionKey]="'ui.allTeams' | translate"
    [disabled]="userRole === ROLES.BLUE && !isAdmin"
    [label]="'ui.team' | translate"
    (selectionChange)="filterStateService.setFilter('team', $event)"
  ></isa-filtered-select>
  <mat-form-field>
    <mat-label
      ><i class="fas fa-filter" aria-hidden="true"></i>
      {{ 'ui.situationReport.filter' | translate }}</mat-label
    >
    <input id="textFilter" autocomplete="off" matInput [formControl]="textFilterFormControl" />
  </mat-form-field>
  @if (shouldShowAITools) {
    <button
      mat-raised-button
      [disabled]="checkedReportIds.size === 0"
      (click)="sendToAIAssessment()"
      *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
    >
      <i class="fas fa-magic-wand-sparkles status-ai-icon" aria-hidden="true"></i>
      {{ 'ui.situationReport.autoAssessSelected' | translate }}
    </button>
    <button
      mat-raised-button
      [matTooltip]="
        effectivePromptOverride?.id
          ? ('ui.situationReport.activeOverride' | translate) + ': ' + effectivePromptOverride?.name
          : ''
      "
      (click)="openAIFabricPromptOverrideDialog()"
      *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
    >
      <mat-icon>android</mat-icon>
      @if (effectivePromptOverride?.id) {
        Prompts ({{ effectivePromptOverride?.name }})
      } @else {
        Prompts (default)
      }
    </button>
  }
</cybexer-widget-header>
<div class="situation-reports cybexer-table-wrapper">
  <table mat-table matSort [dataSource]="dataSource" class="isa-table">
    <ng-container [matColumnDef]="COLUMNS.CHECKED">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          [checked]="isGlobalChecked"
          (change)="setGlobalCheckboxStatus($event.checked)"
          [indeterminate]="isCheckedStatusIndeterminate"
        ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let situationReportData">
        <mat-checkbox
          (change)="onCheckboxChange($event, situationReportData)"
          [disabled]="situationReportData.status === 'CONFIRMED'"
          [checked]="situationReportData.checked"
          (click)="$event.stopPropagation()"
        ></mat-checkbox>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TIMESTAMP">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.situationReport.time' | translate }}
      </th>
      <td mat-cell *matCellDef="let situationReportData">
        {{ situationReportData.timestamp | isaDate: 'medium' }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TEAM_NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ui.team' | translate }}</th>
      <td
        mat-cell
        *matCellDef="let situationReportData"
        [matTooltip]="situationReportData.blueTeamMember"
      >
        {{ situationReportData.teamId | isaTeamName }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.GRADE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.situationReport.grade' | translate }}
      </th>
      <td mat-cell *matCellDef="let situationReportData">{{ situationReportData.grade }}</td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.STATUS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        @if ((filter$ | async).pendingConfirmationOnly) {
          <i
            class="fas fa-filter status-filter-icon"
            aria-hidden="true"
            [matTooltip]="'ui.situationReport.filterUnconfirmed' | translate"
          ></i>
        }
        {{ 'ui.situationReport.status' | translate }}
      </th>
      <td mat-cell *matCellDef="let situationReportData">
        {{ 'ui.' + (situationReportData.status | lowercase) | translate }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.WHITE_TEAM_MEMBER">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.situationReport.whiteTeamMember' | translate }}
      </th>
      <td mat-cell *matCellDef="let situationReportData">
        @if (situationReportData.whiteTeamMember) {
          <i class="fas fa-user" aria-hidden="true"></i>
        }
        {{ situationReportData.whiteTeamMember }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.AI_ASSESSMENT_STATUS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.situationReport.autoAssessStatus' | translate }}
      </th>
      <td mat-cell *matCellDef="let reportData">
        {{ reportData.aiAssessmentStatus | isaReplaceUnderScores }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.AI_GRADE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.situationReport.autoGrade' | translate }}
      </th>
      <td mat-cell *matCellDef="let reportData">
        {{ reportData.aiGrade }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="clickable-row"
      *matRowDef="let situationReportData; columns: displayedColumns"
      (click)="openSituationReportConfirmDialog(situationReportData)"
    ></tr>
  </table>

  @if ((dataSource.filteredCount$ | async) === 0 && (dataSource.loading$ | async) === false) {
    <cybexer-no-data></cybexer-no-data>
  }
  @if (dataSource.loading$ | async) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
  <div class="paginator-container">
    @if (shouldShowAITools) {
      <div class="floating-label">
        <mat-label
          >{{ 'ui.situationReport.selectedCount' | translate }}:
          {{ checkedReportIds.size }}</mat-label
        >
        <button mat-icon-button [disabled]="checkedReportIds.size === 0" (click)="clearSelected()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    }
    <mat-paginator
      [length]="dataSource.filteredCount$ | async"
      [pageSizeOptions]="pageSizes"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
