export const TEMPLATE = [
  'ui.incidentReport.template.chronology.title',
  'ui.incidentReport.template.chronology.subtitle',
  'ui.incidentReport.template.chronology.content',
  'ui.incidentReport.template.impact.title',
  'ui.incidentReport.template.impact.subtitle',
  'ui.incidentReport.template.impact.content',
  'ui.incidentReport.template.source.title',
  'ui.incidentReport.template.source.subtitle',
  'ui.incidentReport.template.source.content',
  'ui.incidentReport.template.actionPlan.title',
  'ui.incidentReport.template.actionPlan.subtitle',
  'ui.incidentReport.template.actionPlan.content',
  'ui.incidentReport.template.additionalInfo.title',
  'ui.incidentReport.template.additionalInfo.content',
];

export const MOCK_TEMPLATE =
  '<h2>Chronology</h2>' +
  '<h3>What and when happened?</h3>' +
  '<p><br></p>' +
  '- Start time.<br>' +
  '- End time, if the incident has been solved.<br>' +
  '- Description of observed matters.<br>' +
  '<p><br></p>' +
  '<h2>Impact</h2>' +
  '<h3>How incident has impacted your information systems or daily operations?</h3>' +
  '<p><br></p>' +
  '- Availability?<br>' +
  '- Integrity?<br>' +
  '- Confidentiality?<br>' +
  '- No impact at all?<br>' +
  "- I don't know<br>" +
  '<p><br></p>' +
  '<h2>Source</h2>' +
  '<h3>What caused the incident?</h3>' +
  '<p><br></p>' +
  '- System failure<br>' +
  '- Environmental accident<br>' +
  '- Human error<br>' +
  '- Malicious activity<br>' +
  '- Third party error<br>' +
  '- Something else?<br>' +
  '<p><br></p>' +
  '<h2>Action Plan</h2>' +
  '<h3>How do you recover?</h3>' +
  '<p><br></p>' +
  '- What is estimated time of recovery?<br>' +
  '- What steps have you taken already?<br>' +
  '- What else do you plan to take to resolve the incident?<br>' +
  "- I don't have a plan<br>" +
  '<p><br></p>' +
  '<h2>Additional information</h2>' +
  '<p><br></p>' +
  '- Do you need some external help to mitigate the incident impact?<br>';
