export const NiceDictionaryJA = {
  // Category 'name' fields should not be translated here, but in ja/messages.properties
  categories: [
    {
      name: 'tasks',
      items: [
        {
          id: 'T0001',
          value:
            '必要なリソースを取得し、管理すること、リーダーシップのサポート、財務リソース、および主要なセキュリティ担当者を含む、情報技術（IT）セキュリティの目標と目的をサポートし、組織全体のリスクを軽減する。',
          softSkill: false,
        },
        {
          id: 'T0002',
          value:
            '効果的な企業継続運営プログラムを実施するために、財務資源を含む必要なリソースを取得します。',
          softSkill: false,
        },
        {
          id: 'T0003',
          value:
            'シニアマネジメント（例：最高情報責任者[CIO]）にリスクレベルとセキュリティ姿勢について助言する。',
          softSkill: false,
        },
        {
          id: 'T0004',
          value:
            '情報セキュリティプログラム、ポリシー、プロセス、システム、要素のコスト/ベネフィット分析について、上級管理職（例えば、CIO）に助言する。',
          softSkill: false,
        },
        {
          id: 'T0005',
          value:
            '組織のサイバーセキュリティ姿勢に影響を与える変更を適切な上級リーダーシップまたは認証責任者に通知する。',
          softSkill: false,
        },
        {
          id: 'T0006',
          value: '法的および立法手続きにおける支持団体の公式の立場。',
          softSkill: false,
        },
        {
          id: 'T0007',
          value: 'データ要件と仕様を分析し、定義する。',
          softSkill: false,
        },
        {
          id: 'T0008',
          value: 'データ容量要件の予想変更を分析し、計画する。',
          softSkill: false,
        },
        {
          id: 'T0009',
          value:
            '情報を分析して、新しいアプリケーションの開発や既存のアプリケーションの改修を決定し、推奨し、計画する。',
          softSkill: false,
        },
        {
          id: 'T0010',
          value:
            '組織のサイバー防御ポリシーと設定を分析し、規制と組織の指示に対するコンプライアンスを評価します。',
          softSkill: false,
        },
        {
          id: 'T0011',
          value:
            'ユーザーのニーズとソフトウェア要件を分析して、時間とコストの制約内での設計の実現可能性を判断します。',
          softSkill: false,
        },
        {
          id: 'T0012',
          value:
            '設計制約を分析し、トレードオフを分析し、詳細なシステムおよびセキュリティ設計を行い、ライフサイクルサポートを考慮する。',
          softSkill: false,
        },
        {
          id: 'T0013',
          value:
            'コーディングとテストの基準を適用し、ファジング、静的解析コードスキャニングツールを含むセキュリティテストツールを適用し、コードレビューを実施する。',
          softSkill: false,
        },
        {
          id: 'T0014',
          value: 'セキュアコードドキュメントを適用してください。',
          softSkill: false,
        },
        {
          id: 'T0015',
          value:
            '相互にインターフェースするアプリケーション、例えばビジネス・ツー・ビジネス（B2B）アプリケーションにセキュリティポリシーを適用する。',
          softSkill: false,
        },
        {
          id: 'T0016',
          value: 'システムのセキュリティ目標を達成するためにセキュリティポリシーを適用します。',
          softSkill: false,
        },
        {
          id: 'T0017',
          value:
            '組織の機密性、完全性、および可用性の要件を満たすために、サービス指向のセキュリティアーキテクチャの原則を適用します。',
          softSkill: false,
        },
        {
          id: 'T0018',
          value: 'システムに利用されるサイバーセキュリティ対策の有効性を評価する。',
          softSkill: false,
        },
        {
          id: 'T0019',
          value:
            'コンピューターシステムの脅威と脆弱性を評価し、セキュリティリスクプロファイルを開発する。',
          softSkill: false,
        },
        {
          id: 'T0020',
          value: 'サイバー防御ツールのためのコンテンツを開発する。',
          softSkill: false,
        },
        {
          id: 'T0021',
          value:
            '作業モデルまたは理論モデルを使用して、製品プロトタイプを構築、テスト、および修正します。',
          softSkill: false,
        },
        {
          id: 'T0022',
          value:
            '要件フェーズ中にセキュリティをプロセスに統合するために使用されるセキュリティコントロールを捉え、主要なセキュリティ目標を特定し、計画やスケジュールへの影響を最小限に抑えつつソフトウェアのセキュリティを最大化します。',
          softSkill: false,
        },
        {
          id: 'T0023',
          value:
            'ネットワークトラフィックを特徴付け、分析して、異常な活動やネットワークリソースへの潜在的な脅威を特定します。',
          softSkill: false,
        },
        {
          id: 'T0024',
          value: 'システムのサイバーセキュリティ報告に必要なデータを収集し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0025',
          value:
            '情報技術（IT）セキュリティの「価値」を組織のすべてのレベルのステークホルダーに伝える。',
          softSkill: false,
        },
        {
          id: 'T0026',
          value:
            'プログラム開発とその後の改訂のドキュメントを作成し、他の人がプログラムを理解できるようにコード化された指示にコメントを挿入します。',
          softSkill: false,
        },
        {
          id: 'T0027',
          value:
            'ログファイル、証拠、その他の情報を分析して、ネットワーク侵入の犯人を特定する最良の方法を決定します。',
          softSkill: false,
        },
        {
          id: 'T0028',
          value:
            '企業ネットワーク資産に対する認可された侵入テストを実施および/またはサポートする。',
          softSkill: false,
        },
        {
          id: 'T0029',
          value: '機能および接続性のテストを実施して、継続的な運用性を確保する。',
          softSkill: false,
        },
        {
          id: 'T0030',
          value: '効果的な学習環境を作るために、インタラクティブなトレーニング演習を実施する。',
          softSkill: false,
        },
        {
          id: 'T0031',
          value: '被害者や目撃者のインタビューを行い、容疑者のインタビューまたは尋問を行う。',
          softSkill: false,
        },
        {
          id: 'T0032',
          value:
            'アプリケーションのセキュリティ設計に対するプライバシー影響評価（PIA）を実施し、個人を特定できる情報（PII）の機密性と完全性を保護する適切なセキュリティコントロールを確保してください。',
          softSkill: false,
        },
        {
          id: 'T0033',
          value:
            'リスク分析、実現可能性調査、および/またはトレードオフ分析を実施し、機能要件と仕様を開発、文書化、および洗練する。',
          softSkill: false,
        },
        {
          id: 'T0034',
          value:
            'システムアナリスト、エンジニア、プログラマー、その他の関係者と協議し、アプリケーションを設計するため、プロジェクトの制限と能力、性能要件、インターフェースに関する情報を取得します。',
          softSkill: false,
        },
        {
          id: 'T0035',
          value:
            'ネットワークハブ、ルーター、スイッチの設定と最適化（例：上位プロトコル、トンネリング）。',
          softSkill: false,
        },
        {
          id: 'T0036',
          value:
            '侵入が確認されたことを確認し、動的分析によって侵入が特定された後、可能であれば新しい情報を発見する。',
          softSkill: false,
        },
        {
          id: 'T0037',
          value:
            'エンドユーザーがアクセスしやすくするために、情報スイートへのアクセスパス（例：リンクページ）を構築する。',
          softSkill: false,
        },
        {
          id: 'T0038',
          value: '顧客のインタビューと要件に基づいて脅威モデルを開発する。',
          softSkill: false,
        },
        {
          id: 'T0039',
          value: '顧客と相談して機能要件を評価する。',
          softSkill: false,
        },
        {
          id: 'T0040',
          value:
            'エンジニアリングスタッフと協議して、ハードウェアとソフトウェアのインターフェースを評価します。',
          softSkill: false,
        },
        {
          id: 'T0041',
          value:
            '企業全体のサイバーディフェンス技術者に対して専門的な技術サポートを調整し、提供して、サイバーディフェンスインシデントを解決します。',
          softSkill: false,
        },
        {
          id: 'T0042',
          value:
            'サイバーディフェンスアナリストと連携して、特殊なサイバーディフェンスアプリケーションのルールや署名（例：侵入検知/防御システム、アンチウイルス、コンテンツブラックリスト）の更新を管理および運用します。',
          softSkill: false,
        },
        {
          id: 'T0043',
          value:
            'エンタープライズ全体のサイバーディフェンススタッフと連携して、ネットワークアラートを検証します。',
          softSkill: false,
        },
        {
          id: 'T0044',
          value:
            '利害関係者と協力して、企業の事業継続プログラム、戦略、およびミッションアシュアランスを確立します。',
          softSkill: false,
        },
        {
          id: 'T0045',
          value:
            'システムアーキテクトおよび開発者と連携し、必要に応じて設計ソリューションの開発に監督を提供します。',
          softSkill: false,
        },
        {
          id: 'T0046',
          value:
            'エラーを修正し、望ましい結果が得られるようにプログラムを再確認して適切な変更を行います。',
          softSkill: false,
        },
        {
          id: 'T0047',
          value:
            'インシデントデータを相関させて特定の脆弱性を特定し、迅速な修復を可能にする推奨事項を提案します。',
          softSkill: false,
        },
        {
          id: 'T0048',
          value:
            '証拠（つまり、法医学的イメージ）の法医学的に正確な複製を作成し、元の証拠が意図せずに変更されないようにします。これは、データ復旧および分析プロセスに使用されます。これには、ハードドライブ、フロッピーディスク、CD、PDA、携帯電話、GPS、およびすべてのテープ形式が含まれますが、これに限定されません。',
          softSkill: false,
        },
        {
          id: 'T0049',
          value: '技術的手段を用いて押収したデータを解読する。',
          softSkill: false,
        },
        {
          id: 'T0050',
          value:
            '定義し、重大な障害イベント後の部分的または完全なシステム復旧のために必要な重要なシステム機能またはビジネス機能を優先順位付けします。',
          softSkill: false,
        },
        {
          id: 'T0051',
          value:
            'システムの重要な機能に基づいて適切なシステム可用性のレベルを定義し、システム要件が適切な災害復旧および業務継続要件を特定することを確認します。これには、適切なフェイルオーバー/代替サイト要件、バックアップ要件、およびシステムの回復/復元のための物資サポート要件が含まれます。',
          softSkill: false,
        },
        {
          id: 'T0052',
          value: '顧客の要件に基づいてプロジェクトの範囲と目標を定義する。',
          softSkill: false,
        },
        {
          id: 'T0053',
          value: 'サイバーセキュリティまたはサイバーセキュリティ対応製品を設計・開発する。',
          softSkill: false,
        },
        {
          id: 'T0054',
          value:
            '組織の標準、ビジネスルール、およびニーズとの互換性を確保するために、グループポリシーとアクセス制御リストを設計します。',
          softSkill: false,
        },
        {
          id: 'T0055',
          value:
            'サイバーセキュリティ要件を適切に対応するためのハードウェア、オペレーティングシステム、およびソフトウェアアプリケーションを設計する。',
          softSkill: false,
        },
        {
          id: 'T0056',
          value:
            '適切なデータバックアップ機能を全体のシステム設計に設計または統合し、セキュアなシステムバックアップとバックアップデータの保護された保管のための適切な技術的および手続き的プロセスが存在することを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0057',
          value:
            '設計、開発、およびソフトウェアシステムを修正すること。科学的分析と数学的モデルを使用して、設計の結果と影響を予測し、測定する。',
          softSkill: false,
        },
        {
          id: 'T0058',
          value: 'テスト結果に基づいて開発された能力の保証レベルを決定する。',
          softSkill: false,
        },
        {
          id: 'T0059',
          value:
            '犯罪、違反、またはコンピューターとインターネットを利用した疑わしい活動を調査する計画を策定する。',
          softSkill: false,
        },
        {
          id: 'T0060',
          value: '情報エンドユーザーのニーズと要件を理解することを発展させる。',
          softSkill: false,
        },
        {
          id: 'T0061',
          value: 'システムテストと検証手順および文書の開発と指導を行う。',
          softSkill: false,
        },
        {
          id: 'T0062',
          value: '設計手順およびプロセスの要件、能力、および制約を開発し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0063',
          value: 'システム管理の標準運用手順を開発し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0064',
          value:
            'データマイニングおよびデータウェアハウジングのプログラム、プロセス、要件をレビューし、検証します。',
          softSkill: false,
        },
        {
          id: 'T0065',
          value: 'ネットワークのバックアップとリカバリ手順を開発して実装する。',
          softSkill: false,
        },
        {
          id: 'T0066',
          value: '戦略計画を策定し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0067',
          value: '技術仕様に一致するアーキテクチャまたはシステムコンポーネントを開発する。',
          softSkill: false,
        },
        {
          id: 'T0068',
          value: 'データ標準、ポリシー、および手順を開発する。',
          softSkill: false,
        },
        {
          id: 'T0069',
          value:
            'コンポーネントおよびインターフェース仕様のセキュリティ設計文書を詳細に作成し、システム設計および開発を支援します。',
          softSkill: false,
        },
        {
          id: 'T0070',
          value:
            '開発中のシステムに対して災害復旧計画および運用継続計画を策定し、システムが本番環境に入る前にテストを実施することを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0071',
          value:
            '政府機関（例：非機密、秘密、極秘）に主に適用される、複数のセキュリティレベルの要件または複数の分類レベルのデータ処理の要件を持つシステムおよびネットワークのためのサイバーセキュリティ設計を開発・統合する。',
          softSkill: false,
        },
        {
          id: 'T0072',
          value: 'リスク、コンプライアンス、および保証努力を監視および測定する方法を開発する。',
          softSkill: false,
        },
        {
          id: 'T0073',
          value: '意図された対象者に適切な新しい啓発・訓練資料を開発するか、既存のものを特定する。',
          softSkill: false,
        },
        {
          id: 'T0074',
          value: '政策、プログラム、および実施のためのガイドラインを策定する。',
          softSkill: false,
        },
        {
          id: 'T0075',
          value: '確立された報告手順に従って、調査結果の技術的要約を提供する。',
          softSkill: false,
        },
        {
          id: 'T0076',
          value:
            '脆弱性を解決するためのリスク軽減戦略を開発し、必要に応じてシステムまたはシステムコンポーネントのセキュリティ変更を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0077',
          value: '安全なコードとエラー処理を開発する。',
          softSkill: false,
        },
        {
          id: 'T0078',
          value:
            'システムおよび/またはアプリケーションのための具体的なサイバーセキュリティ対策とリスク軽減戦略を開発する。',
          softSkill: false,
        },
        {
          id: 'T0079',
          value:
            'リスク、コンプライアンス、および保証の取り組みがソフトウェアアプリケーション、システム、およびネットワーク環境レベルでのセキュリティ、レジリエンス、および信頼性の要件に適合するように仕様を開発します。',
          softSkill: false,
        },
        {
          id: 'T0080',
          value: '仕様と要件に対応するテストプランを作成します。',
          softSkill: false,
        },
        {
          id: 'T0081',
          value: 'ネットワーク接続の問題を診断する。',
          softSkill: false,
        },
        {
          id: 'T0082',
          value:
            'ドキュメントおよび組織の情報セキュリティ、サイバーセキュリティアーキテクチャ、およびシステムセキュリティエンジニアリング要件を、取得ライフサイクル全体で管理します。',
          softSkill: false,
        },
        {
          id: 'T0083',
          value: 'システム運用における予備的または残存するセキュリティリスクの声明案を作成する。',
          softSkill: false,
        },
        {
          id: 'T0084',
          value: '安全な構成管理プロセスを実施する。',
          softSkill: false,
        },
        {
          id: 'T0085',
          value:
            'すべてのシステムのセキュリティ運用と保守活動が適切に文書化され、必要に応じて更新されるようにしてください。',
          softSkill: false,
        },
        {
          id: 'T0086',
          value:
            'システム設計に統合された商用製品のセキュリティパッチの適用が、意図された運用環境の管理権限によって指示されたタイムラインを満たすことを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0087',
          value:
            'すべてのデジタルメディアが連邦証拠規則に従って取得された場合、証拠の保管経路が守られていることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0088',
          value:
            'サイバーセキュリティ対応製品またはその他の補償セキュリティ制御技術が、特定されたリスクを許容可能なレベルまで減少させることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0089',
          value:
            'セキュリティ改善アクションが評価され、検証され、必要に応じて実施されるようにします。',
          softSkill: false,
        },
        {
          id: 'T0090',
          value:
            '取得または開発されたシステムおよびアーキテクチャが組織のサイバーセキュリティアーキテクチャガイドラインと一致していることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0091',
          value:
            'ネットワーク環境に対して、サイバーセキュリティの検査、テスト、およびレビューが調整されるようにしてください。',
          softSkill: false,
        },
        {
          id: 'T0092',
          value:
            'そのシステムおよび/または組織の継続計画にサイバーセキュリティ要件が統合されていることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0093',
          value:
            '保護および検出機能がISセキュリティエンジニアリングアプローチを使用して取得または開発され、組織レベルのサイバーセキュリティアーキテクチャと一致していることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0094',
          value: 'ステークホルダーとのコミュニケーションチャネルを確立し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0095',
          value:
            '組織の全体的なセキュリティ戦略に合わせて、全体的なエンタープライズ情報セキュリティアーキテクチャ（EISA）を確立します。',
          softSkill: false,
        },
        {
          id: 'T0096',
          value:
            'インシデント対応チームと他のグループ（内部（例：法務部）および外部（例：法執行機関、ベンダー、広報専門家））との関係を、該当する場合に確立します。',
          softSkill: false,
        },
        {
          id: 'T0097',
          value:
            '開発努力を評価し、承認することで、基本的なセキュリティ対策が適切にインストールされていることを確認します。',
          softSkill: false,
        },
        {
          id: 'T0098',
          value: '契約を評価して、資金、法的、およびプログラム要件との遵守を確保します。',
          softSkill: false,
        },
        {
          id: 'T0099',
          value: '意思決定プロセスにおけるコスト/ベネフィット、経済的、リスク分析を評価する。',
          softSkill: false,
        },
        {
          id: 'T0100',
          value:
            '報告形式の要件、コスト制約、セキュリティ制限の必要性などの要因を評価して、ハードウェア構成を決定します。',
          softSkill: false,
        },
        {
          id: 'T0101',
          value: '既存のトレーニングプログラムの有効性と包括性を評価する。',
          softSkill: false,
        },
        {
          id: 'T0102',
          value: '法律、規制、政策、基準、または手順の効果を評価する。',
          softSkill: false,
        },
        {
          id: 'T0103',
          value: '問題に関連する情報を復旧したデータから調査する。',
          softSkill: false,
        },
        {
          id: 'T0104',
          value: 'コンピュータネットワーク攻撃分析を犯罪および対情報活動の調査と作戦に統合する。',
          softSkill: false,
        },
        {
          id: 'T0105',
          value:
            'コンポーネントまたは要素を特定し、それらの要素にセキュリティ機能を割り当て、要素間の関係を説明する。',
          softSkill: false,
        },
        {
          id: 'T0106',
          value: '組織のセキュリティ目標に対処するための代替情報セキュリティ戦略を特定する。',
          softSkill: false,
        },
        {
          id: 'T0107',
          value:
            'テストおよび新システムの実装中に発生した技術的問題を特定し、対処する（例えば、互換性のない通信プロトコルを特定し、回避策を見つける）。',
          softSkill: false,
        },
        {
          id: 'T0108',
          value:
            '組織のステークホルダーと協力して、重要なビジネス機能を特定し、優先順位を付けます。',
          softSkill: false,
        },
        {
          id: 'T0109',
          value:
            'システム障害後やシステム復旧イベント中に、継続性と可用性のための全体的なシステム要件に基づいて、復旧または回復のために必要な重要な機能またはビジネス機能をサポートするために必要な重要なシステム機能またはサブシステムを特定し、優先順位を付けます。',
          softSkill: false,
        },
        {
          id: 'T0110',
          value:
            '法律に違反し、特定の法的措置を必要とするかどうかを判断するために、セキュリティインシデントを特定し、評価します。',
          softSkill: false,
        },
        {
          id: 'T0111',
          value: '基本的な一般的なコーディングの欠陥を高いレベルで特定する。',
          softSkill: false,
        },
        {
          id: 'T0112',
          value:
            '証拠となる「価値」のあるデータまたは情報を特定し、対情報活動および犯罪捜査を支援します。',
          softSkill: false,
        },
        {
          id: 'T0113',
          value: 'デジタル証拠を意図せずに変更することなく検査および分析のために特定する。',
          softSkill: false,
        },
        {
          id: 'T0114',
          value: '犯罪の証明要素を特定する。',
          softSkill: false,
        },
        {
          id: 'T0115',
          value:
            '新技術または技術のアップグレードに伴う情報技術（IT）セキュリティプログラムの影響を特定する。',
          softSkill: false,
        },
        {
          id: 'T0116',
          value: '組織のポリシーの利害関係者を特定する。',
          softSkill: false,
        },
        {
          id: 'T0117',
          value:
            '企業のコンピューターシステムにおけるソフトウェア開発において、集中型および分散型環境内でのセキュリティの影響を特定し、方法論を適用する。',
          softSkill: false,
        },
        {
          id: 'T0118',
          value:
            'ソフトウェアの安定稼働と管理におけるセキュリティ問題を特定し、製品がその寿命の終わりに達したときに講じるべきセキュリティ対策を組み込む。',
          softSkill: false,
        },
        {
          id: 'T0119',
          value:
            'システム内での使用のためにサイバーセキュリティまたはサイバーセキュリティ対応製品を特定、評価、推奨し、推奨製品が組織の評価および検証要件に準拠していることを確認します。',
          softSkill: false,
        },
        {
          id: 'T0120',
          value:
            '文書または物理的証拠を特定し、収集し、押収すること。これには、サイバー侵入事件、調査、および操作に関連するデジタルメディアやログも含まれます。',
          softSkill: false,
        },
        {
          id: 'T0121',
          value: '新しいシステム設計手順、テスト手順、および品質基準を実装します。',
          softSkill: false,
        },
        {
          id: 'T0122',
          value: '新しいシステムまたは既存のシステムにセキュリティ設計を実装する。',
          softSkill: false,
        },
        {
          id: 'T0123',
          value:
            'システムおよび/またはアプリケーションに特定のサイバーセキュリティ対策を実装する。',
          softSkill: false,
        },
        {
          id: 'T0124',
          value:
            'システム設計にサイバーセキュリティの脆弱性対策を組み込む（例：サイバーセキュリティ脆弱性アラート）。',
          softSkill: false,
        },
        {
          id: 'T0125',
          value:
            'ネットワークインフラストラクチャデバイスのオペレーティングシステムソフトウェア（例：IOS、ファームウェア）をインストールおよび維持する。',
          softSkill: false,
        },
        {
          id: 'T0126',
          value: 'ネットワークハブ、ルーター、およびスイッチをインストールまたは交換します。',
          softSkill: false,
        },
        {
          id: 'T0127',
          value:
            '情報セキュリティおよび/またはサイバーセキュリティポリシーを統合し、調整して、システム分析がセキュリティ要件を満たすことを確保します。',
          softSkill: false,
        },
        {
          id: 'T0128',
          value:
            '実用的な場合には、システムソフトウェアの更新またはパッチを自動化する機能を統合し、システムの運用環境の現在および予測されるパッチタイムライン要件に基づいて、システムソフトウェアの手動更新およびパッチのプロセスと手順を開発します。',
          softSkill: false,
        },
        {
          id: 'T0129',
          value: '既存のネットワークアーキテクチャに新しいシステムを統合する。',
          softSkill: false,
        },
        {
          id: 'T0130',
          value:
            '外部組織（例：広報、法執行機関、司令部またはコンポーネント監察総監）と連携して、インシデントおよびその他のコンピュータネットワーク防御情報の適切かつ正確な普及を確保します。',
          softSkill: false,
        },
        {
          id: 'T0131',
          value: '法律、規制、ポリシー、基準、または手順を特定の問題に解釈し適用する。',
          softSkill: false,
        },
        {
          id: 'T0132',
          value: '新しい情報技術の能力に関連して、セキュリティ要件を解釈および/または承認する。',
          softSkill: false,
        },
        {
          id: 'T0133',
          value:
            '非遵守のパターンを解釈して、リスクのレベルや企業のサイバーセキュリティプログラムの全体的な有効性に与える影響を判断します。',
          softSkill: false,
        },
        {
          id: 'T0134',
          value:
            '情報技術（IT）のセキュリティ優先順位をセキュリティ戦略と一致させ、リードしてください。',
          softSkill: false,
        },
        {
          id: 'T0135',
          value: '情報セキュリティの予算、スタッフィング、および契約を主導し、監督する。',
          softSkill: false,
        },
        {
          id: 'T0136',
          value: '組織のポリシーに従って基本的なシステムセキュリティを維持する。',
          softSkill: false,
        },
        {
          id: 'T0137',
          value: 'データベース管理システムソフトウェアを維持する。',
          softSkill: false,
        },
        {
          id: 'T0138',
          value:
            'サイバー防衛監査ミッションを支援するために、展開可能なサイバー防衛監査ツールキット（例：特殊なサイバー防衛ソフトウェアおよびハードウェア）を維持する。',
          softSkill: false,
        },
        {
          id: 'T0139',
          value:
            'ディレクトリレプリケーションサービスを維持し、最適化されたルーティングを介して情報がリアサーバーからフォワードユニットに自動的にレプリケートされるようにします。',
          softSkill: false,
        },
        {
          id: 'T0140',
          value:
            '公開、購読、およびアラート機能を通じて情報交換を維持し、ユーザーが必要に応じて重要な情報を送受信できるようにします。',
          softSkill: false,
        },
        {
          id: 'T0141',
          value: '情報システムの保証および認定資料を維持する。',
          softSkill: false,
        },
        {
          id: 'T0142',
          value:
            'サイバーディフェンス監査に特に関連するサイバーディフェンスポリシー、規制、およびコンプライアンス文書の適用知識を維持する。',
          softSkill: false,
        },
        {
          id: 'T0143',
          value: 'テスト結果に基づいて推奨事項を提示する。',
          softSkill: false,
        },
        {
          id: 'T0144',
          value: 'アカウントの管理、ネットワーク権限、システムおよび機器へのアクセス。',
          softSkill: false,
        },
        {
          id: 'T0145',
          value: '認定パッケージを管理および承認する（例：ISO/IEC 15026-2）。',
          softSkill: false,
        },
        {
          id: 'T0146',
          value: 'データのコンパイル、カタログ作成、キャッシング、配布、および取得を管理します。',
          softSkill: false,
        },
        {
          id: 'T0147',
          value: '組織の状況認識を維持するために、情報セキュリティデータソースの監視を管理します。',
          softSkill: false,
        },
        {
          id: 'T0148',
          value:
            '企業の構成員のためのコンピュータネットワーク防御ガイダンス（例：TCNOs、作戦概念、ネットワークアナリストレポート、NTSM、MTOs）の公開を管理する。',
          softSkill: false,
        },
        {
          id: 'T0149',
          value: 'サイバー防衛情報の脅威またはターゲット分析の管理および企業内での脅威情報の生成。',
          softSkill: false,
        },
        {
          id: 'T0150',
          value:
            'システムの情報技術（IT）セキュリティ、レジリエンス、および信頼性要件との適合性を監視および評価します。',
          softSkill: false,
        },
        {
          id: 'T0151',
          value:
            '企業のサイバーセキュリティ対策の有効性を監視し、評価して、意図した保護レベルを提供していることを確認します。',
          softSkill: false,
        },
        {
          id: 'T0152',
          value: 'データベースの監視と維持を行い、最適なパフォーマンスを確保する。',
          softSkill: false,
        },
        {
          id: 'T0153',
          value: 'ネットワークの容量とパフォーマンスを監視する。',
          softSkill: false,
        },
        {
          id: 'T0154',
          value: 'ナレッジマネジメントの資産とリソースの使用状況を監視し、報告する。',
          softSkill: false,
        },
        {
          id: 'T0155',
          value:
            'インシデントを文書化し、エスカレーションする（イベントの履歴、状況、さらなる対応のための潜在的影響を含む）ことは、環境に対して継続的かつ直接的な影響を与える可能性がある。',
          softSkill: false,
        },
        {
          id: 'T0156',
          value: '構成管理に関して監視し、推奨事項を提案する。',
          softSkill: false,
        },
        {
          id: 'T0157',
          value: '情報セキュリティのトレーニングと認識プログラムを監督する。',
          softSkill: false,
        },
        {
          id: 'T0158',
          value: 'セキュリティ評価および認証プロセス中に情報セキュリティリスク評価に参加する。',
          softSkill: false,
        },
        {
          id: 'T0159',
          value:
            'コンピュータ環境のサイバーセキュリティプログラム計画および要件の開発または変更に参加する。',
          softSkill: false,
        },
        {
          id: 'T0160',
          value: '情報が外部の者から保護されるように、ネットワークの脆弱性を修正してください。',
          softSkill: false,
        },
        {
          id: 'T0161',
          value:
            'さまざまなソース（例：個々のホストログ、ネットワークトラフィックログ、ファイアウォールログ、侵入検知システム[IDS]ログ）からのログファイルを分析し、ネットワークセキュリティへの潜在的な脅威を特定します。',
          softSkill: false,
        },
        {
          id: 'T0162',
          value: 'データベースのバックアップとリカバリを実行して、データの整合性を保証します。',
          softSkill: false,
        },
        {
          id: 'T0163',
          value:
            'サイバーディフェンスインシデントのトリアージを実行し、範囲、緊急性、および潜在的な影響を判断し、特定の脆弱性を特定し、迅速な修復を可能にする推奨事項を行います。',
          softSkill: false,
        },
        {
          id: 'T0164',
          value: 'サイバーディフェンスのトレンド分析と報告を行う。',
          softSkill: false,
        },
        {
          id: 'T0165',
          value:
            'ドライブの「イメージ」をブートして、元のドライブがなくても、ユーザーが見たかもしれない侵入をネイティブ環境で確認するために動的分析を行う。',
          softSkill: false,
        },
        {
          id: 'T0166',
          value:
            'さまざまなソースから収集した情報を使用してイベントの相関を実行し、状況認識を得て、観測された攻撃の効果を判断します。',
          softSkill: false,
        },
        {
          id: 'T0167',
          value: 'ファイル署名解析を実行します。',
          softSkill: false,
        },
        {
          id: 'T0168',
          value: 'データベースに対してハッシュ比較を実行します。',
          softSkill: false,
        },
        {
          id: 'T0169',
          value:
            '開発されたアプリケーションおよび/またはシステムのサイバーセキュリティテストを実施する。',
          softSkill: false,
        },
        {
          id: 'T0170',
          value:
            '初期の法的に適切な画像収集を実行し、企業システムにおける可能な緩和/修復を判断するために検査します。',
          softSkill: false,
        },
        {
          id: 'T0171',
          value: 'セキュリティ機能とレジリエンスアタックのための統合品質保証テストを実施する。',
          softSkill: false,
        },
        {
          id: 'T0172',
          value: 'リアルタイムでの法医学的分析を実行する（例：HelixとLiveViewを併用して）。',
          softSkill: false,
        },
        {
          id: 'T0173',
          value: 'タイムライン分析を実行します。',
          softSkill: false,
        },
        {
          id: 'T0174',
          value:
            'ニーズ分析を実施して、新規および改善されたビジネスプロセスソリューションの機会を特定します。',
          softSkill: false,
        },
        {
          id: 'T0175',
          value:
            'リアルタイムのサイバー防御インシデント対応（例：フォレンジック収集、侵入相関と追跡、脅威分析、および直接的なシステム修復）タスクを実行して、展開可能なインシデント対応チーム（IRT）をサポートします。',
          softSkill: false,
        },
        {
          id: 'T0176',
          value:
            'セキュアなプログラミングを行い、コード内の潜在的な欠陥を特定して脆弱性を軽減します。',
          softSkill: false,
        },
        {
          id: 'T0177',
          value:
            'セキュリティレビューを実施し、セキュリティアーキテクチャのギャップを特定し、セキュリティリスク管理計画を策定する。',
          softSkill: false,
        },
        {
          id: 'T0178',
          value:
            'セキュリティレビューを実施し、セキュリティアーキテクチャのセキュリティギャップを特定し、リスク軽減戦略への推奨事項として結果をまとめる。',
          softSkill: false,
        },
        {
          id: 'T0179',
          value: '静的メディア分析を実行します。',
          softSkill: false,
        },
        {
          id: 'T0180',
          value:
            '特殊なサイバーディフェンスアプリケーションおよびシステム（例：アンチウイルス、監査および修復）または仮想プライベートネットワーク（VPN）デバイスのシステム管理を行うこと。これには、インストール、設定、保守、バックアップ、および復元が含まれます。',
          softSkill: false,
        },
        {
          id: 'T0181',
          value:
            'アプリケーションやシステムが大きな変更を受ける際には、リスク分析（例：脅威、脆弱性、発生確率）を実施すること。',
          softSkill: false,
        },
        {
          id: 'T0182',
          value: 'Tier 1、2、および3のマルウェア分析を実行します。',
          softSkill: false,
        },
        {
          id: 'T0183',
          value:
            '実際の結果と期待される結果を比較する検証ステップを実行し、違いを分析して影響とリスクを特定します。',
          softSkill: false,
        },
        {
          id: 'T0184',
          value:
            'システムおよびネットワークの初期インストールのためのセキュリティ認証レビューと保証ケース開発を計画し、実施する。',
          softSkill: false,
        },
        {
          id: 'T0185',
          value: '知識管理プロジェクトの計画と管理を行う。',
          softSkill: false,
        },
        {
          id: 'T0186',
          value: '計画、実行、およびデータ冗長性とシステム回復手順の検証。',
          softSkill: false,
        },
        {
          id: 'T0187',
          value: '運動結果やシステム環境に基づいて、計画と修正または調整を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0188',
          value:
            '技術的および手続き的な調査結果を特定する監査報告書を準備し、推奨される是正戦略/解決策を提供します。',
          softSkill: false,
        },
        {
          id: 'T0189',
          value:
            '入力、出力、論理操作を説明する詳細なワークフローチャートと図を準備し、それらをコンピュータ言語でコード化された一連の指示に変換します。',
          softSkill: false,
        },
        {
          id: 'T0190',
          value:
            'デジタルメディアをイメージングのために準備するには、データの整合性を確保してください（例：標準運用手順に従ったライトブロッカー）。',
          softSkill: false,
        },
        {
          id: 'T0191',
          value:
            '特定の情報技術（IT）ソリューションが必要であることを正当化するためのユースケースを準備する。',
          softSkill: false,
        },
        {
          id: 'T0192',
          value:
            'ネットワークシステムの運用に関するセキュリティの計画、指示、ガイダンス、および標準運用手順を準備、配布、維持する。',
          softSkill: false,
        },
        {
          id: 'T0193',
          value: '現場を処理する。',
          softSkill: false,
        },
        {
          id: 'T0194',
          value:
            'すべてのシステムのセキュリティ実装、運用、および保守活動を適切に文書化し、必要に応じて更新してください。',
          softSkill: false,
        },
        {
          id: 'T0195',
          value:
            'ミッションの要件に基づいて（ウェブベースのポータルまたはその他の手段を通じて）関連情報の管理された流れを提供する。',
          softSkill: false,
        },
        {
          id: 'T0196',
          value:
            'プロジェクトのコスト、デザインコンセプト、またはデザインの変更についてアドバイスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0197',
          value:
            'ソフトウェアアプリケーション、システム、またはネットワークの正確な技術評価を提供し、関連するサイバーセキュリティコンプライアンスに対するセキュリティ姿勢、能力、および脆弱性を文書化します。',
          softSkill: false,
        },
        {
          id: 'T0198',
          value:
            'ネットワークイベントとサイバー防御実践に関連する活動の日次サマリーレポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0199',
          value:
            '事業継続計画の策定に向けた企業のサイバーセキュリティとサプライチェーンリスク管理のガイダンスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0200',
          value:
            'ネットワーク要件に関するフィードバックを提供すること、ネットワークアーキテクチャとインフラを含む。',
          softSkill: false,
        },
        {
          id: 'T0201',
          value: '顧客または設置チームに開発したシステムを実装するためのガイドラインを提供する。',
          softSkill: false,
        },
        {
          id: 'T0202',
          value: 'リーダーシップへのサイバーセキュリティガイダンスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0203',
          value:
            '作業内容明細書およびその他の適切な調達文書に含めるべきセキュリティ要件に関する意見を提供する。',
          softSkill: false,
        },
        {
          id: 'T0204',
          value: '実施計画と標準運用手順に意見を提供する。',
          softSkill: false,
        },
        {
          id: 'T0205',
          value:
            'リスク管理フレームワークのプロセス活動および関連文書（例：システムライフサイクルサポートプラン、運用概念、運用手順、保宜トレーニング資料）への入力を提供する。',
          softSkill: false,
        },
        {
          id: 'T0206',
          value:
            '情報技術（IT）担当者に対してリーダーシップと方向性を提供し、運用担当者の責任に見合ったサイバーセキュリティの認識、基本、リテラシー、およびトレーニングが提供されるようにします。',
          softSkill: false,
        },
        {
          id: 'T0207',
          value: '継続的な最適化と問題解決のサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0208',
          value: '提案を提供し、改善とアップグレードの可能性について説明してください。',
          softSkill: false,
        },
        {
          id: 'T0209',
          value:
            'データ構造とデータベースに関する推奨事項を提供し、レポート/管理情報の正確で質の高い生成を保証します。',
          softSkill: false,
        },
        {
          id: 'T0210',
          value: '新しいデータベース技術とアーキテクチャに関する推薦を提供する。',
          softSkill: false,
        },
        {
          id: 'T0211',
          value:
            'システム関連の入力を提供し、作業明細書およびその他の適切な調達文書に含めるべきサイバーセキュリティ要件を含めます。',
          softSkill: false,
        },
        {
          id: 'T0212',
          value: '適切な人員にデジタル証拠に関する技術的支援を提供する。',
          softSkill: false,
        },
        {
          id: 'T0213',
          value:
            '技術文書、インシデント報告、コンピュータ検査の結果、要約、その他の状況認識情報を上級本部に提供する。',
          softSkill: false,
        },
        {
          id: 'T0214',
          value:
            'さまざまなソースからのネットワークアラートを受信し、分析して、そのようなアラートの可能性のある原因を特定します。',
          softSkill: false,
        },
        {
          id: 'T0215',
          value: '適切な行動をとり、必要に応じてインシデントを報告してください。',
          softSkill: false,
        },
        {
          id: 'T0216',
          value:
            '特定のオペレーティングシステムを示す法医学的アーティファクトを認識し、正確に報告する。',
          softSkill: false,
        },
        {
          id: 'T0217',
          value:
            'ソフトウェア受け入れフェーズにおけるセキュリティの影響を含む完了基準、リスク受け入れとドキュメント、共通基準、独立したテストの方法について説明します。',
          softSkill: false,
        },
        {
          id: 'T0218',
          value:
            'レビューの結果に基づいて、新しいまたは改訂されたセキュリティ、レジリエンス、信頼性の対策を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0219',
          value:
            '組織のサイバーセキュリティ要件を安全に運用および維持するために必要なリソースの割り当てを推奨します。',
          softSkill: false,
        },
        {
          id: 'T0220',
          value: '法律、規則、ポリシー、基準、または手順の間の矛盾を解決する。',
          softSkill: false,
        },
        {
          id: 'T0221',
          value:
            'レビュー認証と保証文書を確認して、各ソフトウェアアプリケーション、システム、およびネットワークのリスクレベルが許容範囲内であることを確認します。',
          softSkill: false,
        },
        {
          id: 'T0222',
          value: '関係者と既存のポリシーと提案されたポリシーをレビューする。',
          softSkill: false,
        },
        {
          id: 'T0223',
          value: '情報技術（IT）プログラムやプロジェクトのレビューまたは監査を行う。',
          softSkill: false,
        },
        {
          id: 'T0224',
          value:
            'トレーニング文書のレビュー（例：コース内容文書[CCD]、レッスンプラン、学生用テキスト、試験、指導スケジュール[SOI]、コースの説明）。',
          softSkill: false,
        },
        {
          id: 'T0225',
          value: '電子機器または情報源を確保してください。',
          softSkill: false,
        },
        {
          id: 'T0226',
          value: '機関および庁間の政策委員会に参加する。',
          softSkill: false,
        },
        {
          id: 'T0227',
          value: 'ポリシーを推奨し、レビューと承認を調整する。',
          softSkill: false,
        },
        {
          id: 'T0228',
          value: 'システムの能力と要件の分析のためにデータを保存、取得、および操作します。',
          softSkill: false,
        },
        {
          id: 'T0229',
          value:
            'サイバーセキュリティインシデントや脆弱性が発見された場合、保護または修正措置を監督または管理する。',
          softSkill: false,
        },
        {
          id: 'T0230',
          value: 'エクササイズシナリオの設計と実行をサポートします。',
          softSkill: false,
        },
        {
          id: 'T0231',
          value: 'セキュリティ/認証テストおよび評価活動をサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0232',
          value:
            'ソフトウェアおよびハードウェアデバイスを含むネットワークインフラストラクチャをテストおよび維持する。',
          softSkill: false,
        },
        {
          id: 'T0233',
          value:
            '初期検出から最終解決までのサイバーディフェンスインシデントを追跡し、文書化します。',
          softSkill: false,
        },
        {
          id: 'T0234',
          value: '監査結果と推奨事項を追跡し、適切な緩和措置が講じられるようにします。',
          softSkill: false,
        },
        {
          id: 'T0235',
          value: '機能要件を技術的解決策に翻訳する。',
          softSkill: false,
        },
        {
          id: 'T0236',
          value:
            'セキュリティ要件をアプリケーション設計要素に翻訳し、ソフトウェア攻撃面の要素を文書化し、脅威モデリングを実施し、特定のセキュリティ基準を定義します。',
          softSkill: false,
        },
        {
          id: 'T0237',
          value: 'システムのハードウェアとソフトウェアをトラブルシューティングします。',
          softSkill: false,
        },
        {
          id: 'T0238',
          value:
            'データをデータカービング技術を使用して抽出する（例：フォレンジック・ツール・キット[FTK]、フォアモスト）。',
          softSkill: false,
        },
        {
          id: 'T0239',
          value:
            '連邦および組織固有の公開文書を使用して、コンピューティング環境システムの運用を管理します。',
          softSkill: false,
        },
        {
          id: 'T0240',
          value:
            'ネットワーク監視ツールを使用して、悪意のある活動に関連するネットワークトラフィックをキャプチャおよび分析します。',
          softSkill: false,
        },
        {
          id: 'T0241',
          value:
            '専門的な機器と技術を使用して、デジタル証拠をカタログ化、文書化、抽出、収集、梱包、保存します。',
          softSkill: false,
        },
        {
          id: 'T0242',
          value:
            'モデルとシミュレーションを利用して、異なる運用条件下でのシステム性能を分析または予測します。',
          softSkill: false,
        },
        {
          id: 'T0243',
          value:
            'アプリケーション/システムのセキュリティ設計の特徴を反映したセキュリティ文書を確認し、更新してください。',
          softSkill: false,
        },
        {
          id: 'T0244',
          value:
            'アプリケーションソフトウェア/ネットワーク/システムのセキュリティ姿勢が指定された通りに実装されていることを確認し、逸脱を文書化し、それらの逸脱を修正するために必要な行動を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0245',
          value:
            'ソフトウェアアプリケーション/ネットワーク/システムの認証および保証文書が最新であることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0246',
          value:
            'サイバー防御技術、ガイダンス、およびインシデントの調査結果に関するレポートを作成し、適切な関係者に公開します。',
          softSkill: false,
        },
        {
          id: 'T0247',
          value:
            '指導資料（例：標準作業手順、生産マニュアル）を作成し、関連する労働力の適切な部分に詳細なガイダンスを提供します。',
          softSkill: false,
        },
        {
          id: 'T0248',
          value:
            '経営陣の間でセキュリティ問題の認識を高め、組織のビジョンと目標に健全なセキュリティ原則が反映されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0249',
          value: '現在の技術を調査して、必要なシステムやネットワークの能力を理解する。',
          softSkill: false,
        },
        {
          id: 'T0250',
          value:
            'ミッション要件に基づいたカスタムハードウェアおよびソフトウェア開発のためのサイバー能力戦略を特定する。',
          softSkill: false,
        },
        {
          id: 'T0251',
          value:
            '外部サービス（例：クラウドサービスプロバイダー、データセンター）のためのセキュリティコンプライアンスプロセスおよび/または監査を開発する。',
          softSkill: false,
        },
        {
          id: 'T0252',
          value:
            '環境内で適切に必要なレビューを実施する（例：技術監視、対策レビュー[TSCM]、TEMPEST対策レビュー）。',
          softSkill: false,
        },
        {
          id: 'T0253',
          value: '簡易的なバイナリ分析を行う。',
          softSkill: false,
        },
        {
          id: 'T0254',
          value:
            'ポリシー基準と実施戦略を監督し、手順とガイドラインがサイバーセキュリティポリシーに準拠していることを確認します。',
          softSkill: false,
        },
        {
          id: 'T0255',
          value:
            'リスクガバナンスプロセスに参加して、セキュリティリスク、軽減策、およびその他の技術的リスクに関する意見を提供します。',
          softSkill: false,
        },
        {
          id: 'T0256',
          value:
            '調達機能が調達活動を通じて情報セキュリティ要件とサプライチェーンリスクに対処する効果を評価し、改善を推奨する。',
          softSkill: false,
        },
        {
          id: 'T0257',
          value:
            '範囲、インフラ、リソース、およびデータサンプルサイズを決定して、システム要件が適切に示されていることを確認します。',
          softSkill: false,
        },
        {
          id: 'T0258',
          value:
            'タイムリーな検出、識別、および可能な攻撃/侵入、異常な活動、および悪用活動の警告を提供し、これらのインシデントとイベントを良性の活動と区別します。',
          softSkill: false,
        },
        {
          id: 'T0259',
          value:
            'システム活動の継続的な監視と分析のためにサイバーディフェンスツールを使用して、悪意のある活動を特定します。',
          softSkill: false,
        },
        {
          id: 'T0260',
          value:
            '悪意のある活動を分析して、悪用された弱点、悪用方法、システムと情報への影響を特定します。',
          softSkill: false,
        },
        {
          id: 'T0261',
          value:
            '重要なサイバー防衛インフラと主要資源の保護を特定、優先順位付け、および調整するのを支援します。',
          softSkill: false,
        },
        {
          id: 'T0262',
          value:
            '承認されたディフェンス・イン・デプスの原則と実践を採用する（例：複数の場所での防御、層状の防御、セキュリティの堅牢性）。',
          softSkill: false,
        },
        {
          id: 'T0263',
          value:
            'システムライフサイクルのすべての段階において、情報技術（IT）システムに特有のセキュリティ要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0264',
          value:
            'リスク評価、監査、検査などで特定された脆弱性に対して、行動計画とマイルストーンまたは是正計画が整っていることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0265',
          value:
            'セキュリティ要件と適切な情報技術（IT）ポリシーおよび手順の成功した実装と機能を保証し、組織の使命と目標と一致させます。',
          softSkill: false,
        },
        {
          id: 'T0266',
          value:
            '新規または更新されたアプリケーションに必要に応じてペネトレーションテストを実施する。',
          softSkill: false,
        },
        {
          id: 'T0267',
          value: 'プログラミング言語の弱点や脆弱性を悪用する可能性に対する対策と緩和策を設計する。',
          softSkill: false,
        },
        {
          id: 'T0268',
          value:
            '新しいシステムの実装、またはシステム間の新しいインターフェースが現在の環境のセキュリティ状態にどのように影響を与えるかを定義し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0269',
          value: 'サイバーセキュリティに関連するキーマネジメント機能を設計および開発する。',
          softSkill: false,
        },
        {
          id: 'T0270',
          value: 'ユーザーのニーズと要件を分析して、システムセキュリティの開発を計画し実施する。',
          softSkill: false,
        },
        {
          id: 'T0271',
          value:
            '特定の運用ニーズや環境要因に合わせたサイバーセキュリティ設計を開発する（例：アクセス制御、自動化アプリケーション、ネットワーク運用、高い完全性および可用性要求、複数の分類レベルのセキュリティ/処理、および機密区画情報の処理）。',
          softSkill: false,
        },
        {
          id: 'T0272',
          value:
            'セキュリティ設計とサイバーセキュリティ開発活動が適切に文書化されていることを確認してください（セキュリティ実装の機能的な説明を提供する）そして必要に応じて更新されます。',
          softSkill: false,
        },
        {
          id: 'T0273',
          value: '適切に、重要なシステム要素の供給チェーンリスクを開発し、文書化します。',
          softSkill: false,
        },
        {
          id: 'T0274',
          value: 'セキュリティ対策の監査可能な証拠を作成する。',
          softSkill: false,
        },
        {
          id: 'T0275',
          value:
            '必要なコンプライアンス活動をサポートする（例：システムセキュリティ構成ガイドラインの遵守を確保する、コンプライアンスモニタリングを行う）。',
          softSkill: false,
        },
        {
          id: 'T0276',
          value:
            '必要に応じて取得プロセスに参加し、適切なサプライチェーンリスク管理の実践に従ってください。',
          softSkill: false,
        },
        {
          id: 'T0277',
          value:
            'すべての取得、調達、およびアウトソーシングの取り組みが、組織の目標と一致する情報セキュリティ要件を満たすようにします。',
          softSkill: false,
        },
        {
          id: 'T0278',
          value:
            '侵入アーティファクト（例：ソースコード、マルウェア、トロイの木馬）を収集し、発見されたデータを使用して企業内の潜在的なサイバーディフェンスインシデントの軽減を可能にします。',
          softSkill: false,
        },
        {
          id: 'T0279',
          value:
            '技術専門家として務め、必要に応じて事件の詳細を法執行機関の職員に説明するための連絡役となる。',
          softSkill: false,
        },
        {
          id: 'T0280',
          value:
            '組織をポリシー/ガイドライン/手順/規制/法律に対して継続的に検証し、コンプライアンスを確保します。',
          softSkill: false,
        },
        {
          id: 'T0281',
          value:
            '進行中のサービス需要を予測し、必要に応じてセキュリティの前提条件が見直されることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0282',
          value: 'ポリシーや手順を定義および/または実施し、適切に重要インフラの保護を確保する。',
          softSkill: false,
        },
        {
          id: 'T0283',
          value: '利害関係者と協力して、適切なソリューション技術を特定および/または開発します。',
          softSkill: false,
        },
        {
          id: 'T0284',
          value: 'サイバーセキュリティに関連する新しいツール/技術を設計・開発する。',
          softSkill: false,
        },
        {
          id: 'T0285',
          value: 'デジタルメディアのウイルススキャンを実行します。',
          softSkill: false,
        },
        {
          id: 'T0286',
          value: 'ファイルシステムのフォレンジック分析を実行します。',
          softSkill: false,
        },
        {
          id: 'T0287',
          value:
            'ドライブの「イメージ」をマウントするために静的解析を実行する（必ずしも元のドライブが必要ではない）。',
          softSkill: false,
        },
        {
          id: 'T0288',
          value: '静的マルウェア分析を実行します。',
          softSkill: false,
        },
        {
          id: 'T0289',
          value:
            '必要に応じて展開可能なフォレンジックツールキットを使用して、オペレーションをサポートしてください。',
          softSkill: false,
        },
        {
          id: 'T0290',
          value: '侵入セットのための戦術、技術、および手順（TTP）を決定する。',
          softSkill: false,
        },
        {
          id: 'T0291',
          value: 'ネットワークトポロジーを調査して、ネットワークを通じたデータフローを理解する。',
          softSkill: false,
        },
        {
          id: 'T0292',
          value: 'コンピューティング環境の脆弱性の修正を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0293',
          value: 'ネットワークトラフィックのメタデータを使用して異常を特定し分析する。',
          softSkill: false,
        },
        {
          id: 'T0294',
          value:
            'さまざまなすべてのソースデータセット（兆候および警告）を横断して、調査、分析、および相関を行います。',
          softSkill: false,
        },
        {
          id: 'T0295',
          value:
            '侵入検知システム（IDS）のアラートをパケット分析ツールを使用してネットワークトラフィックと照合して検証します。',
          softSkill: false,
        },
        {
          id: 'T0296',
          value: 'マルウェアを隔離して削除する。',
          softSkill: false,
        },
        {
          id: 'T0297',
          value:
            'ネットワークトラフィックに基づいてネットワークデバイスのアプリケーションとオペレーティングシステムを特定する。',
          softSkill: false,
        },
        {
          id: 'T0298',
          value: 'ネットワークトラフィックに基づいて悪意のある攻撃や活動を再構築する。',
          softSkill: false,
        },
        {
          id: 'T0299',
          value:
            'ネットワークマッピングとオペレーティングシステム（OS）のフィンガープリンティング活動を特定する。',
          softSkill: false,
        },
        {
          id: 'T0300',
          value:
            'ユーザーエクスペリエンス（UX）の要件を開発し、情報アーキテクチャとユーザーインターフェースの要件を含めて文書化する。',
          softSkill: false,
        },
        {
          id: 'T0301',
          value:
            'アプリケーションソフトウェア/ネットワーク/システムのためのサイバーセキュリティ独立監査プロセスを開発・実施し、運用および研究開発（R&D）プロセスと手順が組織の義務的なサイバーセキュリティ要件に準拠し、システム管理者および他のサイバーセキュリティスタッフが日常活動を行う際に正確に従っていることを確認するために、継続的な独立監査を監督する。',
          softSkill: false,
        },
        {
          id: 'T0302',
          value:
            'サプライチェーン、システム、ネットワーク、および運用のセキュリティが満たされるように契約言語を開発する。',
          softSkill: false,
        },
        {
          id: 'T0303',
          value:
            'エンタープライズ全体のバージョン管理システムを特定し、活用しながら、安全なアプリケーションを設計および開発します。',
          softSkill: false,
        },
        {
          id: 'T0304',
          value:
            'システム開発ライフサイクル（SDLC）の手法（例：IBM Rational Unified Process）を開発環境に実装し、統合する。',
          softSkill: false,
        },
        {
          id: 'T0305',
          value:
            'データベースおよびデータ管理システムの構成管理、問題管理、容量管理、および財務管理を実行します。',
          softSkill: false,
        },
        {
          id: 'T0306',
          value:
            'インシデント管理、サービスレベル管理、変更管理、リリース管理、継続性管理、およびデータベースおよびデータ管理システムの可用性管理をサポートします。',
          softSkill: false,
        },
        {
          id: 'T0307',
          value:
            '候補アーキテクチャを分析し、セキュリティサービスを割り当て、セキュリティメカニズムを選択します。',
          softSkill: false,
        },
        {
          id: 'T0308',
          value: 'インシデントデータを分析して新たなトレンドを把握する。',
          softSkill: false,
        },
        {
          id: 'T0309',
          value: 'セキュリティコントロールの有効性を評価する。',
          softSkill: false,
        },
        {
          id: 'T0310',
          value:
            'ネットワーク環境またはエンクレーブ内で新たに観測された脅威に対応するため、サイバー防御ネットワークツールに実装可能な署名の構築を支援します。',
          softSkill: false,
        },
        {
          id: 'T0311',
          value: '顧客とソフトウェアシステムの設計と保守について相談する。',
          softSkill: false,
        },
        {
          id: 'T0312',
          value: 'インテリジェンスアナリストと連携して、脅威評価データを相関させる。',
          softSkill: false,
        },
        {
          id: 'T0313',
          value: '設計と文書の品質基準。',
          softSkill: false,
        },
        {
          id: 'T0314',
          value:
            'システムセキュリティコンテキストを開発し、予備的なシステムセキュリティ概念運用（CONOPS）を定義し、適用可能なサイバーセキュリティ要件に従って基本的なシステムセキュリティ要件を定義します。',
          softSkill: false,
        },
        {
          id: 'T0315',
          value:
            '他者を教育するため、または顧客のニーズに応えるために技術トレーニングを開発し、提供する。',
          softSkill: false,
        },
        {
          id: 'T0316',
          value:
            'コンピューターベースのトレーニングモジュールまたはクラスの開発、またはその開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0317',
          value: 'コースの課題の開発、またはその開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0318',
          value: 'コース評価の開発、または開発の支援を行う。',
          softSkill: false,
        },
        {
          id: 'T0319',
          value: '評価基準や習熟度基準の開発、またはその開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0320',
          value: '個々の/集団の開発、訓練、および/または補正計画の開発を支援します。',
          softSkill: false,
        },
        {
          id: 'T0321',
          value: '学習目標や目的の開発を行うか、その開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0322',
          value: '職業訓練の教材やプログラムの開発、またはその支援を行う。',
          softSkill: false,
        },
        {
          id: 'T0323',
          value: '学習者の習熟度を測定および評価するための筆記試験の開発または開発支援を行う。',
          softSkill: false,
        },
        {
          id: 'T0324',
          value: 'ソフトウェアプログラミングとドキュメントの開発を直接行う。',
          softSkill: false,
        },
        {
          id: 'T0325',
          value: 'システムの目的と予備的なシステムセキュリティ運用概念を文書化する。',
          softSkill: false,
        },
        {
          id: 'T0326',
          value: '構成管理プロセスを適用する。',
          softSkill: false,
        },
        {
          id: 'T0327',
          value: 'ネットワークインフラの脆弱性を評価して、開発中の機能を強化します。',
          softSkill: false,
        },
        {
          id: 'T0328',
          value:
            'セキュリティアーキテクチャとデザインを評価し、取得文書に含まれる要件に対する提案または提供されたセキュリティデザインとアーキテクチャの適切性を判断します。',
          softSkill: false,
        },
        {
          id: 'T0329',
          value:
            'ソフトウェアおよびシステムエンジニアリングのライフサイクル標準およびプロセスに従ってください。',
          softSkill: false,
        },
        {
          id: 'T0330',
          value: '確実なメッセージ配信システムを維持する。',
          softSkill: false,
        },
        {
          id: 'T0331',
          value: 'インシデント追跡と解決データベースを維持する。',
          softSkill: false,
        },
        {
          id: 'T0332',
          value:
            '指定されたマネージャー、サイバーインシデント対応者、およびサイバーセキュリティサービスプロバイダーチームのメンバーに疑わしいサイバーインシデントを通知し、イベントの履歴、状況、および潜在的な影響を詳述し、組織のサイバーインシデント対応計画に従ってさらなる行動を促す。',
          softSkill: false,
        },
        {
          id: 'T0334',
          value:
            'すべてのシステムコンポーネントが統合および整列できることを確認してください（例：手順、データベース、ポリシー、ソフトウェア、およびハードウェア）。',
          softSkill: false,
        },
        {
          id: 'T0335',
          value: '構築、インストール、設定、専用サイバーディフェンスハードウェアのテストを行う。',
          softSkill: false,
        },
        {
          id: 'T0336',
          value: '撤回：T0228と統合済み',
          softSkill: false,
        },
        {
          id: 'T0337',
          value:
            'プログラマー、デザイナー、テクノロジスト、技術者、その他のエンジニアリングおよび科学的な人材に作業を監督し、割り当てる。',
          softSkill: false,
        },
        {
          id: 'T0338',
          value: '詳細な機能仕様を記述し、アーキテクチャ開発プロセスを文書化します。',
          softSkill: false,
        },
        {
          id: 'T0339',
          value: '組織のナレッジマネジメントと情報共有の使用を促進するための取り組みを主導する。',
          softSkill: false,
        },
        {
          id: 'T0340',
          value:
            '基盤となる情報技術（IT）運用プロセスおよび機能の主要な利害関係者として行動し、サービスが成功裏に提供されるように方向性を提供し、すべての重要な活動を監視します。',
          softSkill: false,
        },
        {
          id: 'T0341',
          value:
            'サイバートレーニングリソースに対する十分な資金提供を主張し、内部および業界提供のコース、インストラクター、関連資料を含めること。',
          softSkill: false,
        },
        {
          id: 'T0342',
          value: 'データソースを分析して、実行可能な推奨事項を提供する。',
          softSkill: false,
        },
        {
          id: 'T0343',
          value: '危機を分析して、公共の安全、個人の安全、および資源の保護を確保します。',
          softSkill: false,
        },
        {
          id: 'T0344',
          value: 'すべての構成管理（変更構成/リリース管理）プロセスを評価します。',
          softSkill: false,
        },
        {
          id: 'T0345',
          value:
            '指導の効果と効率を、指導技術の使用の容易さ、学生の学習、知識の移転、および満足度によって評価します。',
          softSkill: false,
        },
        {
          id: 'T0346',
          value: '個々の被害者、目撃者、または容疑者の行動を調査に関連して評価します。',
          softSkill: false,
        },
        {
          id: 'T0347',
          value: 'ソースデータとその後の調査結果の妥当性を評価する。',
          softSkill: false,
        },
        {
          id: 'T0348',
          value:
            '専用のサイバーディフェンスインフラの実装と維持が及ぼす影響を評価するのを支援します。',
          softSkill: false,
        },
        {
          id: 'T0349',
          value: 'メトリクスとトレンディングデータを収集する。',
          softSkill: false,
        },
        {
          id: 'T0350',
          value:
            '市場分析を実施して、システム内での使用に適した商用、政府の既製品、オープンソース製品を特定、評価し、推奨製品が組織の評価および検証要件に準拠していることを確認します。',
          softSkill: false,
        },
        {
          id: 'T0351',
          value: '仮説検定を統計プロセスを使用して実施する。',
          softSkill: false,
        },
        {
          id: 'T0352',
          value: '学習ニーズの評価を行い、要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0353',
          value:
            'システムアナリスト、エンジニア、プログラマー、その他の人々と協議してアプリケーションを設計します。',
          softSkill: false,
        },
        {
          id: 'T0354',
          value: '顧客に対してエンドツーエンドで提供されるサービス全体を調整し、管理します。',
          softSkill: false,
        },
        {
          id: 'T0355',
          value:
            '内部および外部の専門家と連携し、既存の資格基準が組織の機能要件を反映し、業界基準を満たしていることを確認します。',
          softSkill: false,
        },
        {
          id: 'T0356',
          value:
            '組織の人的資本の適切な割り当てと配布を保証するために、組織の人的資源の関係者と調整してください。',
          softSkill: false,
        },
        {
          id: 'T0357',
          value: 'インタラクティブな学習エクササイズを作成して、効果的な学習環境を作り出す。',
          softSkill: false,
        },
        {
          id: 'T0358',
          value: '特権アクセスユーザーのためのシステム管理および管理機能を設計および開発する。',
          softSkill: false,
        },
        {
          id: 'T0359',
          value:
            '情報システム、物理システム、および/または組み込み技術間の安全なインターフェースを設計、実装、テスト、評価する。',
          softSkill: false,
        },
        {
          id: 'T0360',
          value: '脅威の範囲を特定し、リスクを軽減するための行動計画または対策を推奨する。',
          softSkill: false,
        },
        {
          id: 'T0361',
          value: 'データ収集方法を開発し、促進する。',
          softSkill: false,
        },
        {
          id: 'T0362',
          value: '確立されたサイバー職務に基づいて、標準化された職務記述を開発し、実施する。',
          softSkill: false,
        },
        {
          id: 'T0363',
          value: '現行の人事方針に従って、採用、雇用、および定着手順を開発および見直してください。',
          softSkill: false,
        },
        {
          id: 'T0364',
          value:
            'サイバーキャリア分野の分類構造を開発し、キャリア分野の入門要件やコードや識別子などの他の名称を含める。',
          softSkill: false,
        },
        {
          id: 'T0365',
          value: 'サイバー訓練のための訓練ポリシーとプロトコルの開発または支援を行う。',
          softSkill: false,
        },
        {
          id: 'T0366',
          value: '大規模なデータセットから戦略的洞察を得る。',
          softSkill: false,
        },
        {
          id: 'T0367',
          value: 'サイバーカリキュラムの目標と目的を開発する。',
          softSkill: false,
        },
        {
          id: 'T0368',
          value:
            'サイバーキャリア分野が組織のHRポリシーと指令に従って管理されることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0369',
          value:
            'サイバー労働力管理ポリシーとプロセスが、平等な機会、多様性、公正な採用・雇用慣行に関する法的および組織的要件を遵守していることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0370',
          value:
            '適切なサービスレベル合意（SLA）およびその基盤となる契約が定義されていることを確認し、顧客に対してサービスの説明とサービスの監視方法を明確に示します。',
          softSkill: false,
        },
        {
          id: 'T0371',
          value:
            'ソフトウェアアプリケーション、ネットワーク、またはシステムに対する許容限界を設定する。',
          softSkill: false,
        },
        {
          id: 'T0372',
          value:
            'サイバー労働力の準備状況を監視し、検証するための指標を設定し、収集すること。また、特定されたポジションの状況、充足されたポジション、資格を持った人材で充足されたポジションを評価するためのサイバー労働力データの分析を含む。',
          softSkill: false,
        },
        {
          id: 'T0373',
          value:
            'サイバーキャリア分野の入職及び訓練資格要件に対する免除プロセスを設立し、監督する。',
          softSkill: false,
        },
        {
          id: 'T0374',
          value:
            'サイバーキャリアパスを確立して、キャリアの進展、意図的な発展、およびサイバーキャリア分野内外での成長を可能にします。',
          softSkill: false,
        },
        {
          id: 'T0375',
          value:
            'サイバー労働力管理および報告要件をサポートするための人員、人事、資格データ要素基準を確立します。',
          softSkill: false,
        },
        {
          id: 'T0376',
          value:
            '組織の要件に従って、サイバー労働力管理プログラムを確立し、資源を配置し、実施し、評価する。',
          softSkill: false,
        },
        {
          id: 'T0377',
          value:
            '顧客満足度と内部サービスのパフォーマンスに関するフィードバックを集め、継続的な改善を促進します。',
          softSkill: false,
        },
        {
          id: 'T0378',
          value:
            'リスク主導のシステム保守更新プロセスを取り入れ、システムの不備に対処します（定期的およびサイクル外で）。',
          softSkill: false,
        },
        {
          id: 'T0379',
          value:
            'サービスをサポートする情報技術（IT）プロセスオーナーとの内部関係を管理し、運用レベル合意（OLA）の定義と合意を支援します。',
          softSkill: false,
        },
        {
          id: 'T0380',
          value:
            '教育者やトレーナーと連携して、最も効果的な学習環境のために講義、デモンストレーション、インタラクティブな演習、マルチメディアプレゼンテーション、ビデオコース、ウェブベースのコースなどの教育戦略を計画します。',
          softSkill: false,
        },
        {
          id: 'T0381',
          value: '技術的な情報を技術者および非技術者の聴衆に提示します。',
          softSkill: false,
        },
        {
          id: 'T0382',
          value: '創造的な形式でデータを提示する。',
          softSkill: false,
        },
        {
          id: 'T0383',
          value: 'プログラムカスタムアルゴリズム。',
          softSkill: false,
        },
        {
          id: 'T0384',
          value:
            'サイバーポリシーと戦略に関する認識を経営陣の間で適切に促進し、組織のミッション、ビジョン、および目標に健全な原則が反映されていることを確保してください。',
          softSkill: false,
        },
        {
          id: 'T0385',
          value:
            'データ分析と調査結果に基づいて、重要なステークホルダーに実行可能な推奨事項を提供する。',
          softSkill: false,
        },
        {
          id: 'T0386',
          value: '裁判過程中に、裁判官に刑事捜査のサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0387',
          value: 'サイバーキャリア分野の資格基準を見直し、適用する。',
          softSkill: false,
        },
        {
          id: 'T0388',
          value: 'サイバー労働力に関連または影響を与える組織のポリシーを見直し、適用する。',
          softSkill: false,
        },
        {
          id: 'T0389',
          value:
            'レビューサービスのパフォーマンスレポートを確認し、重要な問題や相違点を特定し、必要に応じて是正措置を開始し、すべての未解決の問題がフォローアップされることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0390',
          value: 'サイバー労働力の効果を評価し、スキルや資格基準を調整します。',
          softSkill: false,
        },
        {
          id: 'T0391',
          value:
            '情報システムのライフサイクル開発プロセスに資格を持つサイバー労働力の人員を統合することをサポートします。',
          softSkill: false,
        },
        {
          id: 'T0392',
          value:
            '技術文書やリソースを利用して、新しい数学、データサイエンス、またはコンピュータサイエンスの方法を実装します。',
          softSkill: false,
        },
        {
          id: 'T0393',
          value: '仕様と要件をテスト可能性の観点から検証してください。',
          softSkill: false,
        },
        {
          id: 'T0394',
          value:
            '他のサービスマネージャーやプロダクトオーナーと協力して、全体的な顧客の要件、制約、および目標を満たすためにサービスをバランス良く優先順位付けします。',
          softSkill: false,
        },
        {
          id: 'T0395',
          value: '行動後レビューを書いて公開する。',
          softSkill: false,
        },
        {
          id: 'T0396',
          value: 'アナリストの目標に応じて適切なツールで画像を処理します。',
          softSkill: false,
        },
        {
          id: 'T0397',
          value: 'Windows レジストリ分析を実行します。',
          softSkill: false,
        },
        {
          id: 'T0398',
          value:
            '動的分析による侵入の特定後、実行中のシステムでファイルおよびレジストリの監視を行う。',
          softSkill: false,
        },
        {
          id: 'T0399',
          value:
            'デジタルメディアの取得情報を追跡データベース（例：プロダクトトラッカーツール）に入力してください。',
          softSkill: false,
        },
        {
          id: 'T0400',
          value: 'インシデントデータを相関させ、サイバー防御レポートを実行します。',
          softSkill: false,
        },
        {
          id: 'T0401',
          value:
            'インシデント対応チームのミッションをサポートするための展開可能なサイバー防御ツールキット（例：特殊なサイバー防御ソフトウェア/ハードウェア）を維持する。',
          softSkill: false,
        },
        {
          id: 'T0402',
          value: 'データ管理システムの設計において、効果的にストレージ容量を割り当てる。',
          softSkill: false,
        },
        {
          id: 'T0403',
          value:
            'WindowsおよびUNIXシステム上で単純なスクリプト（例：Perl、VBScript）を読む、解釈する、書く、修正する、実行する（例：大規模なデータファイルの解析、手動タスクの自動化、リモートデータの取得・処理などのタスクを実行する）。',
          softSkill: false,
        },
        {
          id: 'T0404',
          value:
            '異なるプログラミング言語を使用してコードを書き、ファイルを開き、ファイルを読み、異なるファイルに出力を書き込む。',
          softSkill: false,
        },
        {
          id: 'T0405',
          value:
            'オープンソース言語（例えばR）を活用し、定量的技術（例えば、記述統計と推論統計、サンプリング、実験設計、パラメトリックおよびノンパラメトリックな差の検定、最小二乗回帰、一般線形）を適用してください。',
          softSkill: false,
        },
        {
          id: 'T0406',
          value:
            '設計および開発活動が適切に文書化され（実装の機能的な説明を提供）、必要に応じて更新されることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0407',
          value: '必要に応じて取得プロセスに参加してください。',
          softSkill: false,
        },
        {
          id: 'T0408',
          value: '適用される法律、法令、および規制文書を解釈し、適用し、ポリシーに統合する。',
          softSkill: false,
        },
        {
          id: 'T0409',
          value:
            'プロトタイプの設計とプロセスの問題を、製品設計、開発、および発売前の各段階でトラブルシューティングします。',
          softSkill: false,
        },
        {
          id: 'T0410',
          value:
            '機能的およびセキュリティ関連の特徴を特定し、新しい能力開発の機会を見つけ出し、脆弱性を利用または軽減する。',
          softSkill: false,
        },
        {
          id: 'T0411',
          value: '逆工学ツールを特定および/または開発して、能力を向上させ、脆弱性を検出します。',
          softSkill: false,
        },
        {
          id: 'T0412',
          value: 'システムやソフトウェアを取得するための輸入/輸出レビューを実施する。',
          softSkill: false,
        },
        {
          id: 'T0413',
          value:
            'データ管理機能を開発する（例：クラウドベースの、集中型暗号キー管理）し、モバイルワークフォースをサポートに含める。T0414 サプライチェーン、システム、ネットワーク、パフォーマンス、およびサイバーセキュリティ要件を開発する。T0415 サプライチェーン、システム、ネットワーク、パフォーマンス、およびサイバーセキュリティ要件が契約言語に含まれ、提供されることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0416',
          value:
            '既存の公開鍵インフラ（PKI）ライブラリを活用して、適切な場合に証明書管理と暗号化機能を組み込むことにより、公開鍵を使用するアプリケーションを有効にします。',
          softSkill: false,
        },
        {
          id: 'T0417',
          value:
            '適切な場合には、セキュアなアプリケーションの設計と開発を行う際に、企業全体のセキュリティサービスを特定し活用する（例：エンタープライズPKI、フェデレーテッドアイデンティティサーバー、エンタープライズアンチウイルスソリューション）。',
          softSkill: false,
        },
        {
          id: 'T0418',
          value: 'インストール、更新、およびシステム/サーバーのトラブルシューティング。',
          softSkill: false,
        },
        {
          id: 'T0419',
          value:
            '関連する法律、規制、政策、合意、基準、手順、その他の発行物に生じる憲法上の問題について、作業知識を習得し維持する。',
          softSkill: false,
        },
        {
          id: 'T0420',
          value:
            'テストベッドを管理し、サービスプロバイダーが管理するプラットフォームのアプリケーション、ハードウェアインフラストラクチャ、ルール/シグネチャ、アクセスコントロール、および設定をテストおよび評価します。',
          softSkill: false,
        },
        {
          id: 'T0421',
          value:
            '明示的な組織知識（例：紙の文書、デジタルファイル）の索引付け・カタログ作成、保管、アクセスを管理する。',
          softSkill: false,
        },
        {
          id: 'T0422',
          value: 'データ管理の標準、要件、および仕様を実装する。',
          softSkill: false,
        },
        {
          id: 'T0423',
          value: 'コンピュータ生成の脅威を対情報活動または犯罪活動に対抗するために分析する。',
          softSkill: false,
        },
        {
          id: 'T0424',
          value:
            '利害関係者に情報を分析し提供することで、セキュリティアプリケーションの開発または既存のセキュリティアプリケーションの改変を支援します。',
          softSkill: false,
        },
        {
          id: 'T0425',
          value: '組織のサイバーポリシーを分析する。',
          softSkill: false,
        },
        {
          id: 'T0426',
          value: 'ソフトウェア、ハードウェア、または相互運用性テストの結果を分析します。',
          softSkill: false,
        },
        {
          id: 'T0427',
          value: 'ユーザーのニーズと要件を分析してアーキテクチャを計画する。',
          softSkill: false,
        },
        {
          id: 'T0428',
          value:
            'セキュリティニーズとソフトウェア要件を分析し、時間とコストの制約およびセキュリティ要求内での設計の実現可能性を判断します。',
          softSkill: false,
        },
        {
          id: 'T0429',
          value:
            'ポリシーのニーズを評価し、利害関係者と協力してサイバー活動を管理するためのポリシーを策定する。',
          softSkill: false,
        },
        {
          id: 'T0430',
          value: 'コンピュータ犯罪の起訴に使用される証拠を集め、保全する。',
          softSkill: false,
        },
        {
          id: 'T0431',
          value: 'システムハードウェアの可用性、機能性、完全性、効率性を確認してください。',
          softSkill: false,
        },
        {
          id: 'T0432',
          value:
            '侵入アーティファクト（例：ソースコード、マルウェア、システム設定など）を収集・分析し、発見したデータを利用して企業内の潜在的なサイバーディフェンスインシデントの緩和を可能にします。',
          softSkill: false,
        },
        {
          id: 'T0433',
          value:
            'ログファイル、証拠、その他の情報を分析して、ネットワーク侵入やその他の犯罪の犯人を特定する最良の方法を決定します。',
          softSkill: false,
        },
        {
          id: 'T0434',
          value:
            '訴状のフレーミングを行い、法律、規則、またはポリシー/ガイダンスの違反とされる事項を適切に特定します。',
          softSkill: false,
        },
        {
          id: 'T0435',
          value:
            '定期的なシステムメンテナンスを実施すること。これには、物理的なクリーニングと電子的なクリーニング、ディスクチェック、定期的な再起動、データダンプ、およびテストが含まれます。',
          softSkill: false,
        },
        {
          id: 'T0436',
          value:
            'プログラムやソフトウェアアプリケーションの試験運用を行い、求められた情報が生成され、指示とセキュリティレベルが正しいことを確認します。',
          softSkill: false,
        },
        {
          id: 'T0437',
          value: 'ビジネスまたはミッション要件にトレーニングと学習を関連付ける。',
          softSkill: false,
        },
        {
          id: 'T0438',
          value:
            '特殊なサイバー防御システム（例：ファイアウォールや侵入防止システム）において、ネットワークアクセス制御リストを作成、編集、管理する。',
          softSkill: false,
        },
        {
          id: 'T0439',
          value:
            '暗号化されたデータ、隠語、代替データストリーム、およびその他の形式の隠されたデータを検出して分析します。',
          softSkill: false,
        },
        {
          id: 'T0440',
          value:
            '壊滅的な障害イベント後の部分的または完全なシステム復旧のために必要な重要なシステム機能またはビジネス機能を捕捉し、統合する。',
          softSkill: false,
        },
        {
          id: 'T0441',
          value: '現在および将来のミッション環境を定義し、統合する。',
          softSkill: false,
        },
        {
          id: 'T0442',
          value: '観客と物理的環境に合わせたトレーニングコースを作成する。',
          softSkill: false,
        },
        {
          id: 'T0443',
          value: '聴衆と物理的/仮想的環境に合わせた研修コースを提供する。',
          softSkill: false,
        },
        {
          id: 'T0444',
          value:
            '学生に対して、概念、手順、ソフトウェア、機器、および/または技術アプリケーションを適用します。',
          softSkill: false,
        },
        {
          id: 'T0445',
          value:
            'デザイン/統合するサイバー戦略は、ビジョン、ミッション、および組織の戦略計画に沿った目標を概説します。',
          softSkill: false,
        },
        {
          id: 'T0446',
          value:
            '設計、開発、統合、およびシステムのセキュリティ対策を更新して、機密性、完全性、可用性、認証、および否認防止を提供します。',
          softSkill: false,
        },
        {
          id: 'T0447',
          value:
            '要件に十分に対応するハードウェア、オペレーティングシステム、およびソフトウェアアプリケーションを設計する。',
          softSkill: false,
        },
        {
          id: 'T0448',
          value:
            'ユーザーのニーズに応じたエンタープライズアーキテクチャまたはシステムコンポーネントを開発する。',
          softSkill: false,
        },
        {
          id: 'T0449',
          value:
            'すべてのシステムおよび/またはアプリケーションに対する要件が満たされるように、セキュリティ要件に基づいて設計する。',
          softSkill: false,
        },
        {
          id: 'T0450',
          value: '要件に基づいた研修カリキュラムとコース内容を設計する。',
          softSkill: false,
        },
        {
          id: 'T0451',
          value: 'トレーニングカリキュラムとコース内容の開発に参加する。',
          softSkill: false,
        },
        {
          id: 'T0452',
          value:
            'デザイン、構築、実装、および組織の知的資本にエンドユーザーがアクセスできる知識管理フレームワークを維持する。',
          softSkill: false,
        },
        {
          id: 'T0453',
          value:
            '侵入者やその他の犯罪の責任者を特定または起訴するための情報源を特定し、リードを決定して開発する。',
          softSkill: false,
        },
        {
          id: 'T0454',
          value: '適用されるガイドラインに従って、ベースラインのセキュリティ要件を定義する。',
          softSkill: false,
        },
        {
          id: 'T0455',
          value:
            'ソフトウェアシステムのテストと検証手順、プログラミング、およびドキュメントを開発する。',
          softSkill: false,
        },
        {
          id: 'T0456',
          value: '安全なソフトウェアテストおよび検証手順を開発する。',
          softSkill: false,
        },
        {
          id: 'T0457',
          value: 'システムテストおよび検証手順、プログラミング、およびドキュメントを開発する。',
          softSkill: false,
        },
        {
          id: 'T0458',
          value: '組織のシステム管理標準運用手順に従ってください。',
          softSkill: false,
        },
        {
          id: 'T0459',
          value: 'データマイニングとデータウェアハウジングのアプリケーションを実装する。',
          softSkill: false,
        },
        {
          id: 'T0460',
          value: 'データマイニングとデータウェアハウジングプログラムを開発して実装する。',
          softSkill: false,
        },
        {
          id: 'T0461',
          value: 'ローカルネットワークの使用ポリシーと手順を実装し、適用する。',
          softSkill: false,
        },
        {
          id: 'T0462',
          value:
            'システムの可用性要件に基づいて、システム操作の代替サイトへの移行のための手順を開発し、フェイルオーバーをテストします。',
          softSkill: false,
        },
        {
          id: 'T0463',
          value: '新規または修正されたシステムのコスト見積もりを作成する。',
          softSkill: false,
        },
        {
          id: 'T0464',
          value:
            'システム設計と開発を支援するためのコンポーネントおよびインターフェース仕様の詳細設計文書を作成します。',
          softSkill: false,
        },
        {
          id: 'T0465',
          value: '実施のためのガイドラインを策定する。',
          softSkill: false,
        },
        {
          id: 'T0466',
          value:
            'コスト、スケジュール、性能、およびセキュリティリスクに対処するための緩和戦略を開発する。',
          softSkill: false,
        },
        {
          id: 'T0467',
          value:
            'トレーニングがサイバーセキュリティのトレーニング、教育、または意識向上の目標と目的を満たしていることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0468',
          value:
            '顧客から報告されたシステムのインシデント、問題、およびイベントを診断し、解決します。',
          softSkill: false,
        },
        {
          id: 'T0469',
          value: '組織のセキュリティ姿勢のトレンドを分析し、報告する。',
          softSkill: false,
        },
        {
          id: 'T0470',
          value: 'システムのセキュリティ態勢のトレンドを分析し、報告する。',
          softSkill: false,
        },
        {
          id: 'T0471',
          value:
            'デジタルおよび/または関連する証拠の元の状態を記録する（例：デジタル写真、書面による報告、ハッシュ関数チェックにより）。',
          softSkill: false,
        },
        {
          id: 'T0472',
          value: 'ドラフト、スタッフ、およびサイバーポリシーを公開する。',
          softSkill: false,
        },
        {
          id: 'T0473',
          value: 'すべての定義とアーキテクチャ活動を文書化し、必要に応じて更新してください。',
          softSkill: false,
        },
        {
          id: 'T0474',
          value:
            'インスペクター・ジェネラル、プライバシー担当者、監視およびコンプライアンス担当者に対して、サイバーセキュリティポリシーおよび関連する法的および規制要件の遵守に関して法的分析と判断を提供する。',
          softSkill: false,
        },
        {
          id: 'T0475',
          value: '最小特権と知る必要がある原則に基づいて、適切なアクセス制御を評価します。',
          softSkill: false,
        },
        {
          id: 'T0476',
          value: '法律、規制、政策、基準、または手続きの変更が及ぼす影響を評価する。',
          softSkill: false,
        },
        {
          id: 'T0477',
          value: '災害復旧と業務継続の実行を確保する。',
          softSkill: false,
        },
        {
          id: 'T0478',
          value:
            '管理者、従業員、またはクライアントに法律、規制、ポリシー、基準、または手順に関する指導を提供する。',
          softSkill: false,
        },
        {
          id: 'T0479',
          value:
            '情報技術（IT）システムとデジタル記憶媒体を使用して、人々や財産に対して行われたサイバー犯罪や詐欺を解決、調査、および/または起訴する。',
          softSkill: false,
        },
        {
          id: 'T0480',
          value:
            'コンポーネントまたは要素を特定し、セキュリティ機能を含む包括的な機能コンポーネントを割り当て、要素間の関係を説明する。',
          softSkill: false,
        },
        {
          id: 'T0481',
          value: 'サイバー労働力の計画と管理の問題を特定し、対処する（例：採用、定着、訓練）。',
          softSkill: false,
        },
        {
          id: 'T0482',
          value:
            'トレンド分析に基づいて、顧客体験を向上させるためのソフトウェアおよびハードウェアソリューションの強化に関する推奨事項を提案します。',
          softSkill: false,
        },
        {
          id: 'T0483',
          value:
            'サイバーディフェンスツールの実装における潜在的なコンフリクトを特定する（例：ツールおよびシグネチャのテストと最適化）。',
          softSkill: false,
        },
        {
          id: 'T0484',
          value:
            '情報システムおよびネットワークの保護ニーズ（つまり、セキュリティコントロール）を特定し、適切に文書化する。',
          softSkill: false,
        },
        {
          id: 'T0485',
          value:
            '脆弱性を解決するためのセキュリティ対策を実施し、リスクを軽減し、必要に応じてシステムまたはシステムコンポーネントのセキュリティ変更を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0486',
          value:
            'リスク管理フレームワーク（RMF）/セキュリティ評価および認証（SA&A）の要件を企業内の専用サイバーディフェンスシステムに実装し、それらの記録を文書化して維持する。',
          softSkill: false,
        },
        {
          id: 'T0487',
          value:
            '新規または改訂された法律、規制、行政命令、ポリシー、基準、手順の実施を容易にする。',
          softSkill: false,
        },
        {
          id: 'T0488',
          value: '新しいシステムまたは既存のシステムの設計を実装する。',
          softSkill: false,
        },
        {
          id: 'T0489',
          value:
            '確立された手順に従ってシステムセキュリティ対策を実施し、機密性、完全性、可用性、認証、および否認防止を確保します。',
          softSkill: false,
        },
        {
          id: 'T0490',
          value: 'データベース管理システムとソフトウェアをインストールして設定します。',
          softSkill: false,
        },
        {
          id: 'T0491',
          value:
            'システムユーザーのために、組織の基準に従ってハードウェア、ソフトウェア、周辺機器をインストールおよび設定します。',
          softSkill: false,
        },
        {
          id: 'T0492',
          value: '安全な環境でのクロスドメインソリューション（CDS）の統合と実装を確保する。',
          softSkill: false,
        },
        {
          id: 'T0493',
          value: '予算、人員、および契約の管理と監督を行う。',
          softSkill: false,
        },
        {
          id: 'T0494',
          value: 'アカウント、ネットワーク権限、システムおよび機器へのアクセスを管理します。',
          softSkill: false,
        },
        {
          id: 'T0495',
          value: '認定パッケージを管理する（例：ISO/IEC 15026-2）。',
          softSkill: false,
        },
        {
          id: 'T0496',
          value: '情報技術（IT）リソースの資産管理／在庫管理を行う。',
          softSkill: false,
        },
        {
          id: 'T0497',
          value:
            '情報技術（IT）計画プロセスを管理して、開発されたソリューションが顧客の要件を満たすことを確認します。',
          softSkill: false,
        },
        {
          id: 'T0498',
          value:
            'システム/サーバーリソースを管理すること、パフォーマンス、容量、可用性、サービス性、回復性を含む。',
          softSkill: false,
        },
        {
          id: 'T0499',
          value:
            'セキュリティ/認証テスト中に特定されたセキュリティ不足を軽減/修正し、適切な上級リーダーまたは認可された代表者にリスク受容を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0500',
          value:
            '既存のソフトウェアを修正し、エラーを修正するため、新しいハードウェアに適応させるため、またはインターフェースをアップグレードしてパフォーマンスを向上させるために保守します。',
          softSkill: false,
        },
        {
          id: 'T0501',
          value: 'システム/サーバーの構成を監視し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0502',
          value: 'クライアントレベルのコンピュータシステムのパフォーマンスを監視し、報告する。',
          softSkill: false,
        },
        {
          id: 'T0503',
          value:
            '外部データソース（例：サイバーディフェンスベンダーサイト、コンピュータ緊急対応チーム、セキュリティフォーカス）を監視し、サイバーディフェンスの脅威状況を最新の状態に保ち、どのセキュリティ問題が企業に影響を与える可能性があるかを判断します。',
          softSkill: false,
        },
        {
          id: 'T0504',
          value: 'システム実装およびテスト実践に関連するサイバーセキュリティを評価し、監視する。',
          softSkill: false,
        },
        {
          id: 'T0505',
          value:
            '計画および管理サービスの提供におけるサイバーポリシー、原則、および実践の厳格な適用を監視します。',
          softSkill: false,
        },
        {
          id: 'T0506',
          value: '利害関係者から提案された政策変更についての合意を求める。',
          softSkill: false,
        },
        {
          id: 'T0507',
          value: 'システムコンポーネントのインストール、実装、設定、およびサポートを監視します。',
          softSkill: false,
        },
        {
          id: 'T0508',
          value:
            'すべてのアプリケーションに最低限のセキュリティ要件が整っているか確認してください。',
          softSkill: false,
        },
        {
          id: 'T0509',
          value: '情報セキュリティリスクアセスメントを実施する。',
          softSkill: false,
        },
        {
          id: 'T0510',
          value: 'インシデント対応機能を調整する。',
          softSkill: false,
        },
        {
          id: 'T0511',
          value: '開発中のシステムに対して開発途中試験を実施する。',
          softSkill: false,
        },
        {
          id: 'T0512',
          value: '他のシステムと電子情報を交換するシステムに対して相互運用性テストを実施する。',
          softSkill: false,
        },
        {
          id: 'T0513',
          value: '運用テストを実施する。',
          softSkill: false,
        },
        {
          id: 'T0514',
          value: '故障したシステム/サーバーハードウェアを診断する。',
          softSkill: false,
        },
        {
          id: 'T0515',
          value: '故障したシステム/サーバーハードウェアの修理を行う。',
          softSkill: false,
        },
        {
          id: 'T0516',
          value:
            '安全なプログラムのテスト、レビュー、および/または評価を実施して、コード内の潜在的な欠陥を特定し、脆弱性を軽減します。',
          softSkill: false,
        },
        {
          id: 'T0517',
          value: 'セキュリティアーキテクチャのギャップの特定に関する結果を統合する。',
          softSkill: false,
        },
        {
          id: 'T0518',
          value: 'セキュリティレビューを実施し、アーキテクチャのセキュリティギャップを特定する。',
          softSkill: false,
        },
        {
          id: 'T0519',
          value:
            '教室の技術と形式（例：講義、デモンストレーション、インタラクティブな演習、マルチメディアプレゼンテーション）の配信を計画し、調整して、最も効果的な学習環境を実現します。',
          softSkill: false,
        },
        {
          id: 'T0520',
          value:
            '教室外教育技術と形式を計画する（例：ビデオコース、メンタリング、ウェブベースのコース）。',
          softSkill: false,
        },
        {
          id: 'T0521',
          value: '企業コンポーネントを統合し、整合できるように実施戦略を計画する。',
          softSkill: false,
        },
        {
          id: 'T0522',
          value:
            '法的およびその他の関連文書を準備する（例：供述書、意見書、宣誓供述書、宣言、控訴、訴状、発見）。',
          softSkill: false,
        },
        {
          id: 'T0523',
          value: '調査を文書化する報告書を、法的基準と要件に従って準備してください。',
          softSkill: false,
        },
        {
          id: 'T0524',
          value:
            '組織の運用プロセスとシステムを通じて、情報の所有者/ユーザー間での知識共有を促進する。',
          softSkill: false,
        },
        {
          id: 'T0525',
          value: '企業のサイバーセキュリティとサプライチェーンリスク管理のガイダンスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0526',
          value:
            'リーダーシップに対して重大な脅威と脆弱性に基づいてサイバーセキュリティの推奨事項を提供する。',
          softSkill: false,
        },
        {
          id: 'T0527',
          value:
            '情報システムのセキュリティに関連する実施計画と標準運用手順に入力を提供してください。',
          softSkill: false,
        },
        {
          id: 'T0528',
          value: '実施計画、標準運用手順、保守文書、及び保守訓練資料への入力を提供する。',
          softSkill: false,
        },
        {
          id: 'T0529',
          value: 'サイバー管理、スタッフ、およびユーザーにポリシーガイダンスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0530',
          value: 'トレンド分析と影響レポートを作成する。',
          softSkill: false,
        },
        {
          id: 'T0531',
          value:
            'ハードウェア/ソフトウェアインターフェースと相互運用性の問題をトラブルシューティングします。',
          softSkill: false,
        },
        {
          id: 'T0532',
          value:
            'フォレンジック画像およびその他のデータソース（例：揮発性データ）をレビューして、関連性がある可能性のある情報を回復します。',
          softSkill: false,
        },
        {
          id: 'T0533',
          value: 'サイバープログラムやプロジェクトの監査をレビュー、実施、または参加する。',
          softSkill: false,
        },
        {
          id: 'T0534',
          value:
            '定期的にコース内容の正確性、完全性、整合性、および最新性（例：コース内容の文書、授業計画、学生用テキスト、試験、指導スケジュール、コース説明）のレビュー／改訂を行う。',
          softSkill: false,
        },
        {
          id: 'T0535',
          value:
            '以前のトレーニングセッションからのフィードバックに基づいて、カリキュラムとコース内容の改訂を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0536',
          value:
            '自分の専門分野（例：技術、著作権、印刷メディア、電子メディアなど）において、社内コンサルタントおよびアドバイザーとして機能する。',
          softSkill: false,
        },
        {
          id: 'T0537',
          value: 'CIOをサポートして、サイバー関連のポリシーの策定を行います。',
          softSkill: false,
        },
        {
          id: 'T0538',
          value: 'テストおよび評価活動をサポートする。',
          softSkill: false,
        },
        {
          id: 'T0539',
          value:
            'テスト、評価、およびハードウェアおよび/またはソフトウェアを検証して、定義された仕様および要件との適合を判断します。',
          softSkill: false,
        },
        {
          id: 'T0540',
          value: 'テストデータを記録して管理する。',
          softSkill: false,
        },
        {
          id: 'T0541',
          value: 'システム要件を設計コンポーネントにトレースし、ギャップ分析を実行します。',
          softSkill: false,
        },
        {
          id: 'T0542',
          value: '提案された機能を技術要件に翻訳する。',
          softSkill: false,
        },
        {
          id: 'T0544',
          value:
            'システムアーキテクチャの安定性、相互運用性、移植性、および/またはスケーラビリティを検証します。',
          softSkill: false,
        },
        {
          id: 'T0545',
          value:
            '利害関係者と協力して、コンピュータセキュリティインシデントと脆弱性コンプライアンスを解決します。',
          softSkill: false,
        },
        {
          id: 'T0546',
          value:
            'インシデントの調査結果に関するサイバー防衛の推奨事項、報告書、およびホワイトペーパーを作成し、適切な関係者に公開します。',
          softSkill: false,
        },
        {
          id: 'T0547',
          value: '顧客の要件を満たすために利用可能な技術と基準を調査し評価する。',
          softSkill: false,
        },
        {
          id: 'T0548',
          value: '災害復旧、事態対応、および業務継続計画に対する助言と意見を提供する。',
          softSkill: false,
        },
        {
          id: 'T0549',
          value:
            '技術的（技術の評価）および非技術的（人々や運用の評価）リスクおよび脆弱性評価を、関連する技術焦点領域（例えば、ローカルコンピューティング環境、ネットワークおよびインフラ、エンクレーブ境界、サポートインフラ、およびアプリケーション）に対して実施する。',
          softSkill: false,
        },
        {
          id: 'T0550',
          value:
            'リスクを軽減するためのコスト効果の高いセキュリティ対策の選択に関する推奨事項を提案する（例：情報、システム、プロセスの保護）。',
          softSkill: false,
        },
        {
          id: 'T0551',
          value: 'サプライチェーンのセキュリティとリスク管理に関する文書を作成して公開する。',
          softSkill: false,
        },
        {
          id: 'T0552',
          value: 'サプライチェーンのセキュリティ/リスク管理ポリシーをレビューして承認する。',
          softSkill: false,
        },
        {
          id: 'T0553',
          value:
            'サイバーセキュリティ機能（例：暗号化、アクセス制御、アイデンティティ管理）を適用して、悪用の機会を減らす。',
          softSkill: false,
        },
        {
          id: 'T0554',
          value: 'ソフトウェアを脆弱にするパッチやリリースの範囲を特定し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0555',
          value:
            '新しいシステムまたはシステム間の新しいインターフェースの実装が、セキュリティ姿勢を含むがこれに限定されない現在および目標環境にどのように影響を与えるかを文書化する。',
          softSkill: false,
        },
        {
          id: 'T0556',
          value: 'サイバースペースに関連するセキュリティ管理機能を評価し、設計する。',
          softSkill: false,
        },
        {
          id: 'T0557',
          value: 'サイバースペースに関連するキー管理機能を統合する。',
          softSkill: false,
        },
        {
          id: 'T0558',
          value: 'ユーザーのニーズと要件を分析して、システム開発の計画と実施を行う。',
          softSkill: false,
        },
        {
          id: 'T0559',
          value:
            '特定の運用ニーズと環境要因に対応するための設計を開発する（例：アクセス制御、自動化アプリケーション、ネットワーク運用など）。',
          softSkill: false,
        },
        {
          id: 'T0560',
          value:
            '特定の運用ニーズと環境要因に対応するためのサイバーセキュリティ設計に協力する（例：アクセス制御、自動化アプリケーション、ネットワーク化された運用、高い整合性と可用性の要求、複数の分類レベルのセキュリティ/処理、および機密区画情報の処理）。',
          softSkill: false,
        },
        {
          id: 'T0561',
          value: '正確にターゲットを特定する。',
          softSkill: false,
        },
        {
          id: 'T0562',
          value:
            '特定された問題や課題に対処するため、または全体的な運用要件とコレクションを同期させるために、コレクションの操作または計画を調整してください。',
          softSkill: false,
        },
        {
          id: 'T0563',
          value: '目標達成のために使用される能力の分析、設計、開発、または取得に入力を提供する。',
          softSkill: false,
        },
        {
          id: 'T0564',
          value:
            'フィードバックを分析して、コレクションの製品とサービスが要件をどの程度満たしているかを判断します。',
          softSkill: false,
        },
        {
          id: 'T0565',
          value: '受信したコレクションリクエストを分析します。',
          softSkill: false,
        },
        {
          id: 'T0566',
          value:
            '内部運用アーキテクチャ、ツール、および手順を分析し、パフォーマンスを向上させる方法を検討してください。',
          softSkill: false,
        },
        {
          id: 'T0567',
          value: 'ターゲットの運用アーキテクチャを分析してアクセス方法を見つける。',
          softSkill: false,
        },
        {
          id: 'T0568',
          value:
            '計画、指示、ガイダンス、ポリシーを分析し、コレクション管理の運用構造と要件（例：期間、範囲、コミュニケーション要件、省庁間/国際協定）に影響を与える要因を評価します。',
          softSkill: false,
        },
        {
          id: 'T0569',
          value: '情報のリクエストに回答する。',
          softSkill: false,
        },
        {
          id: 'T0570',
          value:
            '対象ネットワークへのアクセスを可能にするために、認可されたサイバー能力を適用し活用する。',
          softSkill: false,
        },
        {
          id: 'T0571',
          value:
            'ポリシーやプロセスの専門知識を活用して、計画や覚書の開発、交渉、および内部スタッフィングを促進する。',
          softSkill: false,
        },
        {
          id: 'T0572',
          value:
            'サイバー収集、環境準備、およびエンゲージメントの専門知識を適用して、新たな搾取活動を可能にするか、または継続的な収集作業をサポートするか、顧客の要件をサポートします。',
          softSkill: false,
        },
        {
          id: 'T0573',
          value: '運用環境の要因とリスクを収集管理プロセスに評価し適用する。',
          softSkill: false,
        },
        {
          id: 'T0574',
          value: '適用される法令、法律、規則、およびポリシーを適用し、従うこと。',
          softSkill: false,
        },
        {
          id: 'T0575',
          value: '情報支援を作戦計画活動に調整する。',
          softSkill: false,
        },
        {
          id: 'T0576',
          value:
            'すべてのソースのインテリジェンスを評価し、サイバー作戦の目標を支援するためのターゲットを推奨します。',
          softSkill: false,
        },
        {
          id: 'T0577',
          value: '既存の情報交換および管理システムの効率を評価する。',
          softSkill: false,
        },
        {
          id: 'T0578',
          value: '指定された仕様に対する収集資産のパフォーマンスを評価する。',
          softSkill: false,
        },
        {
          id: 'T0579',
          value: '対象の脆弱性および/または運用能力を評価して行動方針を決定する。',
          softSkill: false,
        },
        {
          id: 'T0580',
          value:
            '優先情報ギャップを満たすためのコレクションの有効性を評価し、利用可能な能力と方法を使用して、コレクション戦略とコレクション要件をそれに応じて調整します。',
          softSkill: false,
        },
        {
          id: 'T0581',
          value:
            '組織の目標達成に向けた運用サポートを促進するためのベストプラクティスを特定し、開発するために、庁間パートナーを支援し、助言する。',
          softSkill: false,
        },
        {
          id: 'T0582',
          value: '行動計画の策定に専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0583',
          value: '共通の作戦画像の開発に対する専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0584',
          value: '共通の情報画像を維持する。',
          softSkill: false,
        },
        {
          id: 'T0585',
          value: 'サイバー作戦の特定の指標の開発に対する専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0586',
          value:
            'すべての情報源の収集要件、計画、および/または活動の調整、検証、および管理を支援します。',
          softSkill: false,
        },
        {
          id: 'T0587',
          value: '優先情報要件の開発と洗練を支援する。',
          softSkill: false,
        },
        {
          id: 'T0588',
          value: '効果測定とパフォーマンス測定の開発に専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0589',
          value: '情報収集の不足の特定を支援します。',
          softSkill: false,
        },
        {
          id: 'T0590',
          value:
            '必要に応じて、パートナー組織間でのインテリジェンスサポートプランの同期を有効にする。',
          softSkill: false,
        },
        {
          id: 'T0591',
          value: 'ターゲットインフラストラクチャーの搾取活動の分析を実行します。',
          softSkill: false,
        },
        {
          id: 'T0592',
          value: 'サイバー関連の成功基準の特定に入力を提供する。',
          softSkill: false,
        },
        {
          id: 'T0593',
          value: '簡潔な脅威および/または現在の状況に関する対象。',
          softSkill: false,
        },
        {
          id: 'T0594',
          value: '電子ターゲットフォルダーを構築し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0595',
          value: '文書を分類ガイドラインに従って分類します。',
          softSkill: false,
        },
        {
          id: 'T0596',
          value: '情報に満足したらリクエストを閉じてください。',
          softSkill: false,
        },
        {
          id: 'T0597',
          value: 'インテリジェンスアナリスト/ターゲティング組織と協力すること。関連分野に携わる。',
          softSkill: false,
        },
        {
          id: 'T0598',
          value: '開発組織と協力して、目標を達成するために必要なツールを作成し、展開します。',
          softSkill: false,
        },
        {
          id: 'T0599',
          value:
            '他の顧客、インテリジェンスおよび関連するサイバー領域に関与するターゲティング組織と協力する。',
          softSkill: false,
        },
        {
          id: 'T0600',
          value:
            '他の内部および外部のパートナー組織と協力して、対象アクセスと運用上の問題に取り組む。',
          softSkill: false,
        },
        {
          id: 'T0601',
          value:
            '他のチームメンバーやパートナー組織と協力して、多様な情報資料のプログラム（例：ウェブページ、ブリーフィング、印刷物）を開発します。',
          softSkill: false,
        },
        {
          id: 'T0602',
          value: '顧客と協力して情報要件を定義する。',
          softSkill: false,
        },
        {
          id: 'T0603',
          value:
            '新しい開発、突破、課題、そして学んだ教訓をリーダーシップ、内部および外部の顧客に伝えてください。',
          softSkill: false,
        },
        {
          id: 'T0604',
          value:
            '割り当てられた資産と利用可能な資産を、要件を通じて表現された収集需要と比較してください。',
          softSkill: false,
        },
        {
          id: 'T0605',
          value: '組織のコレクション目標の実行から得られた教訓をまとめる。',
          softSkill: false,
        },
        {
          id: 'T0606',
          value:
            'すべてのソースデータをコンパイル、統合、および/または解釈し、特定のターゲットに対するインテリジェンスまたは脆弱性の「価値」を評価します。',
          softSkill: false,
        },
        {
          id: 'T0607',
          value:
            '対象通信を特定し、操作をサポートするために不可欠な情報を特定するための分析を行う。',
          softSkill: false,
        },
        {
          id: 'T0608',
          value:
            '物理的および論理的デジタル技術（例：ワイヤレス、SCADA、テレコム）の分析を行い、アクセスの潜在的な手段を特定します。',
          softSkill: false,
        },
        {
          id: 'T0609',
          value: 'ワイヤレスコンピューターおよびデジタルネットワークのアクセスを有効にする。',
          softSkill: false,
        },
        {
          id: 'T0610',
          value: '無線コンピュータおよびデジタルネットワークの収集と処理を行う。',
          softSkill: false,
        },
        {
          id: 'T0611',
          value: '業務終了評価を実施する。',
          softSkill: false,
        },
        {
          id: 'T0612',
          value: 'ワイヤレスコンピュータおよびデジタルネットワークの搾取を行う。',
          softSkill: false,
        },
        {
          id: 'T0613',
          value:
            '確立されたガイドラインと手順に従って、正式および非公式な収集要件の調整を行います。',
          softSkill: false,
        },
        {
          id: 'T0614',
          value:
            '独立した詳細なターゲットおよび技術分析を行い、アクセスにつながるターゲット固有の情報（例：文化的、組織的、政治的）を含めます。',
          softSkill: false,
        },
        {
          id: 'T0615',
          value: '徹底的な調査と分析を行う。',
          softSkill: false,
        },
        {
          id: 'T0616',
          value:
            'ネットワーク内のシステムに対するネットワークスカウティングと脆弱性分析を実施する。',
          softSkill: false,
        },
        {
          id: 'T0617',
          value: '節点解析を行う。',
          softSkill: false,
        },
        {
          id: 'T0618',
          value: '配置された技術からデータを制御および抽出するためのオンネット活動を行う。',
          softSkill: false,
        },
        {
          id: 'T0619',
          value:
            'オンネットおよびオフネットの活動を実施し、展開された自動化技術からデータを制御および抽出します。',
          softSkill: false,
        },
        {
          id: 'T0620',
          value: 'さまざまなオンラインツールを通じてオープンソースデータ収集を行う。',
          softSkill: false,
        },
        {
          id: 'T0621',
          value:
            'ネットワークに関して収集された情報の妥当性と関連性を判断するために品質管理を行う。',
          softSkill: false,
        },
        {
          id: 'T0622',
          value:
            'サイバー作戦を支援するために、すべてのレベルの計画指針を開発、レビュー、および実施します。',
          softSkill: false,
        },
        {
          id: 'T0623',
          value: 'コンピューターとデジタルネットワークの調査を行う。',
          softSkill: false,
        },
        {
          id: 'T0624',
          value: '対象の調査と分析を行う。',
          softSkill: false,
        },
        {
          id: 'T0625',
          value:
            '効率性と効果性を考慮し、優先情報要件に対して適用される場合の収集資産およびリソースを評価してください。',
          softSkill: false,
        },
        {
          id: 'T0626',
          value:
            '確立されたガイダンスと手順を使用して、コレクションプランとマトリックスを構築します。',
          softSkill: false,
        },
        {
          id: 'T0627',
          value: 'サイバー作戦の危機対応計画に貢献する。',
          softSkill: false,
        },
        {
          id: 'T0628',
          value: '必要に応じて、組織の意思決定支援ツールの開発に貢献してください。',
          softSkill: false,
        },
        {
          id: 'T0629',
          value:
            'サイバー作戦の政策、人員配置、および調整の開発に貢献し、適切な内部および/または外部の意思決定者とのパフォーマンス基準、計画、および承認パッケージを調整します。',
          softSkill: false,
        },
        {
          id: 'T0630',
          value: 'サイバー作戦計画の全体設計にインテリジェンス・エクイティを組み込む。',
          softSkill: false,
        },
        {
          id: 'T0631',
          value:
            '優先される収集要件に対して、収集分野のリーダーと共に収集資産のリソース割り当てを調整します。',
          softSkill: false,
        },
        {
          id: 'T0632',
          value: '適切な文書に収集計画の組み込みを調整してください。',
          softSkill: false,
        },
        {
          id: 'T0633',
          value: '適切なパートナーとのターゲット審査を調整してください。',
          softSkill: false,
        },
        {
          id: 'T0634',
          value: '再任務またはリダイレクト収集資産とリソース。',
          softSkill: false,
        },
        {
          id: 'T0635',
          value: '情報機関およびサイバー防衛パートナーと連携して、関連する重要情報を取得します。',
          softSkill: false,
        },
        {
          id: 'T0636',
          value:
            'インテリジェンスプランナーと連携して、コレクションマネージャーが情報要件を受け取るようにします。',
          softSkill: false,
        },
        {
          id: 'T0637',
          value:
            'インテリジェンス計画チームと連携して、割り当てられたインテリジェンスタスクを満たす能力を評価します。',
          softSkill: false,
        },
        {
          id: 'T0638',
          value: '調整し、生産し、情報要件を追跡する。',
          softSkill: false,
        },
        {
          id: 'T0639',
          value:
            'サイバー作戦計画の適用可能なインテリジェンスセクションを調整、同期、および草案する。',
          softSkill: false,
        },
        {
          id: 'T0640',
          value: '情報推定を使用して、潜在的なターゲットの行動に対抗してください。',
          softSkill: false,
        },
        {
          id: 'T0641',
          value: '包括的な搾取戦略を作成し、搾取可能な技術的または運用上の脆弱性を特定します。',
          softSkill: false,
        },
        {
          id: 'T0642',
          value:
            '内部および外部のサイバー組織の構造、強み、およびスタッフと技術の配置についての認識を維持する。',
          softSkill: false,
        },
        {
          id: 'T0643',
          value:
            'ターゲットにツールを展開し、展開後にそれらを利用する（例：バックドア、スニファー）。',
          softSkill: false,
        },
        {
          id: 'T0644',
          value: '対象のネットワークやホストに対する攻撃を検出し、適切に対応する。',
          softSkill: false,
        },
        {
          id: 'T0645',
          value: '目標、指導、および運用環境の変更に対処するための行動方針を決定する。',
          softSkill: false,
        },
        {
          id: 'T0646',
          value: '既存のコレクション管理ウェブページのデータベース、図書館、倉庫を特定する。',
          softSkill: false,
        },
        {
          id: 'T0647',
          value:
            '特定された要因がタスキング、収集、処理、搾取、および普及のアーキテクチャの形態と機能にどのように影響するかを決定します。',
          softSkill: false,
        },
        {
          id: 'T0648',
          value: '特定のサイバー作戦目標に最も適した指標（例：効果の測定）を決定する。',
          softSkill: false,
        },
        {
          id: 'T0649',
          value:
            'すべてのアクセス可能な収集資産に対する収集権限を持つ組織および/または階層を特定します。',
          softSkill: false,
        },
        {
          id: 'T0650',
          value: '対象となる技術を特定する。',
          softSkill: false,
        },
        {
          id: 'T0651',
          value:
            'コレクションレポートと未解決の要件を比較する方法を開発し、情報のギャップを特定します。',
          softSkill: false,
        },
        {
          id: 'T0652',
          value: 'すべての情報源からの情報対象資料を開発する。',
          softSkill: false,
        },
        {
          id: 'T0653',
          value: '分析技術を適用して、より多くのターゲット情報を得る。',
          softSkill: false,
        },
        {
          id: 'T0654',
          value: '意図的な計画および/または危機計画を策定し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0655',
          value:
            'より広範な計画活動に統合するための特定のサイバー作戦ガイダンスを開発し、レビューする。',
          softSkill: false,
        },
        {
          id: 'T0656',
          value: 'サイバー作戦の計画と実行に統合するための情報ガイダンスを開発し、レビューする。',
          softSkill: false,
        },
        {
          id: 'T0657',
          value: '各作戦フェーズごとに収集分野別の調整指示を作成します。',
          softSkill: false,
        },
        {
          id: 'T0658',
          value:
            'サイバー作戦計画と指針を策定し、実行とリソース配分の決定が組織の目標と一致するようにします。',
          softSkill: false,
        },
        {
          id: 'T0659',
          value: 'サイバー作戦要件に対する詳細なインテリジェンスサポートを開発する。',
          softSkill: false,
        },
        {
          id: 'T0660',
          value: '優先情報要求に回答するために必要な情報要件を開発する。',
          softSkill: false,
        },
        {
          id: 'T0661',
          value: '効果測定と性能測定を開発する。',
          softSkill: false,
        },
        {
          id: 'T0662',
          value:
            'リーダーシップの指導、優先順位、および/または運用の重点に基づいて、コレクション資産を割り当てます。',
          softSkill: false,
        },
        {
          id: 'T0663',
          value: '弾薬効果評価または作戦評価資料を開発する。',
          softSkill: false,
        },
        {
          id: 'T0664',
          value: 'ターゲットシステムへのアクセスを獲得し維持するための新しい技術を開発する。',
          softSkill: false,
        },
        {
          id: 'T0665',
          value:
            '外部パートナーからのサイバー作戦の同期のために支援を提供、要求、および/または取得するための基準を開発するか、その開発に参加する。',
          softSkill: false,
        },
        {
          id: 'T0666',
          value:
            '組織の目標を達成するために、国際的なサイバー関与戦略、政策、および活動を開発または形成する。',
          softSkill: false,
        },
        {
          id: 'T0667',
          value: '潜在的な行動コースを開発する。',
          softSkill: false,
        },
        {
          id: 'T0668',
          value:
            '収集マネージャー、資産マネージャー、および処理、搾取、および普及センターにフィードバックを提供するための手順を開発する。',
          softSkill: false,
        },
        {
          id: 'T0669',
          value:
            'パートナープランニング、オペレーション、および能力開発のための戦略とプロセスを開発する。',
          softSkill: false,
        },
        {
          id: 'T0670',
          value: '適切な計画手順およびポリシーに変更を開発、実施、および推奨する。',
          softSkill: false,
        },
        {
          id: 'T0671',
          value: '外部パートナーとのサイバー協力セキュリティ協定を開発、維持、評価する。',
          softSkill: false,
        },
        {
          id: 'T0672',
          value: 'サイバー作戦戦略と計画文書を策定、文書化、および検証する。',
          softSkill: false,
        },
        {
          id: 'T0673',
          value: '収集問題について意思決定者に情報を提供するための報告書を配布する。',
          softSkill: false,
        },
        {
          id: 'T0674',
          value: 'タスキングメッセージと収集計画を普及させる。',
          softSkill: false,
        },
        {
          id: 'T0675',
          value: '確立された手順を使用して、収集結果の評価と文書化を行います。',
          softSkill: false,
        },
        {
          id: 'T0676',
          value: 'サイバーインテリジェンス収集および生産要件を起草する。',
          softSkill: false,
        },
        {
          id: 'T0677',
          value:
            'WindowsおよびUNIXシステムで簡単なスクリプト（例：Perl、VBScript）を編集または実行します。',
          softSkill: false,
        },
        {
          id: 'T0678',
          value: '顧客と交流し、顧客の知的ニーズと要望を理解する。',
          softSkill: false,
        },
        {
          id: 'T0679',
          value: '運用計画の努力が現行の業務に効果的に移行されることを確保してください。',
          softSkill: false,
        },
        {
          id: 'T0680',
          value:
            'インテリジェンス計画活動が作戦計画のタイムラインと統合され、同期されるように確保する。',
          softSkill: false,
        },
        {
          id: 'T0681',
          value: '特定された問題や課題に対処するための代替処理、利用、および配信経路を確立する。',
          softSkill: false,
        },
        {
          id: 'T0682',
          value:
            'リーダーシップの重要情報要件および優先情報要件と収集要求との関連を確認してください。',
          softSkill: false,
        },
        {
          id: 'T0683',
          value:
            '承認されたガイダンスおよび/または手順を使用して、処理、搾取、および普及管理活動を確立します。',
          softSkill: false,
        },
        {
          id: 'T0684',
          value: 'サイバー活動を通じて生成される運用効果を見積もる。',
          softSkill: false,
        },
        {
          id: 'T0685',
          value: '脅威評価の意思決定プロセスを評価する。',
          softSkill: false,
        },
        {
          id: 'T0686',
          value: '脅威の脆弱性を特定する。',
          softSkill: false,
        },
        {
          id: 'T0687',
          value: 'ブルーフォースの脆弱性に対する脅威を特定する。',
          softSkill: false,
        },
        {
          id: 'T0688',
          value: '利用可能な能力を望ましい効果と比較して、効率的な解決策を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0689',
          value:
            '収集された情報および/または生成されたインテリジェンスが情報要求をどの程度満たしているかを評価します。',
          softSkill: false,
        },
        {
          id: 'T0690',
          value: '情報評価を行い、計画サイクルを支援します。',
          softSkill: false,
        },
        {
          id: 'T0691',
          value: '利用可能なサイバーインテリジェンス能力の雇用に影響を与える条件を評価する。',
          softSkill: false,
        },
        {
          id: 'T0692',
          value: 'ネットワーク分析戦略の生成と効果の評価を行う。',
          softSkill: false,
        },
        {
          id: 'T0693',
          value: '収集操作が運用要件とどの程度同期されているかを評価します。',
          softSkill: false,
        },
        {
          id: 'T0694',
          value: '収集計画に対する収集作業の効果を評価する。',
          softSkill: false,
        },
        {
          id: 'T0695',
          value: '対象の重要性を理解して、傍受関連のメタデータと内容を調査する。',
          softSkill: false,
        },
        {
          id: 'T0696',
          value:
            'さまざまな方法やツールを使用して、ネットワークデバイス、セキュリティデバイス、および/または端末や環境を悪用する。',
          softSkill: false,
        },
        {
          id: 'T0697',
          value: '物理的および/または無線手段によるアクセスを容易にする。',
          softSkill: false,
        },
        {
          id: 'T0698',
          value:
            '継続的に更新される情報、監視、および可視化入力を共通運用画像マネージャーに提供する。',
          softSkill: false,
        },
        {
          id: 'T0699',
          value:
            '内部および外部のパートナーの意思決定者間の相互作用を促進し、目標を支援するための行動の調整と統合を図る。',
          softSkill: false,
        },
        {
          id: 'T0700',
          value: 'サイバー作戦コミュニティ全体で「ベストプラクティス」と「教訓」の共有を促進する。',
          softSkill: false,
        },
        {
          id: 'T0701',
          value:
            '開発者と協力し、ツール要件の提出において目標と技術知識を伝え、ツール開発を強化する。',
          softSkill: false,
        },
        {
          id: 'T0702',
          value:
            '利用可能なインテリジェンス分野の能力と収集方法の知識に基づいて収集戦略を策定し、多分野の収集能力とアクセスをターゲットとその観測可能なものと整合させます。',
          softSkill: false,
        },
        {
          id: 'T0703',
          value:
            'データを収集・分析し（例：効果の測定）、効果を判断し、後続活動のための報告を提供する。',
          softSkill: false,
        },
        {
          id: 'T0704',
          value: '組織の目標にサイバー作戦と通信セキュリティ支援計画を組み込む。',
          softSkill: false,
        },
        {
          id: 'T0705',
          value:
            '計画開発を支援するために、インテリジェンスとカウンターインテリジェンスを組み込む。',
          softSkill: false,
        },
        {
          id: 'T0706',
          value:
            '伝統的および代替的手法を通じてネットワークに関する情報を収集する（例：ソーシャルネットワーク分析、コールチェーン、トラフィック分析など）。',
          softSkill: false,
        },
        {
          id: 'T0707',
          value: '情報要求を生成する。',
          softSkill: false,
        },
        {
          id: 'T0708',
          value: '脅威の戦術と方法論を特定する。',
          softSkill: false,
        },
        {
          id: 'T0709',
          value:
            'サイバー作戦を支援する利用可能なパートナーのインテリジェンス能力と限界を特定する。',
          softSkill: false,
        },
        {
          id: 'T0710',
          value: '脅威の重要な能力、要件、および脆弱性を特定して評価する。',
          softSkill: false,
        },
        {
          id: 'T0711',
          value:
            '識別、起草、評価、および関連するインテリジェンスまたは情報要件を優先順位付けする。',
          softSkill: false,
        },
        {
          id: 'T0712',
          value: '外部パートナーとのセキュリティ協力の優先事項を特定し、管理する。',
          softSkill: false,
        },
        {
          id: 'T0713',
          value: '情報要件を特定し、優先情報要件を指定する目的で提出してください。',
          softSkill: false,
        },
        {
          id: 'T0714',
          value:
            '協力機関と機能グループとのプロセス、機能、およびアウトプットを調整するためのメカニズムとして機能するコラボレーションフォーラムを特定する。',
          softSkill: false,
        },
        {
          id: 'T0715',
          value: 'ターゲットに対するコレクションのギャップと潜在的な収集戦略を特定する。',
          softSkill: false,
        },
        {
          id: 'T0716',
          value: '指定された収集機関との調整要件と手順を特定する。',
          softSkill: false,
        },
        {
          id: 'T0717',
          value: '重要なターゲット要素を特定します。',
          softSkill: false,
        },
        {
          id: 'T0718',
          value: 'インテリジェンスのギャップと不足を特定する。',
          softSkill: false,
        },
        {
          id: 'T0719',
          value: 'サイバー作戦計画のためのサイバーインテリジェンスのギャップと不足を特定する。',
          softSkill: false,
        },
        {
          id: 'T0720',
          value: 'ターゲット技術における理解のギャップを特定し、革新的な収集アプローチを開発する。',
          softSkill: false,
        },
        {
          id: 'T0721',
          value:
            '問題や問題を特定することが、処理、搾取、および普及アーキテクチャの効果を妨げたり低下させたりする可能性があります。',
          softSkill: false,
        },
        {
          id: 'T0722',
          value:
            'ネットワークコンポーネントとその機能を特定して、分析とターゲット開発を可能にする。',
          softSkill: false,
        },
        {
          id: 'T0723',
          value: '優先情報要件に対する適用のための潜在的な収集規律を特定する。',
          softSkill: false,
        },
        {
          id: 'T0724',
          value: 'ネットワーク内の潜在的な強みと脆弱性を特定する。',
          softSkill: false,
        },
        {
          id: 'T0725',
          value:
            '計画、運用、およびターゲットサイクルをサポートするための収集管理能力に対するリスクを特定し、軽減します。',
          softSkill: false,
        },
        {
          id: 'T0726',
          value:
            '適用可能なインテリジェンス環境準備に由来する生産の必要性、範囲、および時間枠を特定する。',
          softSkill: false,
        },
        {
          id: 'T0727',
          value: '地理空間分析技術を用いて、対象を識別し、位置を特定し、追跡する。',
          softSkill: false,
        },
        {
          id: 'T0728',
          value: '脅威要因に基づいて入力を提供するか、行動方針を策定する。',
          softSkill: false,
        },
        {
          id: 'T0729',
          value:
            '新規または改訂されたポリシーおよびガイダンスがサイバー作戦のパートナーシップ活動に与える可能性のある影響について、外部パートナーに通知してください。',
          softSkill: false,
        },
        {
          id: 'T0730',
          value:
            '利害関係者（例：コレクションマネージャー、アセットマネージャー、処理、搾取および普及センター）に、確立された手順を使用して評価結果を通知する。',
          softSkill: false,
        },
        {
          id: 'T0731',
          value: 'タスクの指示と収集管理の支援を開始してください。',
          softSkill: false,
        },
        {
          id: 'T0732',
          value: '他の組織とサイバープランニング/ターゲティングの取り組みを統合する。',
          softSkill: false,
        },
        {
          id: 'T0733',
          value: '環境準備評価を解釈して行動方針を決定する。',
          softSkill: false,
        },
        {
          id: 'T0734',
          value: '情報要求を出す。',
          softSkill: false,
        },
        {
          id: 'T0735',
          value: '情報支援を作戦計画に導き、調整する。',
          softSkill: false,
        },
        {
          id: 'T0736',
          value: '組織の目標とターゲット要件を支援するための搾取作戦を主導または可能にする。',
          softSkill: false,
        },
        {
          id: 'T0737',
          value: 'リンク優先度収集要件を最適な資産およびリソースに関連付けます。',
          softSkill: false,
        },
        {
          id: 'T0738',
          value:
            'ハードウェアおよびソフトウェア技術の進歩に注意を払い続ける（例：トレーニングや会議への参加、読書）と、それらの潜在的な影響。',
          softSkill: false,
        },
        {
          id: 'T0739',
          value:
            'サイバープランニングまたは関連分野に関与する内部および外部のパートナーとの関係を維持する。',
          softSkill: false,
        },
        {
          id: 'T0740',
          value: '状況認識と有機的運用インフラの機能性を維持する。',
          softSkill: false,
        },
        {
          id: 'T0741',
          value:
            'サイバー関連のインテリジェンス要件および関連するタスクに対する状況認識を維持する。',
          softSkill: false,
        },
        {
          id: 'T0742',
          value: 'パートナーの能力と活動の状況認識を維持する。',
          softSkill: false,
        },
        {
          id: 'T0743',
          value:
            '状況認識を維持して、運用環境の変化が計画の見直しを必要とするかどうかを判断します。',
          softSkill: false,
        },
        {
          id: 'T0744',
          value: 'ターゲットリストを維持する（例：RTL、JTL、CTLなど）。',
          softSkill: false,
        },
        {
          id: 'T0745',
          value: '顧客の要件をサポートするための収集ガイドを推奨する。',
          softSkill: false,
        },
        {
          id: 'T0746',
          value: '必要に応じて収集要件を変更してください。',
          softSkill: false,
        },
        {
          id: 'T0747',
          value: '組織の目標を達成する機会を特定するために、統合サイバー作戦を監視し評価する。',
          softSkill: false,
        },
        {
          id: 'T0748',
          value:
            '脅威の態勢、活動、戦術、能力、目標などの変化を監視し、指定されたサイバー作戦警告問題セットに関連して報告する。',
          softSkill: false,
        },
        {
          id: 'T0749',
          value: '検証済みの脅威活動を監視し、報告する。',
          softSkill: false,
        },
        {
          id: 'T0750',
          value: '再割り当てられた収集努力の完了を監視する。',
          softSkill: false,
        },
        {
          id: 'T0751',
          value:
            'オープンソースのウェブサイトを監視し、組織やパートナーの利益に対する敵対的なコンテンツを特定します。',
          softSkill: false,
        },
        {
          id: 'T0752',
          value:
            '運用環境を監視し、リーダーシップの優先情報要件を満たす敵対的活動について報告する。',
          softSkill: false,
        },
        {
          id: 'T0753',
          value: '処理、搾取、および普及アーキテクチャの運用状況と効果を監視します。',
          softSkill: false,
        },
        {
          id: 'T0754',
          value:
            'ターゲットネットワークを監視して、ターゲット通信の変更や処理の失敗の兆候と警告を提供します。',
          softSkill: false,
        },
        {
          id: 'T0755',
          value:
            '収集運用管理プロセスに対する潜在的要因とリスクを監視するための運用環境を監視します。',
          softSkill: false,
        },
        {
          id: 'T0756',
          value: '対象システムへのアクセスを獲得し維持するための自動システムを操作および維持する。',
          softSkill: false,
        },
        {
          id: 'T0757',
          value:
            '優先情報要件に関連する重要情報の効果と効率を高めるために、収集資産とリソースの組み合わせを最適化します。',
          softSkill: false,
        },
        {
          id: 'T0758',
          value:
            'タイムリーで統合された、全情報源のサイバー作戦インテリジェンスおよび/または警告インテリジェンス製品（例：脅威評価、ブリーフィング、インテリジェンス研究、国別研究）を生成する。',
          softSkill: false,
        },
        {
          id: 'T0759',
          value:
            'ポリシーのレビューと改善に貢献し、そのようなポリシーを支持するかしないかの結果の評価を含めます。',
          softSkill: false,
        },
        {
          id: 'T0760',
          value:
            '必要に応じて、計画チーム、調整グループ、およびタスクフォースに対して専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0761',
          value: '適切に、企画・開発フォーラムや作業グループに対して専門知識とサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0763',
          value: 'サイバー活動における内部および外部のパートナーとの長期的な戦略計画を実施する。',
          softSkill: false,
        },
        {
          id: 'T0764',
          value:
            '内部および外部のサイバーオペレーションパートナーとの計画作業に対する専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0765',
          value: '演習の開発に専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0766',
          value: '外部調整グループとの相互作用を統制する方針を提案する。',
          softSkill: false,
        },
        {
          id: 'T0767',
          value: '組織の目標を達成するためにコンテンツおよび/またはメタデータ分析を実行します。',
          softSkill: false,
        },
        {
          id: 'T0768',
          value:
            'コンピュータやコンピュータネットワークに存在する情報を劣化させたり削除するためのサイバー活動を行う。',
          softSkill: false,
        },
        {
          id: 'T0769',
          value: 'ターゲティング自動化活動を実行する。',
          softSkill: false,
        },
        {
          id: 'T0770',
          value: 'ウェブサイトを特徴づける。',
          softSkill: false,
        },
        {
          id: 'T0771',
          value: 'ウェブサイトの特性評価に主題専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0772',
          value: '演習に備え、専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0773',
          value:
            'プラットフォームの能力に基づいて、収集プラットフォームのための収集要件を優先順位付けします。',
          softSkill: false,
        },
        {
          id: 'T0774',
          value: '顧客への分析および/または配布のために、抽出したデータを処理します。',
          softSkill: false,
        },
        {
          id: 'T0775',
          value: 'ネットワークの再構築を行う。',
          softSkill: false,
        },
        {
          id: 'T0776',
          value: '対象システム分析製品を生成します。',
          softSkill: false,
        },
        {
          id: 'T0777',
          value: 'プロファイルネットワークまたはシステム管理者とその活動。',
          softSkill: false,
        },
        {
          id: 'T0778',
          value: 'プロファイルの対象とその活動。',
          softSkill: false,
        },
        {
          id: 'T0779',
          value:
            '作戦および情報の意思決定者に対して、動的な運用状況に応じて収集資産およびリソースの再割り当てに関する助言/支援を提供する。',
          softSkill: false,
        },
        {
          id: 'T0780',
          value:
            '収集計画を戦略的キャンペーン計画およびその他の適応計画の統合された要素として促進するための助言と支援を提供する。',
          softSkill: false,
        },
        {
          id: 'T0781',
          value: '目標点を提供し、再度の関与に関する推奨事項を示す。',
          softSkill: false,
        },
        {
          id: 'T0782',
          value: '効果評価のための分析とサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0783',
          value:
            '適切に、重要な内部/外部のステークホルダーに対して現在のインテリジェンスサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0784',
          value:
            'サイバーに焦点を当てたガイダンスと、インテリジェンスサポートプランへの入力に関するアドバイスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0785',
          value:
            'インテリジェンス生産、インテリジェンス報告、収集要件、および運用を改善するために必要な評価とフィードバックを提供する。',
          softSkill: false,
        },
        {
          id: 'T0786',
          value:
            'リーダーシップや顧客に情報を提供し、評価すること；目標の策定と精緻化を行うこと；作戦計画と実行を支援すること；および作戦の影響を評価すること。',
          softSkill: false,
        },
        {
          id: 'T0787',
          value:
            'サイバー作戦の目標、優先事項、戦略、計画、およびプログラムの開発と改善のための入力を提供する。',
          softSkill: false,
        },
        {
          id: 'T0788',
          value: '入力を提供し、行動後の効果評価を支援する。',
          softSkill: false,
        },
        {
          id: 'T0789',
          value: '入力を提供し、計画と指導の策定を支援する。',
          softSkill: false,
        },
        {
          id: 'T0790',
          value: 'リーダーシップの受け入れのための効果評価のための入力を提供する。',
          softSkill: false,
        },
        {
          id: 'T0791',
          value: '運用支援計画の管理および物流要素に入力を提供する。',
          softSkill: false,
        },
        {
          id: 'T0792',
          value:
            '指定された演習、計画活動、および時間に敏感な作戦に対して情報分析とサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0793',
          value: '指定された演習や時間に敏感な作戦に対して効果的なサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0794',
          value: '運用と再関与の推奨事項を提供する。',
          softSkill: false,
        },
        {
          id: 'T0795',
          value: '内部および外部のパートナー間で計画支援を提供する。',
          softSkill: false,
        },
        {
          id: 'T0796',
          value: 'リアルタイムで実用的な位置情報を提供する。',
          softSkill: false,
        },
        {
          id: 'T0797',
          value: 'リーダーシップの目標を満たすターゲットの推奨事項を提供する。',
          softSkill: false,
        },
        {
          id: 'T0798',
          value: '指定されたターゲティング製品とターゲティングサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0799',
          value: '時間に敏感なターゲティングサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0800',
          value:
            '組織の目標、リソース、または能力に影響を与える可能性のある差し迫ったまたは敵対的な意図または活動について、適時に通知を提供する。',
          softSkill: false,
        },
        {
          id: 'T0801',
          value: '適切に、運用計画の改善、適応、終了、および実行を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0802',
          value: '適切な情報源をレビューして、収集した情報の妥当性と関連性を判断します。',
          softSkill: false,
        },
        {
          id: 'T0803',
          value: '図または報告書形式でネットワークを再構築します。',
          softSkill: false,
        },
        {
          id: 'T0804',
          value:
            'サイバー効果を達成するための作戦中に、目標に対する情報収集および/または環境準備活動を記録する。',
          softSkill: false,
        },
        {
          id: 'T0805',
          value: '情報由来の重要なネットワークイベントと侵入を報告する。',
          softSkill: false,
        },
        {
          id: 'T0806',
          value:
            '規律固有の処理、搾取、および承認されたガイダンスおよび/または手順に従って、規律の収集資産およびリソースを使用して収集した情報を広めることを要求します。',
          softSkill: false,
        },
        {
          id: 'T0807',
          value:
            '新興技術（コンピュータおよび電話ネットワーク、衛星、ケーブル、無線）における研究コミュニケーションのトレンドを、公開及び機密情報源の両方で調査する。',
          softSkill: false,
        },
        {
          id: 'T0808',
          value: '組織のリーダーシップの目標と計画のためのガイダンスを見直し、理解する。',
          softSkill: false,
        },
        {
          id: 'T0809',
          value: '割り当てられたコレクション資産の能力をレビューする。',
          softSkill: false,
        },
        {
          id: 'T0810',
          value: '情報収集の指針を正確性・適用性の観点から見直してください。',
          softSkill: false,
        },
        {
          id: 'T0811',
          value: '優先順位の高い収集要件と重要情報のレビューリスト。',
          softSkill: false,
        },
        {
          id: 'T0812',
          value: 'レビューおよび必要に応じて包括的な収集計画を更新してください。',
          softSkill: false,
        },
        {
          id: 'T0813',
          value:
            'レビュー、承認、優先順位付け、およびサイバー能力の研究、開発、および/または取得のための運用要件の提出。',
          softSkill: false,
        },
        {
          id: 'T0814',
          value: '最適な資産とリソースの可用性に基づいて収集マトリックスを改訂してください。',
          softSkill: false,
        },
        {
          id: 'T0815',
          value: '情報を消毒し、情報源と方法を保護するために最小限に抑えます。',
          softSkill: false,
        },
        {
          id: 'T0816',
          value: 'サイバーインテリジェンス計画の努力を範囲指定する。',
          softSkill: false,
        },
        {
          id: 'T0817',
          value:
            'パートナーチームからの情報の伝達者として機能し、複雑または異常な状況の調査に協力できる専門家を特定します。',
          softSkill: false,
        },
        {
          id: 'T0818',
          value: '外部パートナーとの連絡役を務める。',
          softSkill: false,
        },
        {
          id: 'T0819',
          value:
            '要求者からの品質、タイムリネス、および収集要件に対する収集の効果についてのフィードバックを募集し、完了まで管理します。',
          softSkill: false,
        },
        {
          id: 'T0820',
          value:
            '収集計画および/または運用環境の変更を指定し、収集資産およびリソースの再割り当てまたは再指向が必要とされる理由を説明してください。',
          softSkill: false,
        },
        {
          id: 'T0821',
          value:
            '特定の専門分野のコレクションおよび/または近い将来に実行されなければならないタスクを指定してください。',
          softSkill: false,
        },
        {
          id: 'T0822',
          value:
            '情報要求を収集要求として処理するために、収集要件管理セクションに提出してください。',
          softSkill: false,
        },
        {
          id: 'T0823',
          value: 'サイバー作戦の衝突回避のための要求を提出または応答する。',
          softSkill: false,
        },
        {
          id: 'T0824',
          value: 'サポートの識別と副作用の文書化。',
          softSkill: false,
        },
        {
          id: 'T0825',
          value:
            '適切にサイバー国際エンゲージメント活動と関連するリソース要件を同期させてください。',
          softSkill: false,
        },
        {
          id: 'T0826',
          value: 'セキュリティ協力計画のサイバー部分を同期させる。',
          softSkill: false,
        },
        {
          id: 'T0827',
          value:
            'すべての利用可能な有機体およびパートナーの情報収集資産の統合使用を、利用可能な協力能力と技術を使用して同期させます。',
          softSkill: false,
        },
        {
          id: 'T0828',
          value: '地元で開発されたツールをテストし、運用に使用するために評価します。',
          softSkill: false,
        },
        {
          id: 'T0829',
          value: '内部で開発されたツールと技術を対象ツールに対してテストする。',
          softSkill: false,
        },
        {
          id: 'T0830',
          value:
            '情報要求の追跡状況を、収集要求として処理されたものや生産要件を含め、確立された手順を使用して追跡します。',
          softSkill: false,
        },
        {
          id: 'T0831',
          value: 'コレクションのリクエストを適用可能な専門分野固有のコレクション要件に翻訳します。',
          softSkill: false,
        },
        {
          id: 'T0832',
          value:
            'フィードバックの結果（例：学んだ教訓）を使用して、収集管理の効率と効果を改善する機会を特定します。',
          softSkill: false,
        },
        {
          id: 'T0833',
          value: '確立された基準に従って情報要求を検証する。',
          softSkill: false,
        },
        {
          id: 'T0834',
          value:
            '計画担当者、インテリジェンスアナリスト、およびコレクションマネージャーと密接に連携し、インテリジェンス要件と収集計画が正確で最新の状態であることを確認します。',
          softSkill: false,
        },
        {
          id: 'T0835',
          value:
            '計画担当者、アナリスト、およびコレクションマネージャーと密接に連携し、インテリジェンスのギャップを特定し、インテリジェンス要件が正確で最新の状態であることを確認します。',
          softSkill: false,
        },
        {
          id: 'T0836',
          value: 'イベントや演習の結果を伝える学んだ教訓を文書化する。',
          softSkill: false,
        },
        {
          id: 'T0837',
          value:
            '組織の目標に影響を与える言語および文化の問題について、マネージャーやオペレーターに助言する。',
          softSkill: false,
        },
        {
          id: 'T0838',
          value: '言語や文化の専門知識を使用して情報を分析・処理する。',
          softSkill: false,
        },
        {
          id: 'T0839',
          value:
            '評価し、文書化し、対象の動機や参照枠を適用して分析、ターゲティング、収集の機会を容易にする。',
          softSkill: false,
        },
        {
          id: 'T0840',
          value:
            '内部および/または外部の組織の枠を超えて協力し、収集、分析、および情報の普及を強化する。',
          softSkill: false,
        },
        {
          id: 'T0841',
          value:
            'すべてのソースのターゲットリサーチを行い、ターゲット言語のオープンソース資料を使用してください。',
          softSkill: false,
        },
        {
          id: 'T0842',
          value:
            '組織の目標を支援するために、ターゲットとなるコミュニケーションの分析を行い、重要な情報を特定します。',
          softSkill: false,
        },
        {
          id: 'T0843',
          value: '翻訳または書き起こし資料の品質レビューを実施し、フィードバックを提供する。',
          softSkill: false,
        },
        {
          id: 'T0844',
          value:
            'メタデータを評価し解釈して、パターン、異常、またはイベントを探し、ターゲティング、分析、および処理を最適化します。',
          softSkill: false,
        },
        {
          id: 'T0845',
          value: 'サイバー脅威の戦術と方法論を特定する。',
          softSkill: false,
        },
        {
          id: 'T0846',
          value: 'グローバルネットワーク内の対象通信を特定する。',
          softSkill: false,
        },
        {
          id: 'T0847',
          value:
            'ターゲット通信ツール、技術、およびターゲット通信ネットワークの特性（例：容量、機能性、経路、重要ノード）を認識し続け、ターゲティング、収集、および分析に対する潜在的な影響を理解する。',
          softSkill: false,
        },
        {
          id: 'T0848',
          value:
            'コレクションマネージャーにフィードバックを提供して、将来のコレクションと分析を強化します。',
          softSkill: false,
        },
        {
          id: 'T0849',
          value: '外国語および方言の識別を初期ソースデータで実行します。',
          softSkill: false,
        },
        {
          id: 'T0850',
          value: '技術的なネットワーク分析およびマッピングを実行またはサポートする。',
          softSkill: false,
        },
        {
          id: 'T0851',
          value: '言語処理ツールの開発を最適化するための要件とフィードバックを提供する。',
          softSkill: false,
        },
        {
          id: 'T0852',
          value: 'ソーシャルネットワーク分析を実行し、適切に文書化する。',
          softSkill: false,
        },
        {
          id: 'T0853',
          value:
            'スキャンし、対象のグラフィック（マシン間通信を含む）および/または音声言語素材を識別し、優先順位を付ける。',
          softSkill: false,
        },
        {
          id: 'T0854',
          value: '適切な顧客に重要または時間に敏感な情報を伝える。',
          softSkill: false,
        },
        {
          id: 'T0855',
          value: 'ターゲット言語でターゲットの音声素材を書き起こしてください。',
          softSkill: false,
        },
        {
          id: 'T0856',
          value: '翻訳（例：逐語的、要旨、および/または要約）対象のグラフィック素材。',
          softSkill: false,
        },
        {
          id: 'T0857',
          value: '翻訳（例：逐語訳、要旨、および/または要約）対象の音声素材。',
          softSkill: false,
        },
        {
          id: 'T0858',
          value: 'コンピュータープログラム内の外国語用語を特定する（例：コメント、変数名）。',
          softSkill: false,
        },
        {
          id: 'T0859',
          value: 'リアルタイムに近い言語分析サポートを提供する（例：ライブオペレーション）。',
          softSkill: false,
        },
        {
          id: 'T0860',
          value: 'サイバー/テクノロジー関連の用語を対象言語で特定する。',
          softSkill: false,
        },
        {
          id: 'T0861',
          value:
            '一般顧問、外部関係、および事業部と協力して、既存のサービスと新しいサービスがプライバシーとデータセキュリティの義務を遵守することを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0862',
          value:
            '法務顧問および経営陣、主要部門および委員会と連携し、組織が適切なプライバシーおよび機密保持の同意、認可書類、情報通知および資料を維持し、現行の組織および法的慣行および要件を反映するようにする。',
          softSkill: false,
        },
        {
          id: 'T0863',
          value:
            '適切な規制機関と連携して、市民の権利、市民の自由、およびプライバシーに関する考慮事項を含むプログラム、ポリシー、および手順が統合的かつ包括的な方法で対処されるようにします。',
          softSkill: false,
        },
        {
          id: 'T0864',
          value: '規制機関および認定機関との連絡を取る。',
          softSkill: false,
        },
        {
          id: 'T0865',
          value:
            '外部関係者と協力して、プライバシーとデータセキュリティ問題を担当する規制当局やその他の政府関係者との関係を築く。',
          softSkill: false,
        },
        {
          id: 'T0866',
          value:
            '適用される連邦および州のプライバシー法および認定基準の現在の知識を維持し、情報プライバシー技術の進歩を監視して、組織の適応とコンプライアンスを確保します。',
          softSkill: false,
        },
        {
          id: 'T0867',
          value:
            'すべての処理および/またはデータベースが必要に応じて地元のプライバシー/データ保護当局に登録されていることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0868',
          value:
            'ビジネスチームおよびシニアマネジメントと協力して、「ベストプラクティス」のプライバシーとデータセキュリティの問題に関する認識を確保します。',
          softSkill: false,
        },
        {
          id: 'T0869',
          value: '組織の上級管理職と協力して、組織全体のプライバシー監視委員会を設立する。',
          softSkill: false,
        },
        {
          id: 'T0870',
          value: 'プライバシー監視委員会の活動でリーダーシップの役割を果たす。',
          softSkill: false,
        },
        {
          id: 'T0871',
          value: 'サイバープライバシーとセキュリティポリシーおよび手順について協力する。',
          softSkill: false,
        },
        {
          id: 'T0872',
          value:
            'サイバーセキュリティ担当者と協力して、プライバシーのコンプライアンスとリスク軽減に対処するためのセキュリティリスク評価プロセスに取り組む',
          softSkill: false,
        },
        {
          id: 'T0873',
          value:
            'シニアマネジメントと連携して、情報の収集、使用、共有の戦略計画を開発し、適用されるプライバシー規制に準拠しながらその「価値」を最大限に高める方法を策定します。',
          softSkill: false,
        },
        {
          id: 'T0874',
          value: '企業幹部に情報リソースと技術に関する戦略的指導を提供する',
          softSkill: false,
        },
        {
          id: 'T0875',
          value: 'セキュリティオフィサーと情報インフラの開発と実装を支援する',
          softSkill: false,
        },
        {
          id: 'T0876',
          value:
            'プライバシー違反の証拠を自己申告するための文書化および報告手順について、企業コンプライアンスオフィサーと調整してください。',
          softSkill: false,
        },
        {
          id: 'T0877',
          value: '適切な組織ユニットと協力して、消費者情報アクセス権を監視する',
          softSkill: false,
        },
        {
          id: 'T0878',
          value: '技術システムのユーザーの情報プライバシー連絡担当として機能する',
          softSkill: false,
        },
        {
          id: 'T0879',
          value: '情報システム部門との連絡役を務める',
          softSkill: false,
        },
        {
          id: 'T0880',
          value:
            'プライバシー研修資料およびその他のコミュニケーションを開発し、従業員が会社のプライバシーポリシー、データ取り扱いの実践と手順、法的義務を理解するのを促進する',
          softSkill: false,
        },
        {
          id: 'T0881',
          value:
            '初期プライバシートレーニングおよびオリエンテーションを全従業員、ボランティア、契約者、提携者、ビジネス関連者、その他適切な第三者に提供、または提供を確実にするために監督、指示、配信する',
          softSkill: false,
        },
        {
          id: 'T0882',
          value: '継続的なプライバシー研修と意識向上活動を実施する',
          softSkill: false,
        },
        {
          id: 'T0883',
          value:
            '外務と連携して、プライバシーとデータセキュリティの問題に関心を持つ消費者団体や他のNGOとの関係を築き、プライバシーとデータセキュリティに関連する公のイベントへの企業参加を管理します。',
          softSkill: false,
        },
        {
          id: 'T0884',
          value:
            '組織の情報プライバシーの利益を代表し、プライバシーの立法、規制、または基準を採用または改正することを約束する政府機関を含む外部の当事者と連携するために、組織管理、法務顧問、およびその他の関連当事者と協力してください。',
          softSkill: false,
        },
        {
          id: 'T0885',
          value:
            '定期的にプライバシープログラムの状況について取締役会、CEO、またはその他の責任者や委員会に報告する。',
          softSkill: false,
        },
        {
          id: 'T0886',
          value:
            '消費者および従業員のデータに関する懸念に対する報道やその他の問い合わせに対応するため、外務と連携する',
          softSkill: false,
        },
        {
          id: 'T0887',
          value: '組織のプライバシープログラムのリーダーシップを提供する',
          softSkill: false,
        },
        {
          id: 'T0888',
          value:
            'プライバシー専門家を直接指導し、上級幹部と全世界でプライバシーとデータセキュリティプログラムを調整して、組織全体での一貫性を保証する',
          softSkill: false,
        },
        {
          id: 'T0889',
          value:
            'プライバシー慣行に準拠し、組織の労働力、拡張労働力、およびすべてのビジネスアソシエイトに対して、人事部、情報セキュリティオフィサー、管理部門、法務顧問と協力して、プライバシーポリシーに違反した場合の制裁を一貫して適用することを確保します。',
          softSkill: false,
        },
        {
          id: 'T0890',
          value: '企業のプライバシーポリシーおよび手順に従わない場合の適切な制裁を策定する',
          softSkill: false,
        },
        {
          id: 'T0891',
          value: '企業のプライバシーポリシーまたは情報慣行通知との非遵守の申し立てを解決する',
          softSkill: false,
        },
        {
          id: 'T0892',
          value:
            'プライバシーのためのリスク管理およびコンプライアンスフレームワークを開発し、調整する',
          softSkill: false,
        },
        {
          id: 'T0893',
          value:
            '会社のデータおよびプライバシープロジェクトを総合的にレビューし、それらが企業のプライバシーおよびデータセキュリティの目標およびポリシーと一致していることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0894',
          value:
            '新製品およびサービスの開発が企業のプライバシーポリシーおよび法的義務と一致していることを保証するために、企業全体の手順を開発して管理します。',
          softSkill: false,
        },
        {
          id: 'T0895',
          value:
            '組織のプライバシーポリシーおよび手順に関するすべての苦情を受け付け、文書化し、追跡し、調査し、対応するプロセスを確立する',
          softSkill: false,
        },
        {
          id: 'T0896',
          value:
            '管理部門および運用部門と協力して、組織の範囲内および法律によって要求される範囲で保護された健康情報へのアクセスを追跡するメカニズムを確立し、資格を持つ個人がそのような活動をレビューまたは報告書を受け取ることを可能にする。',
          softSkill: false,
        },
        {
          id: 'T0897',
          value:
            'プライバシーとセキュリティに関連するプロジェクトの計画、設計、評価においてリーダーシップを提供する',
          softSkill: false,
        },
        {
          id: 'T0898',
          value: '内部プライバシー監査プログラムを確立する',
          softSkill: false,
        },
        {
          id: 'T0899',
          value:
            '定期的に法律、規制、または企業ポリシーの変更を考慮してプライバシープログラムを見直す',
          softSkill: false,
        },
        {
          id: 'T0900',
          value:
            '開発指導を提供し、組織管理、管理部門、法務顧問と連携して、組織の情報プライバシーポリシーおよび手順の特定、実装、維持を支援する',
          softSkill: false,
        },
        {
          id: 'T0901',
          value:
            '技術の使用が個人情報の使用、収集、および開示に関するプライバシー保護を維持し、侵食しないことを保証する',
          softSkill: false,
        },
        {
          id: 'T0902',
          value: 'システム開発と運用をセキュリティとプライバシーのコンプライアンスのために監視する',
          softSkill: false,
        },
        {
          id: 'T0903',
          value:
            '提案された規則が個人情報のプライバシーに与える影響を評価するために、プライバシー影響評価を実施する。これには、収集される個人情報の種類と影響を受ける人々の数を含む。',
          softSkill: false,
        },
        {
          id: 'T0904',
          value:
            '定期的な情報プライバシー影響評価と、組織の他のコンプライアンスおよび運用評価機能との調整による継続的なコンプライアンス監視活動を実施する',
          softSkill: false,
        },
        {
          id: 'T0905',
          value:
            'すべてのシステム関連の情報セキュリティ計画を確認し、セキュリティとプライバシーの実践が一致していることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0906',
          value:
            'すべての組織の人員と協力して、保護された情報の公開に関わるあらゆる側面で、組織のポリシー、手順、および法的要件との調整を確保します。',
          softSkill: false,
        },
        {
          id: 'T0907',
          value:
            '個人情報および/または保護された情報の開示または公開に関する個別の要求を管理し、処理する',
          softSkill: false,
        },
        {
          id: 'T0908',
          value:
            'プライバシーおよびデータセキュリティポリシーおよび法的要件に準拠するためのベンダーの審査および監査手順を開発し、管理します。',
          softSkill: false,
        },
        {
          id: 'T0909',
          value:
            'すべての取引パートナーおよびビジネスアソシエイト契約の実施と継続的なコンプライアンス監視に参加し、すべてのプライバシーに関する懸念、要件、および責任が対処されるようにします。',
          softSkill: false,
        },
        {
          id: 'T0910',
          value: 'ビジネスパートナー契約に関連して、弁護士として行動するか、または協力する',
          softSkill: false,
        },
        {
          id: 'T0911',
          value: '従業員またはビジネスパートナーによる個人情報の使用または開示の影響を軽減する',
          softSkill: false,
        },
        {
          id: 'T0912',
          value: '是正措置手順を開発し、適用する',
          softSkill: false,
        },
        {
          id: 'T0913',
          value:
            '組織のプライバシーポリシーおよび手順に関するすべての苦情に対して行動を実施し、必要に応じて他の類似機能および法的助言と協力・共同で行う',
          softSkill: false,
        },
        {
          id: 'T0914',
          value:
            '組織のプライバシー遵守プログラムをサポートし、プライバシー担当者、最高情報セキュリティ責任者、および他のビジネスリーダーと密接に連携して、連邦および州のプライバシー法規と規制に対する遵守を確保します。',
          softSkill: false,
        },
        {
          id: 'T0915',
          value:
            '潜在的な企業のコンプライアンスのギャップやリスクの領域を特定し、プライバシー規制への完全な準拠を確保する',
          softSkill: false,
        },
        {
          id: 'T0916',
          value:
            'プライバシー事故および違反をプライバシーオフィサー、最高情報セキュリティ責任者、法務顧問、および事業部門と連携して管理する',
          softSkill: false,
        },
        {
          id: 'T0917',
          value:
            '最高情報セキュリティ責任者と連携して、セキュリティとプライバシーの慣行の一致を確保してください',
          softSkill: false,
        },
        {
          id: 'T0918',
          value: 'プライバシー規制に準拠するための組織全体のポリシーと手順を確立、実施、維持する',
          softSkill: false,
        },
        {
          id: 'T0919',
          value:
            '会社が適切なプライバシーおよび機密保持の通知、同意および承認フォーム、および資料を維持することを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0920',
          value:
            '適切なコミュニケーションとトレーニングを開発・維持し、すべての労働者および取締役会のメンバーにプライバシー遵守の問題と要件、および非遵守の結果について啓発・教育する',
          softSkill: false,
        },
        {
          id: 'T0921',
          value: '組織のプライバシープログラムに関連するビジネスパートナー要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0922',
          value:
            '会社のプライバシーポリシーおよび手順に関する苦情を受け付け、文書化、追跡、調査し、適切に是正措置を講じるプロセスを確立し、管理します。',
          softSkill: false,
        },
        {
          id: 'T0923',
          value:
            '関連する規制機関および他の法人、および組織の役員と協力し、コンプライアンスのレビューまたは調査に関与してください。',
          softSkill: false,
        },
        {
          id: 'T0924',
          value: '継続的なプライバシー遵守モニタリング活動を実施する。',
          softSkill: false,
        },
        {
          id: 'T0925',
          value: '情報プライバシー技術の進歩を監視し、組織の採用とコンプライアンスを確保します。',
          softSkill: false,
        },
        {
          id: 'T0926',
          value:
            'プライバシーに関するトレーニング資料の開発を行うか、またはその開発を支援し、社員が会社のプライバシーポリシー、データ取扱いの実践と手順、法的義務についての理解を深めるためのその他のコミュニケーションを行います。',
          softSkill: false,
        },
        {
          id: 'T0927',
          value: 'ITセキュリティ専門家のチームを任命し、指導する。',
          softSkill: false,
        },
        {
          id: 'T0928',
          value:
            '主要なステークホルダーと協力して、サイバーセキュリティリスク管理プログラムを確立します。',
          softSkill: false,
        },
        {
          id: 'T0929',
          value:
            'リスク管理フレームワークの実行に関連する特定の役割に個人を特定し、割り当ててください。',
          softSkill: false,
        },
        {
          id: 'T0930',
          value: '組織のためにリスク許容度の決定を含むリスク管理戦略を確立する。',
          softSkill: false,
        },
        {
          id: 'T0931',
          value:
            'システムがサポートするミッション、ビジネス機能、およびミッション/ビジネスプロセスを特定します。',
          softSkill: false,
        },
        {
          id: 'T0932',
          value:
            'システムの開発、実装、運用、または維持にセキュリティ関心を持つ利害関係者を特定する。',
          softSkill: false,
        },
        {
          id: 'T0933',
          value:
            'システムの開発、実装、運用、または維持にセキュリティ関心を持つ利害関係者を特定する。',
          softSkill: false,
        },
        {
          id: 'T0934',
          value: '利害関係者の資産を特定し、保護が必要です。',
          softSkill: false,
        },
        {
          id: 'T0935',
          value:
            'ステークホルダーの資産に対する初期リスク評価を実施し、リスク評価を継続的に更新する。',
          softSkill: false,
        },
        {
          id: 'T0936',
          value: '利害関係者の保護ニーズと利害関係者のセキュリティ要件を定義する。',
          softSkill: false,
        },
        {
          id: 'T0937',
          value: 'エンタープライズアーキテクチャ内でのシステムの配置を決定する。',
          softSkill: false,
        },
        {
          id: 'T0938',
          value:
            '組織全体で共有される共通コントロールを特定し、組織のシステムが継承できるようにします。',
          softSkill: false,
        },
        {
          id: 'T0939',
          value: '組織システムに対して同じ影響レベルのセカンドレベルセキュリティ分類を実施する。',
          softSkill: false,
        },
        {
          id: 'T0940',
          value: 'システムの境界を決定する。',
          softSkill: false,
        },
        {
          id: 'T0941',
          value: 'システムと組織に割り当てられたセキュリティ要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0942',
          value: 'システムによって処理、保存、または送信される情報の種類を特定する。',
          softSkill: false,
        },
        {
          id: 'T0943',
          value: 'システムを分類し、システム要件の一部としてセキュリティ分類結果を文書化します。',
          softSkill: false,
        },
        {
          id: 'T0944',
          value: 'システムの特性を説明する。',
          softSkill: false,
        },
        {
          id: 'T0945',
          value: '適切な組織プログラム/管理オフィスにシステムを登録してください。',
          softSkill: false,
        },
        {
          id: 'T0946',
          value:
            'システムのセキュリティコントロールを選択し、計画されたコントロール実装の機能的説明をセキュリティプランに文書化します。',
          softSkill: false,
        },
        {
          id: 'T0947',
          value:
            'セキュリティコントロールの有効性を監視する戦略を開発し、システムレベルの戦略を組織およびミッション/ビジネスプロセスレベルの監視戦略と調整します。',
          softSkill: false,
        },
        {
          id: 'T0948',
          value: 'セキュリティプランをレビューして承認する。',
          softSkill: false,
        },
        {
          id: 'T0949',
          value:
            'セキュリティプランまたはその他のシステム文書で指定されたセキュリティコントロールを実装する。',
          softSkill: false,
        },
        {
          id: 'T0950',
          value:
            '計画されたセキュリティコントロールの実装に対する変更を文書化し、システムの構成ベースラインを確立します。',
          softSkill: false,
        },
        {
          id: 'T0951',
          value:
            'システムおよび組織のセキュリティコントロールを評価する計画を開発、レビュー、承認する。',
          softSkill: false,
        },
        {
          id: 'T0952',
          value: 'セキュリティ評価計画で定義された評価手順に従って、セキュリティ管理を評価します。',
          softSkill: false,
        },
        {
          id: 'T0953',
          value:
            'セキュリティコントロール評価からの問題点、調査結果、および推奨事項を文書化するセキュリティ評価レポートを準備してください。',
          softSkill: false,
        },
        {
          id: 'T0954',
          value:
            'セキュリティ評価レポートの調査結果と推奨に基づいて、セキュリティコントロールの初期修復措置を実施し、修復されたコントロールを再評価する。',
          softSkill: false,
        },
        {
          id: 'T0955',
          value:
            'セキュリティ評価レポートの調柤結果と推奨事項に基づいて、実施された是正措置を除外した行動計画とマイルストーンを準備する。',
          softSkill: false,
        },
        {
          id: 'T0956',
          value: '認可パッケージを組み立て、審査のために認可担当者に提出してください。',
          softSkill: false,
        },
        {
          id: 'T0957',
          value:
            'システムの運用または使用、または共通コントロールの提供または使用からのリスクを評価します。',
          softSkill: false,
        },
        {
          id: 'T0958',
          value: 'リスクの判断に基づいて、好ましい行動方針を特定し、実施する。',
          softSkill: false,
        },
        {
          id: 'T0959',
          value:
            'システムの操作または使用、または共通コントロールの提供または使用からのリスクが許容可能かどうかを判断します。',
          softSkill: false,
        },
        {
          id: 'T0960',
          value: 'システムとその運用環境の変更を監視する。',
          softSkill: false,
        },
        {
          id: 'T0961',
          value:
            '組織が定義した監視戦略に従って、システム内およびシステムに継承されたセキュリティコントロールを評価します。',
          softSkill: false,
        },
        {
          id: 'T0962',
          value:
            '継続的な監視活動の結果、リスク評価、および行動計画とマイルストーンの未解決項目に基づいてリスクに対応する。',
          softSkill: false,
        },
        {
          id: 'T0963',
          value:
            '継続的監視プロセスの結果に基づいて、セキュリティプラン、セキュリティ評価レポート、および行動計画とマイルストーンを更新します。',
          softSkill: false,
        },
        {
          id: 'T0964',
          value:
            'システムのセキュリティ状態（セキュリティコントロールの有効性を含む）を、監視戦略に従って継続的に認可担当者に報告します。',
          softSkill: false,
        },
        {
          id: 'T0965',
          value:
            'システムのセキュリティ状態（セキュリティ対策の有効性を含む）を継続的に確認し、リスクが許容範囲内に留まっているかどうかを判断します。',
          softSkill: false,
        },
        {
          id: 'T0966',
          value:
            'システムがサービスから削除されたときに必要なアクションを実行するシステム廃棄戦略を実装します。',
          softSkill: false,
        },
        {
          id: 'T0967',
          value: '組織内で継続的な監視を後援し、促進する。',
          softSkill: false,
        },
        {
          id: 'T0968',
          value: '適切な継続的監視ワーキンググループに必要なスタッフを割り当ててください。',
          softSkill: false,
        },
        {
          id: 'T0969',
          value: '継続的な監視活動を支援するための報告要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0970',
          value: '継続的監視プログラムの効果を測定するためのスコアリングと評価基準を設定する。',
          softSkill: false,
        },
        {
          id: 'T0971',
          value:
            '組織の広範な情報セキュリティガバナンス構造とポリシーに連続的な監視プログラムを統合する方法を決定します。',
          softSkill: false,
        },
        {
          id: 'T0972',
          value:
            '継続的な監視スコアリングと評価指標を使用して、持続的な問題に対処するための情報セキュリティ投資の決定を行います。',
          softSkill: false,
        },
        {
          id: 'T0973',
          value:
            '継続的な監視スタッフが割り当てられた任務を遂行するために必要な訓練とリソース（例えば、スタッフと予算）を確保してください。',
          softSkill: false,
        },
        {
          id: 'T0974',
          value:
            '組織のリスクアナリストと協力して、継続的なモニタリングレポートが組織の適切なレベルをカバーしていることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0975',
          value:
            '組織のリスクアナリストと協力して、リスクメトリクスが現実的に定義され、継続的な監視を支援するようにします。',
          softSkill: false,
        },
        {
          id: 'T0976',
          value:
            '組織の関係者と協力して、継続的な監視ツールのデータがリスクレベルの状況認識を提供することを確認します。',
          softSkill: false,
        },
        {
          id: 'T0977',
          value: '継続的な監視データの許容できないリスク閾値のトリガーを設定します。',
          softSkill: false,
        },
        {
          id: 'T0978',
          value:
            '組織の継続的監視プログラムで使用できるシステムレベルの報告カテゴリを設定するために、組織の関係者と協力する。',
          softSkill: false,
        },
        {
          id: 'T0980',
          value: '継続的監視プログラムの管理と実施を担当する資格のある人物を指定してください。',
          softSkill: false,
        },
        {
          id: 'T0981',
          value:
            '継続的な監視の関係者を特定し、プログラムについて彼らを情報提供するプロセスを確立します。',
          softSkill: false,
        },
        {
          id: 'T0982',
          value:
            '継続的監視プログラムによって満たされる、セキュリティ指向の組織の報告要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0983',
          value:
            '継続的な監視データを使用して、持続的な問題に対処するための情報セキュリティ投資の決定を行ってください。',
          softSkill: false,
        },
        {
          id: 'T0984',
          value:
            '連続監視プログラム内で、許容できないリスクを定義し、解決のための行動が取られるようにするトリガーを定義する。',
          softSkill: false,
        },
        {
          id: 'T0985',
          value: '継続的監視プログラムの効果を測定するための採点および評価基準を設定します。',
          softSkill: false,
        },
        {
          id: 'T0986',
          value:
            'システムレベルで適切な継続的監視報告要件を確立するために、セキュリティマネージャーと協力する。',
          softSkill: false,
        },
        {
          id: 'T0987',
          value: '継続的な監視ツールと技術を使用して、リスクを継続的に評価します。',
          softSkill: false,
        },
        {
          id: 'T0988',
          value:
            '自動制御評価で使用するために、継続的監視プログラムで特定された基準に従って、適切な報告要件を確立します。',
          softSkill: false,
        },
        {
          id: 'T0989',
          value:
            '連続監視ツールや技術からのデータがまだ十分でないか、品質が適切でない場合は、非自動化評価方法を使用してください。',
          softSkill: false,
        },
        {
          id: 'T0990',
          value:
            '外部監査グループと連携して、継続的監視プログラムとセキュリティコントロール評価への影響に関する情報共有方法を開発する。',
          softSkill: false,
        },
        {
          id: 'T0991',
          value: '自動制御評価に使用する報告要件を特定し、継続的な監視をサポートします。',
          softSkill: false,
        },
        {
          id: 'T0992',
          value: '継続的な監視の結果が継続的な認可にどのように使用されるかを決定します。',
          softSkill: false,
        },
        {
          id: 'T0993',
          value: '継続的な監視ツールと技術アクセス制御プロセスおよび手順を確立します。',
          softSkill: false,
        },
        {
          id: 'T0994',
          value:
            '継続的な監視ツールおよび技術のアクセス制御が適切に管理されていることを確認してください。',
          softSkill: false,
        },
        {
          id: 'T0995',
          value: '継続的監視緩和策に技術的支援を提供するプロセスを確立する。',
          softSkill: false,
        },
        {
          id: 'T0996',
          value: 'さまざまなユーザー間で連続的な監視報告要件を調整します。',
          softSkill: false,
        },
        {
          id: 'T0997',
          value: '各連続モニタリングツールまたは技術の実装をサポートするための責任を確立します。',
          softSkill: false,
        },
        {
          id: 'T0998',
          value:
            'スコアリングおよびメトリクス作業グループとの連絡を確立して、継続的な監視をサポートします。',
          softSkill: false,
        },
        {
          id: 'T0999',
          value: '新しいリスクの導入を管理し、継続的な監視を支援するプロセスを確立して運用する。',
          softSkill: false,
        },
        {
          id: 'T1000',
          value: '継続的な監視の設定問題と調整サブグループを確立する。',
          softSkill: false,
        },
        {
          id: 'T1001',
          value: '継続的な監視ツールおよび技術のパフォーマンス測定/管理要件を確立します。',
          softSkill: false,
        },
        {
          id: 'T1002',
          value:
            'スコアと成績を使用して動機付けとパフォーマンス評価を行い、継続的なモニタリングをサポートするための懸念に対処する',
          softSkill: false,
        },
        {
          id: 'T1003',
          value:
            'セキュリティマネージャー（システムオーナー、情報システムセキュリティマネージャー、情報システムセキュリティオフィサーなど）と協力して、システムレベルでの継続的な監視のための適切な報告要件を確立します。',
          softSkill: false,
        },
        {
          id: 'T1004',
          value: '継続的な監視ツールを使用して、リスクを継続的に評価します。',
          softSkill: false,
        },
        {
          id: 'T1005',
          value:
            '継続的な監視データを使用して、持続的な問題に対処するための情報セキュリティ投資の決定を行います。',
          softSkill: false,
        },
        {
          id: 'T1006',
          value: '継続的監視中に指摘された問題に対応し、エスカレートして対応を調整してください。',
          softSkill: false,
        },
        {
          id: 'T1007',
          value: '継続的監視プログラムからのレビュー結果を確認し、リスクをタイムリーに軽減します。',
          softSkill: false,
        },
      ],
    },
    {
      name: 'knowledge',
      items: [
        {
          id: 'K0001',
          value:
            'コンピュータネットワーキングの概念とプロトコル、およびネットワークセキュリティ方法論に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0002',
          value: 'リスク管理プロセスの知識（例えば、リスク評価および軽減方法）。',
          softSkill: true,
        },
        {
          id: 'K0003',
          value:
            'サイバーセキュリティとプライバシーに関連する法律、規制、ポリシー、および倫理に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0004',
          value: 'サイバーセキュリティとプライバシーの原則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0005',
          value: 'サイバー脅威と脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0006',
          value: 'サイバーセキュリティの違反が具体的な運用へ与える影響の知識。',
          softSkill: false,
        },
        {
          id: 'K0007',
          value: '認証、認可、およびアクセス制御方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0008',
          value: '顧客組織の適用可能なビジネスプロセスと運営に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0009',
          value: 'アプリケーションの脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0010',
          value: 'ネットワークインフラを支える通信方法、原則、および概念の知識。',
          softSkill: false,
        },
        {
          id: 'K0011',
          value:
            'ネットワーク機器の能力と応用に関する知識、ルーター、スイッチ、ブリッジ、サーバー、伝送媒体、および関連ハードウェアを含む。',
          softSkill: false,
        },
        {
          id: 'K0012',
          value: '能力と要件分析の知識。',
          softSkill: false,
        },
        {
          id: 'K0013',
          value: 'サイバーディフェンスと脆弱性評価ツール及びその機能に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0014',
          value: '複雑なデータ構造に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0015',
          value: 'コンピュータアルゴリズムの知識。',
          softSkill: false,
        },
        {
          id: 'K0016',
          value: 'コンピュータプログラミングの原則に関する知識',
          softSkill: false,
        },
        {
          id: 'K0017',
          value: 'デジタルフォレンジックデータの処理に関する概念と実践の知識。',
          softSkill: false,
        },
        {
          id: 'K0018',
          value: '暗号化アルゴリズムの知識',
          softSkill: false,
        },
        {
          id: 'K0019',
          value: '暗号学と暗号キー管理の概念に関する知識',
          softSkill: false,
        },
        {
          id: 'K0020',
          value: 'データ管理とデータ標準化ポリシーの知識。',
          softSkill: false,
        },
        {
          id: 'K0021',
          value: 'データのバックアップと復旧の知識。',
          softSkill: true,
        },
        {
          id: 'K0022',
          value: 'データマイニングとデータウェアハウジングの原則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0023',
          value:
            'データベース管理システム、クエリ言語、テーブルの関係性、およびビューに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0024',
          value: 'データベースシステムの知識。',
          softSkill: false,
        },
        {
          id: 'K0025',
          value: 'デジタル著作権管理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0026',
          value: 'ビジネス継続性と災害復旧の運用計画の知識。',
          softSkill: true,
        },
        {
          id: 'K0027',
          value: '組織のエンタープライズ情報セキュリティアーキテクチャの知識。',
          softSkill: false,
        },
        {
          id: 'K0028',
          value: '組織の評価と検証要件に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0029',
          value: '組織のローカルエリアネットワークとワイドエリアネットワーク接続に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0030',
          value:
            'コンピュータアーキテクチャに適用される電気工学の知識（例：回路基板、プロセッサ、チップ、コンピュータハードウェア）。',
          softSkill: false,
        },
        {
          id: 'K0031',
          value: 'エンタープライズメッセージングシステムおよび関連ソフトウェアの知識。',
          softSkill: false,
        },
        {
          id: 'K0032',
          value: 'レジリエンスと冗長性の知識。',
          softSkill: true,
        },
        {
          id: 'K0033',
          value:
            'ホスト/ネットワークアクセス制御メカニズムの知識（例：アクセス制御リスト、機能リスト）。',
          softSkill: false,
        },
        {
          id: 'K0034',
          value:
            'ネットワーク通信を提供するネットワークサービスとプロトコルの相互作用に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0035',
          value: 'システムコンポーネントのインストール、統合、および最適化に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0036',
          value: '人間とコンピュータの相互作用の原則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0037',
          value: 'セキュリティ評価および認可プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0038',
          value:
            '情報またはデータの使用、処理、保管、および伝送に関連するリスクを管理するために使用されるサイバーセキュリティおよびプライバシーの原則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0039',
          value:
            'ソフトウェア開発に適用されるサイバーセキュリティとプライバシーの原則と方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0040',
          value: '脆弱性情報の普及源（例：アラート、アドバイザリー、訂正表、および報告書）の知識。',
          softSkill: false,
        },
        {
          id: 'K0041',
          value: 'インシデントのカテゴリー、インシデント対応、および対応時間に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0042',
          value: 'インシデント対応および処理方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0043',
          value: '業界標準および組織が認める分析原則と方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0044',
          value:
            'サイバーセキュリティとプライバシーの原則、および組織要件に関する知識（機密性、完全性、可用性、認証、否認防止に関連）。',
          softSkill: false,
        },
        {
          id: 'K0045',
          value: '情報セキュリティシステムエンジニアリングの原則の知識（NIST SP 800-160）。',
          softSkill: false,
        },
        {
          id: 'K0046',
          value: 'ホストおよびネットワークベースの侵入を検出するための侵入検知方法論と技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0047',
          value: '情報技術（IT）のアーキテクチャ概念およびフレームワークに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0048',
          value: 'リスク管理フレームワーク（RMF）の要件に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0049',
          value:
            '情報技術（IT）セキュリティの原則と方法（例：ファイアウォール、非武装地帯、暗号化）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0050',
          value: '地域ネットワークおよび広域ネットワークの原則と概念、帯域幅管理を含む知識。',
          softSkill: false,
        },
        {
          id: 'K0051',
          value: '低水準コンピュータ言語（例えば、アセンブリ言語）の知識。',
          softSkill: false,
        },
        {
          id: 'K0052',
          value: '数学の知識（例：対数、三角法、線形代数、微積分、統計学、作業分析）。',
          softSkill: false,
        },
        {
          id: 'K0053',
          value: 'システムのパフォーマンスと可用性の指標または指標に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0054',
          value:
            '情報技術（IT）セキュリティ評価、監視、検出、および修復ツールと手順を評価、実装、および普及するための現行業界方法に関する知識は、標準ベースの概念と能力を活用しています。',
          softSkill: false,
        },
        {
          id: 'K0055',
          value: 'マイクロプロセッサーの知識。',
          softSkill: false,
        },
        {
          id: 'K0056',
          value:
            'ネットワークアクセス、アイデンティティおよびアクセス管理に関する知識（例：公開鍵インフラストラクチャ、Oauth、OpenID、SAML、SPML）。',
          softSkill: false,
        },
        {
          id: 'K0057',
          value: 'ネットワークハードウェアデバイスと機能の知識。',
          softSkill: false,
        },
        {
          id: 'K0058',
          value: 'ネットワークトラフィック分析方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0059',
          value: '新しい情報技術（IT）およびサイバーセキュリティ技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0060',
          value: 'オペレーティングシステムの知識。',
          softSkill: false,
        },
        {
          id: 'K0061',
          value:
            'ネットワーク全体でのトラフィックの流れに関する知識（例：トランスミッション・コントロール・プロトコル[TCP]およびインターネット・プロトコル[IP]、オープン・システム・インターコネクション・モデル[OSI]、情報技術インフラストラクチャ・ライブラリ、現行バージョン[ITIL]）。',
          softSkill: false,
        },
        {
          id: 'K0062',
          value: 'パケットレベル分析の知識。',
          softSkill: false,
        },
        {
          id: 'K0063',
          value: '並列および分散コンピューティングの概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0064',
          value: 'パフォーマンスチューニングのツールとテクニックに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0065',
          value: 'ポリシーベースおよびリスク適応型アクセス制御の知識。',
          softSkill: false,
        },
        {
          id: 'K0066',
          value: 'プライバシー影響評価の知識。',
          softSkill: true,
        },
        {
          id: 'K0067',
          value: 'プロセスエンジニアリングの概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0068',
          value: 'プログラミング言語の構造と論理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0069',
          value: 'SQL（Structured Query Language）などのクエリ言語の知識。',
          softSkill: false,
        },
        {
          id: 'K0070',
          value:
            'システムおよびアプリケーションのセキュリティ脅威と脆弱性に関する知識（例：バッファオーバーフロー、モバイルコード、クロスサイトスクリプティング、手続き型言語/構造化クエリ言語 [PL/SQL] およびインジェクション、競合状態、隠れチャネル、リプレイ、リターン指向攻撃、悪意のあるコード）。',
          softSkill: false,
        },
        {
          id: 'K0071',
          value: 'リモートアクセス技術の概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0072',
          value: 'リソース管理の原則と技術に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0073',
          value:
            'セキュアな構成管理技術の知識。（例：セキュリティ技術実装ガイド（STIGs）、cisecurity.orgのサイバーセキュリティベストプラクティス。）',
          softSkill: false,
        },
        {
          id: 'K0074',
          value: 'セキュリティ管理における主要概念の知識（例：リリース管理、パッチ管理）。',
          softSkill: false,
        },
        {
          id: 'K0075',
          value: 'セキュリティシステム設計ツール、方法、技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0076',
          value: 'サーバー管理およびシステムエンジニアリングの理論、概念、および方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0077',
          value: 'サーバーおよびクライアントのオペレーティングシステムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0078',
          value: 'サーバー診断ツールおよび障害特定技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0079',
          value: 'ソフトウェアデバッグの原則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0080',
          value: 'ソフトウェア設計ツール、方法、および技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0081',
          value: 'ソフトウェア開発モデル（例：ウォーターフォールモデル、スパイラルモデル）の知識。',
          softSkill: false,
        },
        {
          id: 'K0082',
          value: 'ソフトウェアエンジニアリングの知識。',
          softSkill: false,
        },
        {
          id: 'K0083',
          value: '組織のデータ資産のソース、特性、および用途の知識。',
          softSkill: false,
        },
        {
          id: 'K0084',
          value: '構造化分析の原則と方法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0086',
          value:
            'システム設計ツール、方法、技術の知識、自動化されたシステム分析および設計ツールを含む。',
          softSkill: false,
        },
        {
          id: 'K0087',
          value:
            'システムソフトウェアおよび組織設計標準、ポリシー、およびシステム設計に関連する認可されたアプローチ（例：国際標準化機構[ISO]ガイドライン）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0088',
          value: 'システム管理の概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0089',
          value: 'システム診断ツールと故障識別技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0090',
          value:
            'システムライフサイクル管理の原則、ソフトウェアのセキュリティと使いやすさを含む知識。',
          softSkill: false,
        },
        {
          id: 'K0091',
          value: 'システムテストおよび評価方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0092',
          value: 'テクノロジー統合プロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0093',
          value:
            '通信の概念に関する知識（例：通信チャネル、システムリンク予算、スペクトル効率、多重化）。',
          softSkill: false,
        },
        {
          id: 'K0094',
          value:
            'コンテンツ作成技術（例：ウィキ、ソーシャルネットワーキング、コンテンツ管理システム、ブログ）に関連する機能と能力の知識。',
          softSkill: false,
        },
        {
          id: 'K0095',
          value:
            'さまざまな技術の機能と機能に関する知識（例：データベース、ブックマーキングエンジン）。',
          softSkill: false,
        },
        {
          id: 'K0096',
          value:
            'さまざまな共同作業技術（例：グループウェア、SharePoint）の機能と機能に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0097',
          value: '物理的および仮想的なデータ保存媒体の特性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0098',
          value:
            '自組織内のサイバーディフェンスサービスプロバイダーの報告構造とプロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0100',
          value: 'エンタープライズ情報技術（IT）アーキテクチャの知識。',
          softSkill: false,
        },
        {
          id: 'K0101',
          value: '組織のエンタープライズ情報技術（IT）の目標と目的の知識。',
          softSkill: false,
        },
        {
          id: 'K0102',
          value: 'システムエンジニアリングプロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0103',
          value: '定期的なハードウェア保守の種類と頻度に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0104',
          value: '仮想プライベートネットワーク（VPN）のセキュリティに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0105',
          value:
            'ウェブサービスの知識（例：サービス指向アーキテクチャ、シンプルオブジェクトアクセスプロトコル、およびウェブサービス記述言語）。',
          softSkill: false,
        },
        {
          id: 'K0106',
          value:
            'ネットワーク攻撃とは何か、およびネットワーク攻撃が脅威と脆弱性とどのような関係にあるかについての知識。',
          softSkill: false,
        },
        {
          id: 'K0107',
          value: 'インサイダー脅威調査、報告、調査ツール、法律・規制に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0108',
          value:
            'コンセプト、用語、および幅広い通信メディア（コンピュータおよび電話ネットワーク、衛星、ファイバー、ワイヤレス）の操作に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0109',
          value:
            '物理的なコンピュータコンポーネントとアーキテクチャの知識、さまざまなコンポーネントと周辺機器（例：CPU、ネットワークインターフェースカード、データストレージ）の機能を含む。',
          softSkill: false,
        },
        {
          id: 'K0110',
          value: '敵対的な戦術、技術、および手順の知識。',
          softSkill: false,
        },
        {
          id: 'K0111',
          value: 'ネットワークツールの知識（例：ping、traceroute、nslookup）',
          softSkill: false,
        },
        {
          id: 'K0112',
          value: '防御の深さの原則とネットワークセキュリティアーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0113',
          value:
            'さまざまなタイプのネットワーク通信（例：LAN、WAN、MAN、WLAN、WWAN）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0114',
          value:
            '電子機器（例：コンピューターシステム／コンポーネント、アクセス制御装置、デジタルカメラ、デジタルスキャナー、電子オーガナイザー、ハードドライブ、メモリーカード、モデム、ネットワークコンポーネント、ネットワーク家電、ネットワークホームコントロール装置、プリンター、リムーバブルストレージデバイス、電話、コピー機、ファクシミリ機など）。',
          softSkill: false,
        },
        {
          id: 'K0115',
          value: '技術は悪用されうる知識です。',
          softSkill: false,
        },
        {
          id: 'K0116',
          value: 'ファイル拡張子の知識（例：.dll、.bat、.zip、.pcap、.gzip）。',
          softSkill: false,
        },
        {
          id: 'K0117',
          value:
            'ファイルシステムの実装に関する知識（例：New Technology File System [NTFS]、File Allocation Table [FAT]、File Extension [EXT]）。',
          softSkill: false,
        },
        {
          id: 'K0118',
          value: 'デジタル証拠を押収し保存するためのプロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0119',
          value: 'ハッキング手法の知識。',
          softSkill: false,
        },
        {
          id: 'K0120',
          value:
            '情報ニーズと収集要件が拡張エンタープライズ全体でどのように翻訳され、追跡され、優先されるかの知識。',
          softSkill: false,
        },
        {
          id: 'K0121',
          value: '情報セキュリティプログラム管理およびプロジェクト管理の原則と技術に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0122',
          value:
            'ハードウェア、オペレーティングシステム、およびネットワーク技術の調査への影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0123',
          value: '証拠規則など、許容性に関連する法的ガバナンスの知識。',
          softSkill: true,
        },
        {
          id: 'K0124',
          value: '複数の認知ドメインと、各ドメインでの学習に適用可能なツールと方法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0125',
          value:
            '電子証拠の収集、梱包、輸送、保管プロセスの知識および証拠の保全の連続性を維持する。',
          softSkill: true,
        },
        {
          id: 'K0126',
          value: 'サプライチェーンリスクマネジメントプラクティスの知識（NIST SP 800-161）',
          softSkill: true,
        },
        {
          id: 'K0127',
          value: '関連する情報構造（例：国家情報インフラ）の性質と機能に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0128',
          value: 'タイプと永続データの収集に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0129',
          value: 'コマンドラインツールの知識（例：mkdir、mv、ls、passwd、grep）。',
          softSkill: false,
        },
        {
          id: 'K0130',
          value: '仮想化技術および仮想マシンの開発と保守に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0131',
          value: 'ウェブメール収集、検索/分析技術、ツール、およびクッキーに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0132',
          value:
            'どのシステムファイル（例：ログファイル、レジストリファイル、設定ファイル）が関連情報を含んでいるか、そしてそれらのシステムファイルをどこで見つけるかの知識。',
          softSkill: false,
        },
        {
          id: 'K0133',
          value: 'デジタルフォレンジックスデータの種類とその識別方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0134',
          value: '展開可能な法医学の知識。',
          softSkill: false,
        },
        {
          id: 'K0135',
          value: 'ウェブフィルタリング技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0136',
          value:
            'さまざまな電子通信システムと方法（例：電子メール、VOIP、IM、ウェブフォーラム、ダイレクトビデオブロードキャスト）の能力に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0137',
          value: '既存のネットワークの範囲に関する知識（例：PBX、LAN、WAN、WIFI、SCADA）。',
          softSkill: false,
        },
        {
          id: 'K0138',
          value: 'Wi-Fiに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0139',
          value: '解釈されたコンピュータ言語とコンパイルされたコンピュータ言語の知識。',
          softSkill: false,
        },
        {
          id: 'K0140',
          value: '安全なコーディング技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0141',
          value: '撤回 - K0420に統合済み',
          softSkill: true,
        },
        {
          id: 'K0142',
          value: 'コレクション管理プロセス、能力、および制限の知識。',
          softSkill: false,
        },
        {
          id: 'K0143',
          value: 'フロントエンド収集システムの知識、トラフィック収集、フィルタリング、選択を含む。',
          softSkill: false,
        },
        {
          id: 'K0144',
          value: 'グローバルな文脈におけるコンピュータ攻撃者の社会動態に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0145',
          value: 'セキュリティイベント相関ツールの知識。',
          softSkill: false,
        },
        {
          id: 'K0146',
          value: '組織のコアビジネス/ミッションプロセスの知識。',
          softSkill: true,
        },
        {
          id: 'K0147',
          value: '新たに出現するセキュリティ問題、リスク、および脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0148',
          value:
            '輸入・輸出管理規制および供給チェーンリスクを軽減するための責任ある機関に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0149',
          value: '組織のリスク許容度および/またはリスク管理アプローチに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0150',
          value: 'エンタープライズインシデント対応プログラム、役割、および責任に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0151',
          value: '現在および新興の脅威/脅威ベクトルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0152',
          value:
            'ソフトウェア関連情報技術（IT）セキュリティの原則と方法に関する知識（例：モジュール化、レイヤリング、抽象化、データ隠蔽、単純化/最小化）。',
          softSkill: false,
        },
        {
          id: 'K0153',
          value: 'ソフトウェア品質保証プロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0154',
          value: 'サプライチェーンリスク管理の基準、プロセス、および実践に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0155',
          value: '電子証拠法の知識。',
          softSkill: true,
        },
        {
          id: 'K0156',
          value: '証拠法および法廷手続きの知識。',
          softSkill: true,
        },
        {
          id: 'K0157',
          value: 'サイバーディフェンスと情報セキュリティポリシー、手順、および規制に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0158',
          value:
            '組織の情報技術（IT）ユーザーセキュリティポリシーの知識（例：アカウント作成、パスワードルール、アクセス制御）。',
          softSkill: false,
        },
        {
          id: 'K0159',
          value: 'Voice over IP（VoIP）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0160',
          value: 'ネットワーク層における一般的な攻撃ベクトルの知識。',
          softSkill: false,
        },
        {
          id: 'K0161',
          value:
            'さまざまな種類の攻撃（例：受動的攻撃、能動的攻撃、内部者攻撃、接近攻撃、配布攻撃）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0162',
          value:
            'サイバー攻撃者の知識（例：スクリプトキディ、インサイダー脅威、国家非支援、国家支援）。',
          softSkill: false,
        },
        {
          id: 'K0163',
          value: '重要な情報技術（IT）調達要件の知識。',
          softSkill: true,
        },
        {
          id: 'K0164',
          value:
            '機能、品質、およびセキュリティ要件に関する知識、およびこれらが特定の供給品目（すなわち、要素およびプロセス）にどのように適用されるか。',
          softSkill: true,
        },
        {
          id: 'K0165',
          value: 'リスク/脅威評価の知識。',
          softSkill: true,
        },
        {
          id: 'K0167',
          value:
            'システム管理、ネットワーク、およびオペレーティングシステムの強化技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0168',
          value:
            '適用される法律、法令（例：米国法典のタイトル10、18、32、50）、大統領指令、行政部門のガイドライン、および/または行政/刑事法的ガイドラインと手続きの知識。',
          softSkill: true,
        },
        {
          id: 'K0169',
          value:
            '情報技術（IT）サプライチェーンセキュリティおよびサプライチェーンリスク管理ポリシー、要件、および手順に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0170',
          value:
            'システムセキュリティの考慮なしに設計された情報通信技術を持つ重要インフラシステムの知識。',
          softSkill: false,
        },
        {
          id: 'K0171',
          value: 'ハードウェアリバースエンジニアリング技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0172',
          value: 'ミドルウェアの知識（例：エンタープライズサービスバスやメッセージキューイング）。',
          softSkill: false,
        },
        {
          id: 'K0174',
          value: 'ネットワークプロトコルの知識。',
          softSkill: false,
        },
        {
          id: 'K0175',
          value: 'ソフトウェアリバースエンジニアリング技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0176',
          value: '拡張可能マークアップ言語（XML）スキーマの知識。',
          softSkill: false,
        },
        {
          id: 'K0177',
          value:
            'サイバー攻撃の段階の知識（例：偵察、スキャン、列挙、アクセス取得、権限の昇格、アクセスの維持、ネットワークの悪用、足跡の隠蔽）。',
          softSkill: false,
        },
        {
          id: 'K0178',
          value: '安全なソフトウェア展開方法、ツール、および実践に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0179',
          value:
            'ネットワークセキュリティアーキテクチャの概念（トポロジー、プロトコル、コンポーネント、原則など、防御の深層化の適用）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0180',
          value:
            'ネットワークシステム管理の原則、モデル、方法（例：エンドツーエンドのシステムパフォーマンスモニタリング）、およびツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0182',
          value: 'データカービングツールおよび技術の知識（例：Foremost）。',
          softSkill: false,
        },
        {
          id: 'K0183',
          value: 'リバースエンジニアリングの概念についての知識。',
          softSkill: false,
        },
        {
          id: 'K0184',
          value: 'アンチフォレンジックの戦術、技術、および手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0185',
          value: '法医学ラボの設計構成とサポートアプリケーション（例：VMWare、Wireshark）の知識。',
          softSkill: false,
        },
        {
          id: 'K0186',
          value: 'デバッグ手順とツールの知識。',
          softSkill: false,
        },
        {
          id: 'K0187',
          value: '敵対者によるファイルタイプの悪用と異常行動の知識。',
          softSkill: false,
        },
        {
          id: 'K0188',
          value: 'マルウェア解析ツールの知識（例：Oily Debug、Ida Pro）。',
          softSkill: false,
        },
        {
          id: 'K0189',
          value:
            'マルウェアに関する知識で、仮想マシン検出（例：仮想認識マルウェア、デバッガ認識マルウェア、およびコンピュータのディスプレイデバイスでVM関連の文字列を検索する解凍されたマルウェア）。',
          softSkill: false,
        },
        {
          id: 'K0190',
          value: '暗号化手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0191',
          value: 'ウイルス、マルウェア、攻撃に対する署名実装の影響。',
          softSkill: false,
        },
        {
          id: 'K0192',
          value: 'Windows/Unixポートとサービスの知識。',
          softSkill: false,
        },
        {
          id: 'K0193',
          value: 'データベースにおける高度なデータ修復セキュリティ機能の知識。',
          softSkill: false,
        },
        {
          id: 'K0194',
          value:
            'クラウドベースのナレッジマネジメント技術と、セキュリティ、ガバナンス、調達、および管理に関連する概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0195',
          value: '感度やその他のリスク要因に基づくデータ分類基準および方法論の知識。',
          softSkill: false,
        },
        {
          id: 'K0196',
          value: '暗号技術およびその他のセキュリティ技術に関連する輸入/輸出規制に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0197',
          value:
            'データベースアクセスアプリケーションプログラミングインターフェースの知識（例：Java Database Connectivity [JDBC]）。',
          softSkill: false,
        },
        {
          id: 'K0198',
          value:
            '組織のプロセス改善の概念とプロセス成熟度モデル（例えば、開発のための能力成熟度モデル統合（CMMI）、サービスのためのCMMI、および調達のためのCMMI）に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0199',
          value:
            'セキュリティアーキテクチャの概念とエンタープライズアーキテクチャリファレンスモデル（例：ザックマン、連邦エンタープライズアーキテクチャ[FEA]）の知識。',
          softSkill: false,
        },
        {
          id: 'K0200',
          value:
            'ネットワークおよび関連する標準（例えば、情報技術インフラストラクチャライブラリー、現行版 [ITIL]）に対するサービス管理の概念の知識。',
          softSkill: false,
        },
        {
          id: 'K0201',
          value: '対称鍵ローテーション技術と概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0202',
          value:
            'アプリケーションファイアウォールの概念と機能の知識（例：認証/監査/ポリシー適用の単一ポイント、悪意のあるコンテンツのメッセージスキャン、PCIおよびPIIコンプライアンスのためのデータ匿名化、データ損失保護スキャン、暗号化操作の加速、SSLセキュリティ、REST/JSON処理）。',
          softSkill: false,
        },
        {
          id: 'K0203',
          value:
            'セキュリティモデルの知識（例：ベル・ラパドゥーラモデル、ビバ整合性モデル、クラークウィルソン整合性モデル）。',
          softSkill: false,
        },
        {
          id: 'K0204',
          value: '学習評価技術の知識（ルーブリック、評価計画、テスト、クイズ）。',
          softSkill: true,
        },
        {
          id: 'K0205',
          value: '基本的なシステム、ネットワーク、OS強化技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0206',
          value: '倫理的ハッキングの原則と技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0207',
          value: '回路解析の知識。',
          softSkill: false,
        },
        {
          id: 'K0208',
          value: 'コンピューターベースのトレーニングとeラーニングサービスの知識。',
          softSkill: true,
        },
        {
          id: 'K0209',
          value: '秘密のコミュニケーション技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0210',
          value: 'データバックアップおよび復元の概念に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0211',
          value: '機密性、完全性、および可用性要件の知識。',
          softSkill: false,
        },
        {
          id: 'K0212',
          value: 'サイバーセキュリティ対応ソフトウェア製品の知識。',
          softSkill: false,
        },
        {
          id: 'K0213',
          value:
            'インストラクショナルデザインおよび評価モデルの知識（例：ADDIE、スミス/ラガンモデル、ガニエの指導イベント、カークパトリックの評価モデル）。',
          softSkill: true,
        },
        {
          id: 'K0214',
          value: 'リスクマネジメントフレームワーク評価方法論の知識。',
          softSkill: true,
        },
        {
          id: 'K0215',
          value: '組織のトレーニングポリシーに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0216',
          value: '学習レベルの知識（例：ブルームの学習の分類）。',
          softSkill: true,
        },
        {
          id: 'K0217',
          value: 'ラーニングマネジメントシステムの知識と学習管理での使用方法。',
          softSkill: true,
        },
        {
          id: 'K0218',
          value: '学習スタイルの知識（例：吸収型、聴覚型、運動感覚型）。',
          softSkill: true,
        },
        {
          id: 'K0220',
          value: '学習方法に関する知識（例：暗記、観察）。',
          softSkill: true,
        },
        {
          id: 'K0221',
          value: 'OSIモデルと基礎となるネットワークプロトコル（例：TCP/IP）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0222',
          value: 'サイバー防衛活動に関連する法律、法的権限、制限、および規制に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0223',
          value: '撤回 - K0073に統合されました',
          softSkill: true,
        },
        {
          id: 'K0224',
          value:
            'Unix/Linux、IOS、Android、およびWindowsオペレーティングシステムなどのオペレーティングシステムに関するシステム管理の概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0226',
          value: '組織のトレーニングシステムに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0227',
          value: 'さまざまな種類のコンピュータアーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0228',
          value: '分類学と意味論的オントロジー理論の知識。',
          softSkill: false,
        },
        {
          id: 'K0229',
          value:
            'アプリケーションがエラー、例外、およびアプリケーションの障害を記録し、ログを取ることができるアプリケーションの知識。',
          softSkill: false,
        },
        {
          id: 'K0230',
          value:
            'クラウドサービスモデルと、それらのモデルがインシデント対応を制限する方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0231',
          value: '危機管理プロトコル、プロセス、および技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0233',
          value:
            '国家サイバーセキュリティ労働力フレームワーク、職務役割、および関連するタスク、知識、スキル、能力の知識。',
          softSkill: true,
        },
        {
          id: 'K0234',
          value: 'フルスペクトラムサイバー能力（例：防御、攻撃、搾取）の知識。',
          softSkill: false,
        },
        {
          id: 'K0235',
          value: '研究開発センター、シンクタンク、学術研究、産業システムを活用する方法の知識。',
          softSkill: true,
        },
        {
          id: 'K0236',
          value: 'Hadoop、Java、Python、SQL、Hive、Pigを使用してデータを探索する方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0237',
          value: 'サービスデスクの業界ベストプラクティスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0238',
          value: '機械学習の理論と原則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0239',
          value:
            'メディア制作、コミュニケーション、および情報の流布技術と方法に関する知識、書かれたもの、口頭、視覚メディアを通じて情報を伝える代替手段を含む。',
          softSkill: true,
        },
        {
          id: 'K0240',
          value: '多層セキュリティシステムとクロスドメインソリューションの知識。',
          softSkill: false,
        },
        {
          id: 'K0241',
          value: '組織の人事方針、プロセス、および手順に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0242',
          value: '組織のセキュリティポリシーに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0243',
          value: '組織のトレーニングおよび教育ポリシー、プロセス、および手順に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0244',
          value: '物理的および生理的な行動が示すかもしれない疑わしいまたは異常な活動に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0245',
          value: 'トレーニングと教育ニーズ評価を行うための原則とプロセスの知識。',
          softSkill: true,
        },
        {
          id: 'K0246',
          value: '関連する概念、手順、ソフトウェア、機器、および技術アプリケーションの知識。',
          softSkill: false,
        },
        {
          id: 'K0247',
          value: 'カスタマーサポートに関連するリモートアクセスプロセス、ツール、および機能の知識。',
          softSkill: false,
        },
        {
          id: 'K0248',
          value: '戦略理論と実践の知識。',
          softSkill: true,
        },
        {
          id: 'K0249',
          value: '持続可能な技術、プロセス、および戦略に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0250',
          value: '学習者のためのテスト＆評価プロセスの知識。',
          softSkill: true,
        },
        {
          id: 'K0251',
          value: '司法プロセスの知識、事実と証拠の提示を含む。',
          softSkill: true,
        },
        {
          id: 'K0252',
          value:
            '教育と訓練の原則や方法に関する知識、個人やグループのためのカリキュラム設計、教育と指導、教育と訓練の効果の測定。',
          softSkill: true,
        },
        {
          id: 'K0253',
          value: '撤回 - K0227に統合済み',
          softSkill: true,
        },
        {
          id: 'K0254',
          value: 'バイナリ分析の知識。',
          softSkill: false,
        },
        {
          id: 'K0255',
          value:
            'ネットワークアーキテクチャの概念、トポロジー、プロトコル、およびコンポーネントに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0257',
          value: '情報技術（IT）の取得/調達要件に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0258',
          value: 'テスト手順、原則、および方法論（例：能力成熟度モデル統合（CMMI））の知識。',
          softSkill: true,
        },
        {
          id: 'K0259',
          value: 'マルウェア分析の概念と方法論に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0260',
          value: '個人を特定できる情報（PII）データセキュリティ基準に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0261',
          value: '支払いカード業界（PCI）データセキュリティ基準の知識。',
          softSkill: true,
        },
        {
          id: 'K0262',
          value: '個人の健康情報（PHI）データセキュリティ基準に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0263',
          value: '情報技術（IT）リスク管理のポリシー、要件、および手順に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0264',
          value:
            'プログラム保護計画の知識（例：情報技術（IT）サプライチェーンのセキュリティ/リスク管理ポリシー、アンチタンパリング技術、要件）。',
          softSkill: true,
        },
        {
          id: 'K0265',
          value: '情報技術（IT）の安全性、性能、信頼性を支えるインフラの知識。',
          softSkill: false,
        },
        {
          id: 'K0266',
          value: 'サプライヤーや製品の信頼性を評価する方法の知識。',
          softSkill: true,
        },
        {
          id: 'K0267',
          value:
            '重要インフラのサイバーセキュリティに関連する法律、政策、手順、またはガバナンスの知識。',
          softSkill: true,
        },
        {
          id: 'K0268',
          value: '法医学的足跡識別の知識。',
          softSkill: false,
        },
        {
          id: 'K0269',
          value: 'モバイル通信アーキテクチャの知識。',
          softSkill: false,
        },
        {
          id: 'K0270',
          value: '取得/調達ライフサイクルプロセスの知識。',
          softSkill: true,
        },
        {
          id: 'K0271',
          value:
            'オペレーティングシステムの構造と内部構造（例：プロセス管理、ディレクトリ構造、インストールされたアプリケーション）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0272',
          value:
            'ネットワーク分析ツールの知識は、ソフトウェア通信の脆弱性を特定するために使用されます。',
          softSkill: false,
        },
        {
          id: 'K0274',
          value:
            '伝送記録（例：Bluetooth、無線周波数識別（RFID）、赤外線ネットワーキング（IR）、ワイヤレスフィデリティ（Wi-Fi）、ページング、セルラー、衛星アンテナ、インターネットプロトコル上の音声（VoIP））の知識、及び望ましくない情報の伝送を可能にする、またはインストールされたシステムの正常な動作を阻害する妨害技術。',
          softSkill: false,
        },
        {
          id: 'K0275',
          value: '構成管理技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0276',
          value: 'セキュリティ管理の知識。',
          softSkill: false,
        },
        {
          id: 'K0277',
          value:
            'データベースにおける現在および新興のデータ暗号化（例：カラムおよびテーブルスペース暗号化、ファイルおよびディスク暗号化）のセキュリティ機能に関する知識（例：組み込みの暗号キー管理機能）。',
          softSkill: false,
        },
        {
          id: 'K0278',
          value: 'データベースにおける現在および新興のデータ修復セキュリティ機能に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0280',
          value: 'システムエンジニアリングの理論、概念、および方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0281',
          value: '情報技術（IT）サービスカタログの知識。',
          softSkill: false,
        },
        {
          id: 'K0282',
          value: '撤回 - K0200に統合されました',
          softSkill: true,
        },
        {
          id: 'K0283',
          value:
            'プラットフォーム間（例：モバイル、PC、クラウド）でのコラボレーションとコンテンツ同期に関連するユースケースの知識。',
          softSkill: false,
        },
        {
          id: 'K0284',
          value: 'ユーザー認証管理システムの開発と適用に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0285',
          value:
            'データ保管時の暗号化をサポートするためのエンタープライズキーエスクローシステムの実装に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0286',
          value:
            'N層型トポロジーの知識（例：サーバーおよびクライアントのオペレーティングシステムを含む）。',
          softSkill: false,
        },
        {
          id: 'K0287',
          value: '組織の情報分類プログラムと情報漏洩の手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0288',
          value: '業界標準のセキュリティモデルに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0289',
          value: 'システム/サーバー診断ツールおよび障害特定技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0290',
          value: 'システムセキュリティテストおよび評価方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0291',
          value:
            'エンタープライズ情報技術（IT）のアーキテクチャ概念およびパターン（例：ベースライン、検証済みデザイン、ターゲットアーキテクチャ）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0292',
          value: 'インシデント、問題、およびイベント管理の運用とプロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0293',
          value: '組織の目標と目的をアーキテクチャに統合する知識。',
          softSkill: false,
        },
        {
          id: 'K0294',
          value:
            'ITシステムの運用、保守、およびセキュリティに関する知識が必要で、機器を適切に機能させるために必要です。',
          softSkill: false,
        },
        {
          id: 'K0295',
          value: '機密性、完全性、及び可用性の原則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0296',
          value:
            'ネットワーク機器の能力、用途、および潜在的な脆弱性に関する知識。これには、ハブ、ルーター、スイッチ、ブリッジ、サーバー、伝送媒体、および関連ハードウェアが含まれます。',
          softSkill: false,
        },
        {
          id: 'K0297',
          value: '特定のセキュリティリスクに対する対策設計の知識。',
          softSkill: false,
        },
        {
          id: 'K0298',
          value: '特定されたセキュリティリスクに対する対策の知識。',
          softSkill: true,
        },
        {
          id: 'K0299',
          value:
            'セキュリティシステムの機能方法（耐久性や信頼性の能力を含む）を理解する知識、および条件、運用、または環境の変化がこれらの結果にどのように影響するかについての知識。',
          softSkill: false,
        },
        {
          id: 'K0300',
          value: 'ネットワークマッピングとネットワークトポロジの再作成に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0301',
          value: '適切なツール（例：Wireshark、tcpdump）を使用したパケットレベル分析の知識。',
          softSkill: false,
        },
        {
          id: 'K0302',
          value: 'コンピュータの基本操作の知識。',
          softSkill: false,
        },
        {
          id: 'K0303',
          value: 'サブネッティングツールの使用に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0304',
          value: 'デジタルフォレンジックデータの処理に関する概念と実践の知識。',
          softSkill: false,
        },
        {
          id: 'K0305',
          value: 'データ隠蔽の知識（例：暗号化アルゴリズムとステノグラフィー）。',
          softSkill: false,
        },
        {
          id: 'K0308',
          value: '暗号学の知識。',
          softSkill: false,
        },
        {
          id: 'K0309',
          value: '潜在的な搾取の可能性を持つ新興技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0310',
          value: 'ハッキング方法論の知識。',
          softSkill: false,
        },
        {
          id: 'K0311',
          value: '業界の指標に関する知識は、技術トレンドを特定するのに役立ちます。',
          softSkill: false,
        },
        {
          id: 'K0312',
          value: '情報収集の原則、ポリシー、手順に関する知識、法的権限と制限を含む。',
          softSkill: false,
        },
        {
          id: 'K0313',
          value:
            '外部組織およびサイバーフォーカスを持つ学術機関の知識（例：サイバーカリキュラム/トレーニングおよび研究開発）。',
          softSkill: true,
        },
        {
          id: 'K0314',
          value: '業界の技術の潜在的なサイバーセキュリティの脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0315',
          value: '情報を収集し、情報を生成、報告、共有する主要な方法、手順、技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0316',
          value:
            'ビジネスや軍事作戦計画、概念作戦計画、命令、政策、および恒常的な交戦規則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0317',
          value:
            '報告されたインシデント、問題、およびイベントの文書化および照会に使用される手順の知識。',
          softSkill: false,
        },
        {
          id: 'K0318',
          value: 'オペレーティングシステムのコマンドラインツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0319',
          value: '技術的な配信能力とその限界に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0320',
          value: '組織の評価と検証基準に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0321',
          value:
            'エンジニアリングの概念の知識がコンピュータアーキテクチャおよび関連するコンピュータハードウェア/ソフトウェアに適用されます。',
          softSkill: false,
        },
        {
          id: 'K0322',
          value: '組み込みシステムの知識。',
          softSkill: false,
        },
        {
          id: 'K0323',
          value: 'システムのフォールトトレランス手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0324',
          value:
            '侵入検知システム（IDS）/侵入防止システム（IPS）ツールおよびアプリケーションの知識。',
          softSkill: false,
        },
        {
          id: 'K0325',
          value:
            '情報理論の知識（例：ソースコーディング、チャネルコーディング、アルゴリズムの複雑性理論、データ圧縮）。',
          softSkill: false,
        },
        {
          id: 'K0326',
          value: '非武装地帯に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0330',
          value:
            'より一般的でなく、より複雑なシステムの問題に対する解決策を特定するための成功した能力の知識。',
          softSkill: false,
        },
        {
          id: 'K0332',
          value:
            'ネットワークプロトコルの知識、例えばTCP/IP、ダイナミックホスト設定、ドメインネームシステム（DNS）、およびディレクトリサービス。',
          softSkill: false,
        },
        {
          id: 'K0333',
          value:
            'ネットワーク設計プロセスの知識、セキュリティ目標、運用目標、およびトレードオフの理解を含む。',
          softSkill: false,
        },
        {
          id: 'K0334',
          value: 'ネットワークトラフィック分析の知識（ツール、方法論、プロセス）。',
          softSkill: false,
        },
        {
          id: 'K0335',
          value: '現在および新興のサイバーテクノロジーに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0336',
          value: 'アクセス認証方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0337',
          value: '撤回 - K0007に統合されました',
          softSkill: true,
        },
        {
          id: 'K0338',
          value: 'データマイニング技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0339',
          value: 'ネットワーク分析ツールを使用して脆弱性を特定する方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0341',
          value:
            'サイバーセキュリティに関連する外国開示ポリシーおよび輸入/輸出管理規制に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0342',
          value: '侵入テストの原則、ツール、および技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0343',
          value: 'ルート原因分析技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0344',
          value: '組織の脅威環境に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0346',
          value: 'システムコンポーネントを統合するための原則と方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0347',
          value: '運用設計の知識と理解。',
          softSkill: false,
        },
        {
          id: 'K0349',
          value:
            'ウェブサイトの種類、管理、機能、およびコンテンツ管理システム（CMS）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0350',
          value: '受け入れられた組織計画システムの知識。',
          softSkill: true,
        },
        {
          id: 'K0351',
          value:
            'サイバーターゲティングおよび搾取を管理する適用される法令、法律、規制、およびポリシーの知識。',
          softSkill: true,
        },
        {
          id: 'K0352',
          value: '知識の形態、インテリジェンスサポートのニーズ、トピック、焦点領域。',
          softSkill: false,
        },
        {
          id: 'K0353',
          value: '可能な状況に関する知識は、収集管理権限の変更につながる可能性があります。',
          softSkill: true,
        },
        {
          id: 'K0354',
          value: '関連する報告および普及手順の知識。',
          softSkill: false,
        },
        {
          id: 'K0355',
          value: 'すべてのソースの報告と配布手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0356',
          value: '言語、音声、および/またはグラフィック素材のための分析ツールおよび技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0357',
          value: '分析構造とそれらが運用環境を評価するために使用される方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0358',
          value: '分析基準とインテリジェンス信頼度の目的に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0359',
          value: '承認されたインテリジェンス配布プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0361',
          value: '資産の利用可能性、能力、および制限に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0362',
          value: '攻撃方法と技術の知識（DDoS、ブルートフォース、スプーフィングなど）。',
          softSkill: false,
        },
        {
          id: 'K0363',
          value: '監査およびログ記録手順の知識（サーバーベースのログ記録を含む）。',
          softSkill: false,
        },
        {
          id: 'K0364',
          value: '利用可能なデータベースと適切な収集タスクの評価に必要なツールの知識。',
          softSkill: false,
        },
        {
          id: 'K0367',
          value: 'ペネトレーションテストの知識。',
          softSkill: true,
        },
        {
          id: 'K0368',
          value: 'インプラントに関する知識は、サイバー収集および/または準備活動を可能にします。',
          softSkill: false,
        },
        {
          id: 'K0371',
          value:
            '収集開発プロセスの原則の知識（例：ダイヤル番号認識、ソーシャルネットワーク分析）。',
          softSkill: false,
        },
        {
          id: 'K0372',
          value:
            'プログラミングの概念の知識（例：レベル、構造、コンパイル言語とインタプリタ言語）。',
          softSkill: false,
        },
        {
          id: 'K0373',
          value:
            '基本的なソフトウェアアプリケーション（例：データストレージとバックアップ、データベースアプリケーション）に関する知識と、それらのアプリケーションで見つかった脆弱性の種類。',
          softSkill: false,
        },
        {
          id: 'K0375',
          value: 'ワイヤレスアプリケーションの脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0376',
          value:
            '内部および外部の顧客およびパートナー組織に関する知識、情報ニーズ、目的、構造、能力などを含む。',
          softSkill: true,
        },
        {
          id: 'K0377',
          value: '分類および管理マーキングの基準、方針、手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0379',
          value: 'クライアント組織の知識、情報ニーズ、目的、構造、能力などを含む。',
          softSkill: true,
        },
        {
          id: 'K0380',
          value: '協力的なツールと環境に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0381',
          value: '副次的損害と影響の見積もりに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0382',
          value: '収集能力と制限の知識。',
          softSkill: false,
        },
        {
          id: 'K0383',
          value:
            '収集計画を満たすために使用される収集能力、アクセス、性能仕様、および制約に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0384',
          value: 'コレクション管理機能に関する知識（例：ポジション、機能、責任、製品、報告要件）。',
          softSkill: false,
        },
        {
          id: 'K0385',
          value: '撤回 - K0142に統合されました',
          softSkill: true,
        },
        {
          id: 'K0386',
          value: 'コレクション管理ツールの知識。',
          softSkill: false,
        },
        {
          id: 'K0387',
          value: 'コレクションプランニングプロセスとコレクションプランの知識。',
          softSkill: false,
        },
        {
          id: 'K0388',
          value:
            'コレクションの検索/分析技術およびツール、チャット/バディリスト、新興技術、VOIP、メディアオーバーIP、VPN、VSAT/ワイヤレス、ウェブメール、クッキーに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0389',
          value: '収集源に関する知識、従来のソースと非従来のソースを含む。',
          softSkill: false,
        },
        {
          id: 'K0390',
          value: 'コレクション戦略の知識。',
          softSkill: false,
        },
        {
          id: 'K0391',
          value: '収集システム、能力、およびプロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0392',
          value:
            '一般的なコンピューター/ネットワーク感染（ウイルス、トロイの木馬など）と感染方法（ポート、添付ファイルなど）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0393',
          value: '一般的なネットワーキングデバイスとその設定に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0394',
          value: '一般的なレポーティングデータベースとツールの知識。',
          softSkill: false,
        },
        {
          id: 'K0395',
          value:
            'コンピュータネットワーキングの基本（つまり、ネットワークの基本的なコンピュータコンポーネント、ネットワークの種類など）。',
          softSkill: false,
        },
        {
          id: 'K0396',
          value:
            'コンピュータプログラミングの概念、コンピュータ言語、プログラミング、テスト、デバッグ、ファイルタイプに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0397',
          value:
            'オペレーティングシステム（例：Linux、Unix）におけるセキュリティコンセプトの知識。',
          softSkill: false,
        },
        {
          id: 'K0398',
          value:
            'ウェブサイトに関連する概念（例：ウェブサーバー／ページ、ホスティング、DNS、登録、HTMLなどのウェブ言語）の知識。',
          softSkill: false,
        },
        {
          id: 'K0399',
          value: '危機対応計画と時間に敏感な計画手順の知識。',
          softSkill: false,
        },
        {
          id: 'K0400',
          value: 'サイバー作戦のための危機対応計画の知識。',
          softSkill: false,
        },
        {
          id: 'K0401',
          value: '評価基準を知るコレクション製品。',
          softSkill: false,
        },
        {
          id: 'K0402',
          value:
            '対象選定とサイバー領域への適用性における重要性と脆弱性要因（例：「価値」、回復、緩和、対策）の知識。',
          softSkill: false,
        },
        {
          id: 'K0403',
          value: '暗号技術の能力、限界、およびサイバー作戦への貢献に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0404',
          value: '現在の収集要件に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0405',
          value: '現在のコンピュータベースの侵入セットの知識。',
          softSkill: false,
        },
        {
          id: 'K0406',
          value:
            '現行のソフトウェアとアクティブディフェンスおよびシステム強化のための方法論に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0407',
          value: '顧客情報ニーズの知識。',
          softSkill: false,
        },
        {
          id: 'K0408',
          value:
            'サイバーアクション（すなわちサイバー防衛、情報収集、環境準備、サイバー攻撃）の原則、能力、限界、及び影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0409',
          value: 'サイバーインテリジェンス／情報収集能力とリポジトリに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0410',
          value: 'サイバー法の知識とサイバー計画への影響。',
          softSkill: true,
        },
        {
          id: 'K0411',
          value: 'サイバー法と法的考慮事項の知識、及びそれらがサイバープランニングに与える影響。',
          softSkill: true,
        },
        {
          id: 'K0412',
          value: 'サイバー用語/用語の知識',
          softSkill: false,
        },
        {
          id: 'K0413',
          value: 'サイバー作戦の目的、方針、および法的要件に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0414',
          value: 'サイバー作戦の支援または有効化プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0415',
          value: 'サイバー作戦の用語/語彙に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0416',
          value: 'サイバー作戦の知識。',
          softSkill: false,
        },
        {
          id: 'K0417',
          value:
            'データ通信用語の知識（例：ネットワーキングプロトコル、イーサネット、IP、暗号化、光デバイス、リムーバブルメディア）。',
          softSkill: false,
        },
        {
          id: 'K0418',
          value: '端末または環境収集のためのデータフロープロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0419',
          value: 'データベース管理と保守の知識。',
          softSkill: false,
        },
        {
          id: 'K0420',
          value: 'データベース理論の知識。',
          softSkill: false,
        },
        {
          id: 'K0421',
          value: 'データベース、ポータル、および関連する普及手段に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0422',
          value: '対立回避プロセスと手順に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0423',
          value: '対外組織との交流を含むデコンフリクション報告に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0424',
          value: '否認と欺瞞の技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0425',
          value: 'すべてのレベルでの異なる組織の目標の知識、包括的に部下、横の関係、および上位。',
          softSkill: true,
        },
        {
          id: 'K0426',
          value: 'ダイナミックおよび意図的なターゲティングの知識。',
          softSkill: false,
        },
        {
          id: 'K0427',
          value: '暗号化アルゴリズムおよびサイバー能力/ツール（例：SSL、PGP）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0428',
          value: '無線LAN（WLAN）の暗号化アルゴリズムとツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0429',
          value: '企業全体の情報管理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0430',
          value: '回避戦略と技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0431',
          value: '進化/新興のコミュニケーション技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0432',
          value:
            'サイバー作戦の戦略、政策、および組織に関連する既存の問題、新たに出現する問題、および長期的な問題の知識。',
          softSkill: true,
        },
        {
          id: 'K0433',
          value: 'オペレーティングシステムの構造と操作の法医学的意義に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0435',
          value: '基本的なサイバーコンセプト、原則、制限、および影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0436',
          value:
            'サイバー作戦の基本的な概念、用語/語彙（例：環境準備、サイバー攻撃、サイバー防御）、原則、能力、限界、及び影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0437',
          value: '一般的な監視制御とデータ取得（SCADA）システムのコンポーネントに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0438',
          value: 'モバイルセルラー通信アーキテクチャの知識（例：LTE、CDMA、GSM/EDGE、UMTS/HSPA）。',
          softSkill: false,
        },
        {
          id: 'K0439',
          value: '対象とするための統治機関の知識。',
          softSkill: true,
        },
        {
          id: 'K0440',
          value:
            'ホストベースのセキュリティ製品に関する知識と、それらの製品がどのように悪用を影響し、脆弱性を減少させるか。',
          softSkill: false,
        },
        {
          id: 'K0442',
          value: '融合技術がサイバー作戦に与える影響の知識（例：デジタル、電話、ワイヤレス）。',
          softSkill: false,
        },
        {
          id: 'K0443',
          value:
            'ネットワークの設計において、ハブ、スイッチ、ルーターがどのように連携して機能するかの知識。',
          softSkill: false,
        },
        {
          id: 'K0444',
          value:
            'インターネットアプリケーションの仕組みに関する知識（SMTP電子メール、Webベースの電子メール、チャットクライアント、VOIP）。',
          softSkill: false,
        },
        {
          id: 'K0445',
          value: '現代のデジタルおよび電話ネットワークがサイバー作戦に与える影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0446',
          value: '現代の無線通信システムがサイバー作戦に与える影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0447',
          value:
            'ターゲットの興味のある情報をメタデータ（例：メール、HTTP）から収集、閲覧、特定する方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0448',
          value: 'リソースの優先順位を設定する方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0449',
          value: 'メタデータの抽出、分析、および使用方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0450',
          value: '撤回 – K0036に統合済み',
          softSkill: true,
        },
        {
          id: 'K0451',
          value: '識別と報告プロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0452',
          value:
            'UnixおよびWindowsシステムの実装に関する知識で、radius認証とログ記録、DNS、メール、Webサービス、FTPサーバー、DHCP、ファイアウォール、およびSNMPを提供します。',
          softSkill: false,
        },
        {
          id: 'K0453',
          value: '兆候と警告の知識。',
          softSkill: false,
        },
        {
          id: 'K0454',
          value: '情報ニーズの知識。',
          softSkill: false,
        },
        {
          id: 'K0455',
          value: '情報セキュリティの概念、促進技術、および方法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0456',
          value: '情報能力と限界に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0457',
          value: '知能の信頼度レベルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0458',
          value: 'インテリジェンス分野の知識。',
          softSkill: false,
        },
        {
          id: 'K0459',
          value:
            'インテリジェンスの雇用要件に関する知識（例：物流、通信支援、機動性、法的制限など）。',
          softSkill: false,
        },
        {
          id: 'K0460',
          value: '環境の情報準備や類似プロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0461',
          value: 'インテリジェンス生産プロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0462',
          value:
            'インテリジェンス報告の原則、方針、手順、および手段に関する知識、報告形式、報告基準（要件と優先順位）、普及の実践、法的権限と制限を含む。',
          softSkill: false,
        },
        {
          id: 'K0463',
          value: 'インテリジェンス要件タスキングシステムの知識。',
          softSkill: false,
        },
        {
          id: 'K0464',
          value: '計画、実行、評価へのインテリジェンスサポートの知識。',
          softSkill: false,
        },
        {
          id: 'K0465',
          value: '内部および外部パートナーのサイバーオペレーション能力とツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0466',
          value:
            '内部および外部パートナーのインテリジェンスプロセスの知識、情報要件および重要情報の開発。',
          softSkill: false,
        },
        {
          id: 'K0467',
          value:
            '内部および外部パートナー組織の能力と限界に関する知識（タスク、収集、処理、搾取、および普及の責任を持つ者）。',
          softSkill: false,
        },
        {
          id: 'K0468',
          value: '内部および外部パートナーの報告に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0469',
          value: '内部戦術の知識を活用して、脅威の能力や行動を予測し、または模倣する。',
          softSkill: false,
        },
        {
          id: 'K0470',
          value: 'インターネットとルーティングプロトコルの知識。',
          softSkill: false,
        },
        {
          id: 'K0471',
          value:
            'インターネットネットワークアドレッシング（IPアドレス、クラスレスドメイン間ルーティング、TCP/UDPポート番号）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0472',
          value: '侵入検知システムとシグネチャ開発の知識。',
          softSkill: false,
        },
        {
          id: 'K0473',
          value: '侵入セットの知識。',
          softSkill: false,
        },
        {
          id: 'K0474',
          value: '主要なサイバー脅威アクターとその資産に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0475',
          value: '運用環境と脅威の主要因子の知識。',
          softSkill: false,
        },
        {
          id: 'K0476',
          value: '言語処理ツールと技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0477',
          value: 'リーダーシップの意図と目的の知識。',
          softSkill: true,
        },
        {
          id: 'K0478',
          value: 'ターゲティングにおける法的考慮事項の知識。',
          softSkill: true,
        },
        {
          id: 'K0479',
          value: 'マルウェア分析と特性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0480',
          value: 'マルウェアの知識。',
          softSkill: false,
        },
        {
          id: 'K0481',
          value: '様々な悪用活動を検出するために使用される方法と技術の知識。',
          softSkill: false,
        },
        {
          id: 'K0482',
          value: '収集資産の態勢と可用性を確認する方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0483',
          value: 'あらゆる潜在的な情報源から情報を統合し、要約する方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0484',
          value: '中点収集の知識（プロセス、目的、組織、ターゲットなど）。',
          softSkill: false,
        },
        {
          id: 'K0485',
          value: 'ネットワーク管理の知識。',
          softSkill: false,
        },
        {
          id: 'K0486',
          value: 'ネットワーク構築とトポロジーの知識。',
          softSkill: false,
        },
        {
          id: 'K0487',
          value:
            'ネットワークセキュリティの知識（例：暗号化、ファイアウォール、認証、ハニーポット、境界保護）。',
          softSkill: false,
        },
        {
          id: 'K0488',
          value:
            'ネットワークセキュリティの実装（例：ホストベースのIDS、IPS、アクセス制御リスト）に関する知識、それらの機能及びネットワーク内での配置。',
          softSkill: false,
        },
        {
          id: 'K0489',
          value: 'ネットワークトポロジーの知識。',
          softSkill: false,
        },
        {
          id: 'K0490',
          value: '撤回済み - K0058に統合済み',
          softSkill: true,
        },
        {
          id: 'K0491',
          value:
            'ネットワークおよびインターネット通信の基礎知識（すなわち、デバイス、デバイスの設定、ハードウェア、ソフトウェア、アプリケーション、ポート/プロトコル、アドレッシング、ネットワークアーキテクチャとインフラ、ルーティング、オペレーティングシステムなど）。',
          softSkill: false,
        },
        {
          id: 'K0492',
          value: '非伝統的な収集方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0493',
          value: '難読化技術に関する知識（例：TOR/Onion/匿名化ツール、VPN/VPS、暗号化）。',
          softSkill: false,
        },
        {
          id: 'K0494',
          value:
            '目標、状況、作戦環境、および計画支援のために利用可能な内部および外部パートナーの収集能力の状態と配置に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0495',
          value: '進行中および将来の作戦の知識。',
          softSkill: false,
        },
        {
          id: 'K0496',
          value: '運用資産の制約に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0497',
          value: '運用効果評価の知識。',
          softSkill: false,
        },
        {
          id: 'K0498',
          value: '運用計画プロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0499',
          value: 'オペレーションズ・セキュリティの知識。',
          softSkill: false,
        },
        {
          id: 'K0500',
          value:
            '組織やパートナーの収集システム、能力、およびプロセス（例：収集およびプロトコルプロセッサー）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0501',
          value: '組織のサイバー作戦プログラム、戦略、およびリソースに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0502',
          value: '組織の意思決定支援ツールおよび/または方法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0503',
          value:
            '組織のリソースおよび資産の準備報告の形式に関する知識、その運用上の関連性および情報収集の影響。',
          softSkill: false,
        },
        {
          id: 'K0504',
          value:
            'サイバーにおける組織の問題、目標、および運用に関する知識、ならびにサイバー作戦を統制する規制および政策指令。',
          softSkill: true,
        },
        {
          id: 'K0505',
          value: '組織の目標とそれに関連する収集管理への要求に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0506',
          value: '組織の目標、リーダーシップの優先事項、意思決定のリスクに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0507',
          value: 'デジタルネットワークの組織またはパートナーの搾取に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0508',
          value:
            '組織のポリシーと内部および/または外部の組織との連携のための計画コンセプトに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0509',
          value: '組織とパートナーの権限、責任、および目標達成への貢献に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0510',
          value: '組織およびパートナーのポリシー、ツール、能力、および手順に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0511',
          value: '組織の階層構造とサイバー意思決定プロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0512',
          value: '組織計画の概念に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0513',
          value: '組織の優先事項、法的権限、および要件提出プロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0514',
          value: '組織構造と関連するインテリジェンス能力の知識。',
          softSkill: true,
        },
        {
          id: 'K0516',
          value:
            '物理的および論理的なネットワークデバイスおよびインフラストラクチャに関する知識、ハブ、スイッチ、ルーター、ファイアウォールなどを含む。',
          softSkill: false,
        },
        {
          id: 'K0517',
          value: 'ポスト実装レビュー（PIR）承認プロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0518',
          value: '計画活動の開始に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0519',
          value: '計画タイムラインの知識、適応型、危機対応、および時間に敏感な計画。',
          softSkill: false,
        },
        {
          id: 'K0520',
          value:
            'ターゲット開発に関連する原則と実践の知識、例えばターゲット知識、関連性、通信システム、およびインフラストラクチャ。',
          softSkill: false,
        },
        {
          id: 'K0521',
          value:
            '優先情報の知識、それがどのように導き出されるか、どこに公開されるか、どのようにアクセスするかなど。',
          softSkill: false,
        },
        {
          id: 'K0522',
          value: '生産の搾取と普及のニーズとアーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0523',
          value:
            '主要ベンダー（例：セキュリティスイート - トレンドマイクロ、シマンテック、マカフィー、アウトポスト、パンダ）の製品と名称に関する知識、およびそれらの製品がどのように悪用を影響し、脆弱性を減少させるか。',
          softSkill: false,
        },
        {
          id: 'K0524',
          value: '関連する法律、規制、およびポリシーの知識。',
          softSkill: true,
        },
        {
          id: 'K0525',
          value: 'サイバー作戦計画に関連する必要な情報収集製品の知識。',
          softSkill: false,
        },
        {
          id: 'K0526',
          value: 'リサーチ戦略とナレッジマネジメントの知識。',
          softSkill: false,
        },
        {
          id: 'K0527',
          value: 'リスク管理と軽減戦略の知識。',
          softSkill: true,
        },
        {
          id: 'K0528',
          value: '衛星ベースの通信システムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0529',
          value: 'スクリプティングの知識',
          softSkill: false,
        },
        {
          id: 'K0530',
          value:
            'セキュリティハードウェアとソフトウェアのオプションに関する知識、それらが引き起こすネットワークアーティファクトとその悪用への影響を含む。',
          softSkill: false,
        },
        {
          id: 'K0531',
          value: 'ソフトウェア構成のセキュリティへの影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0532',
          value: '専門的なターゲット言語の知識（例：頭字語、専門用語、技術用語、暗号語）。',
          softSkill: false,
        },
        {
          id: 'K0533',
          value: '特定のターゲット識別子とその使用法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0534',
          value: 'スタッフ管理、割り当て、配置プロセスの知識。',
          softSkill: true,
        },
        {
          id: 'K0535',
          value: 'ターゲットリサーチのための戦略とツールの知識。',
          softSkill: false,
        },
        {
          id: 'K0536',
          value:
            '構造、アプローチ、および搾取ツール（例：スニファー、キーロガー）の戦略の知識、および技術（例：バックドアアクセスの獲得、データの収集/抽出、ネットワーク内の他のシステムの脆弱性分析の実施）。',
          softSkill: false,
        },
        {
          id: 'K0538',
          value: '対象および脅威組織の構造、重要能力、および重要な脆弱性に関する知識',
          softSkill: false,
        },
        {
          id: 'K0539',
          value:
            'ターゲットのコミュニケーションプロファイルとその主要要素（例：ターゲットの関連性、活動、コミュニケーションインフラ）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0540',
          value: 'ターゲットコミュニケーションツールとテクニックの知識。',
          softSkill: false,
        },
        {
          id: 'K0541',
          value: '対象文化の参照、方言、表現、慣用句、略語の知識。',
          softSkill: false,
        },
        {
          id: 'K0542',
          value: 'ターゲット開発の知識（概念、役割、責任、製品など）。',
          softSkill: false,
        },
        {
          id: 'K0543',
          value: '目標の修理および回復時間の知識。',
          softSkill: false,
        },
        {
          id: 'K0544',
          value: '対象情報収集および作戦準備技術とライフサイクルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0545',
          value: '対象言語の知識。',
          softSkill: false,
        },
        {
          id: 'K0546',
          value:
            'ターゲットリストの開発に関する知識（例：制限リスト、共同リスト、候補リストなど）。',
          softSkill: false,
        },
        {
          id: 'K0547',
          value: '対象の方法と手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0548',
          value: 'ターゲットまたは脅威サイバー行為者と手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0549',
          value: '対象の選定および検証手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0550',
          value:
            '対象の知識、関連する現在の出来事、コミュニケーションプロファイル、アクター、および歴史（言語、文化）および/または参照フレーム。',
          softSkill: false,
        },
        {
          id: 'K0551',
          value: 'ターゲティングサイクルの知識。',
          softSkill: false,
        },
        {
          id: 'K0552',
          value: 'タスキングメカニズムの知識。',
          softSkill: false,
        },
        {
          id: 'K0553',
          value: '有機的および従属的な収集資産のタスク処理プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0554',
          value: 'タスキング、収集、処理、搾取、および普及の知識。',
          softSkill: false,
        },
        {
          id: 'K0555',
          value: 'TCP/IPネットワーキングプロトコルの知識。',
          softSkill: false,
        },
        {
          id: 'K0556',
          value: '通信の基礎知識。',
          softSkill: false,
        },
        {
          id: 'K0557',
          value: 'ターミナルまたは環境収集に関する知識（プロセス、目的、組織、対象など）。',
          softSkill: false,
        },
        {
          id: 'K0558',
          value: '収集要件と収集管理に関連する利用可能なツールやアプリケーションの知識。',
          softSkill: false,
        },
        {
          id: 'K0559',
          value: '収束アプリケーションの基本的な構造、アーキテクチャ、およびデザインの知識。',
          softSkill: false,
        },
        {
          id: 'K0560',
          value:
            '現代の通信ネットワークの基本的な構造、アーキテクチャ、およびデザインに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0561',
          value:
            'ネットワークセキュリティの基礎知識（例：暗号化、ファイアウォール、認証、ハニーポット、境界保護）。',
          softSkill: false,
        },
        {
          id: 'K0562',
          value: '新しい収集能力、アクセス、および/またはプロセスの能力と限界に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0563',
          value:
            '内部および外部の収集の能力、限界、および計画されたサイバー活動に適用されるタスク処理方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0564',
          value:
            '対象とする通信ネットワークの特性（例：容量、機能性、経路、重要ノード）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0565',
          value:
            '一般的なネットワーキングおよびルーティングプロトコル（例：TCP/IP）、サービス（例：Web、メール、DNS）の知識、およびそれらがどのように相互作用してネットワーク通信を提供するか。',
          softSkill: false,
        },
        {
          id: 'K0566',
          value: '重要な情報要件とそれらが計画でどのように使用されるかの知識。',
          softSkill: false,
        },
        {
          id: 'K0567',
          value: 'データの流れに関する知識：収集元からリポジトリやツールまで。',
          softSkill: false,
        },
        {
          id: 'K0568',
          value: 'コレクション管理とコレクション管理権限の定義に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0569',
          value: '既存のタスキング、収集、処理、搾取、および配信アーキテクチャの知識。',
          softSkill: false,
        },
        {
          id: 'K0570',
          value: '脅威の要因の知識が収集作業に影響を与える可能性があります。',
          softSkill: false,
        },
        {
          id: 'K0571',
          value: '収集プロセスにおけるフィードバックサイクルの知識。',
          softSkill: false,
        },
        {
          id: 'K0572',
          value: '組織に利益をもたらすために脅威活動を模倣する内部チームの機能と能力に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0573',
          value: 'デジタルフォレンジックの基礎知識を活用して実用的な情報を抽出する。',
          softSkill: false,
        },
        {
          id: 'K0574',
          value: '言語分析がオンネットオペレーター機能に与える影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0575',
          value: '内部および外部パートナーのスタッフ推定の影響に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0576',
          value: '情報環境の知識。',
          softSkill: false,
        },
        {
          id: 'K0577',
          value: 'インテリジェンスのフレームワーク、プロセス、および関連システムに関する知識',
          softSkill: false,
        },
        {
          id: 'K0578',
          value: 'インテリジェンス要件開発と情報要求プロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0579',
          value: '組織、役割、および上位、下位、隣接するサブエレメントの責任に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0580',
          value: '組織が定めた収集計画のフォーマットに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0581',
          value: '組織の計画、運用、およびターゲティングサイクルに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0582',
          value: '組織の計画と人員配置のプロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0583',
          value: '組織の計画/指示/ガイダンスに関する知識は、目標を説明します。',
          softSkill: true,
        },
        {
          id: 'K0584',
          value: '組織のポリシー/手順に関する知識、コレクション権限の一時的な移譲のため。',
          softSkill: true,
        },
        {
          id: 'K0585',
          value:
            '組織構造に関する知識、特にフルスペクトラムサイバー作戦に関連して、異なる内部要素間の機能、責任、および相互関係について。',
          softSkill: true,
        },
        {
          id: 'K0586',
          value: '行動の過程と運動分析の結果に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0587',
          value:
            'POC、データベース、ツール、および環境準備と監視製品を確立するために必要なアプリケーションの知識。',
          softSkill: false,
        },
        {
          id: 'K0588',
          value: '組織の下位、横断的、および上位レベルからの優先情報要件の知識。',
          softSkill: false,
        },
        {
          id: 'K0589',
          value: '業務のパフォーマンスと影響を評価するために使用されるプロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0590',
          value: '運用評価手順と重要情報要件プロセスを同期させるプロセスの知識。',
          softSkill: false,
        },
        {
          id: 'K0591',
          value: '生産責任と有機分析および生産能力に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0592',
          value: 'ターゲットテンプレートの目的と貢献に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0593',
          value:
            'サイバー作戦の範囲とその基盤となる情報支援の必要性、トピック、焦点領域に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0594',
          value: '終局、目標、効果、作戦ラインなどの関係に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0595',
          value: '作戦目標、情報要件、および情報生産タスクの関係に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0596',
          value: '情報要求プロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0597',
          value: '組織の他の運営を支援し、促進するネットワーク運用の役割に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0598',
          value: '組織固有の計画、指針、および認可の構造と意図に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0599',
          value:
            '現代のデジタルおよび電話ネットワークの構造、アーキテクチャ、およびデザインに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0600',
          value: '現代の無線通信システムの構造、アーキテクチャ、およびデザインに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0601',
          value: 'システム/アーキテクチャ/コミュニケーションの使用に関する知識が必要です。',
          softSkill: false,
        },
        {
          id: 'K0602',
          value: '収集分野と能力の知識。',
          softSkill: false,
        },
        {
          id: 'K0603',
          value: '対象者または脅威がインターネットを使用する方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0604',
          value: '脅威および/またはターゲットシステムの知識。',
          softSkill: false,
        },
        {
          id: 'K0605',
          value: 'チッピング、キューイング、ミキシング、冗長性の知識。',
          softSkill: false,
        },
        {
          id: 'K0606',
          value: '記録の作成プロセスと技術の知識（例：逐語録、要旨、要約）。',
          softSkill: true,
        },
        {
          id: 'K0607',
          value: '翻訳プロセスと技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0608',
          value:
            'Unix/LinuxおよびWindowsオペレーティングシステムの構造と内部構造に関する知識（例：プロセス管理、ディレクトリ構造、インストールされたアプリケーション）。',
          softSkill: false,
        },
        {
          id: 'K0609',
          value: '仮想マシン技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0610',
          value: '仮想化製品（VMware、Virtual PC）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0611',
          value: '撤回 – K0131に統合された',
          softSkill: true,
        },
        {
          id: 'K0612',
          value: 'ネットワークに対する「脅威」が何であるかの知識。',
          softSkill: false,
        },
        {
          id: 'K0613',
          value:
            '組織の運営計画者が誰であるか、どのようにしてどこで連絡が取れるか、そして彼らの期待は何か。',
          softSkill: true,
        },
        {
          id: 'K0614',
          value:
            '無線技術（例：セルラー、衛星、GSM）に関する知識、現代の無線通信システムの基本的な構造、アーキテクチャ、および設計を含む。',
          softSkill: false,
        },
        {
          id: 'K0615',
          value: '現行法に基づくプライバシー開示声明の知識。',
          softSkill: true,
        },
        {
          id: 'K0616',
          value:
            '継続的監視、そのプロセス、および継続的診断と緩和（CDM）プログラムの活動に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0617',
          value: '自動化セキュリティ制御評価の知識',
          softSkill: true,
        },
        {
          id: 'K0618',
          value:
            'ハードウェア資産管理の知識と、部門、場所、施設、および潜在的にはビジネス機能をサポートするためのネットワークデバイスおよびソフトウェアの位置と構成を追跡する「価値」。',
          softSkill: true,
        },
        {
          id: 'K0619',
          value:
            'ソフトウェア資産管理の知識と、部門、場所、施設、および潜在的にはビジネス機能をサポートするためのネットワークデバイスおよびソフトウェアの場所と構成を追跡する「価値」。',
          softSkill: true,
        },
        {
          id: 'K0620',
          value: '継続的監視技術とツールの知識。',
          softSkill: true,
        },
        {
          id: 'K0621',
          value: 'リスクスコアリングの知識。',
          softSkill: true,
        },
        {
          id: 'K0622',
          value: 'データの使用、処理、保管、および伝送に関連する制御の知識。',
          softSkill: false,
        },
        {
          id: 'K0623',
          value: 'リスク評価方法論の知識。',
          softSkill: true,
        },
        {
          id: 'K0624',
          value:
            'アプリケーションセキュリティリスクの知識（例：オープンウェブアプリケーションセキュリティプロジェクト トップ10リスト）',
          softSkill: false,
        },
        {
          id: 'K0625',
          value:
            '一部のネットワークデバイスにおいて、パッチ適用とソフトウェアアップデートが非現実的であるという知識。',
          softSkill: true,
        },
        {
          id: 'K0626',
          value: '安全な更新メカニズムに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0627',
          value:
            'スプーフィングされたネットワークアドレスに依存する自動化された脅威から保護するためのイングレスフィルタリングの重要性に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0628',
          value:
            'サイバー競技を通じてスキルを開発する方法としての知識は、シミュレートされた実世界の状況で実践的な経験を提供します。',
          softSkill: true,
        },
        {
          id: 'K0629',
          value: 'ホワイトリスト/ブラックリストの知識',
          softSkill: true,
        },
        {
          id: 'K0630',
          value: '組織外部の最新の侵入技術、方法、および文書化された侵入に関する知識。',
          softSkill: true,
        },
      ],
    },
    {
      name: 'skills',
      items: [
        {
          id: 'S0001',
          value: 'セキュリティシステムの脆弱性を認識し、脆弱性スキャンを実施するスキル。',
          softSkill: false,
        },
        {
          id: 'S0002',
          value: 'データ管理システムの設計におけるストレージ容量の割り当てスキル。',
          softSkill: false,
        },
        {
          id: 'S0003',
          value: 'マルウェアの識別、捕捉、封じ込め、報告のスキル。',
          softSkill: false,
        },
        {
          id: 'S0004',
          value: 'ネットワークトラフィックの容量とパフォーマンス特性を分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0005',
          value: '提案されたソリューションに情報技術を適用し組み込むスキル。',
          softSkill: false,
        },
        {
          id: 'S0006',
          value: '機密性、完全性、および可用性の原則を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0007',
          value: 'ホスト/ネットワークアクセス制御の適用スキル（例：アクセス制御リスト）。',
          softSkill: false,
        },
        {
          id: 'S0008',
          value: '組織固有のシステム分析の原則と技術を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0009',
          value: 'セキュリティシステムと設計の堅牢性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0010',
          value: '能力と要件分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0011',
          value: '情報検索を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0012',
          value: '知識マッピング（例：知識リポジトリのマップ）のスキル。',
          softSkill: false,
        },
        {
          id: 'S0013',
          value: 'データ構造を分析するためのクエリ実行とアルゴリズム開発のスキル。',
          softSkill: false,
        },
        {
          id: 'S0014',
          value: 'ソフトウェアデバッグを行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0015',
          value: 'テストイベントの実施スキル。',
          softSkill: false,
        },
        {
          id: 'S0016',
          value: 'ソフトウェアの設定と最適化のスキル。',
          softSkill: false,
        },
        {
          id: 'S0017',
          value: '数学的または統計的モデルを作成し、活用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0018',
          value: 'システムのセキュリティ目標を反映したポリシーを作成するスキル。',
          softSkill: true,
        },
        {
          id: 'S0019',
          value:
            'コマンドライン引数、環境変数、および入力ストリームを含む複数の入力を検証および処理するプログラムを作成するスキル。',
          softSkill: false,
        },
        {
          id: 'S0020',
          value: '署名の開発と展開のスキル。',
          softSkill: false,
        },
        {
          id: 'S0021',
          value:
            'データ分析構造の設計スキル（つまり、テストが生成する必要があるデータの種類とそのデータの分析方法）。',
          softSkill: false,
        },
        {
          id: 'S0022',
          value: '特定されたセキュリティリスクに対する対策を設計するスキル。',
          softSkill: false,
        },
        {
          id: 'S0023',
          value:
            'サイバーセキュリティの原則とテネットに基づいたセキュリティコントロールの設計スキル。',
          softSkill: false,
        },
        {
          id: 'S0024',
          value: 'ハードウェアとソフトウェアソリューションの統合設計のスキル。',
          softSkill: false,
        },
        {
          id: 'S0025',
          value:
            '侵入検知技術（例：Snort）を使用したホストおよびネットワークベースの侵入の検出スキル。',
          softSkill: false,
        },
        {
          id: 'S0026',
          value: '特定のシステムに対する適切なテスト厳格さを決定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0027',
          value:
            'セキュリティシステムの機能方法（耐久性や信頼性の能力を含む）を決定するスキルと、条件、運用、または環境の変化がこれらの結果にどのように影響するかを理解するスキル。',
          softSkill: false,
        },
        {
          id: 'S0028',
          value: 'データ辞書の開発スキル。',
          softSkill: false,
        },
        {
          id: 'S0029',
          value: 'データモデルを開発するスキル。',
          softSkill: false,
        },
        {
          id: 'S0030',
          value: '運用ベースのテストシナリオを開発するスキル。',
          softSkill: false,
        },
        {
          id: 'S0031',
          value: 'セキュリティシステムのアクセス制御を開発し適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0032',
          value: 'ネットワークインフラの緊急対応および回復計画の開発、テスト、および実装のスキル。',
          softSkill: true,
        },
        {
          id: 'S0033',
          value: '接続問題の診断スキル。',
          softSkill: false,
        },
        {
          id: 'S0034',
          value:
            '情報システムやネットワークの保護ニーズ（すなわちセキュリティコントロール）を見極めるスキル。',
          softSkill: false,
        },
        {
          id: 'S0035',
          value: 'ルーティングスキーマを確立するスキル。',
          softSkill: false,
        },
        {
          id: 'S0036',
          value: 'セキュリティ設計の適切性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0037',
          value: 'クエリとレポートの生成スキル。',
          softSkill: false,
        },
        {
          id: 'S0038',
          value:
            'システムのパフォーマンスの指標または指標を識別するスキルと、システムの目標に対してパフォーマンスを改善または修正するために必要な行動。',
          softSkill: false,
        },
        {
          id: 'S0039',
          value:
            'システムのパフォーマンスまたは可用性の低下の原因を特定するスキルと、この低下を軽減するために必要な措置を開始する能力。',
          softSkill: false,
        },
        {
          id: 'S0040',
          value: '実装、維持、および確立されたネットワークセキュリティ慣行の改善におけるスキル。',
          softSkill: false,
        },
        {
          id: 'S0041',
          value:
            'LANおよびWANコンポーネント（ルーター、ハブ、スイッチなど）のインストール、設定、トラブルシューティングのスキル。',
          softSkill: false,
        },
        {
          id: 'S0042',
          value:
            'データベースの管理スキル。（例：バックアップ、リストア、データ削除、トランザクションログファイルなど）。',
          softSkill: false,
        },
        {
          id: 'S0043',
          value:
            'ディレクトリサービスの維持に関するスキル。（例：Microsoft Active Directory、LDAPなど）。',
          softSkill: false,
        },
        {
          id: 'S0044',
          value: '脅威行動を模倣するスキル。',
          softSkill: false,
        },
        {
          id: 'S0045',
          value: 'データベースのパフォーマンスを最適化するスキル。',
          softSkill: false,
        },
        {
          id: 'S0046',
          value: '適切なツール（例：Wireshark、tcpdump）を使用したパケットレベル分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0047',
          value: '標準運用手順または国家基準に従った証拠の完全性を保持するスキル。',
          softSkill: false,
        },
        {
          id: 'S0048',
          value: 'システム統合テストのスキル。',
          softSkill: false,
        },
        {
          id: 'S0049',
          value: '知的資本の測定と報告のスキル。',
          softSkill: false,
        },
        {
          id: 'S0050',
          value: 'デザインモデリングとユースケースの構築におけるスキル（例：統一モデリング言語）。',
          softSkill: false,
        },
        {
          id: 'S0051',
          value: '侵入テストツールと技術の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0052',
          value:
            'ソーシャルエンジニアリング技術の使用スキル。（例：フィッシング、ベイティング、テールゲーティングなど）。',
          softSkill: false,
        },
        {
          id: 'S0053',
          value: 'センサーを調整するスキル。',
          softSkill: false,
        },
        {
          id: 'S0054',
          value: 'インシデント対応方法論を使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0055',
          value: '知識管理技術の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0056',
          value:
            'ネットワークトラフィックパターンを分析するためのネットワーク管理ツールの使用スキル（例：シンプルネットワーク管理プロトコル）。',
          softSkill: false,
        },
        {
          id: 'S0057',
          value: 'プロトコルアナライザーを使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0058',
          value:
            'システムのソフトウェア、ハードウェア、および周辺機器を修理するための適切なツールを使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0059',
          value: '仮想プライベートネットワーク（VPN）デバイスと暗号化のスキル。',
          softSkill: false,
        },
        {
          id: 'S0060',
          value: '現在サポートされているプログラミング言語（例：Java、C++）でコードを書くスキル。',
          softSkill: false,
        },
        {
          id: 'S0061',
          value: 'テストプランを作成するスキル。',
          softSkill: false,
        },
        {
          id: 'S0062',
          value: 'メモリダンプを分析して情報を抽出するスキル。',
          softSkill: false,
        },
        {
          id: 'S0063',
          value: 'さまざまなサイバーディフェンスリソースからデータを収集するスキル。',
          softSkill: false,
        },
        {
          id: 'S0064',
          value: '技術トレーニングプログラムおよびカリキュラムの開発と実行のスキル。',
          softSkill: true,
        },
        {
          id: 'S0065',
          value:
            '多様なメディアにおける法医学的関心のあるデータを識別し、抽出するスキル（メディア法医学）。',
          softSkill: false,
        },
        {
          id: 'S0066',
          value: '技術能力におけるギャップを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0067',
          value:
            'Windows、Unix、Linux内の適用可能なシステムコンポーネント（例：パスワード、ユーザーアカウント、ファイル）を識別、修正、操作するスキル。',
          softSkill: false,
        },
        {
          id: 'S0068',
          value:
            '電子証拠を収集、処理、梱包、輸送、および保管するスキル。データの改ざん、紛失、物理的損傷、または破壊を避けるため。',
          softSkill: false,
        },
        {
          id: 'S0069',
          value: '法医学的ワークステーションの設定スキル。',
          softSkill: false,
        },
        {
          id: 'S0070',
          value: '他人に情報を効果的に伝えるための会話スキル。',
          softSkill: true,
        },
        {
          id: 'S0071',
          value: 'フォレンジックツールスイートの使用スキル（例：EnCase、Sleuthkit、FTK）。',
          softSkill: false,
        },
        {
          id: 'S0072',
          value: '問題を解決するための科学的な規則や方法を使うスキル。',
          softSkill: false,
        },
        {
          id: 'S0073',
          value:
            '仮想マシンの使用スキル。（例：Microsoft Hyper-V、VMWare vSphere、Citrix XenDesktop/Server、Amazon Elastic Compute Cloud など）。',
          softSkill: false,
        },
        {
          id: 'S0074',
          value: 'PCを物理的に分解するスキル。',
          softSkill: false,
        },
        {
          id: 'S0075',
          value:
            '複数のオペレーティングシステム環境での法医学的分析を行うスキル（例：モバイルデバイスシステム）。',
          softSkill: false,
        },
        {
          id: 'S0076',
          value:
            'ソフトウェアベースのコンピュータ保護ツール（例：ソフトウェアファイアウォール、アンチウイルスソフトウェア、アンチスパイウェア）の設定と利用におけるスキル。',
          softSkill: false,
        },
        {
          id: 'S0077',
          value: 'ネットワーク通信の保護に関するスキル。',
          softSkill: false,
        },
        {
          id: 'S0078',
          value: '脆弱性の種類と関連する攻撃を認識し、分類するスキル。',
          softSkill: false,
        },
        {
          id: 'S0079',
          value:
            'マルウェアからネットワークを保護するスキル。（例：NIPS、アンチマルウェア、外部デバイスの制限/防止、スパムフィルター）。',
          softSkill: false,
        },
        {
          id: 'S0080',
          value: '損害評価を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0081',
          value:
            'ネットワーク分析ツールを使用して脆弱性を特定するスキル（例：ファジング、nmapなど）。',
          softSkill: false,
        },
        {
          id: 'S0082',
          value: '適用性と完全性を評価するテストプランのスキル。',
          softSkill: false,
        },
        {
          id: 'S0083',
          value:
            'ソフトウェアリリースの品質保証プロセスにブラックボックスセキュリティテストツールを統合するスキル。',
          softSkill: false,
        },
        {
          id: 'S0084',
          value:
            'ネットワーク保護コンポーネント（例：ファイアウォール、VPN、ネットワーク侵入検知システム）の設定と利用に関するスキル。',
          softSkill: false,
        },
        {
          id: 'S0085',
          value: '技術システムの監査またはレビューを行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0086',
          value: 'サプライヤーや製品の信頼性を評価するスキル。',
          softSkill: true,
        },
        {
          id: 'S0087',
          value: '取得した悪意のあるコード（例：マルウェアフォレンジック）の深い分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0088',
          value: 'バイナリ解析ツールの使用スキル（例：Hexedit、コマンドコードxxd、hexdump）。',
          softSkill: false,
        },
        {
          id: 'S0089',
          value:
            '一方向ハッシュ関数（例：セキュアハッシュアルゴリズム[SHA]、メッセージダイジェストアルゴリズム[MD5]）のスキル。',
          softSkill: false,
        },
        {
          id: 'S0090',
          value: '異常なコードを悪意のあるものか無害かと分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0091',
          value: '不安定なデータを分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0092',
          value: '難読化技術を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0093',
          value:
            'デバッガーの結果を解釈するスキルは、戦術、技術、および手順を確認するために必要です。',
          softSkill: false,
        },
        {
          id: 'S0094',
          value: '16進数データの読み取りスキル。',
          softSkill: false,
        },
        {
          id: 'S0095',
          value:
            '一般的なエンコーディング技術（例：排他的論理和[XOR]、アメリカ標準情報交換コード[ASCII]、ユニコード、Base64、Uuencode、統一資源位置指定子[URL]エンコード）を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0096',
          value: '署名（例：スノート）の読み取りと解釈のスキル。',
          softSkill: false,
        },
        {
          id: 'S0097',
          value: 'セキュリティコントロールの適用スキル。',
          softSkill: false,
        },
        {
          id: 'S0100',
          value:
            '学習活動（例えば、シナリオ、教育ゲーム、インタラクティブな演習など）を利用または開発するスキル。',
          softSkill: true,
        },
        {
          id: 'S0101',
          value:
            '指導目的での技術（例：スマートボード、ウェブサイト、コンピューター、プロジェクター）の使用スキル。',
          softSkill: true,
        },
        {
          id: 'S0102',
          value: '技術的な配信能力の適用スキル。',
          softSkill: true,
        },
        {
          id: 'S0103',
          value: 'モデルの予測力とその後の一般化能力を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0104',
          value: 'テスト準備レビューの実施スキル。',
          softSkill: false,
        },
        {
          id: 'S0106',
          value:
            'データ前処理のスキル（例：代入、次元削減、正規化、変換、抽出、フィルタリング、平滑化）。',
          softSkill: false,
        },
        {
          id: 'S0107',
          value: 'プログラムのテスト＆評価戦略の全体設計と文書化のスキル。',
          softSkill: false,
        },
        {
          id: 'S0108',
          value: '労働力の育成と職位資格基準の策定のスキル。',
          softSkill: true,
        },
        {
          id: 'S0109',
          value: '隠されたパターンや関係を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0110',
          value: 'テスト＆評価インフラストラクチャ（人々、範囲、ツール、計測器）要件の特定スキル。',
          softSkill: false,
        },
        {
          id: 'S0111',
          value: '顧客とのインターフェーススキル。',
          softSkill: true,
        },
        {
          id: 'S0112',
          value:
            'テストアセット、テストリソース、テスト要員を管理するスキルを活用して、テストイベントを効果的に完了させること。',
          softSkill: false,
        },
        {
          id: 'S0113',
          value: 'データの標準表現を作成するための形式変換のスキル。',
          softSkill: false,
        },
        {
          id: 'S0114',
          value: '感度分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0115',
          value: 'テスト＆評価レポートの作成スキル。',
          softSkill: false,
        },
        {
          id: 'S0116',
          value: 'マルチレベルセキュリティ/クロスドメインソリューションの設計スキル。',
          softSkill: false,
        },
        {
          id: 'S0117',
          value: 'テスト＆評価リソース見積もりのスキル。',
          softSkill: false,
        },
        {
          id: 'S0118',
          value: '機械が理解可能なセマンティックオントロジーを開発するスキル。',
          softSkill: false,
        },
        {
          id: 'S0119',
          value:
            '回帰分析のスキル（例：階層的ステップワイズ、一般化線形モデル、最小二乗法、ツリーベースの方法、ロジスティック）。',
          softSkill: false,
        },
        {
          id: 'S0120',
          value: 'ログをレビューして過去の侵入の証拠を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0121',
          value:
            'システム、ネットワーク、OSの強化技術に関するスキル。（例：不要なサービスの削除、パスワードポリシー、ネットワークセグメンテーション、ログの有効化、最小権限など）。',
          softSkill: false,
        },
        {
          id: 'S0122',
          value: 'デザイン方法の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0123',
          value: '変換分析のスキル（例：集約、エンリッチメント、処理）。',
          softSkill: false,
        },
        {
          id: 'S0124',
          value:
            'サイバーディフェンスインフラの異常をトラブルシューティングし、診断し、解決策を導くスキル。',
          softSkill: false,
        },
        {
          id: 'S0125',
          value: '基本的な記述統計と技術を使用するスキル（例：正規性、モデル分布、散布図）。',
          softSkill: false,
        },
        {
          id: 'S0126',
          value: 'データ分析ツールの使用スキル（例：Excel、STATA SAS、SPSS）。',
          softSkill: false,
        },
        {
          id: 'S0127',
          value: 'データマッピングツールの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0128',
          value: '人的資源および人事ITシステムの使用スキル。',
          softSkill: true,
        },
        {
          id: 'S0129',
          value: '外れ値の特定と除去技術の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0130',
          value: 'R、Python、PIG、HIVE、SQLなどのスクリプト作成スキル。',
          softSkill: false,
        },
        {
          id: 'S0131',
          value: 'マルウェアの分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0132',
          value: 'ビットレベル分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0133',
          value:
            'デジタル証拠の処理スキル、証拠の保護および法的に確実なコピーを作成することを含む。',
          softSkill: false,
        },
        {
          id: 'S0134',
          value: 'システムのレビューを行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0135',
          value:
            '安全なテストプラン設計のスキル（例：ユニット、インテグレーション、システム、アクセプタンス）。',
          softSkill: false,
        },
        {
          id: 'S0136',
          value:
            'ネットワークシステム管理の原則、モデル、方法（例：エンドツーエンドのシステムパフォーマンスモニタリング）、およびツールに関するスキル。',
          softSkill: false,
        },
        {
          id: 'S0137',
          value: 'アプリケーションの脆弱性評価を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0138',
          value:
            '公開鍵インフラ（PKI）暗号化およびデジタル署名機能をアプリケーションに組み込むスキル（例：S/MIMEメール、SSLトラフィック）。',
          softSkill: false,
        },
        {
          id: 'S0139',
          value:
            'セキュリティモデルの適用スキル（例：ベル・ラパドゥーラモデル、ビバ整合性モデル、クラークウィルソン整合性モデル）。',
          softSkill: false,
        },
        {
          id: 'S0140',
          value: 'システムエンジニアリングプロセスを適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0141',
          value: 'セキュリティシステムの設計を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0142',
          value: 'クライアントレベルの新規問題のトラブルシューティングのための調査を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0143',
          value: 'システム/サーバーの計画、管理、保守のスキル。',
          softSkill: false,
        },
        {
          id: 'S0144',
          value:
            'システム/サーバーのパフォーマンスに影響を与える物理的および技術的な問題を修正するスキル。',
          softSkill: false,
        },
        {
          id: 'S0145',
          value: 'システムのセキュリティ目標を満たすポリシーを統合し、適用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0146',
          value:
            'パフォーマンス目標を達成するためのシステムを可能にするポリシーを作成するスキル（例：トラフィックルーティング、SLA、CPU仕様）。',
          softSkill: false,
        },
        {
          id: 'S0147',
          value:
            'サイバーセキュリティの原則とテネットに基づいてセキュリティコントロールを評価するスキル。（例：CIS CSC、NIST SP 800-53、サイバーセキュリティフレームワークなど）。',
          softSkill: false,
        },
        {
          id: 'S0148',
          value:
            '技術プロセスとソリューションの統合を設計するスキル、古いシステムと現代のプログラミング言語を含む。',
          softSkill: false,
        },
        {
          id: 'S0149',
          value:
            'アプリケーションのエラー、例外、およびアプリケーションの障害を記録および処理するスキル。',
          softSkill: false,
        },
        {
          id: 'S0150',
          value: 'ネットワークインフラの緊急回復計画と復旧計画の実装とテストのスキル。',
          softSkill: true,
        },
        {
          id: 'S0151',
          value: 'システムコンポーネント（例：サーバー）のトラブルシューティングのスキル',
          softSkill: false,
        },
        {
          id: 'S0152',
          value: '運用要件を保護ニーズ（すなわち、セキュリティコントロール）に翻訳するスキル。',
          softSkill: false,
        },
        {
          id: 'S0153',
          value:
            'システム/サーバーのパフォーマンス、可用性、容量、または構成の問題を識別し、予測するスキル。',
          softSkill: false,
        },
        {
          id: 'S0154',
          value:
            'システムおよびコンポーネントのアップグレードのインストールスキル。（例：サーバー、アプライアンス、ネットワークデバイス）。',
          softSkill: false,
        },
        {
          id: 'S0155',
          value: 'システム/サーバーのパフォーマンスを監視し、最適化するスキル。',
          softSkill: false,
        },
        {
          id: 'S0156',
          value: 'パケットレベル分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0157',
          value:
            'システム/サーバーの障害回復スキル（例：リカバリーソフトウェア、フェイルオーバークラスター、レプリケーションなど）。',
          softSkill: false,
        },
        {
          id: 'S0158',
          value:
            'オペレーティングシステム管理のスキル。（例：アカウント管理、データバックアップ、システムパフォーマンスの維持、新しいハードウェア/ソフトウェアのインストールと設定）。',
          softSkill: false,
        },
        {
          id: 'S0159',
          value:
            '承認された基準および/または仕様に従ってネットワークワークステーションおよび周辺機器を設定し、検証するスキル。',
          softSkill: false,
        },
        {
          id: 'S0160',
          value: '設計モデリングの使用スキル（例：統一モデリング言語）。',
          softSkill: false,
        },
        {
          id: 'S0161',
          value: '撤回済み – S0160に統合済み',
          softSkill: true,
        },
        {
          id: 'S0162',
          value: 'サブネッティングのスキル。',
          softSkill: false,
        },
        {
          id: 'S0163',
          value: '撤回 - S0060に統合済み',
          softSkill: true,
        },
        {
          id: 'S0164',
          value: '暗号化標準の適用を評価するスキル。',
          softSkill: true,
        },
        {
          id: 'S0166',
          value: '技術提供能力におけるギャップを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0167',
          value:
            'セキュリティシステムの脆弱性を認識するスキル。（例：脆弱性とコンプライアンススキャン）。',
          softSkill: false,
        },
        {
          id: 'S0168',
          value:
            '内部のローカルエリアネットワーク（LAN）を他の信頼できないネットワークから分離する物理的または論理的なサブネットワークを設定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0169',
          value: 'トレンド分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0170',
          value:
            'コンピュータ保護コンポーネント（例：ハードウェアファイアウォール、サーバー、ルーターなど）の設定と利用のスキル。',
          softSkill: false,
        },
        {
          id: 'S0171',
          value: '影響/リスク評価のスキル。',
          softSkill: true,
        },
        {
          id: 'S0172',
          value: 'セキュアコーディング技術を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0173',
          value: 'セキュリティイベント相関ツールの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0174',
          value: 'コード解析ツールの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0175',
          value: '原因分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0176',
          value:
            '管理計画活動におけるスキル、機能的および具体的な支援計画の準備、文書の準備と管理、およびスタッフィング手順を含む。',
          softSkill: true,
        },
        {
          id: 'S0177',
          value: 'ターゲットのコミュニケーションネットワークを分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0178',
          value:
            'ネットワークデータ（例：ルータ設定ファイル、ルーティングプロトコル）の分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0179',
          value:
            '言語処理ツールを分析するスキルは、ツール開発を強化するフィードバックを提供するために使用されます。',
          softSkill: false,
        },
        {
          id: 'S0180',
          value: '撤回 - S0062に統合されました',
          softSkill: true,
        },
        {
          id: 'S0181',
          value: '中点収集データの分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0182',
          value: '無線LANから収集されたターゲット通信の内部および外部を分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0183',
          value: '端末または環境収集データの分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0184',
          value: 'トラフィックを分析してネットワークデバイスを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0185',
          value:
            '計画を支援し、推奨される戦略や行動方針を正当化するために通常使用される分析方法を適用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0186',
          value: '危機計画手順を適用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0187',
          value:
            'さまざまな分析方法、ツール、技術（例：競合する仮説；推論の連鎖；シナリオ手法；否認と欺瞞の検出；高影響-低確率；ネットワーク/関連またはリンク分析；ベイジアン、デルファイ、パターン分析）を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0188',
          value: '対象の参照フレームを評価するスキル（例：動機、技術能力、組織構造、感受性）。',
          softSkill: false,
        },
        {
          id: 'S0189',
          value: 'サイバー作戦中および後に発生する効果を評価および/または推定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0190',
          value: '現在のツールを評価して必要な改善点を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0191',
          value: 'さまざまな状況に利用可能な分析ツールの適用可能性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0192',
          value: 'ファイアウォール、境界、ルーター、侵入検知システムの監査スキル。',
          softSkill: false,
        },
        {
          id: 'S0193',
          value: '対象情報に対する法的制限の遵守スキル。',
          softSkill: true,
        },
        {
          id: 'S0194',
          value: '非帰属研究を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0195',
          value: 'すべての利用可能な情報源を使用して研究を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0196',
          value: 'ディープウェブを使用した研究のスキル。',
          softSkill: false,
        },
        {
          id: 'S0197',
          value:
            'ソーシャルネットワーク分析、バディリスト分析、および/またはクッキー分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0198',
          value: 'ソーシャルネットワーク分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0199',
          value: 'パケットキャプチャから重要な情報を作成し、抽出するスキル。',
          softSkill: false,
        },
        {
          id: 'S0200',
          value: 'データ取得活動を支援するための収集要件の作成スキル。',
          softSkill: false,
        },
        {
          id: 'S0201',
          value:
            '遠隔操作を支援する計画を作成するスキル。（例：ホット/ウォーム/コールド/代替サイト、災害復旧）。',
          softSkill: true,
        },
        {
          id: 'S0202',
          value: 'データマイニング技術（例：ファイルシステムの検索）および分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0203',
          value: '運用環境のすべての関連する側面を定義し、特徴づけるスキル。',
          softSkill: false,
        },
        {
          id: 'S0204',
          value: 'ネットワークマップ上でのソースまたは関連データの描写スキル。',
          softSkill: false,
        },
        {
          id: 'S0205',
          value:
            '利用可能な能力と望ましい効果を評価することによる適切なターゲティングオプションの決定スキル。',
          softSkill: false,
        },
        {
          id: 'S0206',
          value:
            'さまざまなオペレーティングシステムにインストールされているパッチを特定し、パッチのシグネチャを識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0207',
          value:
            'ルーターやファイアウォールの設定がLANおよびWAN環境でのトラフィックパターンとネットワークパフォーマンスに与える影響を判断するスキル。',
          softSkill: false,
        },
        {
          id: 'S0208',
          value: 'ネットワークデバイスの物理的な位置を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0209',
          value:
            'サイバー作戦評価プログラムを開発し、実行するスキル。運用性能特性を評価し、検証するための包括的な評価プログラムです。',
          softSkill: false,
        },
        {
          id: 'S0210',
          value: '情報報告を作成するスキル。',
          softSkill: false,
        },
        {
          id: 'S0211',
          value:
            '情報が不完全である、または前例が存在しない問題や状況に対して、分析的アプローチや解決策を開発または推奨するスキル。',
          softSkill: false,
        },
        {
          id: 'S0212',
          value: '最高の「価値」を持つ情報をタイムリーに広めるスキル。',
          softSkill: false,
        },
        {
          id: 'S0213',
          value: '複雑な技術的およびプログラム的情報を文書化し、伝達するスキル。',
          softSkill: true,
        },
        {
          id: 'S0214',
          value: 'インテリジェンスの「価値」を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0215',
          value: 'メタデータの評価と解釈のスキル。',
          softSkill: false,
        },
        {
          id: 'S0216',
          value: '利用可能な能力を評価し、望ましい効果と比較して効果的な行動方針を提供するスキル。',
          softSkill: true,
        },
        {
          id: 'S0217',
          value: 'データソースの関連性、信頼性、および客観性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0218',
          value: '情報の信頼性、妥当性、関連性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0219',
          value: '情報を評価し、関連性、優先順位などを認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0220',
          value: '組織および/またはパートナーのコレクションデータベースを活用/照会するスキル。',
          softSkill: false,
        },
        {
          id: 'S0221',
          value: 'パケットキャプチャから情報を抽出するスキル。',
          softSkill: false,
        },
        {
          id: 'S0222',
          value: '融合分析のスキル',
          softSkill: false,
        },
        {
          id: 'S0223',
          value: 'ミッションとターゲット要件をサポートするための作戦計画を立てるスキル。',
          softSkill: false,
        },
        {
          id: 'S0224',
          value: '対象コミュニケーションの要約スキル。',
          softSkill: false,
        },
        {
          id: 'S0225',
          value: 'ターゲットの通信ネットワークを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0226',
          value: 'ターゲットのネットワーク特性を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0227',
          value: '代替的な分析解釈を特定するスキルは、予期しない結果を最小限に抑えるためです。',
          softSkill: false,
        },
        {
          id: 'S0228',
          value:
            'サイバードメインにおける重要なターゲット要素を含む、重要なターゲット要素の特定スキル。',
          softSkill: false,
        },
        {
          id: 'S0229',
          value: '組織やパートナーの利益を脅かす可能性のあるサイバー脅威を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0230',
          value: '撤回 - S0066に統合されました',
          softSkill: true,
        },
        {
          id: 'S0231',
          value: 'ターゲットがどのようにコミュニケーションを取るかを識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0232',
          value: '情報のギャップと制限を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0233',
          value: '組織の目標に影響を与える可能性のある言語問題を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0234',
          value: 'ターゲット開発のためのリードを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0235',
          value: '地域外の言語や方言を識別するスキル',
          softSkill: false,
        },
        {
          id: 'S0236',
          value: 'プロトコルモデルの各レベルで動作するデバイスを識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0237',
          value: 'スキル：地理空間分析技術を用いた、ターゲットの識別、位置特定、追跡',
          softSkill: false,
        },
        {
          id: 'S0238',
          value: '操作に関連する情報の優先順位付けのスキル。',
          softSkill: false,
        },
        {
          id: 'S0239',
          value: 'コンパイル言語とインタープリタ言語の解釈スキル。',
          softSkill: false,
        },
        {
          id: 'S0240',
          value: '収集システムによって適用されるメタデータとコンテンツの解釈スキル。',
          softSkill: false,
        },
        {
          id: 'S0241',
          value:
            'トレーサルートの結果の解釈スキル、それがネットワーク分析および再構築にどのように適用されるか。',
          softSkill: false,
        },
        {
          id: 'S0242',
          value: '脆弱性スキャナーの結果を解釈するスキルは、脆弱性を特定するために必要です。',
          softSkill: false,
        },
        {
          id: 'S0243',
          value: '知識管理のスキル、技術文書作成技術を含む（例：Wikiページ）。',
          softSkill: false,
        },
        {
          id: 'S0244',
          value:
            'クライアントとの関係管理のスキル、クライアントのニーズ/要件の特定、クライアントの期待の管理、および品質の高い結果を提供することへのコミットメントの実証を含む。',
          softSkill: true,
        },
        {
          id: 'S0245',
          value: 'ネットワーク可視化ソフトウェアを操作するスキル。',
          softSkill: false,
        },
        {
          id: 'S0246',
          value: '数値正規化のスキル。',
          softSkill: false,
        },
        {
          id: 'S0247',
          value:
            '既存のインテリジェンスからデータフュージョンを実行するスキルは、新たな収集と継続的な収集を可能にします。',
          softSkill: false,
        },
        {
          id: 'S0248',
          value: 'ターゲットシステム分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0249',
          value: 'ブリーフィングの準備と発表のスキル。',
          softSkill: true,
        },
        {
          id: 'S0250',
          value: '計画と関連する文書の準備に関するスキル。',
          softSkill: true,
        },
        {
          id: 'S0251',
          value: '対象言語素材の優先順位付けスキル。',
          softSkill: false,
        },
        {
          id: 'S0252',
          value: '収集したデータを後続の分析のために処理するスキル。',
          softSkill: false,
        },
        {
          id: 'S0253',
          value: '対象関連事項（例：言語、文化、コミュニケーション）に関する分析を提供するスキル。',
          softSkill: false,
        },
        {
          id: 'S0254',
          value: '文章の後行動報告書を支援するための分析提供スキル。',
          softSkill: true,
        },
        {
          id: 'S0255',
          value: 'ターゲットインフラを利用したリアルタイムで実用的な位置情報提供スキル。',
          softSkill: false,
        },
        {
          id: 'S0256',
          value:
            'ターゲットまたは脅威システムの理解を提供するスキルは、物理的、機能的、または行動的な関係の識別とリンク分析を通じて行われます。',
          softSkill: false,
        },
        {
          id: 'S0257',
          value:
            'WindowsおよびUnixシステムでのシンプルなスクリプト（例：PERL、VBS）の読み取り、解釈、記述、修正、実行のスキル（例：大量のデータファイルのパース、手動タスクの自動化、リモートデータの取得・処理などを行うスクリプト）。',
          softSkill: false,
        },
        {
          id: 'S0258',
          value: 'トラフィック内の悪意のあるネットワーク活動を認識し解釈するスキル。',
          softSkill: false,
        },
        {
          id: 'S0259',
          value: '対象の否認および欺瞞技術を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0260',
          value: '中間点の機会と重要な情報を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0261',
          value: '情報の関連性を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0262',
          value: 'ターゲットのコミュニケーションパターンの重要な変化を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0263',
          value:
            'メタデータ分析のためのリードとして使用される可能性のある技術情報を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0264',
          value:
            '技術情報を認識するスキルは、リモート操作を可能にするためのリードに使用される可能性があります（データにはユーザー、パスワード、メールアドレス、対象のIP範囲、DNI行動の頻度、メールサーバー、ドメインサーバー、SMTPヘッダー情報が含まれます）。',
          softSkill: false,
        },
        {
          id: 'S0265',
          value:
            'ターゲット開発やインテリジェンス開発に使用される可能性のある技術情報を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0266',
          value: '関連するプログラミング言語（例：C++、Pythonなど）のスキル。',
          softSkill: false,
        },
        {
          id: 'S0267',
          value:
            'リモートコマンドラインおよびグラフィックユーザーインターフェース（GUI）ツールの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0268',
          value: '重要な情報を調査するスキル。',
          softSkill: false,
        },
        {
          id: 'S0269',
          value: 'トラフィックで利用される脆弱性とエクスプロイトの調査スキル。',
          softSkill: false,
        },
        {
          id: 'S0270',
          value:
            'リバースエンジニアリングのスキル（例：ヘックス編集、バイナリパッケージングユーティリティ、デバッグ、文字列解析）を用いて、リモートツールの機能と所有権を特定する。',
          softSkill: false,
        },
        {
          id: 'S0271',
          value: '評価製品のレビューと編集のスキル。',
          softSkill: false,
        },
        {
          id: 'S0272',
          value:
            'サイバー作戦のための様々な情報源からのインテリジェンス製品のレビューおよび編集スキル。',
          softSkill: false,
        },
        {
          id: 'S0273',
          value: '計画のレビューと編集のスキル。',
          softSkill: true,
        },
        {
          id: 'S0274',
          value: '対象素材のレビューと編集のスキル。',
          softSkill: true,
        },
        {
          id: 'S0275',
          value: 'サーバー管理のスキル。',
          softSkill: false,
        },
        {
          id: 'S0276',
          value: '無線LANメタデータの調査、収集、および分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0277',
          value: 'データセット全体での意味の合成、分析、優先順位付けのスキル。',
          softSkill: false,
        },
        {
          id: 'S0278',
          value: '必要なレベル（例：分類および組織化）に合わせた分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0279',
          value: '収集作戦を直接支援するためのターゲット開発のスキル。',
          softSkill: false,
        },
        {
          id: 'S0280',
          value:
            'ターゲットネットワークの異常検出スキル（例：侵入、データフローや処理、新技術のターゲット実装）。',
          softSkill: false,
        },
        {
          id: 'S0281',
          value: '技術的なライティングのスキル。',
          softSkill: true,
        },
        {
          id: 'S0282',
          value: '実装のためのツールをテストし評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0283',
          value: '対象言語のコミュニケーションを書き起こすスキル。',
          softSkill: false,
        },
        {
          id: 'S0284',
          value: '対象グラフィックおよび/または音声言語資料の翻訳スキル。',
          softSkill: false,
        },
        {
          id: 'S0285',
          value: 'ブール演算子を使用して単純および複雑なクエリを構築するスキル。',
          softSkill: false,
        },
        {
          id: 'S0286',
          value: 'データベースを使用してターゲットに関連する情報を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0287',
          value: '地理空間データの使用スキルと地理空間リソースの適用。',
          softSkill: false,
        },
        {
          id: 'S0288',
          value:
            '複数の分析ツール、データベース、技術を使用するスキル（例：アナリストのノートブック、A-Space、Anchory、M3、発散的/収束的思考、リンクチャート、行列など）。',
          softSkill: false,
        },
        {
          id: 'S0289',
          value:
            '複数の検索エンジン（例：Google、Yahoo、LexisNexis、DataStar）およびオープンソース検索を行うためのツールを使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0290',
          value: '非帰属ネットワークの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0291',
          value:
            'ターゲットネットワークを再構築するために、複数の異なるソースを含む研究方法を使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0292',
          value: 'ターゲティングデータベースおよびソフトウェアパッケージの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0293',
          value:
            'ツール、テクニック、および手順を使用して遠隔地からターゲットを悪用し、持続性を確立するスキル。',
          softSkill: false,
        },
        {
          id: 'S0294',
          value:
            'トレースルートツールの使用スキルと、ネットワーク分析および再構築に適用する結果の解釈。',
          softSkill: false,
        },
        {
          id: 'S0295',
          value:
            'さまざまなオープンソースのデータ収集ツール（オンライントレード、DNS、メールなど）の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0296',
          value: 'フィードバックを活用してプロセス、製品、サービスを改善するスキル。',
          softSkill: true,
        },
        {
          id: 'S0297',
          value:
            '仮想コラボレーションワークスペースおよび/またはツール（例：IWS、VTC、チャットルーム、SharePoint）の利用スキル。',
          softSkill: true,
        },
        {
          id: 'S0298',
          value:
            'すべてのファイルの整合性を検証するスキル。（例：チェックサム、排他的論理和、セキュアハッシュ、チェック制約など）。',
          softSkill: false,
        },
        {
          id: 'S0299',
          value:
            '無線ネットワークのターゲット分析、テンプレーティング、およびジオロケーションのスキル。',
          softSkill: false,
        },
        {
          id: 'S0300',
          value: '技術能力のギャップを満たすための要件の記述（および提出）スキル。',
          softSkill: true,
        },
        {
          id: 'S0301',
          value: '事実やアイデアについて、明確で説得力があり、組織的な方法で書くスキル。',
          softSkill: true,
        },
        {
          id: 'S0302',
          value: '効果報告書の作成スキル。',
          softSkill: true,
        },
        {
          id: 'S0303',
          value:
            '複数の情報源からのサイバー関連のインテリジェンス/評価製品の執筆、レビュー、編集のスキル。',
          softSkill: true,
        },
        {
          id: 'S0304',
          value: '現在の資産の利用可能性と使用法に関する情報を取得するスキル。',
          softSkill: false,
        },
        {
          id: 'S0305',
          value: 'データベースにアクセスするスキルは、計画/指示/ガイダンスが保持されています。',
          softSkill: false,
        },
        {
          id: 'S0306',
          value: '問題の明確化や追加の指導が必要な場合の戦略的指導を分析するスキル。',
          softSkill: true,
        },
        {
          id: 'S0307',
          value: '対象または脅威源の強さと士気を分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0308',
          value: '知能能力の使用要件を予測するスキル。',
          softSkill: false,
        },
        {
          id: 'S0309',
          value:
            'リーダーシップの決定を促す可能性が高い主要なターゲットまたは脅威の活動を予測するスキル。',
          softSkill: true,
        },
        {
          id: 'S0310',
          value: 'インテリジェンス製品を評価するための分析基準を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0311',
          value:
            '組織の目標に適用する際の、利用可能なプラットフォーム、センサー、アーキテクチャ、および装置の能力、限界、およびタスキング方法論を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0312',
          value:
            'サイバー作戦のパフォーマンスと影響を評価するために使用されるプロセスを適用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0313',
          value:
            'ニーズステートメント/要件を明確に表現するスキルと、新たな収集能力、アクセス、および/またはプロセスを収集操作に統合する能力。',
          softSkill: false,
        },
        {
          id: 'S0314',
          value: '計画の実行を支援するために利用可能なインテリジェンス能力を明確に説明するスキル。',
          softSkill: true,
        },
        {
          id: 'S0315',
          value: '共同計画者のニーズをオールソースアナリストに明確に伝えるスキル。',
          softSkill: true,
        },
        {
          id: 'S0316',
          value: 'インテリジェンスのギャップを優先情報要件および観測可能事項に関連付けるスキル。',
          softSkill: false,
        },
        {
          id: 'S0317',
          value: '指標/観測値を要件と比較するスキル。',
          softSkill: false,
        },
        {
          id: 'S0318',
          value: '複数の領域と次元において、インテリジェンスプロセス全体を概念化するスキル。',
          softSkill: true,
        },
        {
          id: 'S0319',
          value: 'インテリジェンス要件をインテリジェンス生産タスクに変換するスキル。',
          softSkill: false,
        },
        {
          id: 'S0320',
          value: '調整されたインテリジェンス製品の開発のスキル。',
          softSkill: false,
        },
        {
          id: 'S0321',
          value:
            'インテリジェンスの優先事項とインテリジェンスのリソース/アセットの割り当てを相関させるスキル。',
          softSkill: false,
        },
        {
          id: 'S0322',
          value: '運用進捗/成功の指標を作成するスキル。',
          softSkill: false,
        },
        {
          id: 'S0323',
          value: 'サービス/生産の計画書を作成し、最新の状態に保つスキル。',
          softSkill: true,
        },
        {
          id: 'S0324',
          value: '収集の実現可能性を判断するスキル。',
          softSkill: false,
        },
        {
          id: 'S0325',
          value: '情報収集に使用できる手法を明確に示す収集計画を策定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0326',
          value: '計画の策定において、概念的資源と実際の資源を区別するスキルとその適用性。',
          softSkill: true,
        },
        {
          id: 'S0327',
          value: '利用可能なすべてのリソースを活用する収集戦略を確保するスキル。',
          softSkill: false,
        },
        {
          id: 'S0328',
          value: '運用環境の要因を評価し、目標と情報要件に対応するスキル。',
          softSkill: false,
        },
        {
          id: 'S0329',
          value: '情報要求を評価し、応答情報が存在するかどうかを判断するスキル。',
          softSkill: false,
        },
        {
          id: 'S0330',
          value:
            '有機体、劇場、国家、連合およびその他の収集能力の能力、限界、およびタスク処理方法を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0331',
          value:
            '知能能力の限界と意思決定のリスク及び全体的な運営への影響との関係を口頭及び書面で表現するスキル。',
          softSkill: true,
        },
        {
          id: 'S0332',
          value:
            '利用可能なツールやアプリケーションから情報を抽出するスキル、および収集要件と収集操作管理に関連するスキル。',
          softSkill: false,
        },
        {
          id: 'S0333',
          value:
            '意思決定支援資料に含まれる情報とパートナー能力の見積もりをグラフィカルに描写するスキル。',
          softSkill: true,
        },
        {
          id: 'S0334',
          value:
            'タスキング、収集、処理、搾取、および関連する収集分野に対する普及を識別して適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0335',
          value: 'インテリジェンスのギャップを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0336',
          value: '優先情報要件が満たされたときを識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0337',
          value: '収集管理および運用活動を評価するための確立された手順を実施するスキル。',
          softSkill: false,
        },
        {
          id: 'S0338',
          value: '計画指導を解釈するスキルを持ち、必要な分析サポートのレベルを見極める。',
          softSkill: true,
        },
        {
          id: 'S0339',
          value: '準備報告の解釈スキル、その運用上の関連性および情報収集への影響。',
          softSkill: false,
        },
        {
          id: 'S0340',
          value: 'ターゲットまたは脅威の状況と環境要因を監視するスキル。',
          softSkill: false,
        },
        {
          id: 'S0341',
          value: 'パートナーの能力に対する脅威の影響を監視し、継続的な評価を維持するスキル。',
          softSkill: false,
        },
        {
          id: 'S0342',
          value:
            '繰り返し調整、テスト、再調整を通じて収集システムのパフォーマンスを最適化するスキル。',
          softSkill: false,
        },
        {
          id: 'S0343',
          value:
            'インテリジェンスプランニングチームを統括するスキル、収集と生産サポートを調整し、状況を監視する。',
          softSkill: true,
        },
        {
          id: 'S0344',
          value:
            '報告書、プレゼンテーション、およびブリーフィングを準備して提供するスキル、視覚支援またはプレゼンテーション技術の使用を含む。',
          softSkill: true,
        },
        {
          id: 'S0345',
          value: '情報資源/資産を予想される情報要件に関連付けるスキル。',
          softSkill: false,
        },
        {
          id: 'S0346',
          value: '対立するコレクション要件を解決するスキル。',
          softSkill: false,
        },
        {
          id: 'S0347',
          value: 'パフォーマンス仕様と収集資産の歴史情報をレビューするスキル。',
          softSkill: false,
        },
        {
          id: 'S0348',
          value: '近期に行われるべきコレクションやタスクを指定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0349',
          value: '運用評価手順と重要情報要求プロセスとの同期スキル。',
          softSkill: false,
        },
        {
          id: 'S0350',
          value: '計画活動と必要な情報支援を同期するスキル。',
          softSkill: true,
        },
        {
          id: 'S0351',
          value:
            '有機的、劇場的、国家的、連合およびその他の収集能力の能力、限界、およびタスク方法論を翻訳するスキル。',
          softSkill: false,
        },
        {
          id: 'S0352',
          value: 'コラボレーティブツールおよび環境を使用して収集操作を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0353',
          value:
            'システムやツールを使用して収集要件を追跡し、それらが満たされているかどうかを判断するスキル。',
          softSkill: false,
        },
        {
          id: 'S0354',
          value: 'ビジネスの基本的なプライバシー目標を反映したポリシーを作成するスキル。',
          softSkill: true,
        },
        {
          id: 'S0355',
          value: 'ベンダー契約の交渉スキルとベンダーのプライバシー慣行の評価。',
          softSkill: true,
        },
        {
          id: 'S0356',
          value:
            'すべてのレベルの管理職（例えば、取締役会メンバーなど）とのコミュニケーションスキル（対人スキル、アプローチのしやすさ、効果的なリスニングスキル、聴衆に適したスタイルや言葉遣いの使用など）。',
          softSkill: true,
        },
        {
          id: 'S0357',
          value: '新しいセキュリティ脅威を予測するスキル。',
          softSkill: false,
        },
        {
          id: 'S0358',
          value: '進化する技術インフラに意識を保つスキル。',
          softSkill: false,
        },
        {
          id: 'S0359',
          value: '組織のパターンと関係を分析するための批判的思考を使用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0360',
          value: '内部および外部パートナーのサイバー作戦能力とツールを分析・評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0361',
          value:
            '内部および外部パートナーのインテリジェンスプロセスを分析・評価するスキルと、情報要件および必要情報の開発。',
          softSkill: false,
        },
        {
          id: 'S0362',
          value:
            '内部および外部パートナー組織の能力と限界（タスク、収集、処理、搾取、および普及の責任を持つ者）を分析し評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0363',
          value: '内部および外部パートナーの報告を分析・評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0364',
          value: '組織の脅威環境の文脈についての洞察を開発するスキル',
          softSkill: false,
        },
        {
          id: 'S0365',
          value: 'クラウドサービスモデルのインシデント対応を設計するスキル。',
          softSkill: false,
        },
        {
          id: 'S0366',
          value:
            'より一般的でなく、より複雑なシステムの問題に対する解決策を見つけるための成功能力を識別するスキル。',
          softSkill: true,
        },
        {
          id: 'S0367',
          value:
            '組織の要件にサイバーセキュリティとプライバシーの原則を適用するスキル（機密性、完全性、可用性、認証、否認防止に関連）。',
          softSkill: false,
        },
        {
          id: 'S0368',
          value:
            'リスクスコアリングを使用して、組織がサイバーセキュリティリスクを特定、評価、管理するのを支援するパフォーマンスベースでコスト効果的なアプローチに情報を提供するスキル。',
          softSkill: true,
        },
        {
          id: 'S0369',
          value: '組織のデータ資産のソース、特性、および用途を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0370',
          value:
            '自組織内でサイバーディフェンスサービスプロバイダーの報告構造とプロセスを使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0371',
          value: 'サービスプロバイダーからの脅威共有アラートに対応し、地元で対応するスキル。',
          softSkill: true,
        },
        {
          id: 'S0372',
          value:
            'スキル：翻訳、追跡、および拡張エンタープライズ全体での情報ニーズとインテリジェンス収集要件の優先順位付け。',
          softSkill: false,
        },
        {
          id: 'S0373',
          value:
            '情報システムおよび情報通信技術のサプライチェーンインフラコンポーネントに対する説明責任情報が収集されることを保証するスキル。',
          softSkill: true,
        },
        {
          id: 'S0374',
          value:
            '内部および外部の顧客やパートナー組織との接続から生じるサイバーセキュリティおよびプライバシーの問題を特定するスキル。',
          softSkill: true,
        },
      ],
    },
    {
      name: 'abilities',
      items: [
        {
          id: 'A0001',
          value:
            '脆弱性と構成データの分析に基づいて、システム全体のセキュリティ問題を特定する能力。',
          softSkill: false,
        },
        {
          id: 'A0002',
          value: '特定のアプリケーションや環境に適した知識リポジトリ技術を選択する能力。',
          softSkill: false,
        },
        {
          id: 'A0003',
          value: '技術トレンドデータの妥当性を判断する能力。',
          softSkill: false,
        },
        {
          id: 'A0004',
          value: '対象オーディエンスに適したレベルでトピックに対応するカリキュラムを開発する能力。',
          softSkill: true,
        },
        {
          id: 'A0005',
          value: 'デジタルデータコレクションを解読する能力。',
          softSkill: false,
        },
        {
          id: 'A0006',
          value:
            'システム、ネットワーク、およびデータユーザーがシステムのセキュリティポリシーおよび手順を認識し、それに従うようにするための教育および意識向上のブリーフィングを準備し、提供する能力。',
          softSkill: true,
        },
        {
          id: 'A0007',
          value: 'アプリケーション固有の懸念に合わせたコード分析の調整能力。',
          softSkill: false,
        },
        {
          id: 'A0008',
          value:
            '組織のエンタープライズ情報技術（IT）アーキテクチャを記述、分析、および文書化するための方法、基準、およびアプローチを適用する能力（例：オープン・グループ・アーキテクチャ・フレームワーク [TOGAF]、国防総省アーキテクチャ・フレームワーク [DoDAF]、連邦エンタープライズ・アーキテクチャ・フレームワーク [FEAF]）。',
          softSkill: false,
        },
        {
          id: 'A0009',
          value: 'サプライチェーンリスク管理基準を適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0010',
          value: 'マルウェアを分析する能力。',
          softSkill: false,
        },
        {
          id: 'A0011',
          value: '質問に対して明確かつ簡潔に答える能力。',
          softSkill: true,
        },
        {
          id: 'A0012',
          value: '明確な質問をする能力。',
          softSkill: true,
        },
        {
          id: 'A0013',
          value:
            '複雑な情報、概念、またはアイデアを、口頭、書面、および/または視覚的手段を通じて自信を持って、そして整然と伝える能力。',
          softSkill: true,
        },
        {
          id: 'A0014',
          value: '効果的にコミュニケーションを取る能力。',
          softSkill: true,
        },
        {
          id: 'A0015',
          value: 'セキュリティシステムの脆弱性を認識し、脆弱性スキャンを実施する能力。',
          softSkill: false,
        },
        {
          id: 'A0016',
          value: '少人数グループディスカッションを進行する能力。',
          softSkill: true,
        },
        {
          id: 'A0017',
          value: '学習者の理解度と知識レベルを測る能力。',
          softSkill: true,
        },
        {
          id: 'A0018',
          value: 'ブリーフィングの準備と発表の能力。',
          softSkill: true,
        },
        {
          id: 'A0019',
          value: '技術文書を作成する能力。',
          softSkill: true,
        },
        {
          id: 'A0020',
          value: '学生の学習を向上させるための効果的なフィードバックを提供する能力。',
          softSkill: true,
        },
        {
          id: 'A0021',
          value: '複雑な数学的概念（例：離散数学）を使用し理解する能力。',
          softSkill: false,
        },
        {
          id: 'A0022',
          value: '大人の学習の原則を適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0023',
          value: '有効で信頼性のある評価を設計する能力。',
          softSkill: false,
        },
        {
          id: 'A0024',
          value: '明確な指示と教材を開発する能力。',
          softSkill: true,
        },
        {
          id: 'A0025',
          value:
            'トラブルチケットシステムでインシデント、問題、およびイベントを正確に定義する能力。',
          softSkill: false,
        },
        {
          id: 'A0026',
          value: 'テストデータを分析する能力。',
          softSkill: false,
        },
        {
          id: 'A0027',
          value: '組織の目標と目的を適用してアーキテクチャを開発し維持する能力。',
          softSkill: false,
        },
        {
          id: 'A0028',
          value: '組織の目標を達成するための人員要件を評価し、予測する能力。',
          softSkill: true,
        },
        {
          id: 'A0029',
          value: '複雑なデータ構造と高級プログラミング言語を構築する能力。',
          softSkill: false,
        },
        {
          id: 'A0030',
          value: 'テストデータを収集、検証、および検証する能力。',
          softSkill: false,
        },
        {
          id: 'A0031',
          value:
            '政府および業界の能力と適切な価格設定を理解するための市場調査を実施し、適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0032',
          value: '仮想環境内で使用するカリキュラムを開発する能力。',
          softSkill: true,
        },
        {
          id: 'A0033',
          value:
            '法律、規制、ポリシー、および標準に準拠したポリシー、計画、および戦略を策定する能力、組織のサイバー活動をサポートするため。',
          softSkill: true,
        },
        {
          id: 'A0034',
          value: '標準作業手順（SOP）を開発、更新、および/または維持する能力。',
          softSkill: true,
        },
        {
          id: 'A0035',
          value: '問題を分析し、関連性がないように見えるデータ間の相互関係を調査する能力。',
          softSkill: false,
        },
        {
          id: 'A0036',
          value: '高いレベルで基本的な一般的なコーディングの欠陥を識別する能力。',
          softSkill: false,
        },
        {
          id: 'A0037',
          value:
            'サイバー問題を扱う外部の組織や学術機関のベストプラクティスと学んだ教訓を活用する能力。',
          softSkill: true,
        },
        {
          id: 'A0038',
          value: 'エンタープライズのパフォーマンス要件を満たすためのシステムを最適化する能力。',
          softSkill: false,
        },
        {
          id: 'A0039',
          value: 'ライフサイクルコスト見積もりの開発と更新を監督する能力。',
          softSkill: true,
        },
        {
          id: 'A0040',
          value: 'データとテスト結果を評価的結論に翻訳する能力。',
          softSkill: false,
        },
        {
          id: 'A0041',
          value:
            'データ可視化ツールの使用能力（例：Flare、HighCharts、AmCharts、D3.js、Processing、Google Visualization API、Tableau、Raphael.js）。',
          softSkill: false,
        },
        {
          id: 'A0042',
          value: 'キャリアパスの機会を開発する能力。',
          softSkill: true,
        },
        {
          id: 'A0043',
          value: 'WindowsおよびUnix/Linux環境での法医学的分析を行う能力。',
          softSkill: false,
        },
        {
          id: 'A0044',
          value: 'プログラミング言語の構造（例：ソースコードレビュー）とロジックを適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0045',
          value: 'サプライヤーおよび/または製品の信頼性を評価/確保する能力。',
          softSkill: true,
        },
        {
          id: 'A0046',
          value:
            '新興技術が法律、規制、および/または政策に与える可能性のある影響を監視し評価する能力。',
          softSkill: false,
        },
        {
          id: 'A0047',
          value:
            '安全なソフトウェア展開方法論、ツール、および実践に従って安全なソフトウェアを開発する能力。',
          softSkill: false,
        },
        {
          id: 'A0048',
          value:
            'ネットワークセキュリティアーキテクチャの概念を適用する能力（トポロジー、プロトコル、コンポーネント、原則を含む。例えば、防御の深さの適用）。',
          softSkill: false,
        },
        {
          id: 'A0049',
          value: '安全なシステム設計ツール、方法、技術を適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0050',
          value:
            'システム設計ツール、方法、技術を適用する能力、自動化されたシステム分析および設計ツールを含む。',
          softSkill: false,
        },
        {
          id: 'A0051',
          value: 'テクノロジー統合プロセスを実行する能力。',
          softSkill: false,
        },
        {
          id: 'A0052',
          value:
            'ネットワーク機器、ハブ、ルーター、スイッチ、ブリッジ、サーバー、伝送媒体、および関連ハードウェアの操作能力。',
          softSkill: false,
        },
        {
          id: 'A0053',
          value: '労働力トレンドデータの妥当性を判断する能力。',
          softSkill: true,
        },
        {
          id: 'A0054',
          value: 'インストラクショナルシステムデザイン（ISD）方法論を適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0055',
          value: '一般的なネットワークツール（例：ping、traceroute、nslookup）の操作能力。',
          softSkill: false,
        },
        {
          id: 'A0056',
          value: '取得プロセス全体でセキュリティ対策が遵守される能力。',
          softSkill: true,
        },
        {
          id: 'A0057',
          value: '対象オーディエンスに適したレベルで話題に対応するカリキュラムを調整する能力。',
          softSkill: true,
        },
        {
          id: 'A0058',
          value: 'OSコマンドラインを実行する能力（例：ipconfig、netstat、dir、nbtstat）。',
          softSkill: false,
        },
        {
          id: 'A0059',
          value: '組織のLAN/WANパスウェイを操作する能力。',
          softSkill: false,
        },
        {
          id: 'A0060',
          value: 'アーキテクチャとフレームワークを構築する能力。',
          softSkill: false,
        },
        {
          id: 'A0061',
          value: 'アーキテクチャとフレームワークを設計する能力。',
          softSkill: false,
        },
        {
          id: 'A0062',
          value: 'システムのパフォーマンスと可用性の指標または指標を監視する能力。',
          softSkill: false,
        },
        {
          id: 'A0063',
          value:
            'さまざまな電子通信システムおよび方法（例：電子メール、VOIP、IM、ウェブフォーラム、ダイレクトビデオブロードキャスト）の操作能力。',
          softSkill: false,
        },
        {
          id: 'A0064',
          value: '顧客の要求を運用能力に翻訳・解釈する能力。',
          softSkill: false,
        },
        {
          id: 'A0065',
          value: 'ネットワーク全体のトラフィックフローを監視する能力。',
          softSkill: false,
        },
        {
          id: 'A0066',
          value:
            'インテリジェンス、評価、および/または計画製品で使用されるすべてのデータを正確かつ完全に出典する能力。',
          softSkill: false,
        },
        {
          id: 'A0067',
          value: '多様で予測不可能で、挑戦的で、テンポの速い職場環境に適応し、活動する能力。',
          softSkill: true,
        },
        {
          id: 'A0068',
          value: '承認された計画開発とスタッフィングプロセスを適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0069',
          value: '協力スキルと戦略を適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0070',
          value: '批判的読解・思考スキルを適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0071',
          value: '言語と文化の専門知識を分析に応用する能力。',
          softSkill: false,
        },
        {
          id: 'A0072',
          value:
            'インテリジェンス要件を明確に表現し、調査追跡目的のための研究質問とデータ追跡変数にうまく整形する能力。',
          softSkill: true,
        },
        {
          id: 'A0073',
          value:
            'インテリジェンス要件を明確に表現し、よく構成された研究質問や情報要求に落とし込む能力。',
          softSkill: true,
        },
        {
          id: 'A0074',
          value: '他の人と効果的に協力する能力。',
          softSkill: true,
        },
        {
          id: 'A0076',
          value: 'アナリストと連携し、監視要件と重要情報の開発について協力する能力。',
          softSkill: true,
        },
        {
          id: 'A0077',
          value: '他の組織機能やサポート活動とのサイバー作戦の調整能力。',
          softSkill: false,
        },
        {
          id: 'A0078',
          value: '部下、同僚、上位の組織に情報を調整、協力し、普及する能力。',
          softSkill: false,
        },
        {
          id: 'A0079',
          value: '各組織または要素を収集計画とマトリックスに正しく適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0080',
          value:
            '情報が不完全である、または前例がない問題や状況に対して、分析的アプローチや解決策を開発または推奨する能力。',
          softSkill: false,
        },
        {
          id: 'A0081',
          value: '前例のない問題や状況に対して、計画ソリューションを開発または推奨する能力。',
          softSkill: false,
        },
        {
          id: 'A0082',
          value: '仮想チームを通じて効果的に協力する能力。',
          softSkill: true,
        },
        {
          id: 'A0083',
          value: '情報の信頼性、妥当性、関連性を評価する能力。',
          softSkill: false,
        },
        {
          id: 'A0084',
          value:
            '大量のデータ（断片的で矛盾する可能性がある）を評価、分析、統合し、高品質な融合ターゲティング/インテリジェンス製品にする能力。',
          softSkill: false,
        },
        {
          id: 'A0085',
          value: '明確に定義されていないポリシーの際に判断を行使する能力。',
          softSkill: true,
        },
        {
          id: 'A0086',
          value:
            '対象分析と収集を行い、関心の対象を特定することによってネットワークアクセスを拡大する能力。',
          softSkill: false,
        },
        {
          id: 'A0087',
          value: '顧客の意思決定ニーズに合わせて研究努力を集中する能力。',
          softSkill: true,
        },
        {
          id: 'A0088',
          value: 'ダイナミックでスピード感のある環境で効果的に機能する能力。',
          softSkill: true,
        },
        {
          id: 'A0089',
          value:
            '協調的な環境で機能する能力、組織内外の他のアナリストや専門家と継続的に相談を求め、分析的および技術的な専門知識を活用する。',
          softSkill: true,
        },
        {
          id: 'A0090',
          value: '共通のサイバー作戦関心事を持つ外部パートナーを特定する能力。',
          softSkill: true,
        },
        {
          id: 'A0091',
          value: 'インテリジェンスのギャップを特定する能力。',
          softSkill: false,
        },
        {
          id: 'A0092',
          value: '対象の脆弱性を特定/記述する能力。',
          softSkill: false,
        },
        {
          id: 'A0093',
          value: '対象の技術的搾取を行うための技術/方法を識別/記述する能力。',
          softSkill: false,
        },
        {
          id: 'A0094',
          value:
            '組織のサイバー目標に関連する法律、規制、ポリシー、およびガイダンスを解釈し適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0095',
          value: '顧客の要件を解釈し、運用行動に翻訳する能力。',
          softSkill: false,
        },
        {
          id: 'A0096',
          value: '複雑で急速に進化する概念を解釈し理解する能力。',
          softSkill: true,
        },
        {
          id: 'A0097',
          value:
            'システムの操作を監視し、トリガーやトレンドの観察、または異常な活動に対応してイベントに反応する能力。',
          softSkill: false,
        },
        {
          id: 'A0098',
          value:
            '計画チーム、調整グループ、および必要に応じてタスクフォースのメンバーとして参加する能力。',
          softSkill: true,
        },
        {
          id: 'A0099',
          value:
            'ネットワーク収集の戦術、技術、および手順を実行する能力、および復号化機能/ツールを含む。',
          softSkill: false,
        },
        {
          id: 'A0100',
          value: 'ワイヤレス収集手順を実行する能力を含む、復号化機能/ツール。',
          softSkill: false,
        },
        {
          id: 'A0101',
          value: '認識し、分析に影響を与える可能性がある認知バイアスを軽減する能力。',
          softSkill: true,
        },
        {
          id: 'A0102',
          value: '報告と分析における欺瞞を認識し、軽減する能力。',
          softSkill: false,
        },
        {
          id: 'A0103',
          value: '対象言語の資料を精度と完全性でレビューする能力。',
          softSkill: false,
        },
        {
          id: 'A0104',
          value: '適切なインプラントを選択する能力は、運用目標を達成するために重要です。',
          softSkill: false,
        },
        {
          id: 'A0105',
          value: '顧客の理解度に合わせて技術的および計画情報をカスタマイズする能力。',
          softSkill: false,
        },
        {
          id: 'A0106',
          value: '批判的に考える能力。',
          softSkill: true,
        },
        {
          id: 'A0107',
          value: '脅威アクターのように考える能力。',
          softSkill: false,
        },
        {
          id: 'A0108',
          value: '目標と効果を理解する能力。',
          softSkill: true,
        },
        {
          id: 'A0109',
          value: 'すべての情報分野を横断して複数の情報源を活用する能力。',
          softSkill: false,
        },
        {
          id: 'A0110',
          value: '情報プライバシー法の進展を監視し、組織の適応とコンプライアンスを保証する能力。',
          softSkill: true,
        },
        {
          id: 'A0111',
          value:
            '部門や事業部を横断して組織のプライバシー原則とプログラムを実施し、プライバシーの目標をセキュリティの目標と一致させる能力。',
          softSkill: true,
        },
        {
          id: 'A0112',
          value: '情報プライバシー技術の進歩を監視し、組織の適応とコンプライアンスを保証する能力。',
          softSkill: false,
        },
        {
          id: 'A0113',
          value:
            'セキュリティインシデントがプライバシー原則または特定の法的行動を要求する法的基準に違反しているかどうかを判断する能力。',
          softSkill: false,
        },
        {
          id: 'A0114',
          value: '対象に適したレベルでトピックに対応するカリキュラムを開発または調達する能力。',
          softSkill: true,
        },
        {
          id: 'A0115',
          value:
            '部門やビジネスユニット間で連携し、組織のプライバシー原則とプログラムを実施し、プライバシーの目標をセキュリティの目標と整合させる能力。',
          softSkill: true,
        },
        {
          id: 'A0116',
          value: 'サイバーセキュリティリソースを正しく効率的に優先順位付けし、割り当てる能力。',
          softSkill: true,
        },
        {
          id: 'A0117',
          value: '組織ダイナミクスの文脈で戦略、ビジネス、テクノロジーを関連付ける能力。',
          softSkill: true,
        },
        {
          id: 'A0118',
          value:
            '組織プロセスと問題解決に関連する技術、管理、およびリーダーシップの問題を理解する能力。',
          softSkill: true,
        },
        {
          id: 'A0119',
          value: 'サイバーとその組織への影響に関連する基本的な概念と問題を理解する能力。',
          softSkill: true,
        },
        {
          id: 'A0120',
          value:
            '組織の脅威環境の文脈について意味のある洞察を共有する能力は、そのリスク管理の姿勢を改善します。',
          softSkill: true,
        },
        {
          id: 'A0121',
          value: 'クラウドサービスモデルのインシデント対応を設計する能力。',
          softSkill: false,
        },
        {
          id: 'A0122',
          value:
            'より一般的でなく、より複雑なシステムの問題に対する解決策を見つける能力を設計する能力。',
          softSkill: false,
        },
        {
          id: 'A0123',
          value:
            '組織の要件にサイバーセキュリティとプライバシーの原則を適用する能力（機密性、完全性、可用性、認証、否認防止に関連）。',
          softSkill: false,
        },
        {
          id: 'A0124',
          value: '自動化されたセキュリティコントロール評価を確立し維持する能力',
          softSkill: true,
        },
        {
          id: 'A0125',
          value: '現行法に基づいたプライバシー開示声明を作成する能力。',
          softSkill: true,
        },
        {
          id: 'A0126',
          value:
            '部門、場所、施設、および潜在的にはビジネス機能をサポートするネットワークデバイスとソフトウェアの位置と構成を追跡する能力。',
          softSkill: true,
        },
        {
          id: 'A0127',
          value: '継続的な監視技術とツールを展開する能力。',
          softSkill: true,
        },
        {
          id: 'A0128',
          value:
            '侵入検知技術を使用してホストおよびネットワークベースの侵入を検出する技術を適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0129',
          value:
            '情報セキュリティ管理プロセスが戦略的および運用計画プロセスと統合されていることを保証する能力。',
          softSkill: true,
        },
        {
          id: 'A0130',
          value:
            '組織内の上級職員が、彼らの管理下にある運用と資産を支える情報とシステムの情報セキュリティを確保する能力。',
          softSkill: false,
        },
        {
          id: 'A0131',
          value:
            '組織が法律、行政命令、ポリシー、指令、指示、基準、およびガイドラインのセキュリティ要件を遵守するのを支援するために、適切に訓練された人員を確保する能力。',
          softSkill: true,
        },
        {
          id: 'A0132',
          value:
            '組織のシニアリーダーシップと協力して、リスクに対処するための包括的で組織全体にわたるホリスティックなアプローチを提供する能力。このアプローチは、組織の統合された運営の理解を深めるものである。',
          softSkill: true,
        },
        {
          id: 'A0133',
          value:
            '組織の上級リーダーシップと連携して、組織のセキュリティ関連リスクに対する戦略的な視点を提供するリスク管理戦略を開発する能力。',
          softSkill: true,
        },
        {
          id: 'A0134',
          value:
            '組織の上級リーダーシップと調整する能力は、認可担当者および組織内の他の上級リーダー間でリスク関連情報の共有を促進するために不可欠です。',
          softSkill: true,
        },
        {
          id: 'A0135',
          value:
            '組織の上級リーダーシップと連携し、組織全体でのリスク管理関連活動の監視を提供し、一貫性のある効果的なリスク受容決定を支援する能力。',
          softSkill: true,
        },
        {
          id: 'A0136',
          value:
            '組織の上級リーダーシップと調整する能力は、ミッションとビジネスの成功に必要なすべての要因を考慮して認可決定が行われるようにするために重要です。',
          softSkill: true,
        },
        {
          id: 'A0137',
          value:
            '組織の運営や資産、個人、他の組織、そして国家に対するすべてのリスク源（集約されたリスクを含む）を考慮するための組織全体のフォーラムを提供するために、組織の上級リーダーシップと調整する能力。',
          softSkill: true,
        },
        {
          id: 'A0138',
          value:
            '組織の上級リーダーシップと調整する能力は、共有責任を必要とする認可行動を含む、認可担当者間の協力とコラボレーションを促進するために重要です。',
          softSkill: true,
        },
        {
          id: 'A0139',
          value:
            '組織のミッション/ビジネス機能をサポートするための共有責任を確保し、システム、サービス、およびアプリケーションの外部プロバイダーを使用することが必要な可視性を得て、適切な意思決定権限に引き上げられるよう、組織の上級リーダーシップと調整する能力。',
          softSkill: true,
        },
        {
          id: 'A0140',
          value:
            '組織が責任を持つシステムの運用と使用から集約されたリスクに基づいて、組織のリスク姿勢を特定するために、組織の上級リーダーシップと調整する能力。',
          softSkill: true,
        },
        {
          id: 'A0141',
          value:
            '承認者およびその指定代表者と密接に連携し、組織全体のセキュリティプログラムが効果的に実施され、すべての組織システムおよび運用環境に対して適切なセキュリティが確保されるよう支援する能力。',
          softSkill: true,
        },
        {
          id: 'A0142',
          value:
            '承認者およびその指定代表者と密接に連携し、セキュリティ考慮事項がプログラミング/計画/予算編成サイクル、エンタープライズアーキテクチャ、および取得/システム開発ライフサイクルに統合されるよう支援する能力。',
          softSkill: true,
        },
        {
          id: 'A0143',
          value:
            '承認されたセキュリティプランによってカバーされ、現行の認証を持つ組織システムおよび共通コントロールを支援するために、認証官およびその指定代表者と密接に協力する能力。',
          softSkill: true,
        },
        {
          id: 'A0144',
          value:
            '承認者およびその指定代表者と密接に協力し、組織全体で必要とされるセキュリティ関連の活動が効率的で、コスト効果的かつ迅速に達成されるよう支援する能力。',
          softSkill: true,
        },
        {
          id: 'A0145',
          value:
            '承認者およびその指定代表者と密接に協力し、セキュリティ関連活動の集中報告が確実に行われるよう支援する能力。',
          softSkill: true,
        },
        {
          id: 'A0146',
          value:
            '情報の適切な使用と保護のためのルールを確立する能力を持ち、その情報が他の組織と共有されたり提供されたりした場合でもその責任を保持します。',
          softSkill: true,
        },
        {
          id: 'A0147',
          value:
            'セキュリティ計画、合意覚書または理解覚書、行動計画およびマイルストーンを承認する能力、およびシステムまたは運用環境の重要な変更が再認証を必要とするかどうかを判断する。',
          softSkill: true,
        },
        {
          id: 'A0148',
          value:
            'エンタープライズアーキテクトとシステムセキュリティエンジニアとの間の主要な連絡役として機能し、システムオーナー、共通コントロールプロバイダー、およびシステムセキュリティオフィサーと協力して、セキュリティコントロールをシステム固有のもの、ハイブリッド、または共通コントロールとして割り当てます。',
          softSkill: true,
        },
        {
          id: 'A0149',
          value:
            '能力、システムセキュリティオフィサーと密接に調整し、認可担当者、最高情報責任者、上級情報セキュリティオフィサー、およびリスク管理/リスクエグゼクティブ（機能）のための高責任者に対して、セキュリティ関連の問題（例えば、システム境界の設定；システムの弱点や不備の重大性の評価；行動計画とマイルストーン；リスク軽減アプローチ；セキュリティアラート；特定された脆弱性の潜在的な悪影響）について助言します。',
          softSkill: true,
        },
        {
          id: 'A0150',
          value: 'システムセキュリティエンジニアリング活動を行う能力（NIST SP 800-16）。',
          softSkill: true,
        },
        {
          id: 'A0151',
          value:
            'セキュリティ要件を捉え、洗練する能力と、意図的なセキュリティアーキテクチャ、設計、開発、および構成を通じて、要件がコンポーネント製品およびシステムに効果的に統合されることを確保します。',
          softSkill: true,
        },
        {
          id: 'A0152',
          value:
            'システム内のセキュリティコントロールを実装する際にベストプラクティスを適用する能力。ソフトウェアエンジニアリング方法論、システムおよびセキュリティエンジニアリングの原則、セキュアデザイン、セキュアアーキテクチャ、およびセキュアコーディング技術。',
          softSkill: true,
        },
        {
          id: 'A0153',
          value:
            'セキュリティアーキテクト、シニア情報セキュリティオフィサー、システムオーナー、共通コントロールプロバイダー、およびシステムセキュリティオフィサーとのセキュリティ関連活動を調整する能力。',
          softSkill: true,
        },
        {
          id: 'A0154',
          value:
            'システムによって採用されたり継承されたりする管理、運用、および技術的なセキュリティコントロールおよびコントロールの強化を包括的に評価する能力。これにより、セキュリティコントロールが正しく実装されているか、意図した通りに機能しているか、およびシステムのセキュリティ要件を満たすための望ましい結果を生み出しているか（つまり、セキュリティコントロールの有効性）を判断します。',
          softSkill: true,
        },
        {
          id: 'A0155',
          value:
            'システムおよびその運用環境で発見された弱点や不備の重大度を評価する能力と、特定された脆弱性に対処するための是正措置を推奨する能力。',
          softSkill: true,
        },
        {
          id: 'A0156',
          value: '最終セキュリティ評価レポートを準備する能力。評価からの結果と所見を含む。',
          softSkill: true,
        },
        {
          id: 'A0157',
          value:
            'セキュリティプランを評価する能力は、そのプランがシステムのために述べられたセキュリティ要件を満たすセキュリティコントロールのセットを提供することを確認するのに役立ちます。',
          softSkill: true,
        },
        {
          id: 'A0158',
          value:
            '契約で機能およびセキュリティ要件が適切に対処され、契約者が契約で述べられた機能およびセキュリティ要件を満たしていることを確保する能力。',
          softSkill: true,
        },
        {
          id: 'A0159',
          value:
            'ネットワークツール（例：Nslookup、Ping、Traceroute）によって収集された情報を解釈する能力。',
          softSkill: false,
        },
        {
          id: 'A0160',
          value:
            '拡張エンタープライズ全体で情報ニーズとインテリジェンス収集要件を翻訳、追跡、優先順位付けする能力。',
          softSkill: false,
        },
        {
          id: 'A0161',
          value:
            '情報セキュリティ要件を調達プロセスに統合する能力；適用可能なベースラインセキュリティコントロールをセキュリティ要件の情報源の一つとして使用する；堅牢なソフトウェア品質管理プロセスを確保する；そして、重要なシステム要素のための複数の情報源（例えば、配送ルート）を確立する。',
          softSkill: true,
        },
        {
          id: 'A0162',
          value:
            '情報システムのセキュリティを確保する能力、調達担当者、法務顧問、その他の適切なアドバイザーやステークホルダーがシステムの概念定義/レビューから意思決定に参加し、システムのライフサイクル全体を通じて各マイルストーンの決定に関与または承認しています。',
          softSkill: true,
        },
        {
          id: 'A0162',
          value:
            'コミュニケーションセキュリティ（COMSEC）環境と階層のユニークな側面を認識する能力。',
          softSkill: true,
        },
        {
          id: 'A0163',
          value: '通信セキュリティ（COMSEC）の用語、ガイドライン、手順を解釈する能力。',
          softSkill: true,
        },
        {
          id: 'A0164',
          value: '指定された通信セキュリティ（COMSEC）担当者の役割と責任を識別する能力。',
          softSkill: true,
        },
        {
          id: 'A0165',
          value: '通信セキュリティ（COMSEC）資料の会計、管理、および使用手順を管理する能力。',
          softSkill: true,
        },
        {
          id: 'A0166',
          value:
            'コミュニケーションセキュリティ（COMSEC）インシデントの種類を識別し、それらがどのように報告されるかの能力',
          softSkill: false,
        },
        {
          id: 'A0167',
          value:
            'コミュニケーション・セキュリティ（COMSEC）資料およびアカウントの監査の重要性を認識する能力。',
          softSkill: true,
        },
        {
          id: 'A0168',
          value: 'In-Process会計の要件を特定する能力（COMSEC）',
          softSkill: true,
        },
        {
          id: 'A0170',
          value:
            '情報通信技術を用いて設計されたが、システムセキュリティの考慮が欠けている重要インフラシステムを識別する能力。',
          softSkill: false,
        },
        {
          id: 'A0171',
          value: 'トレーニングと教育ニーズ評価を行う能力。',
          softSkill: true,
        },
        {
          id: 'A0172',
          value:
            '内部のローカルエリアネットワーク（LAN）を他の信頼できないネットワークから分離する物理的または論理的なサブネットワークを設定する能力。',
          softSkill: false,
        },
        {
          id: 'A0173',
          value:
            'システムや環境の変更がリスク許容度に対する残存リスクを変更する可能性を認識する能力。',
          softSkill: true,
        },
        {
          id: 'A0174',
          value:
            'TORネットワークを使用してダークウェブを見つけ、ナビゲートし、市場やフォーラムを探す能力。',
          softSkill: false,
        },
        {
          id: 'A0175',
          value: '複数のオペレーティングシステムプラットフォームでデジタルメディアを調査する能力。',
          softSkill: false,
        },
        {
          id: 'A0176',
          value:
            'データベースを維持する能力。（例：バックアップ、復元、データ削除、トランザクションログファイルなど）。',
          softSkill: false,
        },
      ],
    },
  ],
};

export interface WorkRole {
  id: string;
  name: string;
  specialityArea: string;
  category: string;
  description: string;
  tasks: string[];
  knowledge: string[];
  skills: string[];
  abilities: string[];
}

export const NiceWorkRoles: WorkRole[] = [
  {
    id: 'SP-RSK-001',
    name: '認可官／指定代表',
    specialityArea: 'リスク管理 (RSK)',
    category: 'セキュアにプロビジョニング（SP）',
    description:
      '組織の運営（ミッション、機能、イメージ、または評判を含む）、組織の資産、個人、他の組織、および国（CNSSI 4009）に対する受け入れ可能なリスクレベルで情報システムの運用を正式に引き受ける権限を持つ高級職員または幹部。',
    tasks: ['T0145', 'T0221', 'T0371', 'T0495'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0013',
      'K0019',
      'K0027',
      'K0028',
      'K0037',
      'K0038',
      'K0040',
      'K0044',
      'K0048',
      'K0049',
      'K0054',
      'K0059',
      'K0070',
      'K0084',
      'K0089',
      'K0101',
      'K0126',
      'K0146',
      'K0168',
      'K0169',
      'K0170',
      'K0179',
      'K0199',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0267',
      'K0295',
      'K0322',
      'K0342',
      'K0622',
      'K0624',
    ],
    skills: ['S0034', 'S0367'],
    abilities: [
      'A0028',
      'A0033',
      'A0077',
      'A0090',
      'A0094',
      'A0111',
      'A0117',
      'A0118',
      'A0119',
      'A0123',
      'A0170',
    ],
  },
  {
    id: 'SP-RSK-002',
    name: 'セキュリティコントロールアセッサー',
    specialityArea: 'リスク管理 (RSK)',
    category: 'セキュアにプロビジョニングする (SP)',
    description:
      '情報技術（IT）システムによって採用されたり継承されたりする管理、運用、および技術的セキュリティコントロールおよびコントロールの強化に対して、独立した包括的な評価を実施し、コントロールの全体的な有効性（NIST SP 800-37で定義されているように）を判断します。',
    tasks: [
      'T0145',
      'T0177',
      'T0178',
      'T0181',
      'T0184',
      'T0205',
      'T0221',
      'T0243',
      'T0244',
      'T0251',
      'T0255',
      'T0264',
      'T0265',
      'T0268',
      'T0272',
      'T0275',
      'T0277',
      'T0309',
      'T0344',
      'T0371',
      'T0495',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0008',
      'K0009',
      'K0010',
      'K0011',
      'K0013',
      'K0018',
      'K0019',
      'K0021',
      'K0024',
      'K0026',
      'K0027',
      'K0028',
      'K0029',
      'K0037',
      'K0038',
      'K0040',
      'K0044',
      'K0048',
      'K0049',
      'K0054',
      'K0056',
      'K0059',
      'K0070',
      'K0084',
      'K0089',
      'K0098',
      'K0100',
      'K0101',
      'K0126',
      'K0146',
      'K0168',
      'K0169',
      'K0170',
      'K0179',
      'K0199',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0267',
      'K0287',
      'K0322',
      'K0342',
      'K0622',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0006',
      'S0027',
      'S0034',
      'S0038',
      'S0073',
      'S0078',
      'S0097',
      'S0100',
      'S0110',
      'S0111',
      'S0112',
      'S0115',
      'S0120',
      'S0124',
      'S0128',
      'S0134',
      'S0135',
      'S0136',
      'S0137',
      'S0138',
      'S0141',
      'S0145',
      'S0147',
      'S0171',
      'S0172',
      'S0173',
      'S0174',
      'S0175',
      'S0176',
      'S0177',
      'S0184',
      'S0232',
      'S0233',
      'S0234',
      'S0235',
      'S0236',
      'S0237',
      'S0238',
      'S0239',
      'S0240',
      'S0241',
      'S0242',
      'S0243',
      'S0244',
      'S0248',
      'S0249',
      'S0250',
      'S0251',
      'S0252',
      'S0254',
      'S0271',
      'S0273',
      'S0278',
      'S0279',
      'S0280',
      'S0281',
      'S0296',
      'S0304',
      'S0305',
      'S0306',
      'S0307',
      'S0325',
      'S0329',
      'S0332',
      'S0367',
      'S0370',
      'S0374',
    ],
    abilities: [
      'A0001',
      'A0011',
      'A0012',
      'A0013',
      'A0014',
      'A0015',
      'A0016',
      'A0018',
      'A0019',
      'A0023',
      'A0026',
      'A0030',
      'A0035',
      'A0036',
      'A0040',
      'A0056',
      'A0069',
      'A0070',
      'A0082',
      'A0083',
      'A0084',
      'A0085',
      'A0086',
      'A0087',
      'A0088',
      'A0089',
      'A0090',
      'A0091',
      'A0092',
      'A0093',
      'A0094',
      'A0095',
      'A0096',
      'A0098',
      'A0101',
      'A0106',
      'A0108',
      'A0109',
      'A0111',
      'A0112',
      'A0114',
      'A0115',
      'A0116',
      'A0117',
      'A0118',
      'A0119',
      'A0123',
      'A0170',
    ],
  },
  {
    id: 'SP-DEV-001',
    name: 'ソフトウェア開発者',
    specialityArea: 'ソフトウェア開発（DEV）',
    category: 'セキュアにプロビジョニング（SP）',
    description:
      '開発、作成、維持、および新しい（または既存のものを修正）コンピュータアプリケーション、ソフトウェア、または特殊ユーティリティプログラムを書く/コードする。',
    tasks: [
      'T0009',
      'T0011',
      'T0013',
      'T0014',
      'T0022',
      'T0026',
      'T0034',
      'T0040',
      'T0046',
      'T0057',
      'T0077',
      'T0100',
      'T0111',
      'T0117',
      'T0118',
      'T0171',
      'T0176',
      'T0181',
      'T0189',
      'T0217',
      'T0228',
      'T0236',
      'T0267',
      'T0303',
      'T0311',
      'T0324',
      'T0337',
      'T0416',
      'T0417',
      'T0436',
      'T0455',
      'T0500',
      'T0553',
      'T0554',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0014',
      'K0016',
      'K0027',
      'K0028',
      'K0039',
      'K0044',
      'K0050',
      'K0051',
      'K0060',
      'K0066',
      'K0068',
      'K0070',
      'K0073',
      'K0079',
      'K0080',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0105',
      'K0139',
      'K0140',
      'K0152',
      'K0153',
      'K0154',
      'K0170',
      'K0179',
      'K0199',
      'K0202',
      'K0260',
      'K0261',
      'K0262',
      'K0263',
      'K0322',
      'K0332',
      'K0342',
      'K0343',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0014',
      'S0017',
      'S0019',
      'S0022',
      'S0031',
      'S0034',
      'S0060',
      'S0135',
      'S0138',
      'S0149',
      'S0174',
      'S0175',
      'S0367',
    ],
    abilities: ['A0007', 'A0021', 'A0047', 'A0123', 'A0170'],
  },
  {
    id: 'SP-DEV-002',
    name: 'セキュアソフトウェアアセッサー',
    specialityArea: 'ソフトウェア開発（DEV）',
    category: '安全にプロビジョニングする (SP)',
    description:
      '新規または既存のコンピュータアプリケーション、ソフトウェア、または特殊なユーティリティプログラムのセキュリティを分析し、実行可能な結果を提供します。',
    tasks: [
      'T0013',
      'T0014',
      'T0022',
      'T0038',
      'T0040',
      'T0100',
      'T0111',
      'T0117',
      'T0118',
      'T0171',
      'T0181',
      'T0217',
      'T0228',
      'T0236',
      'T0266',
      'T0311',
      'T0324',
      'T0337',
      'T0424',
      'T0428',
      'T0436',
      'T0456',
      'T0457',
      'T0516',
      'T0554',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0014',
      'K0016',
      'K0027',
      'K0028',
      'K0039',
      'K0044',
      'K0050',
      'K0051',
      'K0060',
      'K0066',
      'K0068',
      'K0070',
      'K0073',
      'K0079',
      'K0080',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0105',
      'K0139',
      'K0140',
      'K0152',
      'K0153',
      'K0154',
      'K0170',
      'K0178',
      'K0179',
      'K0199',
      'K0202',
      'K0260',
      'K0261',
      'K0262',
      'K0263',
      'K0322',
      'K0342',
      'K0343',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0022',
      'S0031',
      'S0034',
      'S0083',
      'S0135',
      'S0138',
      'S0174',
      'S0175',
      'S0367',
    ],
    abilities: ['A0021', 'A0123', 'A0170'],
  },
  {
    id: 'SP-ARC-001',
    name: 'エンタープライズアーキテクト',
    specialityArea: 'システムアーキテクチャ (ARC)',
    category: '安全にプロビジョニングする (SP)',
    description:
      '企業のミッションニーズをサポートするためのビジネス、システム、および情報プロセスを開発・維持する。ベースラインとターゲットアーキテクチャを説明する情報技術（IT）ルールおよび要件を開発する。',
    tasks: [
      'T0051',
      'T0084',
      'T0090',
      'T0108',
      'T0196',
      'T0205',
      'T0307',
      'T0314',
      'T0328',
      'T0338',
      'T0427',
      'T0440',
      'T0448',
      'T0473',
      'T0517',
      'T0521',
      'T0542',
      'T0555',
      'T0557',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0024',
      'K0027',
      'K0028',
      'K0030',
      'K0035',
      'K0037',
      'K0043',
      'K0044',
      'K0052',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0074',
      'K0075',
      'K0082',
      'K0091',
      'K0093',
      'K0102',
      'K0170',
      'K0179',
      'K0180',
      'K0198',
      'K0200',
      'K0203',
      'K0207',
      'K0211',
      'K0212',
      'K0214',
      'K0227',
      'K0240',
      'K0264',
      'K0275',
      'K0286',
      'K0287',
      'K0291',
      'K0293',
      'K0299',
      'K0322',
      'K0323',
      'K0325',
      'K0326',
      'K0332',
      'K0333',
      'K0487',
      'K0516',
    ],
    skills: ['S0005', 'S0024', 'S0027', 'S0050', 'S0060', 'S0122', 'S0367', 'S0374'],
    abilities: ['A0008', 'A0015', 'A0027', 'A0038', 'A0051', 'A0060', 'A0123', 'A0170', 'A0172'],
  },
  {
    id: 'SP-ARC-002',
    name: 'セキュリティアーキテクト',
    specialityArea: 'システムアーキテクチャ (ARC)',
    category: 'セキュアにプロビジョニングする (SP)',
    description:
      '利害関係者のセキュリティ要件が組織の使命とビジネスプロセスを保護するために必要であり、エンタープライズアーキテクチャのすべての側面（参照モデル、セグメントおよびソリューションアーキテクチャ、およびそれらの使命とビジネスプロセスをサポートする結果として生じるシステム）で適切に対処されていることを保証します。',
    tasks: [
      'T0050',
      'T0051',
      'T0071',
      'T0082',
      'T0084',
      'T0090',
      'T0108',
      'T0177',
      'T0196',
      'T0203',
      'T0205',
      'T0268',
      'T0307',
      'T0314',
      'T0328',
      'T0338',
      'T0427',
      'T0448',
      'T0473',
      'T0484',
      'T0542',
      'T0556',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0008',
      'K0009',
      'K0010',
      'K0011',
      'K0012',
      'K0013',
      'K0015',
      'K0018',
      'K0019',
      'K0024',
      'K0026',
      'K0027',
      'K0030',
      'K0035',
      'K0036',
      'K0037',
      'K0043',
      'K0044',
      'K0052',
      'K0055',
      'K0056',
      'K0057',
      'K0059',
      'K0060',
      'K0061',
      'K0063',
      'K0071',
      'K0074',
      'K0082',
      'K0091',
      'K0092',
      'K0093',
      'K0102',
      'K0170',
      'K0180',
      'K0198',
      'K0200',
      'K0202',
      'K0211',
      'K0212',
      'K0214',
      'K0227',
      'K0240',
      'K0260',
      'K0261',
      'K0262',
      'K0264',
      'K0275',
      'K0277',
      'K0286',
      'K0287',
      'K0291',
      'K0293',
      'K0320',
      'K0322',
      'K0323',
      'K0325',
      'K0326',
      'K0332',
      'K0333',
      'K0336',
      'K0374',
      'K0565',
    ],
    skills: [
      'S0005',
      'S0022',
      'S0024',
      'S0027',
      'S0050',
      'S0059',
      'S0061',
      'S0076',
      'S0116',
      'S0122',
      'S0138',
      'S0139',
      'S0152',
      'S0168',
      'S0170',
      'S0367',
      'S0374',
    ],
    abilities: [
      'A0008',
      'A0014',
      'A0015',
      'A0027',
      'A0038',
      'A0048',
      'A0049',
      'A0050',
      'A0061',
      'A0123',
      'A0148',
      'A0149',
      'A0170',
      'A0172',
    ],
  },
  {
    id: 'SP-TRD-001',
    name: '研究開発スペシャリスト',
    specialityArea: 'テクノロジー研究開発 (TRD)',
    category: 'セキュアにプロビジョニングする (SP)',
    description:
      'ソフトウェアおよびシステムエンジニアリングを行い、サイバーセキュリティが完全に統合されるように新しい機能を開発するためのソフトウェアシステムの研究を行います。サイバースペースシステムの潜在的な脆弱性を評価するために、包括的な技術研究を行います。',
    tasks: [
      'T0064',
      'T0249',
      'T0250',
      'T0283',
      'T0284',
      'T0327',
      'T0329',
      'T0409',
      'T0410',
      'T0411',
      'T0413',
      'T0547',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0019',
      'K0059',
      'K0090',
      'K0126',
      'K0169',
      'K0170',
      'K0171',
      'K0172',
      'K0174',
      'K0175',
      'K0176',
      'K0179',
      'K0202',
      'K0209',
      'K0267',
      'K0268',
      'K0269',
      'K0271',
      'K0272',
      'K0288',
      'K0296',
      'K0310',
      'K0314',
      'K0321',
      'K0342',
      'K0499',
    ],
    skills: ['S0005', 'S0017', 'S0072', 'S0140', 'S0148', 'S0172'],
    abilities: ['A0001', 'A0018', 'A0019', 'A0170'],
  },
  {
    id: 'SP-SRP-001',
    name: 'システム要件プランナー',
    specialityArea: 'システム要件計画 (SRP)',
    category: 'セキュアにプロビジョニング（SP）',
    description: '顧客と相談して機能要件を評価し、機能要件を技術的な解決策に変換します。',
    tasks: [
      'T0033',
      'T0039',
      'T0045',
      'T0052',
      'T0062',
      'T0127',
      'T0156',
      'T0174',
      'T0191',
      'T0235',
      'T0273',
      'T0300',
      'T0313',
      'T0325',
      'T0334',
      'T0454',
      'T0463',
      'T0497',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0008',
      'K0012',
      'K0018',
      'K0019',
      'K0032',
      'K0035',
      'K0038',
      'K0043',
      'K0044',
      'K0045',
      'K0047',
      'K0055',
      'K0056',
      'K0059',
      'K0060',
      'K0061',
      'K0063',
      'K0066',
      'K0067',
      'K0073',
      'K0074',
      'K0086',
      'K0087',
      'K0090',
      'K0091',
      'K0093',
      'K0101',
      'K0102',
      'K0126',
      'K0163',
      'K0164',
      'K0168',
      'K0169',
      'K0170',
      'K0180',
      'K0200',
      'K0267',
      'K0287',
      'K0325',
      'K0332',
      'K0333',
      'K0622',
    ],
    skills: ['S0005', 'S0006', 'S0008', 'S0010', 'S0050', 'S0134', 'S0367'],
    abilities: ['A0064', 'A0123', 'A0170'],
  },
  {
    id: 'SP-TST-001',
    name: 'システムテストおよび評価スペシャリスト',
    specialityArea: 'テストと評価 (TST)',
    category: 'セキュアリー・プロビジョン (SP)',
    description:
      '計画、準備、およびシステムのテストを実行して、仕様および要件に対する結果を評価し、テスト結果を分析/報告します。',
    tasks: [
      'T0058',
      'T0080',
      'T0125',
      'T0143',
      'T0257',
      'T0274',
      'T0393',
      'T0426',
      'T0511',
      'T0512',
      'T0513',
      'T0539',
      'T0540',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0027',
      'K0028',
      'K0037',
      'K0044',
      'K0057',
      'K0088',
      'K0091',
      'K0102',
      'K0126',
      'K0139',
      'K0169',
      'K0170',
      'K0179',
      'K0199',
      'K0203',
      'K0212',
      'K0250',
      'K0260',
      'K0261',
      'K0262',
      'K0287',
      'K0332',
    ],
    skills: [
      'S0015',
      'S0021',
      'S0026',
      'S0030',
      'S0048',
      'S0060',
      'S0061',
      'S0082',
      'S0104',
      'S0107',
      'S0110',
      'S0112',
      'S0115',
      'S0117',
      'S0367',
    ],
    abilities: ['A0026', 'A0030', 'A0040', 'A0123'],
  },
  {
    id: 'SP-SYS-001',
    name: '情報システムセキュリティ開発者',
    specialityArea: 'システム開発（SYS）',
    category: '安全にプロビジョニングする (SP)',
    description:
      '設計、開発、テスト、およびシステム開発ライフサイクル全体での情報システムのセキュリティを評価します。',
    tasks: [
      'T0012',
      'T0015',
      'T0018',
      'T0019',
      'T0021',
      'T0032',
      'T0053',
      'T0055',
      'T0056',
      'T0061',
      'T0069',
      'T0070',
      'T0076',
      'T0078',
      'T0105',
      'T0107',
      'T0109',
      'T0119',
      'T0122',
      'T0124',
      'T0181',
      'T0201',
      'T0205',
      'T0228',
      'T0231',
      'T0242',
      'T0269',
      'T0270',
      'T0271',
      'T0272',
      'T0304',
      'T0326',
      'T0359',
      'T0446',
      'T0449',
      'T0466',
      'T0509',
      'T0518',
      'T0527',
      'T0541',
      'T0544',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0018',
      'K0024',
      'K0027',
      'K0028',
      'K0030',
      'K0032',
      'K0035',
      'K0036',
      'K0044',
      'K0045',
      'K0049',
      'K0050',
      'K0052',
      'K0055',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0065',
      'K0066',
      'K0067',
      'K0073',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0087',
      'K0090',
      'K0091',
      'K0093',
      'K0102',
      'K0126',
      'K0139',
      'K0169',
      'K0170',
      'K0179',
      'K0180',
      'K0200',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0276',
      'K0287',
      'K0297',
      'K0308',
      'K0322',
      'K0325',
      'K0332',
      'K0333',
      'K0336',
    ],
    skills: [
      'S0001',
      'S0022',
      'S0023',
      'S0024',
      'S0031',
      'S0034',
      'S0036',
      'S0085',
      'S0145',
      'S0160',
      'S0367',
    ],
    abilities: [
      'A0001',
      'A0008',
      'A0012',
      'A0013',
      'A0015',
      'A0019',
      'A0026',
      'A0040',
      'A0048',
      'A0049',
      'A0050',
      'A0056',
      'A0061',
      'A0074',
      'A0089',
      'A0098',
      'A0108',
      'A0119',
      'A0123',
      'A0170',
    ],
  },
  {
    id: 'SP-SYS-002',
    name: 'システム開発者',
    specialityArea: 'システム開発（SYS）',
    category: '安全にプロビジョニングする (SP)',
    description:
      '設計、開発、テスト、およびシステム開発ライフサイクルを通じて情報システムを評価します。',
    tasks: [
      'T0012',
      'T0021',
      'T0053',
      'T0056',
      'T0061',
      'T0067',
      'T0070',
      'T0107',
      'T0109',
      'T0119',
      'T0181',
      'T0201',
      'T0205',
      'T0228',
      'T0242',
      'T0304',
      'T0326',
      'T0350',
      'T0358',
      'T0359',
      'T0378',
      'T0406',
      'T0447',
      'T0449',
      'T0464',
      'T0466',
      'T0480',
      'T0488',
      'T0518',
      'T0528',
      'T0538',
      'T0541',
      'T0544',
      'T0558',
      'T0559',
      'T0560',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0018',
      'K0024',
      'K0027',
      'K0028',
      'K0030',
      'K0032',
      'K0035',
      'K0036',
      'K0044',
      'K0045',
      'K0049',
      'K0050',
      'K0052',
      'K0055',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0065',
      'K0066',
      'K0067',
      'K0073',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0087',
      'K0090',
      'K0091',
      'K0093',
      'K0102',
      'K0126',
      'K0139',
      'K0169',
      'K0170',
      'K0179',
      'K0180',
      'K0200',
      'K0203',
      'K0207',
      'K0212',
      'K0227',
      'K0260',
      'K0261',
      'K0262',
      'K0276',
      'K0287',
      'K0297',
      'K0308',
      'K0322',
      'K0325',
      'K0332',
      'K0333',
      'K0336',
    ],
    skills: [
      'S0018',
      'S0022',
      'S0023',
      'S0024',
      'S0025',
      'S0031',
      'S0034',
      'S0036',
      'S0060',
      'S0085',
      'S0097',
      'S0136',
      'S0145',
      'S0146',
      'S0160',
      'S0367',
    ],
    abilities: ['A0123', 'A0170'],
  },
  {
    id: 'OM-DTA-001',
    name: 'データベース管理者',
    specialityArea: 'データ管理（DTA）',
    category: '運用と保守 (OM)',
    description:
      'データベースおよび/またはデータ管理システムを管理し、データの安全な保存、クエリ、保護、および利用を可能にします。',
    tasks: [
      'T0008',
      'T0137',
      'T0139',
      'T0140',
      'T0146',
      'T0152',
      'T0162',
      'T0210',
      'T0305',
      'T0306',
      'T0330',
      'T0422',
      'T0459',
      'T0490',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0020',
      'K0021',
      'K0022',
      'K0023',
      'K0025',
      'K0031',
      'K0056',
      'K0060',
      'K0065',
      'K0069',
      'K0083',
      'K0097',
      'K0197',
      'K0260',
      'K0261',
      'K0262',
      'K0277',
      'K0278',
      'K0287',
      'K0420',
    ],
    skills: ['S0002', 'S0013', 'S0037', 'S0042', 'S0045'],
    abilities: ['A0176'],
  },
  {
    id: 'OM-DTA-002',
    name: 'データアナリスト',
    specialityArea: 'データ管理（DTA）',
    category: '運用と保守 (OM)',
    description:
      '複数の異なるソースからのデータを調査し、セキュリティとプライバシーの洞察を提供することを目的としています。モデリング、データマイニング、および研究目的で使用される複雑な企業規模のデータセットのためのカスタムアルゴリズム、ワークフロープロセス、およびレイアウトを設計および実装します。',
    tasks: [
      'T0007',
      'T0008',
      'T0068',
      'T0146',
      'T0195',
      'T0210',
      'T0342',
      'T0347',
      'T0349',
      'T0351',
      'T0353',
      'T0361',
      'T0366',
      'T0381',
      'T0382',
      'T0383',
      'T0385',
      'T0392',
      'T0402',
      'T0403',
      'T0404',
      'T0405',
      'T0460',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0016',
      'K0020',
      'K0022',
      'K0023',
      'K0025',
      'K0031',
      'K0051',
      'K0052',
      'K0056',
      'K0060',
      'K0065',
      'K0068',
      'K0069',
      'K0083',
      'K0095',
      'K0129',
      'K0139',
      'K0140',
      'K0193',
      'K0197',
      'K0229',
      'K0236',
      'K0238',
      'K0325',
      'K0420',
    ],
    skills: [
      'S0013',
      'S0017',
      'S0028',
      'S0029',
      'S0037',
      'S0060',
      'S0088',
      'S0089',
      'S0094',
      'S0095',
      'S0103',
      'S0106',
      'S0109',
      'S0113',
      'S0114',
      'S0118',
      'S0119',
      'S0123',
      'S0125',
      'S0126',
      'S0127',
      'S0129',
      'S0130',
      'S0160',
      'S0202',
      'S0369',
    ],
    abilities: ['A0029', 'A0035', 'A0036', 'A0041', 'A0066'],
  },
  {
    id: 'OM-KMG-001',
    name: 'ナレッジマネージャー',
    specialityArea: 'ナレッジマネジメント (KMG)',
    category: '運用と保守 (OM)',
    description:
      '組織が知的資本と情報コンテンツを特定、文書化、アクセスするためのプロセスとツールの管理と運営を担当します。',
    tasks: ['T0037', 'T0060', 'T0154', 'T0185', 'T0209', 'T0339', 'T0421', 'T0452', 'T0524'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0013',
      'K0094',
      'K0095',
      'K0096',
      'K0146',
      'K0194',
      'K0195',
      'K0228',
      'K0260',
      'K0261',
      'K0262',
      'K0283',
      'K0287',
      'K0315',
      'K0338',
      'K0420',
    ],
    skills: ['S0011', 'S0012', 'S0049', 'S0055'],
    abilities: ['A0002'],
  },
  {
    id: 'OM-STS-001',
    name: 'テクニカルサポートスペシャリスト',
    specialityArea: 'カスタマーサービスとテクニカルサポート (STS)',
    category: '運用と保守 (OM)',
    description:
      '顧客がクライアントレベルのハードウェアおよびソフトウェアを使用する際に必要とする支援を提供し、確立されたまたは承認された組織プロセスコンポーネント（例えば、適用される場合はマスターインシデント管理計画）に従って行います。',
    tasks: [
      'T0125',
      'T0237',
      'T0308',
      'T0315',
      'T0331',
      'T0468',
      'T0482',
      'T0491',
      'T0494',
      'T0496',
      'T0502',
      'T0530',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0053',
      'K0088',
      'K0109',
      'K0114',
      'K0116',
      'K0194',
      'K0224',
      'K0237',
      'K0242',
      'K0247',
      'K0260',
      'K0261',
      'K0262',
      'K0287',
      'K0292',
      'K0294',
      'K0302',
      'K0317',
      'K0330',
    ],
    skills: ['S0039', 'S0058', 'S0142', 'S0159', 'S0365'],
    abilities: ['A0025', 'A0034', 'A0122'],
  },
  {
    id: 'OM-NET-001',
    name: 'ネットワーク運用スペシャリスト',
    specialityArea: 'ネットワークサービス（NET）',
    category: '運用と保守 (OM)',
    description:
      '計画、実装、およびネットワークサービス/システムの運用を行い、ハードウェアおよび仮想環境を含む。',
    tasks: [
      'T0035',
      'T0065',
      'T0081',
      'T0121',
      'T0125',
      'T0126',
      'T0129',
      'T0153',
      'T0160',
      'T0200',
      'T0232',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0010',
      'K0011',
      'K0029',
      'K0038',
      'K0049',
      'K0050',
      'K0053',
      'K0061',
      'K0071',
      'K0076',
      'K0093',
      'K0104',
      'K0108',
      'K0111',
      'K0113',
      'K0135',
      'K0136',
      'K0137',
      'K0138',
      'K0159',
      'K0160',
      'K0179',
      'K0180',
      'K0200',
      'K0201',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0274',
      'K0287',
      'K0332',
      'K0622',
    ],
    skills: [
      'S0004',
      'S0035',
      'S0040',
      'S0041',
      'S0056',
      'S0077',
      'S0079',
      'S0084',
      'S0150',
      'S0162',
      'S0170',
    ],
    abilities: ['A0052', 'A0055', 'A0058', 'A0059', 'A0062', 'A0063', 'A0065', 'A0159'],
  },
  {
    id: 'OM-ADM-001',
    name: 'システム管理者',
    specialityArea: 'システム管理（ADM）',
    category: '運用と保守 (OM)',
    description:
      'システム全体またはシステムの特定のコンポーネント（例：ハードウェアとソフトウェアのインストール、設定、更新；ユーザーアカウントの設定と管理；バックアップとリカバリー作業の監督または実施；運用と技術のセキュリティコントロールの実装；組織のセキュリティポリシーと手順への準拠）の設定と維持を担当します。',
    tasks: [
      'T0029',
      'T0054',
      'T0063',
      'T0136',
      'T0144',
      'T0186',
      'T0207',
      'T0418',
      'T0431',
      'T0435',
      'T0458',
      'T0461',
      'T0498',
      'T0501',
      'T0507',
      'T0514',
      'T0515',
      'T0531',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0049',
      'K0050',
      'K0053',
      'K0064',
      'K0077',
      'K0088',
      'K0100',
      'K0103',
      'K0104',
      'K0117',
      'K0130',
      'K0158',
      'K0167',
      'K0179',
      'K0260',
      'K0261',
      'K0262',
      'K0274',
      'K0280',
      'K0289',
      'K0318',
      'K0332',
      'K0346',
    ],
    skills: [
      'S0016',
      'S0033',
      'S0043',
      'S0073',
      'S0076',
      'S0111',
      'S0143',
      'S0144',
      'S0151',
      'S0153',
      'S0154',
      'S0155',
      'S0157',
      'S0158',
    ],
    abilities: ['A0025', 'A0027', 'A0034', 'A0055', 'A0062', 'A0074', 'A0088', 'A0123', 'A0124'],
  },
  {
    id: 'OM-ANA-001',
    name: 'システムセキュリティアナリスト',
    specialityArea: 'システム分析 (ANA)',
    category: '運用と保守 (OM)',
    description: 'システムセキュリティの統合、テスト、運用、保守の分析と開発を担当します。',
    tasks: [
      'T0015',
      'T0016',
      'T0017',
      'T0085',
      'T0086',
      'T0088',
      'T0123',
      'T0128',
      'T0169',
      'T0177',
      'T0187',
      'T0194',
      'T0202',
      'T0205',
      'T0243',
      'T0309',
      'T0344',
      'T0462',
      'T0469',
      'T0470',
      'T0475',
      'T0477',
      'T0485',
      'T0489',
      'T0492',
      'T0499',
      'T0504',
      'T0508',
      'T0526',
      'T0545',
      'T0548',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0018',
      'K0019',
      'K0024',
      'K0035',
      'K0036',
      'K0040',
      'K0044',
      'K0049',
      'K0052',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0075',
      'K0082',
      'K0093',
      'K0102',
      'K0179',
      'K0180',
      'K0200',
      'K0203',
      'K0227',
      'K0260',
      'K0261',
      'K0262',
      'K0263',
      'K0266',
      'K0267',
      'K0275',
      'K0276',
      'K0281',
      'K0284',
      'K0285',
      'K0287',
      'K0290',
      'K0297',
      'K0322',
      'K0333',
      'K0339',
    ],
    skills: ['S0024', 'S0027', 'S0031', 'S0036', 'S0060', 'S0141', 'S0147', 'S0167', 'S0367'],
    abilities: ['A0015', 'A0123'],
  },
  {
    id: 'OV-LGA-001',
    name: 'サイバー法務顧問',
    specialityArea: '法律相談と支援 (LGA)',
    category: '監督と統治 (OV)',
    description:
      'サイバー法に関連する適切なトピックについての法的アドバイスと推奨事項を提供します。',
    tasks: [
      'T0006',
      'T0098',
      'T0102',
      'T0131',
      'T0220',
      'T0419',
      'T0434',
      'T0465',
      'T0474',
      'T0476',
      'T0478',
      'T0487',
      'T0522',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0017',
      'K0059',
      'K0107',
      'K0157',
      'K0261',
      'K0262',
      'K0267',
      'K0312',
      'K0316',
      'K0341',
      'K0615',
    ],
    skills: ['S0356'],
    abilities: ['A0046'],
  },
  {
    id: 'OV-LGA-002',
    name: 'プライバシー責任者/プライバシー遵守マネージャー',
    specialityArea: '法的助言と擁護 (LGA)',
    category: '監督と統治 (OV)',
    description:
      'プライバシー コンプライアンス プログラムとプライバシー プログラム スタッフを開発・監督し、プライバシーとセキュリティのエグゼクティブおよびそのチームのプライバシー コンプライアンス、ガバナンス/ポリシー、およびインシデント対応のニーズをサポートします。',
    tasks: [
      'T0003',
      'T0004',
      'T0029',
      'T0032',
      'T0066',
      'T0098',
      'T0099',
      'T0131',
      'T0133',
      'T0188',
      'T0381',
      'T0384',
      'T0478',
      'T0861',
      'T0862',
      'T0863',
      'T0864',
      'T0865',
      'T0866',
      'T0867',
      'T0868',
      'T0869',
      'T0870',
      'T0871',
      'T0872',
      'T0873',
      'T0874',
      'T0875',
      'T0876',
      'T0877',
      'T0878',
      'T0879',
      'T0880',
      'T0881',
      'T0882',
      'T0883',
      'T0884',
      'T0885',
      'T0886',
      'T0887',
      'T0888',
      'T0889',
      'T0890',
      'T0891',
      'T0892',
      'T0893',
      'T0894',
      'T0895',
      'T0896',
      'T0897',
      'T0898',
      'T0899',
      'T0900',
      'T0901',
      'T0902',
      'T0903',
      'T0904',
      'T0905',
      'T0906',
      'T0907',
      'T0908',
      'T0909',
      'T0910',
      'T0911',
      'T0912',
      'T0913',
      'T0914',
      'T0915',
      'T0916',
      'T0917',
      'T0918',
      'T0919',
      'T0930',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0008',
      'K0066',
      'K0168',
      'K0612',
      'K0613',
      'K0614',
      'K0615',
    ],
    skills: ['S0354', 'S0355', 'S0356'],
    abilities: [
      'A0024',
      'A0033',
      'A0034',
      'A0104',
      'A0105',
      'A0110',
      'A0111',
      'A0112',
      'A0113',
      'A0114',
      'A0115',
      'A0125',
    ],
  },
  {
    id: 'OV-TEA-001',
    name: 'サイバー教育カリキュラム開発者',
    specialityArea: 'トレーニング、教育、意識 (TEA)',
    category: '監督と統治 (OV)',
    description:
      '開発、計画、調整、および指導的ニーズに基づいたサイバートレーニング/教育コース、方法、技術を評価します。',
    tasks: [
      'T0247',
      'T0248',
      'T0249',
      'T0345',
      'T0352',
      'T0357',
      'T0365',
      'T0367',
      'T0380',
      'T0437',
      'T0442',
      'T0450',
      'T0451',
      'T0534',
      'T0536',
      'T0926',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0059',
      'K0124',
      'K0146',
      'K0147',
      'K0204',
      'K0208',
      'K0213',
      'K0216',
      'K0217',
      'K0220',
      'K0239',
      'K0243',
      'K0245',
      'K0246',
      'K0250',
      'K0252',
      'K0287',
      'K0628',
    ],
    skills: ['S0064', 'S0066', 'S0070', 'S0102', 'S0166', 'S0296'],
    abilities: [
      'A0004',
      'A0013',
      'A0015',
      'A0018',
      'A0019',
      'A0022',
      'A0024',
      'A0032',
      'A0054',
      'A0055',
      'A0057',
      'A0058',
      'A0063',
      'A0070',
      'A0083',
      'A0089',
      'A0105',
      'A0106',
      'A0112',
      'A0114',
      'A0118',
      'A0119',
      'A0171',
    ],
  },
  {
    id: 'OV-TEA-002',
    name: 'サイバーインストラクター',
    specialityArea: 'トレーニング、教育、意識 (TEA)',
    category: '監督と統治 (OV)',
    description: 'サイバー領域内の人員の訓練または教育を開発し、実施します。',
    tasks: [
      'T0030',
      'T0073',
      'T0101',
      'T0224',
      'T0230',
      'T0247',
      'T0316',
      'T0317',
      'T0318',
      'T0319',
      'T0320',
      'T0321',
      'T0322',
      'T0323',
      'T0352',
      'T0365',
      'T0367',
      'T0381',
      'T0382',
      'T0395',
      'T0443',
      'T0444',
      'T0450',
      'T0451',
      'T0467',
      'T0519',
      'T0520',
      'T0535',
      'T0536',
      'T0926',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0059',
      'K0115',
      'K0124',
      'K0130',
      'K0146',
      'K0147',
      'K0204',
      'K0208',
      'K0213',
      'K0215',
      'K0216',
      'K0217',
      'K0218',
      'K0220',
      'K0226',
      'K0239',
      'K0245',
      'K0246',
      'K0250',
      'K0252',
      'K0287',
      'K0313',
      'K0319',
      'K0628',
    ],
    skills: [
      'S0001',
      'S0004',
      'S0006',
      'S0051',
      'S0052',
      'S0053',
      'S0055',
      'S0056',
      'S0057',
      'S0060',
      'S0064',
      'S0070',
      'S0073',
      'S0075',
      'S0076',
      'S0081',
      'S0084',
      'S0097',
      'S0098',
      'S0100',
      'S0101',
      'S0121',
      'S0131',
      'S0156',
      'S0184',
      'S0270',
      'S0271',
      'S0281',
      'S0293',
      'S0301',
      'S0356',
      'S0358',
    ],
    abilities: [
      'A0006',
      'A0011',
      'A0012',
      'A0013',
      'A0014',
      'A0015',
      'A0016',
      'A0017',
      'A0018',
      'A0019',
      'A0020',
      'A0022',
      'A0023',
      'A0024',
      'A0032',
      'A0055',
      'A0057',
      'A0058',
      'A0063',
      'A0066',
      'A0070',
      'A0083',
      'A0089',
      'A0105',
      'A0106',
      'A0112',
      'A0114',
      'A0118',
      'A0119',
      'A0171',
    ],
  },
  {
    id: 'OV-MGT-001',
    name: '情報システムセキュリティマネージャー',
    specialityArea: 'サイバーセキュリティ管理 (MGT)',
    category: '監督と統治 (OV)',
    description:
      'プログラム、組織、システム、またはエンクレーブのサイバーセキュリティを担当します。',
    tasks: [
      'T0147',
      'T0148',
      'T0149',
      'T0151',
      'T0157',
      'T0158',
      'T0159',
      'T0192',
      'T0199',
      'T0206',
      'T0211',
      'T0213',
      'T0215',
      'T0219',
      'T0227',
      'T0229',
      'T0234',
      'T0239',
      'T0248',
      'T0254',
      'T0255',
      'T0256',
      'T0263',
      'T0264',
      'T0265',
      'T0275',
      'T0276',
      'T0277',
      'T0280',
      'T0281',
      'T0282',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0008',
      'K0018',
      'K0021',
      'K0026',
      'K0033',
      'K0038',
      'K0040',
      'K0042',
      'K0043',
      'K0046',
      'K0048',
      'K0053',
      'K0054',
      'K0058',
      'K0059',
      'K0061',
      'K0070',
      'K0072',
      'K0076',
      'K0077',
      'K0087',
      'K0090',
      'K0092',
      'K0101',
      'K0106',
      'K0121',
      'K0126',
      'K0149',
      'K0150',
      'K0151',
      'K0163',
      'K0167',
      'K0168',
      'K0169',
      'K0170',
      'K0179',
      'K0180',
      'K0199',
      'K0260',
      'K0261',
      'K0262',
      'K0267',
      'K0287',
      'K0332',
      'K0342',
      'K0622',
      'K0624',
    ],
    skills: ['S0018', 'S0027', 'S0086'],
    abilities: ['A0128', 'A0161', 'A0170'],
  },
  {
    id: 'OV-MGT-002',
    name: '通信セキュリティ（COMSEC）マネージャー',
    specialityArea: 'サイバーセキュリティ管理 (MGT)',
    category: '監督と統治 (OV)',
    description:
      '組織の通信セキュリティ（COMSEC）リソースを管理する個人（CNSSI 4009）または暗号キー管理システム（CKMS）のキーカストディアン。',
    tasks: ['T0003', 'T0004', 'T0025', 'T0044', 'T0089', 'T0095', 'T0099', 'T0215', 'T0229'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0018',
      'K0026',
      'K0038',
      'K0042',
      'K0090',
      'K0101',
      'K0121',
      'K0126',
      'K0163',
      'K0267',
      'K0285',
      'K0287',
      'K0622',
    ],
    skills: ['S0027', 'S0059', 'S0138'],
    abilities: ['A0177', 'A0163', 'A0164', 'A0165', 'A0166', 'A0167', 'A0168'],
  },
  {
    id: 'OV-SPP-001',
    name: 'サイバーワークフォース開発者およびマネージャー',
    specialityArea: '戦略計画と政策 (SPP)',
    category: '監督と統治 (OV)',
    description:
      'サイバースペースの労働力計画、戦略、および指針を開発し、サイバースペースの労働力の人員、人事、訓練、教育要件を支援し、サイバースペースの政策、教義、物資、部隊構造、および教育訓練要件の変更に対処します。',
    tasks: [
      'T0001',
      'T0004',
      'T0025',
      'T0044',
      'T0074',
      'T0094',
      'T0099',
      'T0116',
      'T0222',
      'T0226',
      'T0341',
      'T0352',
      'T0355',
      'T0356',
      'T0362',
      'T0363',
      'T0364',
      'T0365',
      'T0368',
      'T0369',
      'T0372',
      'T0373',
      'T0374',
      'T0375',
      'T0376',
      'T0384',
      'T0387',
      'T0388',
      'T0390',
      'T0391',
      'T0408',
      'T0425',
      'T0429',
      'T0437',
      'T0441',
      'T0445',
      'T0472',
      'T0481',
      'T0505',
      'T0506',
      'T0529',
      'T0533',
      'T0536',
      'T0537',
      'T0552',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0072',
      'K0101',
      'K0127',
      'K0146',
      'K0147',
      'K0168',
      'K0169',
      'K0204',
      'K0215',
      'K0233',
      'K0234',
      'K0241',
      'K0243',
      'K0309',
      'K0311',
      'K0313',
      'K0335',
    ],
    skills: ['S0108', 'S0128'],
    abilities: ['A0023', 'A0028', 'A0033', 'A0037', 'A0042', 'A0053'],
  },
  {
    id: 'OV-SPP-002',
    name: 'サイバーポリシーと戦略プランナー',
    specialityArea: '戦略計画と政策 (SPP)',
    category: '監督と統治 (OV)',
    description:
      'サイバーセキュリティ計画、戦略、およびポリシーを開発・維持し、組織のサイバーセキュリティイニシアチブおよび規制遵守をサポートし、それに沿って行います。',
    tasks: [
      'T0074',
      'T0094',
      'T0222',
      'T0226',
      'T0341',
      'T0369',
      'T0384',
      'T0390',
      'T0408',
      'T0425',
      'T0429',
      'T0441',
      'T0445',
      'T0472',
      'T0505',
      'T0506',
      'T0529',
      'T0533',
      'T0537',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0070',
      'K0127',
      'K0146',
      'K0168',
      'K0234',
      'K0248',
      'K0309',
      'K0311',
      'K0313',
      'K0335',
      'K0624',
    ],
    skills: ['S0176', 'S0250'],
    abilities: ['A0003', 'A0033', 'A0037'],
  },
  {
    id: 'OV-EXL-001',
    name: 'エグゼクティブ・サイバー・リーダーシップ',
    specialityArea: 'エグゼクティブ・サイバー・リーダーシップ（EXL）',
    category: '監督と統治 (OV)',
    description:
      '組織のサイバーおよびサイバー関連リソースおよび/またはオペレーションのためのビジョンと方向性を確立し、意思決定権限を実行します。',
    tasks: [
      'T0001',
      'T0002',
      'T0004',
      'T0006',
      'T0025',
      'T0066',
      'T0130',
      'T0134',
      'T0135',
      'T0148',
      'T0151',
      'T0227',
      'T0229',
      'T0248',
      'T0254',
      'T0263',
      'T0264',
      'T0282',
      'T0337',
      'T0356',
      'T0429',
      'T0445',
      'T0509',
      'T0763',
      'T0871',
      'T0872',
      'T0927',
      'T0928',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0070',
      'K0106',
      'K0147',
      'K0296',
      'K0314',
      'K0624',
      'K0628',
    ],
    skills: ['S0018', 'S0356', 'S0357', 'S0358', 'S0359'],
    abilities: [
      'A0033',
      'A0070',
      'A0085',
      'A0094',
      'A0105',
      'A0106',
      'A0116',
      'A0117',
      'A0118',
      'A0119',
      'A0129',
      'A0130',
    ],
  },
  {
    id: 'OV-PMA-001',
    name: 'プログラムマネージャー',
    specialityArea: 'プログラム/プロジェクト管理と調達 (PMA)',
    category: '監督と統治 (OV)',
    description:
      'リードし、調整し、コミュニケーションを取り、統合し、プログラムの全体的な成功に責任を持ち、機関または企業の優先事項との整合性を保証します。',
    tasks: [
      'T0066',
      'T0072',
      'T0174',
      'T0199',
      'T0220',
      'T0223',
      'T0256',
      'T0273',
      'T0277',
      'T0302',
      'T0340',
      'T0354',
      'T0377',
      'T0379',
      'T0407',
      'T0412',
      'T0414',
      'T0415',
      'T0481',
      'T0493',
      'T0551',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0047',
      'K0048',
      'K0072',
      'K0090',
      'K0101',
      'K0120',
      'K0126',
      'K0146',
      'K0148',
      'K0154',
      'K0164',
      'K0165',
      'K0169',
      'K0194',
      'K0196',
      'K0198',
      'K0200',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0372'],
    abilities: ['A0009', 'A0039', 'A0045', 'A0056'],
  },
  {
    id: 'OV-PMA-002',
    name: 'ITプロジェクトマネージャー',
    specialityArea: 'プログラム/プロジェクト管理と調達 (PMA)',
    category: '監督と統治 (OV)',
    description: '直接情報技術プロジェクトを管理します。',
    tasks: [
      'T0072',
      'T0174',
      'T0196',
      'T0199',
      'T0207',
      'T0208',
      'T0220',
      'T0223',
      'T0256',
      'T0273',
      'T0277',
      'T0340',
      'T0354',
      'T0370',
      'T0377',
      'T0379',
      'T0389',
      'T0394',
      'T0407',
      'T0412',
      'T0414',
      'T0415',
      'T0481',
      'T0493',
      'T0551',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0012',
      'K0043',
      'K0047',
      'K0048',
      'K0059',
      'K0072',
      'K0090',
      'K0101',
      'K0120',
      'K0126',
      'K0146',
      'K0148',
      'K0154',
      'K0164',
      'K0165',
      'K0169',
      'K0194',
      'K0196',
      'K0198',
      'K0200',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0372'],
    abilities: ['A0009', 'A0039', 'A0045', 'A0056'],
  },
  {
    id: 'OV-PMA-003',
    name: '製品サポートマネージャー',
    specialityArea: 'プログラム/プロジェクト管理と取得 (PMA)',
    category: '監督と統治 (OV)',
    description:
      'システムおよびコンポーネントの準備態勢と運用能力を維持するために必要なサポート機能のパッケージを管理します。',
    tasks: [
      'T0072',
      'T0174',
      'T0196',
      'T0204',
      'T0207',
      'T0208',
      'T0220',
      'T0223',
      'T0256',
      'T0273',
      'T0277',
      'T0302',
      'T0340',
      'T0354',
      'T0370',
      'T0377',
      'T0389',
      'T0394',
      'T0412',
      'T0414',
      'T0493',
      'T0525',
      'T0551',
      'T0553',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0043',
      'K0048',
      'K0059',
      'K0072',
      'K0090',
      'K0120',
      'K0126',
      'K0148',
      'K0150',
      'K0154',
      'K0164',
      'K0165',
      'K0169',
      'K0194',
      'K0196',
      'K0198',
      'K0200',
      'K0235',
      'K0249',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0372'],
    abilities: ['A0009', 'A0031', 'A0039', 'A0045', 'A0056'],
  },
  {
    id: 'OV-PMA-004',
    name: 'IT投資/ポートフォリオマネージャー',
    specialityArea: 'プログラム/プロジェクト管理と調達 (PMA)',
    category: '監督と統治 (OV)',
    description:
      'ミッションとエンタープライズの優先事項全体と一致するIT投資のポートフォリオを管理します。',
    tasks: ['T0220', 'T0223', 'T0277', 'T0302', 'T0377', 'T0415', 'T0493', 'T0551'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0048',
      'K0072',
      'K0120',
      'K0126',
      'K0146',
      'K0154',
      'K0165',
      'K0169',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0372'],
    abilities: ['A0039'],
  },
  {
    id: 'OV-PMA-005',
    name: 'ITプログラム監査員',
    specialityArea: 'プログラム/プロジェクト管理と取得 (PMA)',
    category: '監督と統治 (OV)',
    description:
      'ITプログラムまたはその個々のコンポーネントを評価して、公開された基準との遵守を判断します。',
    tasks: ['T0072', 'T0207', 'T0208', 'T0223', 'T0256', 'T0389', 'T0412', 'T0415'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0043',
      'K0047',
      'K0048',
      'K0072',
      'K0090',
      'K0120',
      'K0126',
      'K0148',
      'K0154',
      'K0165',
      'K0169',
      'K0198',
      'K0200',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0085', 'S0372'],
    abilities: ['A0056'],
  },
  {
    id: 'PR-CDA-001',
    name: 'サイバーディフェンスアナリスト',
    specialityArea: 'サイバーセキュリティ防御分析 (CDA)',
    category: '保護と防衛 (PR)',
    description:
      'さまざまなサイバーディフェンスツール（例：IDSアラート、ファイアウォール、ネットワークトラフィックログ）から収集されたデータを使用して、脅威を軽減する目的で環境内で発生するイベントを分析します。',
    tasks: [
      'T0020',
      'T0023',
      'T0043',
      'T0088',
      'T0155',
      'T0164',
      'T0166',
      'T0178',
      'T0187',
      'T0198',
      'T0214',
      'T0258',
      'T0259',
      'T0260',
      'T0290',
      'T0291',
      'T0292',
      'T0293',
      'T0294',
      'T0295',
      'T0296',
      'T0297',
      'T0298',
      'T0299',
      'T0310',
      'T0332',
      'T0469',
      'T0470',
      'T0475',
      'T0503',
      'T0504',
      'T0526',
      'T0545',
      'T0548',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0013',
      'K0015',
      'K0018',
      'K0019',
      'K0024',
      'K0033',
      'K0040',
      'K0042',
      'K0044',
      'K0046',
      'K0049',
      'K0056',
      'K0058',
      'K0059',
      'K0060',
      'K0061',
      'K0065',
      'K0070',
      'K0074',
      'K0075',
      'K0093',
      'K0098',
      'K0104',
      'K0106',
      'K0107',
      'K0110',
      'K0111',
      'K0112',
      'K0113',
      'K0116',
      'K0139',
      'K0142',
      'K0143',
      'K0157',
      'K0160',
      'K0161',
      'K0162',
      'K0167',
      'K0168',
      'K0177',
      'K0179',
      'K0180',
      'K0190',
      'K0191',
      'K0192',
      'K0203',
      'K0221',
      'K0222',
      'K0260',
      'K0261',
      'K0262',
      'K0290',
      'K0297',
      'K0300',
      'K0301',
      'K0303',
      'K0318',
      'K0322',
      'K0324',
      'K0332',
      'K0339',
      'K0342',
      'K0624',
    ],
    skills: [
      'S0020',
      'S0025',
      'S0027',
      'S0036',
      'S0054',
      'S0057',
      'S0063',
      'S0078',
      'S0096',
      'S0147',
      'S0156',
      'S0167',
      'S0169',
      'S0367',
      'S0370',
    ],
    abilities: ['A0010', 'A0015', 'A0066', 'A0123', 'A0128', 'A0159'],
  },
  {
    id: 'PR-INF-001',
    name: 'サイバーディフェンスインフラストラクチャーサポートスペシャリスト',
    specialityArea: 'サイバーセキュリティ防御インフラサポート (INF)',
    category: 'プロテクト・アンド・ディフェンド (PR)',
    description:
      'テスト、実装、展開、保守、およびインフラストラクチャのハードウェアとソフトウェアを管理します。',
    tasks: ['T0042', 'T0180', 'T0261', 'T0335', 'T0348', 'T0420', 'T0438', 'T0483', 'T0486'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0021',
      'K0033',
      'K0042',
      'K0044',
      'K0058',
      'K0061',
      'K0062',
      'K0104',
      'K0106',
      'K0135',
      'K0157',
      'K0179',
      'K0205',
      'K0258',
      'K0274',
      'K0324',
      'K0332',
      'K0334',
    ],
    skills: ['S0007', 'S0053', 'S0054', 'S0059', 'S0077', 'S0079', 'S0121', 'S0124', 'S0367'],
    abilities: ['A0123'],
  },
  {
    id: 'PR-CIR-001',
    name: 'サイバーディフェンスインシデントレスポンダー',
    specialityArea: 'インシデント対応（CIR）',
    category: '保護と防衛 (PR)',
    description:
      'ネットワーク環境またはエンクレーブ内でサイバーインシデントを調査、分析、対応します。',
    tasks: [
      'T0041',
      'T0047',
      'T0161',
      'T0163',
      'T0164',
      'T0170',
      'T0175',
      'T0214',
      'T0233',
      'T0246',
      'T0262',
      'T0278',
      'T0279',
      'T0312',
      'T0395',
      'T0503',
      'T0510',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0021',
      'K0026',
      'K0033',
      'K0034',
      'K0041',
      'K0042',
      'K0046',
      'K0058',
      'K0062',
      'K0070',
      'K0106',
      'K0157',
      'K0161',
      'K0162',
      'K0167',
      'K0177',
      'K0179',
      'K0221',
      'K0230',
      'K0259',
      'K0287',
      'K0332',
      'K0565',
      'K0624',
    ],
    skills: ['S0003', 'S0047', 'S0077', 'S0078', 'S0079', 'S0080', 'S0173', 'S0365'],
    abilities: ['A0121', 'A0128'],
  },
  {
    id: 'PR-VAM-001',
    name: '脆弱性評価アナリスト',
    specialityArea: '脆弱性評価と管理 (VAM)',
    category: '保護と防衛 (PR)',
    description:
      'ネットワーク環境またはエンクレーブ内のシステムやネットワークを評価し、それらのシステム/ネットワークが許容可能な構成、エンクレーブポリシー、またはローカルポリシーから逸脱している場所を特定します。既知の脆弱性に対する防御の深さのアーキテクチャの効果を測定します。',
    tasks: ['T0010', 'T0028', 'T0138', 'T0142', 'T0188', 'T0252', 'T0549', 'T0550'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0019',
      'K0021',
      'K0033',
      'K0044',
      'K0056',
      'K0061',
      'K0068',
      'K0070',
      'K0089',
      'K0106',
      'K0139',
      'K0161',
      'K0162',
      'K0167',
      'K0177',
      'K0179',
      'K0203',
      'K0206',
      'K0210',
      'K0224',
      'K0265',
      'K0287',
      'K0301',
      'K0308',
      'K0332',
      'K0342',
      'K0344',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0009',
      'S0025',
      'S0044',
      'S0051',
      'S0052',
      'S0081',
      'S0120',
      'S0137',
      'S0171',
      'S0364',
      'S0367',
    ],
    abilities: ['A0001', 'A0044', 'A0120', 'A0123'],
  },
  {
    id: 'AN-TWA-001',
    name: '脅威/警告アナリスト',
    specialityArea: '脅威分析 (TWA)',
    category: '分析する (AN)',
    description:
      'サイバーインジケータを開発して、非常にダイナミックな運用環境の状況を把握するための意識を維持します。サイバー脅威/警告評価を収集、処理、分析、および普及します。',
    tasks: [
      'T0569',
      'T0583',
      'T0584',
      'T0585',
      'T0586',
      'T0589',
      'T0593',
      'T0597',
      'T0615',
      'T0617',
      'T0660',
      'T0685',
      'T0687',
      'T0707',
      'T0708',
      'T0718',
      'T0748',
      'T0749',
      'T0751',
      'T0752',
      'T0758',
      'T0761',
      'T0783',
      'T0785',
      'T0786',
      'T0792',
      'T0800',
      'T0805',
      'T0834',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0177',
      'K0349',
      'K0362',
      'K0377',
      'K0392',
      'K0395',
      'K0405',
      'K0409',
      'K0415',
      'K0417',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0458',
      'K0460',
      'K0464',
      'K0469',
      'K0471',
      'K0480',
      'K0499',
      'K0511',
      'K0516',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0603',
      'K0604',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0194',
      'S0196',
      'S0203',
      'S0211',
      'S0218',
      'S0227',
      'S0228',
      'S0229',
      'S0249',
      'S0256',
      'S0278',
      'S0285',
      'S0288',
      'S0289',
      'S0296',
      'S0297',
      'S0303',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0072',
      'A0080',
      'A0082',
      'A0083',
      'A0084',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0107',
      'A0109',
    ],
  },
  {
    id: 'AN-EXP-001',
    name: '搾取分析士',
    specialityArea: '搾取分析 (EXP)',
    category: '分析する (AN)',
    description:
      '協力して、サイバー収集および/または準備活動を通じて満たすことができるアクセスと収集のギャップを特定します。対象ネットワークに侵入するために、すべての認可されたリソースと分析技術を活用します。',
    tasks: [
      'T0028',
      'T0266',
      'T0570',
      'T0572',
      'T0574',
      'T0591',
      'T0600',
      'T0603',
      'T0608',
      'T0614',
      'T0641',
      'T0695',
      'T0701',
      'T0720',
      'T0727',
      'T0736',
      'T0738',
      'T0754',
      'T0775',
      'T0777',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0109',
      'K0131',
      'K0142',
      'K0143',
      'K0177',
      'K0224',
      'K0349',
      'K0351',
      'K0354',
      'K0362',
      'K0368',
      'K0371',
      'K0376',
      'K0379',
      'K0388',
      'K0393',
      'K0394',
      'K0397',
      'K0417',
      'K0418',
      'K0430',
      'K0443',
      'K0444',
      'K0447',
      'K0451',
      'K0470',
      'K0471',
      'K0473',
      'K0484',
      'K0487',
      'K0489',
      'K0509',
      'K0510',
      'K0523',
      'K0529',
      'K0535',
      'K0544',
      'K0557',
      'K0559',
      'K0560',
      'K0608',
    ],
    skills: [
      'S0066',
      'S0184',
      'S0199',
      'S0200',
      'S0201',
      'S0204',
      'S0207',
      'S0214',
      'S0223',
      'S0236',
      'S0237',
      'S0239',
      'S0240',
      'S0245',
      'S0247',
      'S0258',
      'S0260',
      'S0264',
      'S0269',
      'S0279',
      'S0286',
      'S0290',
      'S0294',
      'S0300',
    ],
    abilities: ['A0013', 'A0066', 'A0074', 'A0080', 'A0084', 'A0086', 'A0092', 'A0093', 'A0104'],
  },
  {
    id: 'AN-ASA-001',
    name: 'オールソースアナリスト',
    specialityArea: 'オールソース分析 (ASA)',
    category: '分析する (AN)',
    description:
      'データ/情報を一つまたは複数のソースから分析し、環境の準備を行うため、情報要求に応じて、計画と作戦を支援するための情報収集および生産要件を提出します。',
    tasks: [
      'T0569',
      'T0582',
      'T0583',
      'T0584',
      'T0585',
      'T0586',
      'T0589',
      'T0593',
      'T0597',
      'T0615',
      'T0617',
      'T0642',
      'T0660',
      'T0678',
      'T0685',
      'T0686',
      'T0687',
      'T0707',
      'T0708',
      'T0710',
      'T0713',
      'T0718',
      'T0748',
      'T0749',
      'T0751',
      'T0752',
      'T0758',
      'T0761',
      'T0771',
      'T0782',
      'T0783',
      'T0785',
      'T0786',
      'T0788',
      'T0789',
      'T0792',
      'T0797',
      'T0800',
      'T0805',
      'T0834',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0177',
      'K0221',
      'K0349',
      'K0357',
      'K0362',
      'K0377',
      'K0392',
      'K0395',
      'K0405',
      'K0409',
      'K0410',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0457',
      'K0458',
      'K0460',
      'K0464',
      'K0465',
      'K0469',
      'K0471',
      'K0480',
      'K0507',
      'K0511',
      'K0516',
      'K0533',
      'K0542',
      'K0549',
      'K0551',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0577',
      'K0598',
      'K0603',
      'K0604',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0189',
      'S0194',
      'S0203',
      'S0211',
      'S0218',
      'S0227',
      'S0229',
      'S0249',
      'S0254',
      'S0256',
      'S0278',
      'S0285',
      'S0288',
      'S0289',
      'S0296',
      'S0297',
      'S0303',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0072',
      'A0080',
      'A0082',
      'A0083',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0107',
      'A0108',
      'A0109',
    ],
  },
  {
    id: 'AN-ASA-002',
    name: 'ミッション評価スペシャリスト',
    specialityArea: 'オールソース分析 (ASA)',
    category: '分析する (AN)',
    description:
      '評価計画を策定し、パフォーマンス/効果の尺度を測定します。必要に応じてサイバーイベントの戦略的および運用効果の評価を行います。システムが期待通りに機能したかどうかを判断し、運用効果の決定に対する意見を提供します。',
    tasks: [
      'T0582',
      'T0583',
      'T0585',
      'T0586',
      'T0588',
      'T0589',
      'T0593',
      'T0597',
      'T0611',
      'T0615',
      'T0617',
      'T0624',
      'T0660',
      'T0661',
      'T0663',
      'T0678',
      'T0684',
      'T0685',
      'T0686',
      'T0707',
      'T0718',
      'T0748',
      'T0749',
      'T0752',
      'T0758',
      'T0761',
      'T0782',
      'T0783',
      'T0785',
      'T0786',
      'T0788',
      'T0789',
      'T0793',
      'T0797',
      'T0834',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0177',
      'K0349',
      'K0362',
      'K0377',
      'K0392',
      'K0395',
      'K0405',
      'K0409',
      'K0410',
      'K0414',
      'K0417',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0457',
      'K0460',
      'K0464',
      'K0465',
      'K0469',
      'K0471',
      'K0480',
      'K0507',
      'K0511',
      'K0516',
      'K0549',
      'K0551',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0598',
      'K0603',
      'K0604',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0189',
      'S0194',
      'S0203',
      'S0211',
      'S0216',
      'S0218',
      'S0227',
      'S0228',
      'S0229',
      'S0249',
      'S0254',
      'S0256',
      'S0271',
      'S0278',
      'S0285',
      'S0288',
      'S0289',
      'S0292',
      'S0296',
      'S0297',
      'S0303',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0072',
      'A0080',
      'A0082',
      'A0083',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0107',
      'A0108',
      'A0109',
    ],
  },
  {
    id: 'AN-TGT-001',
    name: 'ターゲットデベロッパー',
    specialityArea: 'ターゲット (TGT)',
    category: '分析する (AN)',
    description:
      '対象システムの分析を行い、環境準備からの入力や内部または外部の情報源を含む電子的なターゲットフォルダを構築および/または維持します。パートナーターゲット活動および情報機関と調整し、候補となるターゲットを審査および検証のために提示します。',
    tasks: [
      'T0561',
      'T0582',
      'T0588',
      'T0594',
      'T0597',
      'T0599',
      'T0617',
      'T0624',
      'T0633',
      'T0642',
      'T0650',
      'T0652',
      'T0661',
      'T0663',
      'T0684',
      'T0688',
      'T0707',
      'T0710',
      'T0717',
      'T0731',
      'T0744',
      'T0769',
      'T0770',
      'T0776',
      'T0781',
      'T0782',
      'T0790',
      'T0794',
      'T0797',
      'T0798',
      'T0799',
      'T0802',
      'T0815',
      'T0824',
      'T0835',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0142',
      'K0177',
      'K0349',
      'K0351',
      'K0357',
      'K0362',
      'K0379',
      'K0381',
      'K0392',
      'K0395',
      'K0402',
      'K0409',
      'K0413',
      'K0417',
      'K0426',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0439',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0457',
      'K0458',
      'K0460',
      'K0461',
      'K0464',
      'K0465',
      'K0466',
      'K0471',
      'K0473',
      'K0478',
      'K0479',
      'K0497',
      'K0499',
      'K0507',
      'K0516',
      'K0533',
      'K0542',
      'K0543',
      'K0546',
      'K0547',
      'K0549',
      'K0551',
      'K0555',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0598',
      'K0603',
      'K0604',
      'K0614',
    ],
    skills: [
      'S0187',
      'S0189',
      'S0194',
      'S0196',
      'S0203',
      'S0205',
      'S0208',
      'S0216',
      'S0218',
      'S0222',
      'S0227',
      'S0228',
      'S0229',
      'S0248',
      'S0249',
      'S0256',
      'S0274',
      'S0278',
      'S0285',
      'S0287',
      'S0288',
      'S0289',
      'S0292',
      'S0296',
      'S0297',
      'S0302',
      'S0360',
      'S0361',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0073',
      'A0080',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0109',
    ],
  },
  {
    id: 'AN-TGT-002',
    name: 'ターゲットネットワークアナリスト',
    specialityArea: 'ターゲット (TGT)',
    category: '分析する (AN)',
    description:
      'コレクションとオープンソースデータの高度な分析を実施し、ターゲットの継続性を確保するため；ターゲットとその活動をプロファイリングし；さらにターゲット情報を得るための技術を開発する。ターゲットの技術、デジタルネットワーク、およびそれらにおけるアプリケーションの知識に基づいて、ターゲットがどのように通信し、移動し、操作し、生活するかを決定する。',
    tasks: [
      'T0582',
      'T0595',
      'T0599',
      'T0606',
      'T0607',
      'T0617',
      'T0621',
      'T0624',
      'T0650',
      'T0653',
      'T0692',
      'T0706',
      'T0707',
      'T0710',
      'T0715',
      'T0722',
      'T0745',
      'T0765',
      'T0767',
      'T0778',
      'T0797',
      'T0802',
      'T0803',
      'T0807',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0109',
      'K0177',
      'K0349',
      'K0362',
      'K0379',
      'K0389',
      'K0392',
      'K0395',
      'K0403',
      'K0413',
      'K0424',
      'K0431',
      'K0436',
      'K0439',
      'K0440',
      'K0442',
      'K0444',
      'K0445',
      'K0449',
      'K0462',
      'K0471',
      'K0472',
      'K0473',
      'K0479',
      'K0483',
      'K0487',
      'K0499',
      'K0500',
      'K0516',
      'K0520',
      'K0544',
      'K0547',
      'K0550',
      'K0559',
      'K0567',
      'K0592',
      'K0599',
      'K0600',
    ],
    skills: [
      'S0177',
      'S0178',
      'S0181',
      'S0183',
      'S0187',
      'S0191',
      'S0194',
      'S0196',
      'S0197',
      'S0203',
      'S0205',
      'S0208',
      'S0217',
      'S0219',
      'S0220',
      'S0222',
      'S0225',
      'S0228',
      'S0229',
      'S0231',
      'S0234',
      'S0244',
      'S0246',
      'S0248',
      'S0256',
      'S0259',
      'S0261',
      'S0262',
      'S0263',
      'S0268',
      'S0274',
      'S0277',
      'S0280',
      'S0287',
      'S0291',
      'S0301',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0073',
      'A0080',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0109',
    ],
  },
  {
    id: 'AN-LNG-001',
    name: '多分野言語アナリスト',
    specialityArea: '言語分析 (LNG)',
    category: '分析する (AN)',
    description:
      '言語と文化の専門知識を対象/脅威および技術知識と組み合わせて、言語、音声、および/またはグラフィック素材から得られた情報を処理、分析、および/または普及させる。サイバーアクションの実行を支援し、重要な知識共有を保証するために、言語特有のデータベースと作業支援を作成および維持する。外国語集中型または学際的プロジェクトにおける主題専門家として提供する。',
    tasks: [
      'T0606',
      'T0650',
      'T0715',
      'T0745',
      'T0761',
      'T0837',
      'T0838',
      'T0839',
      'T0840',
      'T0841',
      'T0842',
      'T0843',
      'T0844',
      'T0845',
      'T0846',
      'T0847',
      'T0848',
      'T0849',
      'T0850',
      'T0851',
      'T0852',
      'T0853',
      'T0854',
      'T0855',
      'T0856',
      'T0857',
      'T0858',
      'T0859',
      'T0860',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0143',
      'K0177',
      'K0356',
      'K0359',
      'K0377',
      'K0391',
      'K0396',
      'K0398',
      'K0407',
      'K0413',
      'K0416',
      'K0417',
      'K0431',
      'K0449',
      'K0462',
      'K0476',
      'K0487',
      'K0488',
      'K0491',
      'K0493',
      'K0499',
      'K0520',
      'K0524',
      'K0532',
      'K0539',
      'K0540',
      'K0541',
      'K0545',
      'K0548',
      'K0550',
      'K0564',
      'K0567',
      'K0571',
      'K0574',
      'K0579',
      'K0596',
      'K0599',
      'K0600',
      'K0606',
      'K0607',
    ],
    skills: [
      'S0179',
      'S0184',
      'S0187',
      'S0188',
      'S0193',
      'S0195',
      'S0198',
      'S0210',
      'S0212',
      'S0215',
      'S0217',
      'S0218',
      'S0224',
      'S0226',
      'S0232',
      'S0233',
      'S0235',
      'S0241',
      'S0244',
      'S0251',
      'S0253',
      'S0259',
      'S0262',
      'S0265',
      'S0277',
      'S0283',
      'S0284',
      'S0290',
    ],
    abilities: ['A0013', 'A0071', 'A0089', 'A0103'],
  },
  {
    id: 'CO-CLO-001',
    name: 'すべてのソース・コレクション・マネージャ',
    specialityArea: 'コレクション操作 (CLO)',
    category: '収集して操作する (CO)',
    description:
      '収集機関と環境を特定し、優先情報要件を収集管理に組み込む；リーダーシップの意図を満たすための概念を開発する。利用可能な収集資産の能力を決定し、新たな収集能力を特定する；そして収集計画を構築して配布する。タスク収集の実行を監視し、収集計画の効果的な実行を確保する。',
    tasks: [
      'T0562',
      'T0564',
      'T0568',
      'T0573',
      'T0578',
      'T0604',
      'T0605',
      'T0625',
      'T0626',
      'T0631',
      'T0632',
      'T0634',
      'T0645',
      'T0646',
      'T0647',
      'T0649',
      'T0651',
      'T0657',
      'T0662',
      'T0674',
      'T0681',
      'T0683',
      'T0698',
      'T0702',
      'T0714',
      'T0716',
      'T0721',
      'T0723',
      'T0725',
      'T0734',
      'T0737',
      'T0750',
      'T0753',
      'T0755',
      'T0757',
      'T0773',
      'T0779',
      'T0806',
      'T0809',
      'T0810',
      'T0811',
      'T0812',
      'T0814',
      'T0820',
      'T0821',
      'T0827',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0109',
      'K0177',
      'K0353',
      'K0361',
      'K0364',
      'K0380',
      'K0382',
      'K0383',
      'K0386',
      'K0387',
      'K0390',
      'K0392',
      'K0395',
      'K0401',
      'K0404',
      'K0405',
      'K0412',
      'K0417',
      'K0419',
      'K0425',
      'K0427',
      'K0431',
      'K0435',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0448',
      'K0449',
      'K0453',
      'K0454',
      'K0467',
      'K0471',
      'K0474',
      'K0475',
      'K0477',
      'K0480',
      'K0482',
      'K0492',
      'K0495',
      'K0496',
      'K0498',
      'K0503',
      'K0505',
      'K0513',
      'K0516',
      'K0521',
      'K0522',
      'K0526',
      'K0527',
      'K0552',
      'K0553',
      'K0554',
      'K0558',
      'K0560',
      'K0561',
      'K0562',
      'K0563',
      'K0565',
      'K0569',
      'K0570',
      'K0579',
      'K0580',
      'K0581',
      'K0583',
      'K0584',
      'K0587',
      'K0588',
      'K0596',
      'K0601',
      'K0605',
      'K0610',
      'K0612',
      'K0613',
    ],
    skills: [
      'S0238',
      'S0304',
      'S0305',
      'S0311',
      'S0313',
      'S0316',
      'S0317',
      'S0324',
      'S0325',
      'S0327',
      'S0328',
      'S0330',
      'S0332',
      'S0334',
      'S0335',
      'S0336',
      'S0339',
      'S0342',
      'S0344',
      'S0347',
      'S0351',
      'S0352',
      'S0362',
    ],
    abilities: ['A0069', 'A0070', 'A0076', 'A0078', 'A0079'],
  },
  {
    id: 'CO-CLO-002',
    name: 'すべてのソース収集要件マネージャー',
    specialityArea: 'コレクション操作（CLO）',
    category: '収集して操作する (CO)',
    description:
      'コレクション操作を評価し、利用可能なソースと方法を使用してコレクションを改善するための効果ベースのコレクション要件戦略を開発します。コレクション要件の開発、処理、検証、および提出の調整を行います。コレクション資産とコレクション操作のパフォーマンスを評価します。',
    tasks: [
      'T0564',
      'T0565',
      'T0568',
      'T0577',
      'T0578',
      'T0580',
      'T0596',
      'T0602',
      'T0605',
      'T0613',
      'T0651',
      'T0668',
      'T0673',
      'T0675',
      'T0682',
      'T0689',
      'T0693',
      'T0694',
      'T0714',
      'T0725',
      'T0730',
      'T0734',
      'T0746',
      'T0780',
      'T0809',
      'T0810',
      'T0811',
      'T0819',
      'T0822',
      'T0830',
      'T0831',
      'T0832',
      'T0833',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0109',
      'K0177',
      'K0353',
      'K0361',
      'K0364',
      'K0380',
      'K0382',
      'K0383',
      'K0384',
      'K0386',
      'K0387',
      'K0390',
      'K0395',
      'K0401',
      'K0404',
      'K0412',
      'K0417',
      'K0419',
      'K0421',
      'K0425',
      'K0427',
      'K0431',
      'K0435',
      'K0444',
      'K0445',
      'K0446',
      'K0448',
      'K0453',
      'K0454',
      'K0467',
      'K0474',
      'K0475',
      'K0477',
      'K0480',
      'K0482',
      'K0492',
      'K0495',
      'K0496',
      'K0498',
      'K0505',
      'K0513',
      'K0516',
      'K0521',
      'K0526',
      'K0527',
      'K0552',
      'K0554',
      'K0558',
      'K0560',
      'K0561',
      'K0562',
      'K0563',
      'K0565',
      'K0568',
      'K0569',
      'K0570',
      'K0579',
      'K0580',
      'K0581',
      'K0584',
      'K0587',
      'K0588',
      'K0596',
      'K0605',
      'K0610',
      'K0612',
    ],
    skills: [
      'S0304',
      'S0305',
      'S0316',
      'S0317',
      'S0327',
      'S0329',
      'S0330',
      'S0334',
      'S0335',
      'S0336',
      'S0337',
      'S0339',
      'S0344',
      'S0346',
      'S0347',
      'S0348',
      'S0352',
      'S0353',
      'S0362',
    ],
    abilities: ['A0069', 'A0070', 'A0078'],
  },
  {
    id: 'CO-OPL-001',
    name: 'サイバーインテルプランナー',
    specialityArea: 'サイバー作戦計画 (OPL)',
    category: '収集して操作する (CO)',
    description:
      'サイバー作戦の要件を満たすために詳細なインテリジェンス計画を策定します。サイバー作戦の計画者と協力して、収集と分析のための要件を特定、検証、課します。ターゲティングの選択、検証、同期、およびサイバー行動の実行に参加します。サイバースペースでの組織目標を支援するためにインテリジェンス活動を同期します。',
    tasks: [
      'T0563',
      'T0575',
      'T0576',
      'T0579',
      'T0581',
      'T0587',
      'T0590',
      'T0592',
      'T0601',
      'T0627',
      'T0628',
      'T0630',
      'T0636',
      'T0637',
      'T0638',
      'T0639',
      'T0640',
      'T0648',
      'T0656',
      'T0659',
      'T0667',
      'T0670',
      'T0676',
      'T0680',
      'T0690',
      'T0691',
      'T0705',
      'T0709',
      'T0711',
      'T0719',
      'T0726',
      'T0728',
      'T0733',
      'T0734',
      'T0735',
      'T0739',
      'T0743',
      'T0760',
      'T0763',
      'T0772',
      'T0784',
      'T0801',
      'T0808',
      'T0816',
      'T0836',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0108',
      'K0109',
      'K0120',
      'K0347',
      'K0349',
      'K0350',
      'K0352',
      'K0355',
      'K0358',
      'K0362',
      'K0377',
      'K0379',
      'K0392',
      'K0395',
      'K0399',
      'K0400',
      'K0403',
      'K0405',
      'K0408',
      'K0411',
      'K0414',
      'K0417',
      'K0422',
      'K0427',
      'K0431',
      'K0432',
      'K0435',
      'K0436',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0455',
      'K0456',
      'K0459',
      'K0460',
      'K0463',
      'K0464',
      'K0465',
      'K0471',
      'K0480',
      'K0494',
      'K0499',
      'K0501',
      'K0502',
      'K0504',
      'K0506',
      'K0507',
      'K0508',
      'K0511',
      'K0512',
      'K0514',
      'K0517',
      'K0518',
      'K0519',
      'K0525',
      'K0538',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0566',
      'K0572',
      'K0575',
      'K0577',
      'K0578',
      'K0582',
      'K0585',
      'K0586',
      'K0589',
      'K0590',
      'K0591',
      'K0593',
      'K0594',
      'K0595',
      'K0598',
      'K0599',
      'K0602',
      'K0603',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0176',
      'S0185',
      'S0186',
      'S0203',
      'S0213',
      'S0218',
      'S0249',
      'S0250',
      'S0272',
      'S0273',
      'S0278',
      'S0296',
      'S0297',
      'S0306',
      'S0307',
      'S0308',
      'S0309',
      'S0310',
      'S0312',
      'S0314',
      'S0315',
      'S0318',
      'S0319',
      'S0320',
      'S0321',
      'S0322',
      'S0323',
      'S0331',
      'S0333',
      'S0338',
      'S0340',
      'S0341',
      'S0343',
      'S0345',
      'S0350',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0067',
      'A0068',
      'A0070',
      'A0074',
      'A0077',
      'A0081',
      'A0082',
      'A0085',
      'A0089',
      'A0090',
      'A0094',
      'A0096',
      'A0098',
      'A0105',
      'A0160',
    ],
  },
  {
    id: 'CO-OPL-002',
    name: 'サイバーオプスプランナー',
    specialityArea: 'サイバー作戦計画 (OPL)',
    category: '収集して操作する (CO)',
    description:
      '詳細な計画を策定し、他のプランナー、オペレーター、またはアナリストとの協力を通じて適用可能な範囲のサイバー作戦の実施または支援を行います。ターゲティングの選択、検証、同期を行い、サイバー行動の実行中に統合を可能にします。',
    tasks: [
      'T0680',
      'T0690',
      'T0699',
      'T0703',
      'T0704',
      'T0719',
      'T0732',
      'T0733',
      'T0734',
      'T0739',
      'T0741',
      'T0742',
      'T0743',
      'T0747',
      'T0763',
      'T0764',
      'T0772',
      'T0787',
      'T0791',
      'T0795',
      'T0801',
      'T0813',
      'T0823',
      'T0836',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0108',
      'K0109',
      'K0347',
      'K0349',
      'K0350',
      'K0352',
      'K0362',
      'K0377',
      'K0379',
      'K0392',
      'K0395',
      'K0399',
      'K0400',
      'K0403',
      'K0408',
      'K0411',
      'K0414',
      'K0417',
      'K0422',
      'K0431',
      'K0432',
      'K0435',
      'K0436',
      'K0444',
      'K0445',
      'K0446',
      'K0455',
      'K0464',
      'K0465',
      'K0471',
      'K0480',
      'K0494',
      'K0497',
      'K0499',
      'K0501',
      'K0502',
      'K0504',
      'K0506',
      'K0507',
      'K0508',
      'K0511',
      'K0512',
      'K0514',
      'K0516',
      'K0518',
      'K0519',
      'K0525',
      'K0534',
      'K0538',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0566',
      'K0572',
      'K0576',
      'K0582',
      'K0585',
      'K0586',
      'K0589',
      'K0590',
      'K0593',
      'K0594',
      'K0597',
      'K0598',
      'K0599',
      'K0603',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0176',
      'S0185',
      'S0186',
      'S0209',
      'S0213',
      'S0218',
      'S0249',
      'S0250',
      'S0273',
      'S0296',
      'S0297',
      'S0309',
      'S0312',
      'S0322',
      'S0326',
      'S0333',
      'S0349',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0067',
      'A0068',
      'A0070',
      'A0074',
      'A0077',
      'A0081',
      'A0082',
      'A0085',
      'A0089',
      'A0090',
      'A0094',
      'A0096',
      'A0098',
      'A0105',
    ],
  },
  {
    id: 'CO-OPL-003',
    name: 'パートナー統合プランナー',
    specialityArea: 'サイバー作戦計画 (OPL)',
    category: '収集して操作する (CO)',
    description:
      '組織間または国境を越えたサイバー作戦パートナー間の協力を進めるための活動。パートナーのサイバーチームの統合を支援するために、ガイダンス、リソース、および協力を提供し、ベストプラクティスの開発と統合されたサイバー行動の目標達成のための組織的サポートを促進する。',
    tasks: [
      'T0571',
      'T0581',
      'T0582',
      'T0601',
      'T0627',
      'T0629',
      'T0635',
      'T0665',
      'T0666',
      'T0669',
      'T0670',
      'T0671',
      'T0699',
      'T0700',
      'T0712',
      'T0729',
      'T0732',
      'T0739',
      'T0747',
      'T0759',
      'T0760',
      'T0763',
      'T0764',
      'T0766',
      'T0772',
      'T0784',
      'T0787',
      'T0795',
      'T0817',
      'T0818',
      'T0823',
      'T0825',
      'T0826',
      'T0836',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0109',
      'K0350',
      'K0362',
      'K0377',
      'K0379',
      'K0392',
      'K0395',
      'K0400',
      'K0403',
      'K0408',
      'K0411',
      'K0414',
      'K0417',
      'K0422',
      'K0431',
      'K0432',
      'K0435',
      'K0436',
      'K0444',
      'K0455',
      'K0465',
      'K0499',
      'K0501',
      'K0504',
      'K0506',
      'K0507',
      'K0508',
      'K0511',
      'K0512',
      'K0514',
      'K0538',
      'K0585',
      'K0598',
      'K0599',
    ],
    skills: [
      'S0185',
      'S0186',
      'S0213',
      'S0218',
      'S0249',
      'S0250',
      'S0296',
      'S0297',
      'S0326',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0067',
      'A0068',
      'A0070',
      'A0074',
      'A0077',
      'A0081',
      'A0082',
      'A0085',
      'A0089',
      'A0090',
      'A0094',
      'A0096',
      'A0098',
      'A0105',
    ],
  },
  {
    id: 'CO-OPS-001',
    name: 'サイバーオペレーター',
    specialityArea: 'サイバー作戦 (OPS)',
    category: '収集して操作する (CO)',
    description:
      'システムの収集、処理、および/または地理位置情報を行い、興味の対象を搾取、特定、および/または追跡します。ネットワークナビゲーション、戦術的なフォレンジック分析を行い、指示された場合にはオンネット作戦を実行します。',
    tasks: [
      'T0566',
      'T0567',
      'T0598',
      'T0609',
      'T0610',
      'T0612',
      'T0616',
      'T0618',
      'T0619',
      'T0620',
      'T0623',
      'T0643',
      'T0644',
      'T0664',
      'T0677',
      'T0696',
      'T0697',
      'T0724',
      'T0740',
      'T0756',
      'T0768',
      'T0774',
      'T0796',
      'T0804',
      'T0828',
      'T0829',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0021',
      'K0051',
      'K0109',
      'K0142',
      'K0224',
      'K0363',
      'K0372',
      'K0373',
      'K0375',
      'K0379',
      'K0403',
      'K0406',
      'K0420',
      'K0423',
      'K0427',
      'K0428',
      'K0429',
      'K0430',
      'K0433',
      'K0438',
      'K0440',
      'K0452',
      'K0468',
      'K0480',
      'K0481',
      'K0485',
      'K0486',
      'K0516',
      'K0528',
      'K0530',
      'K0531',
      'K0536',
      'K0560',
      'K0565',
      'K0573',
      'K0608',
      'K0609',
    ],
    skills: [
      'S0062',
      'S0182',
      'S0183',
      'S0190',
      'S0192',
      'S0202',
      'S0206',
      'S0221',
      'S0236',
      'S0242',
      'S0243',
      'S0252',
      'S0255',
      'S0257',
      'S0266',
      'S0267',
      'S0270',
      'S0275',
      'S0276',
      'S0281',
      'S0282',
      'S0293',
      'S0295',
      'S0298',
      'S0299',
      'S0363',
    ],
    abilities: ['A0095', 'A0097', 'A0099', 'A0100'],
  },
  {
    id: 'IN-INV-001',
    name: 'サイバー犯罪捜査官',
    specialityArea: 'サイバー調査 (INV)',
    category: '調査する (IN)',
    description:
      '識別し、収集し、調査し、証拠を保存するために、管理され文書化された分析的及び捜査技術を使用します。',
    tasks: [
      'T0031',
      'T0059',
      'T0096',
      'T0103',
      'T0104',
      'T0110',
      'T0112',
      'T0113',
      'T0114',
      'T0120',
      'T0193',
      'T0225',
      'T0241',
      'T0343',
      'T0346',
      'T0360',
      'T0386',
      'T0423',
      'T0430',
      'T0433',
      'T0453',
      'T0471',
      'T0479',
      'T0523',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0046',
      'K0070',
      'K0107',
      'K0110',
      'K0114',
      'K0118',
      'K0123',
      'K0125',
      'K0128',
      'K0144',
      'K0155',
      'K0156',
      'K0168',
      'K0209',
      'K0231',
      'K0244',
      'K0251',
      'K0351',
      'K0624',
    ],
    skills: ['S0047', 'S0068', 'S0072', 'S0086'],
    abilities: ['A0174', 'A0175'],
  },
  {
    id: 'IN-FOR-001',
    name: '法執行/対諜報フォレンジックアナリスト',
    specialityArea: 'デジタルフォレンジックス（FOR）',
    category: '調査する (IN)',
    description:
      'コンピューターベースの犯罪に関する詳細な調査を行い、サイバー侵入事件に関連するデジタルメディアやログを含む文書または物理的証拠を確立します。',
    tasks: [
      'T0059',
      'T0096',
      'T0220',
      'T0308',
      'T0398',
      'T0401',
      'T0403',
      'T0411',
      'T0419',
      'T0425',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0017',
      'K0021',
      'K0042',
      'K0060',
      'K0070',
      'K0077',
      'K0078',
      'K0107',
      'K0109',
      'K0117',
      'K0118',
      'K0119',
      'K0122',
      'K0123',
      'K0125',
      'K0128',
      'K0131',
      'K0132',
      'K0133',
      'K0134',
      'K0145',
      'K0155',
      'K0156',
      'K0167',
      'K0168',
      'K0179',
      'K0182',
      'K0183',
      'K0184',
      'K0185',
      'K0186',
      'K0187',
      'K0188',
      'K0189',
      'K0305',
      'K0624',
    ],
    skills: [
      'S0032',
      'S0046',
      'S0047',
      'S0062',
      'S0065',
      'S0067',
      'S0068',
      'S0069',
      'S0071',
      'S0073',
      'S0074',
      'S0075',
      'S0087',
      'S0088',
      'S0089',
      'S0090',
      'S0091',
      'S0092',
      'S0093',
    ],
    abilities: ['A0005', 'A0175'],
  },
  {
    id: 'IN-FOR-002',
    name: 'サイバーディフェンスフォレンジックスアナリスト',
    specialityArea: 'デジタルフォレンジックス (FOR)',
    category: '調査する (IN)',
    description:
      'デジタル証拠を分析し、コンピュータセキュリティインシデントを調査して、システム/ネットワークの脆弱性緩和を支援するための有用な情報を導き出します。',
    tasks: [
      'T0027',
      'T0036',
      'T0048',
      'T0049',
      'T0075',
      'T0087',
      'T0103',
      'T0113',
      'T0165',
      'T0167',
      'T0168',
      'T0172',
      'T0173',
      'T0175',
      'T0179',
      'T0182',
      'T0190',
      'T0212',
      'T0216',
      'T0238',
      'T0240',
      'T0241',
      'T0253',
      'T0279',
      'T0285',
      'T0286',
      'T0287',
      'T0288',
      'T0289',
      'T0312',
      'T0396',
      'T0397',
      'T0398',
      'T0399',
      'T0400',
      'T0401',
      'T0432',
      'T0532',
      'T0546',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0018',
      'K0021',
      'K0042',
      'K0060',
      'K0070',
      'K0077',
      'K0078',
      'K0109',
      'K0117',
      'K0118',
      'K0119',
      'K0122',
      'K0123',
      'K0125',
      'K0128',
      'K0131',
      'K0132',
      'K0133',
      'K0134',
      'K0145',
      'K0155',
      'K0156',
      'K0167',
      'K0168',
      'K0179',
      'K0182',
      'K0183',
      'K0184',
      'K0185',
      'K0186',
      'K0187',
      'K0188',
      'K0189',
      'K0224',
      'K0254',
      'K0255',
      'K0301',
      'K0304',
      'K0347',
      'K0624',
    ],
    skills: [
      'S0032',
      'S0047',
      'S0062',
      'S0065',
      'S0067',
      'S0068',
      'S0069',
      'S0071',
      'S0073',
      'S0074',
      'S0075',
      'S0087',
      'S0088',
      'S0089',
      'S0090',
      'S0091',
      'S0092',
      'S0093',
      'S0131',
      'S0132',
      'S0133',
      'S0156',
    ],
    abilities: ['A0005', 'A0043'],
  },
];
