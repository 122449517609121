import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NotificationsService } from '@cybexer/ngx-commons';
import { PAGE_SIZES } from 'app/shared';
import { ChartOptions } from 'chart.js';
import { ThemeService } from 'ng2-charts';
import { Preference } from '../../../models';
import { Theme } from '../../../models/shared/theme.model';
import { PreferenceService } from '../../../services';
import { chartThemeOverrides } from '../../../views/gamenet/shared';

@Component({
  selector: 'isa-users-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  dateFormat: string;
  defaultListSize: number;
  isLightTheme = false;
  private currentTheme = Theme.DARK;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private preferenceService: PreferenceService,
    private notificationsService: NotificationsService,
    private chartThemeService: ThemeService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.preferenceService.getPreferences().subscribe((preferences) => {
      this.dateFormat = preferences.dateFormat;
      this.defaultListSize = preferences.defaultListSize;
      this.isLightTheme = !!preferences.isLightTheme;
    });
  }

  changeDateFormat(dateFormat: string): void {
    this.updatePreference({ dateFormat: dateFormat }, 'Date format has been changed');
  }

  changeDefaultListSize(listSize: number): void {
    this.updatePreference({ defaultListSize: listSize }, 'Default list size has been changed');
  }

  get pageSizes(): number[] {
    return PAGE_SIZES;
  }

  switchTheme(): void {
    this.currentTheme = this.isLightTheme ? Theme.LIGHT : Theme.DARK;
    this.renderer.setAttribute(this.document.body, 'class', this.currentTheme);
    localStorage.setItem('activeTheme', this.currentTheme);
    this.preferenceService.isLightTheme.next(this.isLightTheme);
    this.updatePreference({ isLightTheme: this.isLightTheme }, 'Theme has been changed');
    this.updateChartTheme(this.isLightTheme);
  }

  private updatePreference(preference: Partial<Preference>, successMessage: string) {
    this.preferenceService.updatePreferences(preference).subscribe(() => {
      this.notificationsService.success(successMessage);
    });
  }

  private updateChartTheme(isLightTheme: boolean): void {
    let overrides: ChartOptions = chartThemeOverrides(isLightTheme);
    this.chartThemeService.setColorschemesOptions(overrides);
  }
}
