<canvas id="smart-city-canvas" #canvas></canvas>
@if (selectedCity?.name === 'Tartu') {
  @if (!isVehicleCameraActive) {
    <button class="vehicle-camera" mat-raised-button (click)="switchToTruckCamera()">
      <mat-icon>local_shipping</mat-icon>Follow vehicle
    </button>
  } @else {
    <button class="vehicle-camera" mat-raised-button (click)="mapChange()">
      <mat-icon>location_city</mat-icon>City camera
    </button>
  }
}

@if (isMapOpen) {
  <button (click)="toggleMap()" class="map-close-button" mat-icon-button aria-label="show map">
    <mat-icon>close</mat-icon>
  </button>
} @else {
  <button (click)="toggleMap()" class="map-button" mat-raised-button aria-label="show map">
    <mat-icon>map</mat-icon>
    Map
  </button>
}

@if (loading) {
  <cybexer-progress-spinner></cybexer-progress-spinner>
}
