import { Chart, ChartOptions, InteractionMode, TooltipItem } from 'chart.js';
import { ScoreType } from '../../../models';
import { ScoreEvent } from '../../../models/gamenet/ctf/assessment-report.model';

export function HORIZONTAL_STACKED_CHART_OPTIONS(isLightTheme: boolean): ChartOptions {
  return {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.3)' : 'rgba(220, 220, 220, 0.6)';
            }
            return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
          },
        },
        ticks: {
          autoSkip: false,
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
            size: 14,
          },
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
        grid: {
          color: function (context) {
            // last from top is undefined
            if (context.index === undefined) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
            }
            return 'rgba(0, 0, 0, 0)';
          },
        },
        ticks: {
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          autoSkip: false,
        },
      },
    },
    animation: {
      onProgress: function (animation) {
        addHorizontalStackedChartTotalLabel(animation, this, this.data, isLightTheme);
      },
      onComplete: function (animation) {
        addHorizontalStackedChartTotalLabel(animation, this, this.data, isLightTheme);
      },
    },
    plugins: {
      // @ts-ignore
      barLabelsPlugin: false,
      tooltip: {
        callbacks: {
          labelColor: function (context) {
            return {
              borderColor: context.dataset.backgroundColor as string,
              backgroundColor: context.dataset.backgroundColor as string,
            };
          },
        },
      },
      legend: {
        display: true,
        onClick: (e) => {},
        position: 'bottom',
      },
    },
    layout: {
      padding: {
        right: 100,
      },
    },
  };
}

export function VERTICAL_STACKED_CHART_OPTIONS(isLightTheme: boolean): ChartOptions {
  return {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
            }
            return 'rgba(0, 0, 0, 0)';
          },
        },
        ticks: {
          autoSkip: false,
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
            size: 14,
          },
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.3)' : 'rgba(220, 220, 220, 0.6)';
            }
            return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
          },
        },
        ticks: {
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        onClick: (e) => {},
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          labelColor: function (context) {
            return {
              borderColor: context.dataset.backgroundColor as string,
              backgroundColor: context.dataset.backgroundColor as string,
            };
          },
        },
      },
    },
  };
}

export function SUMMARY_TIMELINE_CHART_OPTIONS(isLightTheme: boolean): ChartOptions {
  return {
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
        hitRadius: 7,
        hoverRadius: 7,
      },
      line: {
        tension: 0.08,
      },
    },
    scales: {
      x: {
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
            }
            return 'rgba(0, 0, 0, 0)';
          },
        },
        ticks: {
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            size: 14,
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.3)' : 'rgba(220, 220, 220, 0.6)';
            }
            return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
          },
        },
        ticks: {
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            size: 14,
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
          },
        },
      },
    },
    plugins: {
      // @ts-ignore
      barLabelsPlugin: false,
      tooltip: {
        callbacks: {
          labelColor: function (context) {
            return {
              borderColor: context.dataset.borderColor as string,
              backgroundColor: context.dataset.borderColor as string,
            };
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
      },
    },
  };
}

export function QUEUE_STATISTICS_TIMELINE_CHART_OPTIONS(isLightTheme: boolean, maxValue: number) {
  const baseChartOptions = SUMMARY_TIMELINE_CHART_OPTIONS(isLightTheme);
  // @ts-ignore
  baseChartOptions.scales.y.ticks.stepSize = 1;
  // @ts-ignore
  baseChartOptions.scales.y.suggestedMax = maxValue;
  return baseChartOptions;
}

export function ASSESSMENT_REPORT_TIMELINE(isLightTheme: boolean): ChartOptions {
  return {
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      point: {
        radius: 0,
        hitRadius: 7,
        hoverRadius: 7,
      },
      line: {
        tension: 0.08,
      },
    },
    scales: {
      x: {
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
            }
            return 'rgba(0, 0, 0, 0)';
          },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
            size: 14,
          },
          // todo make work with new chart version *if still needed*
          // source: 'data',
          // callback: (valueLabel, index, values): string | number => {
          //   const currValue = moment(values[index].value);
          //   if (index === 0 || index === values.length - 1) {
          //     // Show date for first and last label
          //     return valueLabel + currValue.format('L');
          //   }
          //   if (index !== 0) {
          //     const prevValue = moment(values[index - 1].value);
          //     const isPrevValueNotSameDay = prevValue.dayOfYear() !== currValue.dayOfYear();
          //     if (isPrevValueNotSameDay) {
          //       // Show date if the current label is a different day than the previous
          //       return valueLabel + currValue.format('L');
          //     }
          //   }
          //   return valueLabel;
          // },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.3)' : 'rgba(220, 220, 220, 0.6)';
            }
            return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
          },
        },
        ticks: {
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        mode: 'index' as InteractionMode,
        callbacks: {
          label: (item: TooltipItem<'line'>) => {
            const chartPoint = item.raw;
            const scoreEvent: ScoreEvent = chartPoint['scoreEvent']; // Custom field, added in assessment-report component
            return (
              scoreEvent.totalScore +
              ` (${scoreEvent.displayText()}` +
              (scoreEvent.eventScore ? `: ${scoreEvent.eventScore}` : '') +
              ')'
            );
          },
        },
      },
      // @ts-ignore
      barLabelsPlugin: false,
    },
  };
}

export function ASSESSMENT_REPORT_PROGRESS_DOUGHNUT(
  isLightTheme: boolean
): ChartOptions<'doughnut'> {
  return {
    maintainAspectRatio: false,
    cutout: '90%',
    elements: {
      line: {
        tension: 0.3,
      },
      arc: {},
    },
    events: [],
    plugins: {
      // @ts-ignore
      barLabelsPlugin: false,
      legend: {
        display: false,
      },
    },
    animation: {
      onProgress: (animation) => drawDoughnutCenterLabel(animation, isLightTheme),
      onComplete: (animation) => drawDoughnutCenterLabel(animation, isLightTheme),
      duration: 0,
    },
  };
}

/**
 * @param animation - Chart.Animation, see https://www.chartjs.org/docs/latest/configuration/animations.html#animation-callbacks
 * @param isLightTheme
 */
function drawDoughnutCenterLabel(animation, isLightTheme) {
  const chart = animation.chart as Chart;
  const width = chart.width,
    height = chart.height,
    ctx = chart.ctx;
  ctx.restore();
  const fontSize = (height / 114).toFixed(2);
  ctx.font = fontSize + 'em "Exo 2"';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#dddddd';
  // Assuming that we want to display the percentage of the first data entry
  // (assuming it contains the % of which the doughnut should be filled)
  const firstDataValue = chart.data.datasets[0]?.data[0];
  const text = `${firstDataValue}%`,
    textX = Math.round((width - ctx.measureText(text).width) / 2),
    textY = height / 2;
  ctx.fillText(text, textX, textY);
  ctx.save();
}

export function NICE_SUMMARY_DOUGHNUT_OPTIONS(isLightTheme: boolean): ChartOptions<'doughnut'> {
  return {
    maintainAspectRatio: false,
    cutout: '90%',
    elements: {
      line: {
        tension: 0.3,
      },
      arc: {},
    },
    events: [],
    plugins: {
      // @ts-ignore
      barLabelsPlugin: false,
      legend: {
        display: false,
      },
    },
    animation: {
      onProgress: (animation) => drawNiceDoughnutCenterLabel(animation, isLightTheme),
      onComplete: (animation) => drawNiceDoughnutCenterLabel(animation, isLightTheme),
      duration: 0,
    },
  };
}

function drawNiceDoughnutCenterLabel(animation, isLightTheme) {
  const chart = animation.chart as Chart;
  const width = chart.width,
    height = chart.height,
    ctx = chart.ctx;
  ctx.restore();
  const fontSize = (height / 120).toFixed(2);
  ctx.font = fontSize + 'em "Exo 2"';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#dddddd';
  const solvedItems = chart.data.datasets[0].data[0];
  // @ts-ignore
  const totalItems = chart.data.datasets[0].data[0] + chart.data.datasets[0].data[1];
  const solvedRatio = `${solvedItems} / ${totalItems}`,
    solvedRatioX = Math.round((width - ctx.measureText(solvedRatio).width) / 2),
    solvedRatioY = height / 2 - 10;
  ctx.fillText(solvedRatio, solvedRatioX, solvedRatioY);
  ctx.font = '1' + 'em "Exo 2"';
  const label = `${chart.data.datasets[0].label}`,
    labelX = Math.round((width - ctx.measureText(label).width) / 2),
    labelY = height / 2 + 10;
  ctx.fillText(label, labelX, labelY);
  ctx.save();
}

export function getCardChartColors(isLightTheme: boolean) {
  return [
    {
      backgroundColor: isLightTheme ? ['#32c559', '#d6d4d4'] : ['#32c559', '#303841'],
      borderColor: isLightTheme ? ['#32c559', '#d6d4d4'] : ['#32c559', '#303841'],
    },
  ];
}

export function getChartColors(isLightTheme: boolean) {
  return [
    {
      backgroundColor: isLightTheme ? ['#32c559', '#d6d4d4'] : ['#32c559', '#363e48'],
      borderColor: isLightTheme ? ['#32c559', '#d6d4d4'] : ['#32c559', '#363e48'],
    },
  ];
}

export function TEAM_TIMELINE_CHART_OPTIONS(isLightTheme: boolean) {
  return {
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
        hitRadius: 7,
        hoverRadius: 7,
      },
      line: {
        tension: 0.08,
      },
    },
    scales: {
      x: {
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
            }
            return 'rgba(0, 0, 0, 0)';
          },
        },
        ticks: {
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
          },
        },
      },
      y: {
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.3)' : 'rgba(220, 220, 220, 0.6)';
            }
            return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
          },
        },
        ticks: {
          beginAtZero: true,
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
          },
        },
      },
    },
    plugins: {
      // @ts-ignore
      barLabelsPlugin: false,
      tooltip: {
        callbacks: {
          labelColor: function (context) {
            return {
              borderColor: context.dataset.borderColor,
              backgroundColor: context.dataset.borderColor,
            };
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
      },
    },
  };
}

export function BAR_CHART_OPTIONS(isLightTheme: boolean): ChartOptions {
  return {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
            }
            return 'rgba(0, 0, 0, 0)';
          },
        },
        ticks: {
          autoSkip: false,
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
            size: 14,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: function (context) {
            if (context.tick?.value === 0) {
              return isLightTheme ? 'rgba(0, 0, 0, 0.3)' : 'rgba(220, 220, 220, 0.6)';
            }
            return isLightTheme ? 'rgba(0, 0, 0, 0.15)' : 'rgba(220, 220, 220, 0.1)';
          },
        },
        ticks: {
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
            size: 14,
          },
        },
      },
    },
    plugins: {
      // @ts-ignore
      barWidthPlugin: false,
      tooltip: {
        callbacks: {
          labelColor: function (context) {
            return {
              borderColor: context.dataset.backgroundColor as string,
              backgroundColor: context.dataset.backgroundColor as string,
            };
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };
}

function addHorizontalStackedChartTotalLabel(animation, chartInstance, data, isLightTheme) {
  const ctx = chartInstance.ctx;
  ctx.textAlign = 'left';
  ctx.font = '12px "Exo 2"';
  ctx.textBaseline = 'center';
  ctx.fillStyle = isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#dddddd';
  const totalScores: { lastScoreTypeWithValue: ScoreType; total: number }[] = [];
  const labelPadding = 6;

  for (let i = 0; i < data.datasets[0].data.length; i++) {
    let lastScoreTypeWithValue: ScoreType = undefined;
    let total = 0;
    data.datasets.forEach((dataset) => {
      total += dataset.data[i];
      if ((dataset.data[i] as number) != 0) {
        lastScoreTypeWithValue = dataset.label;
      }
    });
    totalScores.push({ lastScoreTypeWithValue, total });
  }

  data.datasets.forEach(function (dataset, i) {
    const meta = chartInstance.getDatasetMeta(i);
    meta.data.forEach(function (bar, index) {
      if (totalScores[index].lastScoreTypeWithValue === dataset.label) {
        const score = dataset.data[index];
        const scoreYPosition = bar.getProps(['y']).y;
        const scoreXPosition =
          (score < 0 ? bar.getProps(['base']).base : bar.getProps(['x']).x) + labelPadding;
        ctx.textAlign = 'left';
        ctx.fillText(totalScores[index].total.toFixed(2), scoreXPosition, scoreYPosition);
      }
    });
  });
}

export class ChartBackgroundColorConfig {
  backgroundColor: string;

  constructor(options: { color: string }) {
    this.backgroundColor = options.color;
  }
}

export class ChartBorderColorConfig {
  borderColor: string;
  backgroundColor: string;
  borderWidth = 4;

  constructor(options: { borderColor: string; backgroundColor: string }) {
    this.borderColor = options.borderColor;
    this.backgroundColor = options.backgroundColor;
  }
}

class ChartScoreData {
  mainColor: string;
  secondaryColor: string;

  constructor(options: { mainColor: string; secondaryColor: string }) {
    this.mainColor = options.mainColor;
    this.secondaryColor = options.secondaryColor;
  }
}

export class ChartColorConfigurator {
  static SUMMARY_TIMELINE_CHART_COLORS = [
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#f58231',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#f032e6',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#008080',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#e6194b',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#3cb44b',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#ffe119',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#4363d8',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#911eb4',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#46f0f0',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#bcf60c',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#fabebe',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#e6beff',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#9a6324',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#fffac8',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#800000',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#aaffc3',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#808000',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#ffd8b1',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#000075',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#808080',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#4e805f',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#859bc3',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#f4ff99',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#5cff69',
    }),
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#408491',
    }),
  ];

  static ASSESSMENT_REPORT_TIMELINE_CHART_COLORS = [
    new ChartBorderColorConfig({
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: '#d55858',
    }),
  ];

  private static CHART_SCORE_TYPE_COLOR_CONFIG = {
    // COMMON
    [ScoreType.SPECIAL]: new ChartScoreData({
      mainColor: '#EF7126',
      secondaryColor: 'rgba(239, 113, 38, 0.1)',
    }),
    [ScoreType.TOTAL]: new ChartScoreData({
      mainColor: '#9a4db8',
      secondaryColor: 'rgba(239, 113, 38, 0)',
    }),
    // CAMPAIGN
    [ScoreType.ATTACK_REPORTS]: new ChartScoreData({
      mainColor: '#d6474f',
      secondaryColor: 'rgba(214, 71, 79, 0.1)',
    }),
    [ScoreType.AVAILABILITY]: new ChartScoreData({
      mainColor: '#32c559',
      secondaryColor: 'rgba(50, 197, 89, 0.1)',
    }),
    [ScoreType.INCIDENT_REPORTS]: new ChartScoreData({
      mainColor: '#6CCECB',
      secondaryColor: 'rgba(108, 206, 203, 0.1)',
    }),
    [ScoreType.RESTORE_FROM_BACKUP]: new ChartScoreData({
      mainColor: '#E1C056',
      secondaryColor: 'rgba(250, 217, 87, 0.1)',
    }),
    [ScoreType.SITUATION_REPORTS]: new ChartScoreData({
      mainColor: '#218C8D',
      secondaryColor: 'rgba(33, 140, 141, 0.1)',
    }),
    // HYBRID CTF
    [ScoreType.ABANDON_TASK]: new ChartScoreData({
      mainColor: '#a83e60',
      secondaryColor: 'rgba(168, 62, 96, 0.1)',
    }),
    [ScoreType.SOLVE_TASK]: new ChartScoreData({
      mainColor: '#36a771',
      secondaryColor: 'rgba(54, 167, 113, 0.1)',
    }),
    [ScoreType.USE_HINT]: new ChartScoreData({
      mainColor: '#5d75a0',
      secondaryColor: 'rgba(93, 117, 160, 0.1)',
    }),
    // VERSUS
    [ScoreType.ATTACK_VERSUS]: new ChartScoreData({
      mainColor: '#d6474f',
      secondaryColor: 'rgba(214, 71, 79, 0.1)',
    }),
    [ScoreType.DEFEND_VERSUS]: new ChartScoreData({
      mainColor: '#6ccecb',
      secondaryColor: 'rgba(93, 117, 160, 0.1)',
    }),
    [ScoreType.ABANDON_ATTACK]: new ChartScoreData({
      mainColor: '#f3759b',
      secondaryColor: 'rgba(168, 62, 96, 0.1)',
    }),
    [ScoreType.INVALID_FLAG]: new ChartScoreData({
      mainColor: '#b90036',
      secondaryColor: 'rgba(119,0,38,0.1)',
    }),
  };

  private static CTF_CHART_SCORE_TYPE_COLOR_CONFIG = {
    // COMMON
    [ScoreType.SPECIAL]: new ChartScoreData({
      mainColor: '#EF7126',
      secondaryColor: 'rgba(239, 113, 38, 0.1)',
    }),
    [ScoreType.TOTAL]: new ChartScoreData({
      mainColor: '#e3e3e3',
      secondaryColor: 'rgba(239, 113, 38, 0)',
    }),
    // CTF
    [ScoreType.ABANDON_TASK]: new ChartScoreData({
      mainColor: '#d6474f',
      secondaryColor: 'rgba(214, 71, 79, 0.1)',
    }),
    [ScoreType.SOLVE_TASK]: new ChartScoreData({
      mainColor: '#32c559',
      secondaryColor: 'rgba(50, 197, 89, 0.1)',
    }),
    [ScoreType.USE_HINT]: new ChartScoreData({
      mainColor: '#6ccecb',
      secondaryColor: 'rgba(108, 206, 203, 0.1)',
    }),
    // VERSUS
    [ScoreType.ATTACK_VERSUS]: new ChartScoreData({
      mainColor: '#d6474f',
      secondaryColor: 'rgba(214, 71, 79, 0.1)',
    }),
    [ScoreType.DEFEND_VERSUS]: new ChartScoreData({
      mainColor: '#6ccecb',
      secondaryColor: 'rgba(93, 117, 160, 0.1)',
    }),
    [ScoreType.ABANDON_ATTACK]: new ChartScoreData({
      mainColor: '#f3759b',
      secondaryColor: 'rgba(168, 62, 96, 0.1)',
    }),
    [ScoreType.INVALID_FLAG]: new ChartScoreData({
      mainColor: '#b90036',
      secondaryColor: 'rgba(119,0,38,0.1)',
    }),
  };

  static createBackgroundColorConfig(scoreType: ScoreType, isCTF = false): any {
    return new ChartBackgroundColorConfig({
      color: isCTF
        ? this.CTF_CHART_SCORE_TYPE_COLOR_CONFIG[scoreType].mainColor
        : this.CHART_SCORE_TYPE_COLOR_CONFIG[scoreType].mainColor,
    });
  }

  static createBorderColorConfig(scoreType: ScoreType, isCTF = false): any {
    const chartScoreData: ChartScoreData = isCTF
      ? this.CTF_CHART_SCORE_TYPE_COLOR_CONFIG[scoreType]
      : this.CHART_SCORE_TYPE_COLOR_CONFIG[scoreType];
    return new ChartBorderColorConfig({
      borderColor: chartScoreData.mainColor,
      backgroundColor: chartScoreData.secondaryColor,
    });
  }
}

export const TOTAL_SCORE_CHART_COLOR: any[] = [{ backgroundColor: '#9a4db8' }];

export const SCORE_TYPES_ORDER = [
  ScoreType.INVALID_FLAG,
  ScoreType.USE_HINT,
  ScoreType.ATTACK_REPORTS,
  ScoreType.ATTACK_VERSUS,
  ScoreType.DEFEND_VERSUS,
  ScoreType.RESTORE_FROM_BACKUP,
  ScoreType.ABANDON_TASK,
  ScoreType.ABANDON_ATTACK,
  ScoreType.SPECIAL,
  ScoreType.INCIDENT_REPORTS,
  ScoreType.SITUATION_REPORTS,
  ScoreType.SOLVE_TASK,
  ScoreType.AVAILABILITY,
].map((scoreType) => scoreType.replace(new RegExp('_', 'g'), ' '));

export const CTF_SCORE_TYPES_ORDER = [
  ScoreType.USE_HINT,
  ScoreType.ABANDON_TASK,
  ScoreType.SPECIAL,
  ScoreType.SOLVE_TASK,
].map((scoreType) => scoreType.replace(new RegExp('_', 'g'), ' '));

// todo move as much colors here as possible
export function chartThemeOverrides(isLightTheme: boolean): ChartOptions {
  return {
    elements: {
      arc: {
        backgroundColor: isLightTheme ? ['#32c559', '#d6d4d4'] : ['#32c559', '#363e48'],
        borderColor: isLightTheme ? ['#32c559', '#d6d4d4'] : ['#32c559', '#363e48'],
      },
    },
    plugins: {
      legend: {
        labels: {
          color: isLightTheme ? 'rgba(0, 0, 0, 0.87)' : '#E3E3E3',
          font: {
            family: '"Exo 2", Roboto, "Helvetica Neue", sans-serif',
          },
        },
      },
    },
  };
}

export function registerBarLabelsPlugin() {
  Chart.register({
    id: 'barLabelsPlugin',
    afterDatasetsDraw: function (chart, args, options) {
      const ctx = chart.ctx;
      ctx.textAlign = 'center';
      ctx.font = '13px "Exo 2"';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = '#eeeeee';
      chart.data.datasets.forEach(function (dataset, i) {
        const meta = chart.getDatasetMeta(i);
        meta.data.forEach(function (bar, index) {
          const score = dataset.data[index];
          const scoreYPosition = (bar.getProps(['y']).y + bar.getProps(['base']).base) / 2;
          const scoreXPosition = bar.getProps(['x']).x;
          const isBarHeightTallEnoughForShowingScore =
            bar.getProps(['width']).width > 75 &&
            Math.abs(bar.getProps(['base']).base - bar.getProps(['y']).y) > 20;
          if (isBarHeightTallEnoughForShowingScore) {
            ctx.fillText(score.toString(), scoreXPosition, scoreYPosition);
          }
        });
      });
    },
  });
}
