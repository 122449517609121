import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isaIncidentTypeName',
})
export class IncidentTypeNamePipe implements PipeTransform {
  transform(value: string) {
    let type: string;
    switch (value) {
      case 'ONLINE':
        type = 'ui.online';
        break;
      case 'OFFLINE':
        type = 'ui.offline';
        break;
      case 'COMPROMISED':
        type = 'ui.compromised';
        break;
      case 'NOT_COMPROMISED':
        type = 'ui.notCompromised';
        break;
      default:
        type = value;
        break;
    }
    return type;
  }
}
