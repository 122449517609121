import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { IsaErrorMessage, IsaErrorType } from './models';
import { AuthenticationService } from './services';
import { EXERCISE_PERMISSIONS } from './shared';

export const ERROR_CODES = {
  INTERNAL_ERROR: 'Oops, we managed to produce error 500',
  INVALID_REQUEST: 'Request is not valid',
  UNAUTHENTICATED_REQUEST: 'Request is not authenticated',
  UNAUTHORIZED_REQUEST: 'Request is not authorized',
  ENTITY_NOT_FOUND: 'Resource not found',
};

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler implements ErrorHandler {
  private cachedRouter: Router;

  constructor(
    private injector: Injector,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private zone: NgZone
  ) {}

  handleError(error: any): void {
    if (error.rejection && error.rejection.code) {
      this.processByRejectionCode(error);
    } else if (error.status != null) {
      this.processByStatus(error);
    } else if (this.isSessionHttpError(error)) {
      // if a session resolver get http error, we need to redirect to global error page.
      this.zone.run(() => this.router.navigateByUrl('/error/' + error.rejection.status));
    } else {
      this.showError('Error occurred', 'Please refresh the page if any problems persist');
    }

    try {
      Sentry.captureException(AppErrorHandler.extractError(error));
    } catch (e) {
      console.error(e);
    }

    throw error;
  }

  private processByRejectionCode(error: any) {
    switch (error.rejection.code) {
      case ERROR_CODES.UNAUTHENTICATED_REQUEST:
        this.process401Error();
        break;
      case ERROR_CODES.UNAUTHORIZED_REQUEST:
        this.process403Error();
        break;
      case ERROR_CODES.ENTITY_NOT_FOUND:
        this.process404Error();
        break;
      default:
        this.showError('Error occurred', error.statusText);
    }
  }

  private processByStatus(error: any) {
    switch (error.status) {
      case 400:
        this.process400Error(error);
        break;
      case 401:
        this.process401Error();
        break;
      case 403:
        this.process403Error(error);
        break;
      case 404:
        this.process404Error(error);
        break;
      case 500:
        this.process500Error();
        break;
      case 504:
      case 0:
        this.showConnectionError('Attempting to reconnect to your session...', '');
        break;
      default:
        this.showError('Error occurred', error.statusText);
    }
  }

  private isSessionHttpError(error: any): boolean {
    return (
      error.rejection &&
      error.rejection.status !== 0 &&
      error.rejection.url &&
      error.rejection.url.indexOf('auth/whoami') !== -1
    );
  }

  private get router(): Router {
    if (!this.cachedRouter) {
      this.cachedRouter = this.injector.get(Router);
    }
    return this.cachedRouter;
  }

  private process401Error() {
    const path = window.location.pathname;
    if (path.indexOf('login') === -1) {
      this.zone.run(() => {
        const externalUrl = this.authenticationService.currentUser.redirectUrl;
        if (externalUrl != null) {
          window.location.href = externalUrl;
          return;
        }

        this.router.navigateByUrl('/intro/login/' + encodeURIComponent(path));
      });
    }
  }

  private process403Error(error: HttpErrorResponse = null) {
    const currentUser = this.authenticationService.currentUser;
    const isObserver = currentUser && currentUser.isObserver();
    if (isObserver) {
      this.authenticationService.logout().subscribe(() => {
        this.zone.run(() => this.router.navigateByUrl('/intro/login/'));
      });
    } else {
      this.authenticationService
        .hasPermission(EXERCISE_PERMISSIONS.WAITING_ROOM, false)
        .subscribe((hasWaitingRoomPermission) => {
          if (hasWaitingRoomPermission && !currentUser.isAdmin && !currentUser.isGamenetAdmin()) {
            this.zone.run(() => this.router.navigateByUrl(`/app/gamenet/waiting-room`));
          } else {
            this.showError(
              ERROR_CODES.UNAUTHORIZED_REQUEST,
              AppErrorHandler.getIsaErrorMessage(error)
            );
          }
        });
    }
  }

  private process404Error(error: HttpErrorResponse = null) {
    this.showError(ERROR_CODES.ENTITY_NOT_FOUND, AppErrorHandler.getIsaErrorMessage(error));
  }

  private process500Error() {
    this.showError(ERROR_CODES.INTERNAL_ERROR, 'Please try again later');
  }

  private process400Error(error: any = null) {
    this.showError(ERROR_CODES.INVALID_REQUEST, AppErrorHandler.getIsaErrorMessage(error));
  }

  private showError(title: string, content?: string) {
    this.zone.run(() => this.notificationsService.error(title, content));
  }

  private showConnectionError(title: string, content?: string) {
    this.zone.run(() => this.notificationsService.info(title, content));
  }

  // Error extractor from Sentry's repository
  // https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts
  private static extractError(error: any) {
    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof Error) {
        return error.error;
      }

      if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
      }

      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      return error.message;
    }

    if (error.error && error.error.message) {
      return error.error.message;
    }

    if (error.message) {
      return error.message;
    }

    return null;
  }

  private static getIsaErrorMessage(errorResponse?: HttpErrorResponse): string {
    if (errorResponse == null) {
      return '';
    }

    const isaErrorMessage = AppErrorHandler.getIsaError(errorResponse);
    if (isaErrorMessage != null && isaErrorMessage.type !== IsaErrorType.INTERNAL_ERROR) {
      return errorResponse.error.error;
    }

    return '';
  }

  static getIsaError(errorResponse: HttpErrorResponse): IsaErrorMessage {
    return errorResponse.error ? new IsaErrorMessage(errorResponse.error) : null;
  }
}
