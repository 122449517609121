import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmartCityService } from '../../../services/gamenet/smart-city.service';
import {
  AuthenticationService,
  ExerciseService,
  IntervalService,
  MissionBoardService,
  TargetService,
} from '../../../services';
import {
  BlueTeam,
  CTFTaskDTO,
  Exercise,
  ExerciseType,
  SmartCityBuildingDetails,
  SmartCityEventLog,
  SmartCityMapConfiguration,
  SmartCityTargetData,
  SmartCityTaskData,
  Target,
} from '../../../models';
import { forkJoin, Observable, of, Subscription, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FilterStateModel, FilterStateService } from '../../../shared';
import { SmartCityBuilding } from '../../../models';
import { first, take } from 'rxjs/operators';

@Component({
  selector: 'isa-smart-city',
  templateUrl: './smart-city.component.html',
  styleUrls: ['./smart-city.component.scss'],
})
@UntilDestroy()
export class SmartCityComponent implements OnInit {
  teams: BlueTeam[] = [];
  cities: SmartCityMapConfiguration[] = [
    {
      name: 'Tartu',
      sceneFile: 'TartuCity.gltf',
      scenePath: 'assets/3D_geo/Tartu/',
      fogStart: 60,
      fogEnd: 90,
      height: 40,
      depth: 40,
      radioTowerCoordinates: [
        { x: -7.5, y: 0, z: 3.5 },
        { x: 9, y: 0, z: -5 },
        { x: 12, y: 0, z: 11 },
      ],
      waterTreatmentPlantCoordinates: { x: -7.5, y: 0, z: 13.5 },
      drone: {
        coordinates: { x: -15, y: 2, z: 8 },
        buildingId: 'ID_airport1',
        affectedAnimationName: 'Finnair',
      },
    },
    {
      name: 'Baltimore',
      sceneFile: 'Boston_city.gltf',
      scenePath: 'assets/3D_geo/',
      fogStart: 30,
      fogEnd: 50,
      height: 20,
      depth: 20,
    },
    {
      name: 'Battlelab',
      sceneFile: 'battlelab.gltf',
      scenePath: 'assets/3D_geo/Battlelab/',
      fogStart: 30,
      fogEnd: 50,
      height: 20,
      depth: 20,
    },
    {
      name: 'Cheltenham',
      sceneFile: 'Cheltenham_s.gltf',
      scenePath: 'assets/3D_geo/Cheltenham/',
      fogStart: 50,
      fogEnd: 80,
      height: 20,
      depth: 20,
    },
    {
      name: 'London',
      sceneFile: 'london.gltf',
      scenePath: 'assets/3D_geo/London/',
      fogStart: 50,
      fogEnd: 80,
      height: 20,
      depth: 20,
    },
  ];
  selectedCity = this.cities[0];
  isLogOpen = false;
  isInfoPanelOpen = this.smartCityService.isInfoPanelOpen;
  filter$: Observable<Partial<FilterStateModel>>;
  eventLogs: SmartCityEventLog[] = [];
  selectedBuildingDetails: SmartCityBuildingDetails;
  selectedTarget?: SmartCityTargetData;
  selectedTask?: SmartCityTaskData;
  exercise: Exercise;
  isAdmin: boolean;
  targets: Target[];
  ctfTasks: CTFTaskDTO[];
  teamId: string;
  cityState: any;
  selectedMeshId: string;
  intervalSubscription: Subscription;

  constructor(
    private smartCityService: SmartCityService,
    private intervalService: IntervalService,
    private exerciseService: ExerciseService,
    private authenticationService: AuthenticationService,
    private targetService: TargetService,
    private missionBoardService: MissionBoardService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.filter$ = this.filterStateService.filter$('team');
    this.getExercise();
    this.isAdmin = this.authenticationService.currentUser.isAdmin;
    this.filter$.pipe(untilDestroyed(this)).subscribe((filter) => {
      if (filter.team) {
        this.teamId = filter.team;
        this.loadData();
      }
    });
  }

  getExercise() {
    this.exerciseService
      .getActiveExercise()
      .pipe(untilDestroyed(this))
      .subscribe((exercise) => {
        this.exercise = exercise;
        this.teams = exercise.blueTeams;
        this.filterStateService.setFilterIfEmptyOrDefault('team', this.exercise.blueTeams[0].id);
      });
  }

  loadData() {
    if (this.exercise == null || this.teamId == null) return;
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    this.getTargets(this.teamId);
    this.getTasks(this.teamId);

    this.intervalSubscription = this.intervalService
      .getWidgetRefreshInterval()
      .pipe(
        switchMap(() => {
          return forkJoin([
            this.smartCityService.getData(this.exercise.id, this.teamId),
            this.smartCityService.getEventLogs(this.exercise.id, this.teamId),
          ]);
        }),
        untilDestroyed(this)
      )
      .subscribe(([cityState, logs]) => {
        this.cityState = cityState;
        this.eventLogs = logs;
      });
  }

  getTasks(team: string) {
    if (this.exercise.type !== ExerciseType.CAMPAIGN) {
      this.missionBoardService
        .getData(this.exercise.id, team)
        .pipe(first())
        .subscribe((mission) => {
          this.ctfTasks = mission.tasks;
        });
    }
  }

  getTargets(team: string) {
    if (this.exercise.type !== ExerciseType.CTF) {
      this.targetService
        .getTargets(this.exercise.id, team)
        .pipe(first())
        .subscribe((targets) => (this.targets = targets.targets));
    }
  }

  getDataByID(id: string): SmartCityBuilding {
    if (!this.cityState) return;
    return this.cityState.find((item: any) => item.id === id);
  }

  showInfoPanel(buildingId: string) {
    this.selectedMeshId = buildingId;
    const buildingWidgetData = this.getDataByID(buildingId);
    this.selectedTarget = buildingWidgetData?.target;
    this.selectedTask = buildingWidgetData?.task;

    const buildingDetails$ =
      buildingWidgetData != null
        ? of(this.convertToDetails(buildingWidgetData))
        : this.smartCityService
            .getBuildingDetails(this.exercise.id, this.teamId, buildingId)
            .pipe(take(1));

    buildingDetails$.subscribe((details) => {
      this.selectedBuildingDetails = details;
      this.isInfoPanelOpen.set(true);
    });
  }

  convertToDetails(building: SmartCityBuilding): SmartCityBuildingDetails {
    const details = new SmartCityBuildingDetails();

    details.id = building.id;
    details.type = building.buildingType;
    details.address = building.address;
    details.dependencies = building.dependencies;
    details.media = building.media;

    return details;
  }
}
