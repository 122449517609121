import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IPreference, Preference } from '../../models';

@Injectable()
export class PreferenceService {
  currentPreferences: Preference;
  isLightTheme: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.isLightTheme = new BehaviorSubject<boolean>(null);
  }

  getPreferences(forceRefresh: boolean = false): Observable<Preference> {
    if (this.currentPreferences && !forceRefresh) {
      return of(this.currentPreferences);
    }
    return this.http.get<Preference>('api/preferences').pipe(
      map((data) => new Preference(data)),
      tap((preference) => (this.currentPreferences = preference)),
      catchError((err: unknown) => {
        if (!(err instanceof HttpErrorResponse)) throw err;
        if (err.status === 401) {
          this.router.navigate(['intro/login']);
        }
        return of(null);
      })
    );
  }

  updatePreferences(pref: IPreference): Observable<Preference> {
    // Merge preferences, so that any fields that are not given in input would not be
    // assigned as null but would stay what they were instead.
    const mergedPreferences = { ...this.currentPreferences, ...pref };
    return this.http.put<Preference>('api/preferences', mergedPreferences).pipe(
      map((data) => new Preference(data)),
      tap((preference) => (this.currentPreferences = preference))
    );
  }
}
