import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { ThemeService } from 'ng2-charts';
import QuillNamespace from 'quill';
import { firstValueFrom } from 'rxjs';
import { Theme } from './models/shared/theme.model';
import { AuthenticationService, PreferenceService, SettingsService } from './services';
import { IndentStyle } from './shared';
import { chartThemeOverrides, registerBarLabelsPlugin } from './views/gamenet/shared';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LanguageUtils } from './shared/language.utils';

const Quill: any = QuillNamespace;
const AlignStyle = Quill.import('attributors/style/align');
const SizeStyle = Quill.import('attributors/style/size');

Quill.register(AlignStyle, true);
Quill.register(SizeStyle, true);
Quill.register(IndentStyle, true);

@UntilDestroy()
@Component({
  selector: 'isa-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isLightTheme: boolean = false;
  private currentTheme = Theme.DARK;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private preferenceService: PreferenceService,
    private authenticationService: AuthenticationService,
    private settingsService: SettingsService,
    private chartThemeService: ThemeService,
    private translate: TranslateService,
    private languageUtils: LanguageUtils,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setTheme();
    registerBarLabelsPlugin();
    this.settingsService
      .getSettings()
      .subscribe(() => this.translate.use(this.languageUtils.getLanguage()));
  }

  async setTheme(): Promise<void> {
    const isUserLoggedIn = await firstValueFrom(this.authenticationService.isUserLoggedIn());

    if (isUserLoggedIn) {
      this.isLightTheme = (
        await firstValueFrom(this.preferenceService.getPreferences())
      )?.isLightTheme;
      this.currentTheme = this.isLightTheme ? Theme.LIGHT : Theme.DARK;
    } else {
      const activeTheme = localStorage.getItem('activeTheme');
      if (activeTheme) {
        this.currentTheme = activeTheme as Theme;
        this.isLightTheme = this.currentTheme === Theme.LIGHT;
      } else {
        this.isLightTheme = (
          await firstValueFrom(this.settingsService.getSettings())
        )?.isLightTheme;
        this.currentTheme = this.isLightTheme ? Theme.LIGHT : Theme.DARK;
      }
    }

    this.preferenceService.isLightTheme.next(this.isLightTheme);
    this.renderer.setAttribute(this.document.body, 'class', this.currentTheme);
    this.updateChartTheme(this.isLightTheme);
  }

  private updateChartTheme(isLightTheme: boolean): void {
    let overrides: ChartOptions = chartThemeOverrides(isLightTheme);
    this.chartThemeService.setColorschemesOptions(overrides);
  }
}
