export const TEMPLATE = {
  section1: 'ui.situationReport.template.section1',
  section2: 'ui.situationReport.template.section2',
  section3: 'ui.situationReport.template.section3',
  section4: 'ui.situationReport.template.section4',
  section5: 'ui.situationReport.template.section5',
  section6: 'ui.situationReport.template.section6',
};

export const MOCK_TEMPLATE =
  '' +
  '<h1>undefined</h1>' +
  '<p><br></p><p><br></p>' +
  '<h1>undefined</h1>' +
  '<p><br></p><p><br></p>' +
  '<h1>undefined</h1>' +
  '<p><br></p><p><br></p>' +
  '<h1>undefined</h1>' +
  '<p><br></p><p><br></p>' +
  '<h1>undefined</h1>' +
  '<p><br></p><p><br></p>' +
  '<h1>undefined</h1>' +
  '<p><br></p><p><br></p>';
