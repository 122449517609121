import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LanguageUtils } from '../../shared/language.utils';

@Injectable({
  providedIn: 'root',
})
export class TranslationService implements TranslateLoader {
  private static TRANSLATION_API_PATH = 'api/translation';

  constructor(private http: HttpClient) {}

  getTranslation(lang: string, namespaces?: string[]): Observable<any> {
    return this.http.get(
      `${TranslationService.TRANSLATION_API_PATH}/${lang}/${namespaces ? namespaces : LanguageUtils.NAMESPACES}`
    );
  }
}
