import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Column, ColumnType } from '@cybexer/ngx-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { debounceTime, filter, finalize, tap } from 'rxjs/operators';
import {
  BlueTeam,
  Exercise,
  Meta,
  ScoreType,
  ScoringLogData,
  ScoringLogItem,
} from '../../../models';
import { DatePipe, FixedNumberPipe, TeamNamePipe } from '../../../pipes';
import { AuthenticationService, ExerciseService, ScoringLogService } from '../../../services';
import { FilterStateModel, FilterStateService, RoleCode, ROLES } from '../../../shared';
import { ScoringLogDetailsDialogComponent } from './scoring-log-details-dialog/scoring-log-details-dialog.component';
import { TranslatePipe } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'isa-scoring-log',
  templateUrl: 'scoring-log.component.html',
  styleUrls: ['scoring-log.component.scss'],
})
export class ScoringLogComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;

  detailsDialogRef: MatDialogRef<ScoringLogDetailsDialogComponent>;
  exercise: Exercise;
  teams: BlueTeam[] = [];
  categories: string[] = [];
  scoringLogData: ScoringLogItem[] = [];
  scoringLogColumns: Column[] = [];
  metaData: Meta;
  timestamp: Date;
  infiniteScrollDisabled: boolean;
  loading: boolean;
  ROLES = ROLES;
  isAdmin = false;
  userRole: RoleCode;

  private page = 1;
  private readonly size = 25;

  constructor(
    private dialog: MatDialog,
    private exerciseService: ExerciseService,
    private scoringLogService: ScoringLogService,
    private authenticationService: AuthenticationService,
    private translate: TranslatePipe,
    private datePipe: DatePipe,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.authenticationService.currentUser.isAdmin;
    this.filter$ = this.filterStateService.filter$(
      'team',
      'scoreCategories',
      'startDate',
      'endDate'
    );
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        tap((exercise) => {
          this.infiniteScrollDisabled = true; // disable infiniteScroll during update
          this.exercise = exercise;
          this.teams = exercise.blueTeams;
          this.userRole = this.authenticationService.getRole(exercise.id);
          this.page = 1;
          this.scoringLogData = [];
          this.timestamp = null;
          this.infiniteScrollDisabled = false;
          if (!this.isAdmin) {
            this.isAdmin = this.authenticationService.currentUser.isGamenetAdmin(this.exercise.id);
          }
        }),
        switchMap((exercise: Exercise) =>
          forkJoin([
            this.userRole === this.ROLES.BLUE
              ? this.exerciseService.getUserBlueTeam(exercise.id)
              : of(null),
            this.exerciseService.getExerciseScoreTypes(exercise.id),
          ])
        ),
        tap(([userBlueTeamId, scoreTypes]) => {
          if (this.userRole === this.ROLES.BLUE && userBlueTeamId) {
            this.filterStateService.setFilterIfEmptyOrDefault('team', userBlueTeamId);
          }
          this.categories = scoreTypes;
        }),
        switchMap(() => this.filter$),
        debounceTime(50),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.refresh();
      });

    this.initTable();
  }

  private initTable(): void {
    this.scoringLogColumns = [
      new Column({
        name: 'Time',
        translateKey: 'ui.time',
        dataKey: 'timestamp',
        pipeFn: (data) => this.datePipe.transform(data, 'medium'),
      }),
      new Column({
        name: 'Category',
        translateKey: 'ui.scoring.category',
        dataKey: 'category',
        pipeFn: (data) => this.translate.transform('ui.' + data.toLowerCase()),
      }),
      new Column({
        name: this.exercise?.isIndividualAssessment ? 'User' : 'Team',
        translateKey: this.exercise?.isIndividualAssessment ? 'ui.user' : 'ui.team',
        dataKey: 'teamId',
        pipeFn: (data) => new TeamNamePipe(this.exerciseService).transform(data),
      }),
      new Column({
        name: 'Score',
        translateKey: 'ui.scoring.score',
        dataKey: 'score',
        position: 'right',
        type: ColumnType.SCORE,
        pipeFn: (data) => new FixedNumberPipe().transform(data, 4),
      }),
      new Column({
        name: 'Special details',
        translateKey: 'ui.scoring.specialDetails',
        isNameHidden: true,
        type: ColumnType.ACTION,
        label: 'Show details',
        icon: 'fas fa-info-circle',
        narrow: true,
        isVisibleFn: (data) => data.category === ScoreType.SPECIAL,
        actionFn: (data) => this.openSpecialScoringMessageDetailsDialog(data),
      }),
    ];
  }

  onScroll(): void {
    this.infiniteScrollDisabled = true;
    this.page++;
    this.getScoringLogData();
  }

  getScoringLogData(): void {
    this.loading = true;
    const filter: FilterStateModel = this.filterStateService.snapshot();
    this.scoringLogService
      .getLog(
        this.exercise.id,
        filter.team,
        filter.scoreCategories,
        filter.startDate,
        filter.endDate,
        this.timestamp,
        this.page,
        this.size
      )
      .pipe(
        finalize(() => (this.loading = false)),
        untilDestroyed(this)
      )
      .subscribe((res: ScoringLogData) => {
        if (!this.timestamp) {
          this.timestamp = new Date(res.meta.timestamp);
        }
        this.scoringLogData = this.scoringLogData.concat(res.data);
        this.metaData = res.meta;
        this.infiniteScrollDisabled = res.data.length === 0;
      });
  }

  refresh(): void {
    if (!this.exercise) return;

    this.page = 1;
    this.scoringLogData = [];
    this.infiniteScrollDisabled = false;
    this.timestamp = null;
    this.getScoringLogData();
  }

  openSpecialScoringMessageDetailsDialog(scoringLogItem: ScoringLogItem) {
    this.detailsDialogRef = this.dialog.open(ScoringLogDetailsDialogComponent, {
      disableClose: false,
      data: {
        exerciseId: this.exercise.id,
        scoringLogItem: scoringLogItem,
      },
    });
  }
}
