import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, skip, switchMap, tap } from 'rxjs/operators';
import { TaskReportsDatasource } from '../../../../datasources';
import {
  BlueTeam,
  Exercise,
  ReportFilter,
  TaskReportConfirmation,
  TaskReportsData,
} from '../../../../models';
import {
  AuthenticationService,
  ExerciseService,
  IntervalService,
  PreferenceService,
  TaskReportsService,
} from '../../../../services';
import {
  DEFAULT_PAGE_SIZE,
  EXERCISE_PERMISSIONS,
  FilterStateModel,
  FilterStateService,
  PAGE_SIZES,
  RoleCode,
  ROLES,
} from '../../../../shared';
import { TaskReportConfirmDialogComponent } from './task-report-confirm-dialog/task-report-confirm-dialog.component';

@UntilDestroy()
@Component({
  selector: 'isa-task-reports',
  templateUrl: './task-reports.component.html',
  styleUrls: ['./task-reports.component.scss'],
})
export class TaskReportsComponent implements OnInit, AfterViewInit {
  static DEFAULT_LIMIT = 25;
  static DEFAULT_OFFSET = 0;

  filter$: Observable<Partial<FilterStateModel>>;

  exercise: Exercise;
  teams: BlueTeam[] = [];
  taskConfirmDialogRef: MatDialogRef<TaskReportConfirmDialogComponent>;
  textFilterFormControl = new UntypedFormControl();
  ROLES = ROLES;
  userRole: RoleCode;
  loading = true;
  isAdmin: boolean;
  readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;

  // table
  readonly COLUMNS = {
    TIMESTAMP: 'timestamp',
    TITLE: 'title',
    CATEGORY: 'category',
    TEAM_NAME: 'teamName',
    SCORE: 'score',
    STATUS: 'status',
    WHITE_TEAM_MEMBER: 'whiteTeamMember',
  };
  readonly displayedColumns = [
    this.COLUMNS.TIMESTAMP,
    this.COLUMNS.TITLE,
    this.COLUMNS.CATEGORY,
    this.COLUMNS.TEAM_NAME,
    this.COLUMNS.SCORE,
    this.COLUMNS.STATUS,
    this.COLUMNS.WHITE_TEAM_MEMBER,
  ];
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  dataSource: TaskReportsDatasource;

  constructor(
    private dialog: MatDialog,
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private taskReportsService: TaskReportsService,
    private authenticationService: AuthenticationService,
    private preferenceService: PreferenceService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.isAdmin = this.authenticationService.currentUser.isAdmin;
    this.filter$ = this.filterStateService.filter$('team', 'pendingConfirmationOnly');
    this.dataSource = new TaskReportsDatasource(this.taskReportsService);
    this.paginator.pageSize =
      this.preferenceService.currentPreferences.defaultListSize || DEFAULT_PAGE_SIZE;

    this.exerciseService.activeExercise
      .pipe(
        tap((exercise: Exercise) => (this.exercise = exercise)),
        filter((exercise: Exercise) => !!exercise),
        tap((exercise: Exercise) => {
          this.teams = exercise.blueTeams;
          this.userRole = this.authenticationService.getRole(exercise.id);
        }),
        switchMap((exercise) => {
          if (!this.isAdmin) {
            this.isAdmin = this.authenticationService.currentUser.isGamenetAdmin(this.exercise.id);
          }
          if (this.userRole === this.ROLES.BLUE) {
            return this.exerciseService.getUserBlueTeam(exercise.id).pipe(untilDestroyed(this));
          }

          return of(null);
        }),
        tap((userBlueTeamId) => {
          if (userBlueTeamId != null) {
            this.filterStateService.setFilterIfEmptyOrDefault('team', userBlueTeamId);
          }

          this.loadTaskReports();
        }),
        switchMap(() => this.intervalService.getWidgetRefreshInterval()),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(() => this.loadReportsSilently());

    // TODO: first call to filter needs to be investigated
    this.filter$.pipe(skip(1), untilDestroyed(this)).subscribe(() => {
      this.paginator.firstPage();
      this.loadTaskReports();
    });
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe(() => this.paginator.firstPage());

    this.textFilterFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.firstPage();
          this.loadTaskReports();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadTaskReports()))
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  private loadTaskReports(loadSilently: boolean = false): void {
    if (this.exercise) {
      this.dataSource.loadReports(this.exercise.id, this._createReportFilter(), loadSilently);
    }
  }

  private loadReportsSilently(): void {
    this.loadTaskReports(true);
  }

  private _createReportFilter(): ReportFilter {
    const limit = this.paginator.pageSize || TaskReportsComponent.DEFAULT_LIMIT;
    const { team, pendingConfirmationOnly } = this.filterStateService.snapshot();
    const sortDirection = this.sort.direction;
    const sortOrder = sortDirection != null && !!sortDirection ? sortDirection : null;

    return new ReportFilter({
      offset:
        this.paginator.pageIndex == null
          ? TaskReportsComponent.DEFAULT_OFFSET
          : this.paginator.pageIndex * limit,
      limit: limit,
      unconfirmedOnlyFilter: pendingConfirmationOnly,
      teamIdFilter: team,
      textFilter: this.textFilterFormControl.value,
      sortColumn: sortOrder ? this.sort.active : null,
      sortOrder: sortOrder,
    });
  }

  openTaskReportConfirmDialog(taskReportsData: TaskReportsData): void {
    this.taskConfirmDialogRef = this.dialog.open(TaskReportConfirmDialogComponent, {
      disableClose: false,
      data: {
        taskReportsData: taskReportsData,
        exercise: this.exercise,
      },
    });

    this.taskConfirmDialogRef
      .afterClosed()
      .subscribe((taskConfirmation: TaskReportConfirmation) => {
        if (taskConfirmation) {
          this.loadTaskReports();
        }
        this.taskConfirmDialogRef = null;
      });
  }

  get pageSizes(): number[] {
    return PAGE_SIZES;
  }
}
