<form id="multi-report-form" [formGroup]="form" (ngSubmit)="report()">
  <h2 matDialogTitle>{{ 'ui.attackCampaign.reportAllTeamObjectives' | translate }}</h2>
  <mat-dialog-content>
    <div class="isa-details-box">
      <div class="row">
        <label class="label" for="objective-name">{{
          'ui.attackCampaign.objective' | translate
        }}</label>
        <div id="objective-name" class="disable-uppercase">{{ data.objective.name }}</div>
      </div>
      <div class="row">
        <label class="label">{{ 'ui.attackCampaign.teams' | translate }}</label>
      </div>
    </div>

    <mat-toolbar>
      <button
        type="button"
        mat-raised-button
        (click)="setStatusForAll(OBJECTIVE_STATUS.COMPROMISED)"
        data-testid="set-all-successful-btn"
      >
        {{ 'ui.attackCampaign.setAllSuccessful' | translate }}
      </button>
      <button
        type="button"
        mat-raised-button
        (click)="setStatusForAll(OBJECTIVE_STATUS.UNABLE_TO_COMPROMISE)"
        data-testid="set-all-unsuccessful-btn"
      >
        {{ 'ui.attackCampaign.setAllUnsuccessful' | translate }}
      </button>
    </mat-toolbar>

    <mat-accordion class="isa-content-accordion" multi="false">
      @for (team of filteredTeams; track team) {
        <mat-expansion-panel [formGroupName]="team.teamId">
          <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
            <mat-panel-title class="accordion-title">
              <span class="disable-uppercase">
                <isa-objective-status-icon
                  [status]="team.objectiveStatus"
                ></isa-objective-status-icon>
                {{ team.teamId | isaTeamName }}&nbsp;
              </span>
              @if (team.reportCount > 0) {
                <span class="disable-uppercase">
                  ({{ 'ui.attackCampaign.reportsSubmitted' | translate }}: {{ team.reportCount }})
                </span>
              }
              @if (getStatusCtrl(team.teamId)?.value; as reportStatus) {
                <span class="report-status" data-testid="report-status">
                  {{ getStatusLabel(reportStatus) }}
                  <isa-objective-status-icon [status]="reportStatus"></isa-objective-status-icon>
                </span>
              }
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <mat-radio-group class="status-radio-group" formControlName="status">
              <mat-radio-button
                class="status-radio-button"
                [value]="OBJECTIVE_STATUS.COMPROMISED"
                [attr.data-testid]="'status-radio-compromised'"
              >
                {{ getStatusLabel(OBJECTIVE_STATUS.COMPROMISED) }}
              </mat-radio-button>
              <mat-radio-button
                class="status-radio-button"
                [value]="OBJECTIVE_STATUS.UNABLE_TO_COMPROMISE"
                [attr.data-testid]="'status-radio-unable'"
              >
                {{ getStatusLabel(OBJECTIVE_STATUS.UNABLE_TO_COMPROMISE) }}
              </mat-radio-button>
            </mat-radio-group>
            <quill-editor
              class="evidence-quill-editor"
              formControlName="evidence"
              [placeholder]="'ui.attackCampaign.evidence' | translate"
              (onEditorCreated)="addQuillImageHandler($event)"
            >
            </quill-editor>
          </div>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="status-error" data-testid="footer-error">
      @if (form.invalid && isSubmitted) {
        <mat-error>{{ 'ui.attackCampaign.statusFieldAllTeamsRequired' | translate }}</mat-error>
      }
    </div>
    <div>
      <button mat-raised-button color="primary" type="submit" [disabled]="isSubmitting">
        {{ 'ui.attackCampaign.report' | translate }}
      </button>
      <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
    </div>
  </mat-dialog-actions>
</form>
