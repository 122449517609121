import { Pipe, PipeTransform } from '@angular/core';
import { IntegrationEventType } from '../models/shared/integration-event.model';

@Pipe({
  name: 'isaIntegrationEventTypeName',
})
export class IntegrationEventTypeNamePipe implements PipeTransform {
  transform(value: string) {
    switch (value) {
      case IntegrationEventType.TARGET_STATUS_CHANGE:
        return 'Target status change';
      case IntegrationEventType.EXERCISE_START:
        return 'Exercise start';
      case IntegrationEventType.EXERCISE_END:
        return 'Exercise end';
      case IntegrationEventType.EXERCISE_PROGRESS_UPDATE:
        return 'Exercise progress update';
      case IntegrationEventType.EXERCISE_TEAM_PROGRESS_UPDATE:
        return 'Exercise team progress update';
      default:
        return value;
    }
  }
}
