<div class="situation-report-confirm-dialog">
  <h2 matDialogTitle>{{ 'ui.situationReport.blueSituationReport' | translate }}</h2>
  <form [formGroup]="blueStatusReportForm">
    <mat-dialog-content class="situation-report-confirm-dialog-content">
      <div class="isa-details-box">
        @if (aiFeaturesEnabled && situationReportsData.aiAssessmentStatus) {
          <mat-card class="ai-assessment-results">
            <mat-card-title>
              <mat-icon>android</mat-icon>
              <span
                >{{ 'ui.situationReport.autoAssessSelected' | translate }}:
                {{ situationReportsData.aiAssessmentStatus }}</span
              >
            </mat-card-title>
            @if (situationReportsData.aiGrade != null) {
              <div>
                {{ 'ui.situationReport.grade' | translate }}: {{ situationReportsData.aiGrade }}
              </div>
            }
            @if (situationReportsData.aiFeedback) {
              <div>
                <mat-label>{{ 'ui.situationReport.feedback' | translate }}</mat-label>
                <div class="report-content disable-uppercase">
                  {{ situationReportsData.aiFeedback }}
                </div>
              </div>
            }
          </mat-card>
        }

        @if (hasPermissionToConfirmOrDeny) {
          <div class="row">
            <mat-form-field class="situation-report-confirm-dialog-grade">
              <mat-label>{{ 'ui.situationReport.grade' | translate }}</mat-label>
              <input
                matInput
                autocomplete="off"
                formControlName="grade"
                type="number"
                (keypress)="isNumber($event)"
                (paste)="onPaste($event)"
                min="0"
                max="10"
                required="true"
              />
              @if (blueStatusReportForm.controls['grade'].hasError('required')) {
                <mat-error> {{ 'ui.situationReport.gradeRequiredError' | translate }}</mat-error>
              }
              @if (
                blueStatusReportForm.controls['grade'].hasError('integer') &&
                !blueStatusReportForm.controls['grade'].hasError('required')
              ) {
                <mat-error> {{ 'ui.situationReport.invalidGradeError' | translate }}</mat-error>
              }
            </mat-form-field>
          </div>
        }
        <div class="row">
          <span class="label">{{ 'ui.situationReport.report' | translate }}</span>
          @if (reportDetailsLoaded) {
            <quill-editor
              [ngModel]="reportDetails"
              [ngModelOptions]="{ standalone: true }"
              [readOnly]="true"
              [modules]="{ toolbar: false }"
            ></quill-editor>
          }
          @if (!reportDetailsLoaded) {
            <cybexer-progress-spinner></cybexer-progress-spinner>
          }
        </div>
        <div class="row">
          <span class="label">{{ 'ui.situationReport.time' | translate }}</span>
          {{ situationReportsData.timestamp | isaDate: 'medium' }}
        </div>
        <div class="row">
          <span class="label">{{ 'ui.team' | translate }}</span>
          {{ situationReportsData.teamId | isaTeamName }}
        </div>
      </div>
      @if (showFeedback()) {
        <mat-form-field class="full-width textarea feedback">
          <mat-label>{{ 'ui.situationReport.feedback' | translate }}</mat-label>
          <textarea
            formControlName="feedback"
            matInput
            [cdkTextareaAutosize]
            tabindex="-1"
          ></textarea>
        </mat-form-field>
      }
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      @if (
        hasPermissionToConfirmOrDeny &&
        situationReportsData?.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION
      ) {
        @if (
          aiFeaturesEnabled &&
          (situationReportsData.aiAssessmentStatus == null ||
            situationReportsData.aiAssessmentStatus === ASSESSMENT_STATUS.ASSESSMENT_FAILED)
        ) {
          <button mat-raised-button color="primary" (click)="sendToAIAssessment()">
            <i class="fas fa-magic-wand-sparkles status-ai-icon" aria-hidden="true"></i>
            {{ 'ui.situationReport.autoAssess' | translate }}
          </button>
        }
        <cybexer-confirm-action>
          <button
            class="isa-button-confirm"
            (click)="submitConfirmation()"
            mat-raised-button
            color="primary"
          >
            <i class="fas fa-check-circle mat-button-fa" aria-hidden="true"></i
            >{{ 'ui.confirm' | translate }}
          </button>
        </cybexer-confirm-action>
      }
      <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
    </mat-dialog-actions>
  </form>
</div>
