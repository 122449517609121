import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileInfo } from '../../models';
import { FileMetaInfo } from '../../models/shared/file-info.model';
import { FileUploadInfo } from '../../models/shared/file-upload-info.model';

@Injectable()
export class FileService {
  constructor(private http: HttpClient) {}

  getBlob(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  uploadFileTo(file: File, url: string): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<FileInfo>(url, formData)
      .pipe(map((fileInfo) => `api/files/${fileInfo.id}`));
  }

  getFolderContents(folderId: string): Observable<FileMetaInfo[]> {
    return this.http.get<FileMetaInfo[]>(`api/emi/file/folder-contents/${folderId}`).pipe(
      map((dataArray) => {
        return dataArray.map((data) => new FileMetaInfo(data));
      })
    );
  }

  upload(fileUploadInfo: FileUploadInfo): Promise<FileMetaInfo> {
    const formData = new FormData();
    formData.append('file', fileUploadInfo.file);
    formData.append('offset', fileUploadInfo.offset);
    formData.append('fileType', fileUploadInfo.fileType);
    formData.append('fileSize', fileUploadInfo.fileSize);
    formData.append('fileId', fileUploadInfo.fileId);
    formData.append('customFileName', fileUploadInfo.customFileName);
    formData.append('path', fileUploadInfo.path);
    formData.append('isFolder', String(fileUploadInfo.isFolder));
    formData.append('parentId', String(fileUploadInfo.parentId));

    const fileMetaInfo$ = this.http
      .post('api/emi/file/upload-file', formData)
      .pipe(map((data) => new FileMetaInfo(data)));
    return lastValueFrom(fileMetaInfo$);
  }

  rename(fileId: string, customFileName: string): Observable<FileMetaInfo> {
    const formData = new FormData();
    formData.append('fileId', fileId);
    formData.append('customFileName', customFileName);
    return this.http
      .post('api/emi/file/rename', formData)
      .pipe(map((data) => new FileMetaInfo(data)));
  }

  delete(fileId: string) {
    return this.http.delete(`api/emi/file/delete/${fileId}`).pipe(map(() => true));
  }

  downloadFile(fileId: string, fileName: string): void {
    saveAs(`/api/emi/file/download/${fileId}`, fileName);
  }
}
