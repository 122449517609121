import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  AIFabricPromptOverrideDialogComponent,
  AIFabricService,
  GuacamoleTunnelType,
  NamedEntity,
  NotificationsService,
  PromptType,
} from '@cybexer/ngx-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExerciseOverview } from 'app/models/gamenet/exercise.model';
import { Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { FileInfo } from '../../../models';
import { IsaModule } from '../../../models/shared/isa-module.model';
import {
  ApiToken,
  IAIFabricSettings,
  ILdapSettings,
  IObserverSettings,
  ISettings,
  IVlmSettings,
  LdapGroupMapping,
  ObservableView,
  Settings,
  SigningKey,
} from '../../../models/shared/settings.model';
import {
  AuthenticationService,
  ExerciseService,
  PreferenceService,
  SettingsService,
  TargetCheckAvailabilityService,
} from '../../../services';
import { DefaultSound } from '../../../services/shared/sound.service';
import { CustomValidators, FormUtil } from '../../../shared';
import { OBSERVABLE_VIEWS } from '../../../shared/views-config';
import { ImageRemovalDialogComponent } from './image-removal-dialog/image-removal-dialog.component';
import { UploadImageDialogComponent } from './upload-image-dialog/upload-image-dialog.component';
import { IsaFeature } from '../../../models/shared/features.model';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'isa-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss'],
})
export class GlobalSettingsComponent implements OnInit {
  settingsForm: UntypedFormGroup;
  processing = false;
  recalculatingAvailabilityData?: boolean = null;
  availabilityDataRecalculationSubscription: Subscription;
  lastModified: Date;
  ldapPasswordRequired = true;
  DefaultSound = DefaultSound;
  urlProtocolHost = this.getUrlProtocolHostAndPort();
  exerciseOverviews: ExerciseOverview[];
  customLogoSrc: any;
  customLabelSrc: any;
  removedCustomLogoImageId: string;
  removedCustomLabelImageId: string;
  GuacamoleTunnelType = GuacamoleTunnelType;
  isWhiteLabelEnabled: boolean;
  uploadImageDialogRef: MatDialogRef<UploadImageDialogComponent>;
  imageRemovalDialogRef: MatDialogRef<ImageRemovalDialogComponent>;
  isAIAvailable: boolean = false;

  constructor(
    private notificationsService: NotificationsService,
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService,
    private targetCheckAvailabilityService: TargetCheckAvailabilityService,
    private exerciseService: ExerciseService,
    private aiFabricService: AIFabricService,
    private preferenceService: PreferenceService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.settingsService
      .getSettings(true)
      .pipe(finalize(() => this.cdr.detectChanges()))
      .subscribe((settings) => this.processSettings(settings));

    this.settingsService
      .getEnabledFeatures()
      .pipe(first())
      .subscribe((features) => {
        this.isWhiteLabelEnabled = features.some((feature) => feature == IsaFeature.WHITE_LABEL);
      });

    this.aiFabricService
      .isAvailable()
      .pipe(untilDestroyed(this))
      .subscribe((isAvailable) => {
        this.isAIAvailable =
          isAvailable &&
          this.settingsForm?.value?.aiFabricEnabled &&
          !!this.settingsForm.value.aiFabricSettings.serverUrl &&
          !!this.settingsForm.value.aiFabricSettings.serviceKey;
      });
  }

  private listenToAvailabilityDataCalculationProgressEvents(settings: Settings) {
    if (!settings.isModuleEnabled(IsaModule.GAMENET)) {
      this.unsubscribeFromListeningToAvailabilityDataRecalculationEvents();
      return;
    }

    this.unsubscribeFromListeningToAvailabilityDataRecalculationEvents();
    this.availabilityDataRecalculationSubscription = this.targetCheckAvailabilityService
      .listenForCalculationProgressEvent()
      .pipe(untilDestroyed(this))
      .subscribe((data) => (this.recalculatingAvailabilityData = data.inProgress));
  }

  private unsubscribeFromListeningToAvailabilityDataRecalculationEvents() {
    if (
      this.availabilityDataRecalculationSubscription &&
      !this.availabilityDataRecalculationSubscription.closed
    ) {
      this.availabilityDataRecalculationSubscription.unsubscribe();
      this.availabilityDataRecalculationSubscription = null;
    }
  }

  private processSettings(settings: Settings) {
    this.updateLastModifiedTimestamp(settings.timestamp);
    this.updateLdapSettingsPasswordValidator(settings);
    this.createForm(settings);
    this.getExercises(settings);
    this.listenToAvailabilityDataCalculationProgressEvents(settings);
    this.getImageUrl(settings.customLogoImageId, false);
    this.getImageUrl(settings.customLabelImageId, true);
    this.updateAIStatus();
  }

  private updateAIStatus() {
    this.aiFabricService.updateAvailability({}, `/api${AIFabricService.AI_FABRIC_PATH}-management`);
  }

  private updateLastModifiedTimestamp(timestamp: Date) {
    this.lastModified = timestamp;
  }

  private updateLdapSettingsPasswordValidator(settings: Settings) {
    if (settings.ldapSettings.useSavedPassword) {
      this.ldapPasswordRequired = false;
    }
  }

  private createForm(settings: Settings) {
    this.settingsForm = new UntypedFormGroup({
      gamenetEnabled: new UntypedFormControl(settings.gamenetEnabled),
      observerEnabled: new UntypedFormControl(settings.observerEnabled),
      ldapEnabled: new UntypedFormControl(settings.ldapEnabled),
      vlmEnabled: new UntypedFormControl(settings.vlmEnabled),
      aiFabricEnabled: new UntypedFormControl(settings.aiFabricEnabled),
      gamenetSettings: new UntypedFormGroup({
        soundOnTeamChangeEnabled: new UntypedFormControl(
          settings.gamenetSettings.soundOnTeamChangeEnabled
        ),
        selectedSoundId: new UntypedFormControl(settings.gamenetSettings.selectedSoundId),
        ctfPodiumFirstPlaceSoundEnabled: new UntypedFormControl(
          settings.gamenetSettings.ctfPodiumFirstPlaceSoundEnabled
        ),
        ctfPodiumFirstPlaceSoundId: new UntypedFormControl(
          settings.gamenetSettings.ctfPodiumFirstPlaceSoundId
        ),
        ctfPodiumSecondThirdPlaceSoundEnabled: new UntypedFormControl(
          settings.gamenetSettings.ctfPodiumSecondThirdPlaceSoundEnabled
        ),
        ctfPodiumSecondThirdPlaceSoundId: new UntypedFormControl(
          settings.gamenetSettings.ctfPodiumSecondThirdPlaceSoundId
        ),
        exerciseRefreshInterval: new UntypedFormControl(
          settings.gamenetSettings.exerciseRefreshInterval
        ),
        scoringTimelineWidgetRefreshInterval: new UntypedFormControl(
          settings.gamenetSettings.scoringTimelineWidgetRefreshInterval
        ),
      }),
      ldapSettings: new UntypedFormGroup({
        principal: new UntypedFormControl(settings.ldapSettings.principal),
        password: new UntypedFormControl(''),
        principalSuffix: new UntypedFormControl(settings.ldapSettings.principalSuffix),
        searchBase: new UntypedFormControl(settings.ldapSettings.searchBase),
        urls: new UntypedFormArray(
          settings.ldapSettings.urls.map((url) => new UntypedFormControl(url))
        ),
        groupMappings: new UntypedFormArray(
          settings.ldapSettings.groupMappings.map(
            (mapping) =>
              new UntypedFormGroup({
                role: new UntypedFormControl(mapping.role),
                distinguishedName: new UntypedFormControl(mapping.distinguishedName),
              })
          )
        ),
      }),
      observerSettings: new UntypedFormGroup({
        key: new UntypedFormControl(
          settings.observerSettings.key,
          settings.observerEnabled ? [Validators.required] : null
        ),
        enabledViews: new UntypedFormControl(settings.observerSettings.enabledViews),
        exerciseId: new UntypedFormControl(
          settings.observerSettings.exerciseId,
          settings.observerEnabled ? [Validators.required] : null
        ),
      }),
      vlmSettings: new UntypedFormGroup({
        serverUrl: new UntypedFormControl(settings.vlmSettings.serverUrl),
        clientUrl: new UntypedFormControl(settings.vlmSettings.clientUrl),
        serviceKey: new UntypedFormControl(settings.vlmSettings.serviceKey),
        nativeConsoleProxyEnabled: new UntypedFormControl(
          settings.vlmSettings.nativeConsoleProxyEnabled
        ),
        guacamoleTunnelType: new UntypedFormControl(settings.vlmSettings.guacamoleTunnelType),
        guacamoleTunnelProxyEnabled: new UntypedFormControl(
          settings.vlmSettings.guacamoleTunnelProxyEnabled
        ),
      }),
      aiFabricSettings: new UntypedFormGroup({
        serverUrl: new UntypedFormControl(settings.aiFabricSettings.serverUrl),
        serviceKey: new UntypedFormControl(settings.aiFabricSettings.serviceKey),
      }),
      apiTokens: new FormArray(
        settings.apiTokens.map((apiToken) => this.convertToFormGroup(apiToken))
      ),
      widgetRefreshInterval: new UntypedFormControl(settings.widgetRefreshInterval),
      applicationUrl: new UntypedFormControl(settings.applicationUrl),
      isLightTheme: new UntypedFormControl(settings.isLightTheme),
      customLogoImageId: new UntypedFormControl(settings.customLogoImageId),
      customLabelImageId: new UntypedFormControl(settings.customLabelImageId),
      frontEndSentryDsn: new UntypedFormControl(settings.frontEndSentryDsn),
      experimentalFeaturesEnabled: new UntypedFormControl(settings.experimentalFeaturesEnabled),
      languages: new UntypedFormControl(settings.languages),
      defaultLanguage: new UntypedFormControl(settings.defaultLanguage, [Validators.required]),
      enabledLanguages: new UntypedFormControl(settings.enabledLanguages, [Validators.required]),
    });

    this.settingsForm.get('observerEnabled').valueChanges.subscribe((newValue) => {
      const keyCtrl = this.settingsForm.get('observerSettings.key');
      const exerciseIdCtrl = this.settingsForm.get('observerSettings.exerciseId');

      if (newValue) {
        if (this.exerciseOverviews == null) {
          this.getExerciseOverviews();
        }
        keyCtrl.setValidators([Validators.required]);
        exerciseIdCtrl.setValidators([Validators.required]);
      } else {
        keyCtrl.clearValidators();
        exerciseIdCtrl.clearValidators();
      }

      keyCtrl.updateValueAndValidity();
      exerciseIdCtrl.updateValueAndValidity();
    });
  }

  getExercises(settings: Settings) {
    if (!settings.gamenetEnabled || !settings.observerEnabled) {
      return;
    }

    this.getExerciseOverviews();
  }

  private getExerciseOverviews() {
    this.exerciseService.getExerciseOverviews().subscribe((overviews) => {
      this.exerciseOverviews = overviews;
      const exerciseIdCtrl = this.settingsForm.get('observerSettings.exerciseId');
      if (
        exerciseIdCtrl.value != null &&
        this.exerciseOverviews.find((it) => it.id === exerciseIdCtrl.value) == null
      ) {
        exerciseIdCtrl.setValue(null);
        exerciseIdCtrl.setValidators([Validators.required]);
        exerciseIdCtrl.updateValueAndValidity();
      }
    });
  }

  getImageUrl(imageId: string, isLabel: boolean): void {
    if (!imageId) {
      return;
    }

    this.settingsService.getImageUrl(imageId).subscribe((imageUrl) => {
      if (isLabel) {
        this.customLabelSrc = imageUrl;
      } else {
        this.customLogoSrc = imageUrl;
      }
    });
  }

  get observableViews(): ObservableView[] {
    const selectedExerciseId = this.getObserverSelectedExercise();
    if (this.exerciseOverviews == null) {
      return [];
    }

    const selectedExerciseOverview = this.exerciseOverviews.find(
      (overview) => overview.id === selectedExerciseId
    );
    if (selectedExerciseOverview == null) {
      return [];
    }

    return OBSERVABLE_VIEWS.filter((view) =>
      view.supportedExerciseTypes.some(
        (exerciseType) => exerciseType === selectedExerciseOverview.type
      )
    );
  }

  onSoundSelect(soundId: string, formControlName: string) {
    this.settingsForm.get(formControlName).setValue(soundId);
  }

  save(): void {
    if (
      !this.settingsForm
        .get('enabledLanguages')
        .value.includes(this.settingsForm.get('defaultLanguage').value)
    ) {
      this.settingsForm.get('defaultLanguage').setValue('');
      this.notificationsService.error('ui.settings.defaultLanguageError');
      return;
    }
    if (!this.settingsForm.valid) {
      FormUtil.markFormControlAsTouchedRecursively(this.settingsForm);
      return;
    }
    this.processing = true;
    const settingsDTO = this.constructSettingsSaveDTO();
    this.settingsForm.disable();
    this.settingsService
      .saveSettings(settingsDTO)
      .pipe(
        finalize(() => {
          this.processing = false;
          this.settingsForm.enable();
        })
      )
      .subscribe({
        next: (updatedSettings) => {
          this.updateLastModifiedTimestamp(updatedSettings.timestamp);
          this.updateLdapSettingsPasswordValidator(updatedSettings);
          this.listenToAvailabilityDataCalculationProgressEvents(updatedSettings);
          this.settingsForm.get('ldapSettings.password').setValue('');
          this.deleteCustomImage(this.removedCustomLogoImageId, false);
          this.deleteCustomImage(this.removedCustomLabelImageId, true);
          this.updateAIStatus();
          this.notificationsService.success('ui.settings.settingsSaved');
        },
        error: () => this.notificationsService.error('ui.settings.settingsSaveError'),
      });
  }

  private constructSettingsSaveDTO(): ISettings {
    const formValues = this.settingsForm.value;
    return {
      gamenetEnabled: formValues.gamenetEnabled,
      observerEnabled: formValues.observerEnabled,
      ldapEnabled: formValues.ldapEnabled,
      vlmEnabled: formValues.vlmEnabled,
      aiFabricEnabled: formValues.aiFabricEnabled,
      gamenetSettings: {
        soundOnTeamChangeEnabled: formValues.gamenetSettings.soundOnTeamChangeEnabled,
        selectedSoundId: formValues.gamenetSettings.selectedSoundId,
        ctfPodiumFirstPlaceSoundEnabled: formValues.gamenetSettings.ctfPodiumFirstPlaceSoundEnabled,
        ctfPodiumFirstPlaceSoundId: formValues.gamenetSettings.ctfPodiumFirstPlaceSoundId,
        ctfPodiumSecondThirdPlaceSoundEnabled:
          formValues.gamenetSettings.ctfPodiumSecondThirdPlaceSoundEnabled,
        ctfPodiumSecondThirdPlaceSoundId:
          formValues.gamenetSettings.ctfPodiumSecondThirdPlaceSoundId,
        exerciseRefreshInterval: formValues.gamenetSettings.exerciseRefreshInterval,
        scoringTimelineWidgetRefreshInterval:
          formValues.gamenetSettings.scoringTimelineWidgetRefreshInterval,
      },
      ldapSettings: GlobalSettingsComponent.constructLdapSettingsDTO(formValues.ldapSettings),
      observerSettings: GlobalSettingsComponent.constructObserverSettingsDTO(
        formValues.observerSettings
      ),
      vlmSettings: GlobalSettingsComponent.constructVlmSettingsDTO(formValues.vlmSettings),
      aiFabricSettings: GlobalSettingsComponent.constructAIFabricSettingsDTO(
        formValues.aiFabricSettings
      ),
      apiTokens: formValues.apiTokens,
      widgetRefreshInterval: formValues.widgetRefreshInterval,
      applicationUrl: formValues.applicationUrl,
      isLightTheme: formValues.isLightTheme,
      customLogoImageId: formValues.customLogoImageId,
      customLabelImageId: formValues.customLabelImageId,
      frontEndSentryDsn: formValues.frontEndSentryDsn,
      experimentalFeaturesEnabled: formValues.experimentalFeaturesEnabled,
      languages: formValues.languages,
      enabledLanguages: formValues.enabledLanguages,
      defaultLanguage: formValues.defaultLanguage,
    } as ISettings;
  }

  clearAuthorizationCache() {
    this.authenticationService.clearAuthorizationCache().subscribe(() => {
      this.notificationsService.success('ui.settings.authorizationCacheSuccess');
    });
  }

  recalculateAvailabilityData() {
    this.recalculatingAvailabilityData = true;
    this.targetCheckAvailabilityService
      .recalculateData()
      .pipe(finalize(() => (this.recalculatingAvailabilityData = false)))
      .subscribe({
        next: () => this.notificationsService.success('ui.settings.recalculationSuccess'),
        error: () => this.notificationsService.error('ui.settings.recalculationError'),
      });
  }

  get apiTokenFormArray() {
    return this.settingsForm.get('apiTokens') as FormArray;
  }

  addApiToken = () => {
    const apiTokens = this.apiTokenFormArray;
    this.settingsService.generateToken().subscribe((apiToken) => {
      apiTokens.push(this.convertToFormGroup(apiToken));
    });
  };

  isSingingKeyDefined(index: number): boolean {
    const apiTokens = this.apiTokenFormArray;
    const apiToken = apiTokens.at(index);

    return apiToken.get('signingKey') != null;
  }

  addSigningKey = (apiTokenIndex: number) => {
    const apiTokens = this.apiTokenFormArray;
    const apiToken = apiTokens.at(apiTokenIndex) as UntypedFormGroup;

    this.settingsService.generateTokenSigningKey().subscribe((signingKey) => {
      apiToken.setControl(
        'signingKey',
        GlobalSettingsComponent.convertToSigningKeyFormGroup(signingKey)
      );
    });
  };

  removeSigningKey(apiTokenIndex: number) {
    const apiTokens = this.apiTokenFormArray;
    (apiTokens.at(apiTokenIndex) as UntypedFormGroup).removeControl('signingKey');
  }

  removeApiToken(index: number) {
    const apiTokens = this.apiTokenFormArray;
    apiTokens.removeAt(index);
  }

  addLdapUrl(): void {
    this.ldapUrls.push(new UntypedFormControl(''));
  }

  get ldapUrls(): UntypedFormArray {
    return this.settingsForm.get('ldapSettings.urls') as UntypedFormArray;
  }

  removeLdapUrl(index: number): void {
    this.ldapUrls.removeAt(index);
  }

  addLdapGroupMapping(): void {
    this.ldapGroupMappings.push(
      new UntypedFormGroup({
        role: new UntypedFormControl(''),
        distinguishedName: new UntypedFormControl(''),
      })
    );
  }

  get ldapGroupMappings(): UntypedFormArray {
    return this.settingsForm.get('ldapSettings.groupMappings') as UntypedFormArray;
  }

  removeLdapGroupMapping(index: number): void {
    this.ldapGroupMappings.removeAt(index);
  }

  convertToFormGroup(apiToken: ApiToken): FormGroup {
    const formGroup = new UntypedFormGroup({
      name: new FormControl(apiToken.name, CustomValidators.notBlank()),
      value: new FormControl(apiToken.value),
      timestamp: new FormControl(apiToken.timestamp),
    });

    if (apiToken.signingKey) {
      formGroup.setControl(
        'signingKey',
        GlobalSettingsComponent.convertToSigningKeyFormGroup(apiToken.signingKey)
      );
    }

    return formGroup;
  }

  resetObserverKey(event: Event) {
    event.stopPropagation();
    this.settingsForm
      .get('observerSettings.key')
      .setValue(GlobalSettingsComponent.generateObserverKey());
  }

  copyViewUrl(view: { name: string; path: string }): void {
    const url =
      this.urlProtocolHost +
      view.path +
      ';ok=' +
      this.settingsForm.get('observerSettings.key').value;
    const textareaEl = document.createElement('textarea');
    textareaEl.style.position = 'fixed';
    textareaEl.style.left = '0';
    textareaEl.style.top = '0';
    textareaEl.style.opacity = '0';
    textareaEl.value = url;
    document.body.appendChild(textareaEl);
    textareaEl.focus();
    textareaEl.select();
    document.execCommand('copy');
    document.body.removeChild(textareaEl);
    this.notificationsService.info(
      `${view.name} ${this.translate.instant('ui.settings.urlCopiedToClipboard')}`
    );
  }

  getUrlProtocolHostAndPort(): string {
    const windowLocation = this.getWindowLocation();
    const port = windowLocation.port;
    const portPart = port !== '80' && port !== '443' ? `:${port}` : '';
    return `${windowLocation.protocol}//${windowLocation.hostname}${portPart}`;
  }

  getWindowLocation(): Location {
    return window.location;
  }

  isDefaultExerciseSelected(): boolean {
    const observerSelectedExerciseId = this.getObserverSelectedExercise();
    return (
      observerSelectedExerciseId != null &&
      this.exerciseOverviews != null &&
      this.exerciseOverviews.find((it) => it.id === observerSelectedExerciseId) != null
    );
  }

  private getObserverSelectedExercise(): string {
    return this.settingsForm.get('observerSettings.exerciseId').value;
  }

  openUploadImageDialog(isLabel: boolean): void {
    this.uploadImageDialogRef = this.dialog.open(UploadImageDialogComponent);
    this.uploadImageDialogRef.afterClosed().subscribe((imageFileInfo: FileInfo) => {
      this.setCustomImage(imageFileInfo, isLabel);
      this.uploadImageDialogRef = null;
    });
  }

  setCustomImage(imageFileInfo: FileInfo, isLabel: boolean) {
    if (imageFileInfo) {
      if (isLabel) {
        this.removedCustomLabelImageId = null;
        this.settingsForm.get('customLabelImageId').setValue(imageFileInfo.id);
      } else {
        this.removedCustomLogoImageId = null;
        this.settingsForm.get('customLogoImageId').setValue(imageFileInfo.id);
      }
      this.getImageUrl(imageFileInfo.id, isLabel);
    }
  }

  openImageRemovalDialog(isLabel: boolean) {
    const customImageId = isLabel
      ? this.settingsForm.get('customLabelImageId')
      : this.settingsForm.get('customLogoImageId');
    if (customImageId.value == null) {
      return;
    }

    this.imageRemovalDialogRef = this.dialog.open(ImageRemovalDialogComponent, {
      disableClose: false,
      panelClass: 'removal-dialog-container',
    });

    this.imageRemovalDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.removedCustomLogoImageId = customImageId.value;
        customImageId.setValue(null);
        isLabel ? (this.customLabelSrc = null) : (this.customLogoSrc = null);
      }
      this.imageRemovalDialogRef = null;
    });
  }

  deleteCustomImage(imageId: string, isLabel: boolean) {
    if (imageId == null) {
      return;
    }

    this.settingsService.deleteImage(imageId).subscribe(() => {
      isLabel ? (this.removedCustomLabelImageId = null) : (this.removedCustomLogoImageId = null);
    });
  }

  openAIFabricPromptOverrideDialog() {
    this.dialog.open(AIFabricPromptOverrideDialogComponent, {
      data: {
        aiFabricPromptOverrideService: this.aiFabricService,
        isLightTheme$: this.preferenceService.isLightTheme.asObservable(),
        currentPreferenceOverrideId: this.preferenceService.currentPreferences?.promptOverride?.id,
        onPreferenceOverride: (selectedPromptOverride: NamedEntity) => {
          this.preferenceService
            .updatePreferences({
              promptOverride: selectedPromptOverride || null,
            })
            .pipe(first())
            .subscribe();
        },
        isExerciseAdmin: true,
        editablePromptIds: [
          PromptType.SITUATION_REPORT_ASSESSMENT,
          PromptType.INCIDENT_REPORT_ASSESSMENT,
          PromptType.ATTACK_REPORT_ASSESSMENT,
        ],
      },
    });
  }

  disableSort(): number {
    return 0;
  }

  private static generateObserverKey(): string {
    const length = 16;
    const charset = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += charset[Math.floor(Math.random() * charset.length)];
    }
    return result;
  }

  private static constructLdapSettingsDTO(formValues: any): ILdapSettings {
    return {
      principal: formValues.principal,
      password: formValues.password,
      principalSuffix: formValues.principalSuffix,
      searchBase: formValues.searchBase,
      urls: formValues.urls,
      groupMappings: formValues.groupMappings.map(
        (groupMappingValues) => new LdapGroupMapping(groupMappingValues)
      ),
      useSavedPassword: formValues.password.trim() === '',
    };
  }

  private static constructVlmSettingsDTO(formValues: any): IVlmSettings {
    return {
      serverUrl: formValues.serverUrl,
      clientUrl: formValues.clientUrl !== '' ? formValues.clientUrl : null,
      serviceKey: formValues.serviceKey,
      nativeConsoleProxyEnabled: formValues.nativeConsoleProxyEnabled,
      guacamoleTunnelType: formValues.guacamoleTunnelType,
      guacamoleTunnelProxyEnabled: formValues.guacamoleTunnelProxyEnabled,
    };
  }

  private static constructAIFabricSettingsDTO(formValues: any): IAIFabricSettings {
    return {
      serverUrl: formValues.serverUrl,
      serviceKey: formValues.serviceKey,
    };
  }

  private static constructObserverSettingsDTO(formValues: any): IObserverSettings {
    return {
      key: formValues.key,
      enabledViews: formValues.enabledViews,
      exerciseId: formValues.exerciseId,
    };
  }

  private static convertToSigningKeyFormGroup(signingKey: SigningKey): UntypedFormGroup {
    return new UntypedFormGroup({
      value: new UntypedFormControl(signingKey.value),
      timestamp: new UntypedFormControl(signingKey.timestamp),
    });
  }
}
