import { Pipe, PipeTransform } from '@angular/core';
import { CTFTaskStatus } from '../../models';

@Pipe({
  name: 'taskStatusText',
})
export class TaskStatusTextPipe implements PipeTransform {
  transform(status: CTFTaskStatus) {
    switch (status) {
      case CTFTaskStatus.IN_PROGRESS:
      case CTFTaskStatus.ATTACK_ABANDONED:
        return 'ui.open';
      case CTFTaskStatus.NOT_STARTED:
        return 'ui.notStarted';
      case CTFTaskStatus.ABANDONED:
        return 'ui.onHold';
      case CTFTaskStatus.SOLVED:
        return 'ui.solved';
      case CTFTaskStatus.PARTLY_SOLVED:
        return 'ui.partlySolved';
      case CTFTaskStatus.DEPENDENCIES_UNSOLVED:
      case CTFTaskStatus.LOCKED:
        return 'ui.locked';
      case CTFTaskStatus.VALIDATING:
        return 'ui.validating';
      case CTFTaskStatus.ATTACK_IN_PROGRESS:
        return 'ui.activeAttack';
      default:
        return 'ui.unknown';
    }
  }
}
