import { Injectable } from '@angular/core';
import { Settings } from '../models/shared/settings.model';

@Injectable({
  providedIn: 'root',
})
export class LanguageUtils {
  static STORAGE_KEYS = {
    LANG: 'ch-lang',
    DEFAULT_LANG: 'ch-default-lang',
  };

  static NAMESPACES = ['ui+tag'];

  getLanguage(): string {
    return LanguageUtils.getLanguage();
  }

  getISO639_1ToNativeLanguageMap() {
    return {
      en: 'English',
      ja: '日本語',
    };
  }

  convertISO639_1ToNativeLanguage(lang: string): string {
    const mappings: any = this.getISO639_1ToNativeLanguageMap();
    const lowerCaseLang: string = lang.toLowerCase();
    if (mappings[lowerCaseLang]) {
      return mappings[lowerCaseLang];
    }
    throw Error(`${lang} is not a supported language!`);
  }

  setLang(lang: string): void {
    this.setLangInStorage(LanguageUtils.STORAGE_KEYS.LANG, lang);
  }

  private setDefaultLang(lang: string): void {
    this.setLangInStorage(LanguageUtils.STORAGE_KEYS.DEFAULT_LANG, lang);
  }

  private setLangInStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  filterLanguages(enabledLanguages: string[]) {
    const activeLanguageIndex = enabledLanguages.indexOf(this.getLanguage());
    return enabledLanguages.filter(
      (language: string) => language !== enabledLanguages[activeLanguageIndex]
    );
  }

  updateLanguageSettings(settings: Settings): void {
    this.setDefaultLang(settings.defaultLanguage);

    const selectedLanguage = this.getLanguage();
    if (settings.languages.indexOf(selectedLanguage) === -1) {
      this.setLang(settings.languages[0]);
    }
  }

  static getLanguage(): string {
    return (
      localStorage.getItem(LanguageUtils.STORAGE_KEYS.LANG) ||
      localStorage.getItem(LanguageUtils.STORAGE_KEYS.DEFAULT_LANG) ||
      'EN'
    );
  }
}
