<form [formGroup]="assignForm" (ngSubmit)="onSubmit(assignForm)">
  <h2 matDialogTitle>Reassign objective to me</h2>
  <mat-dialog-content>
    <div class="isa-details-box">
      <div class="row">
        <label class="label" for="objective-name">Objective</label>
        <div id="objective-name" class="disable-uppercase">{{ objective.name }}</div>
      </div>
      <div class="row">
        <label class="label" for="team-name">Team</label>
        <div id="team-name" class="disable-uppercase">{{ team.teamId | isaTeamName }}</div>
      </div>
      <div class="row">
        <label class="label" for="assignee">Assignee</label>
        <div id="assignee" class="disable-uppercase">{{ team.objectiveUserName }}</div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button mat-raised-button color="primary" type="submit">Reassign</button>
      <button mat-button matDialogClose>Cancel</button>
    </div>
  </mat-dialog-actions>
</form>
